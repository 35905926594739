import React, { useState, useEffect, useRef } from 'react'
import {
  Container,
  Table,
  Button,
  Navbar
} from 'reactstrap'
import { Editor, NumberDisplay, UnderConstruction } from 'components'
import { showAlert, showSuccess } from 'utils/toast'
import dayjs from 'dayjs'
import { Loader } from 'components'
import { fetchEggHistory } from 'api/eggHistoryApi'
import colors from 'assets/colors'
import { useParams } from 'react-router-dom'
import UserBadge from 'components/UserBadge'
import { betStatuses, betOutcomes } from 'libs/translations'


const renderHistory = (history) => {
  if (history.classification === '베팅') {

    if (history.ref && history.ref.betting) {
      const { betting } = history?.ref

      if (betting) {

        return (
          <Table bordered style={{ marginTop: '2rem' }}>
            <tbody className='align-items-center'>
              <tr>
                <td className='form-header text-center w-200p'>
                  분류
                </td>
                <td >
                  베팅
                </td>
                <td className='form-header text-center w-200p'>
                  아이디/닉네임
                </td>
                <td >
                  <UserBadge user={betting?.user} />
                </td>
              </tr>
              <tr>
                <td className='form-header text-center w-200p'>
                  게임사/분류
                </td>
                <td >
                  {betting?.provider}/{betting?.category}
                </td>
                <td className='form-header text-center w-200p'>
                  게임명
                </td>
                <td >
                  {betting?.provider === 'pmgame' ?
                    '-'
                    : betting?.detail?.gameName
                  }
                </td>
              </tr>
              <tr>
                <td className='form-header text-center w-200p'>
                  베팅시간
                </td>
                <td >
                  {betting?.provider === 'pmgame' ?
                    dayjs(betting?.detail?.createTime).format('YYYY-MM-DD HH:mm:ss')
                    : dayjs(betting?.createdAt).format('YYYY-MM-DD HH:mm:ss')
                  }
                </td>
                <td className='form-header text-center'>
                  베팅금액
                </td>
                <td >
                  <NumberDisplay
                    value={betting?.betAmount}
                    suffix="원"
                  />
                </td>
              </tr>
              <tr>
                <td className='form-header text-center w-200p'>
                  당첨금
                </td>
                <td >
                  <NumberDisplay
                    showPrefix
                    useNegativeColor
                    usePositiveColor
                    value={betting?.resultAmount - betting?.betAmount}
                    suffix="원"
                  />
                </td>
                <td className='form-header text-center w-200p'>
                  최종정산금
                </td>
                <td >

                  <NumberDisplay
                    showPrefix
                    value={betting?.resultAmount}
                    suffix="원"
                  />
                </td>
              </tr>
            </tbody>
          </Table>
        )

      }

    }
  }

  return <UnderConstruction />
}

function EggHistoryView(props) {
  // const { id } = useParams()
  const id = props?.targetId ?? null
  const editorRef = useRef()
  const [isLoading, setIsLoading] = useState(false)
  const [error, setError] = useState(null)
  const [eggHistory, setEggHistory] = useState({
    name: '',
    inquiry: '',
    answer: ''
  })

  useEffect(() => { }, [])

  const fetchData = async () => {
    setIsLoading(true)
    try {
      const { success, data, message } = await fetchEggHistory(id)

      if (!success) throw new Error(message)
      if (data) {
        setEggHistory(data)
      }
      setError(null)
    } catch (error) {
      console.error(error)
      setError(error)
    } finally {
      setIsLoading(false)
    }
  }

  useEffect(() => {
    fetchData()
  }, [])

  console.log(eggHistory)

  return (
    <>
      <Navbar
        style={{ backgroundColor: colors.primary, padding: '1rem' }}
        expand='md'
      >
        <h5 className='m-0'>
          {eggHistory?.user?.username}({eggHistory?.user?.nickname})의 {dayjs(eggHistory?.createdAt).format('YY/MM/DD HH:mm:ss')} {'알 기록 상세'}
        </h5>{' '}
        {/* Use the label based on the active tab */}
      </Navbar>

      <Container className='page-content' style={{ paddingBottom: 100 }}>
        {isLoading ? (
          <Loader />
        ) : renderHistory(eggHistory)}

        {/* Action bar */}
        {/* <div className={`action-bar-container show`}>
          <div className='action-bar'>
            <div className='left-actions'>
              <Button
                color='warning'
                onClick={() => window.close()}
              >
                닫기
              </Button>
            </div>
          </div>
        </div> */}
      </Container>
    </>
  )
}

export default EggHistoryView
