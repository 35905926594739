import React, { lazy, useState, Suspense } from 'react'
import './Referral.css'
import { Layout } from 'components'
import {
  Nav,
  NavItem,
  NavLink,
  TabContent,
  TabPane,
  Row,
  Container
} from 'reactstrap'

const ReferralList = lazy(() => import('./ReferralList'))
const ReferralSetting = lazy(() => import('./ReferralSetting'))

function Referral (props) {
  const [activeTab, setActiveTab] = useState('tab1')

  const toggleTab = tab => {
    if (activeTab !== tab) setActiveTab(tab)
  }
  return (
    <Layout>
      <Container className='page-content' fluid style={{ padding: 30 }}>
        <Row>
          <Nav tabs>
            <NavItem>
              <NavLink
                className={activeTab === 'tab1' ? 'sub-link active' : ''}
                onClick={() => toggleTab('tab1')}
              >
                추천코드
              </NavLink>
            </NavItem>
          </Nav>
        </Row>
        <Row>
          <TabContent activeTab={activeTab}>
            {activeTab === 'tab1' && (
              <TabPane tabId='tab1'>
                <Suspense fallback={<div>Loading...</div>}>
                  <ReferralList />
                </Suspense>
              </TabPane>
            )}
          </TabContent>
        </Row>
      </Container>
    </Layout>
  )
}

export default Referral
