import React, { useCallback, useContext, useEffect } from "react";
import {
  Modal,
  Button,
  Navbar,
  NavItem,
  NavbarToggler,
  Collapse,
  NavLink,
  Nav,
  NavbarBrand,
  UncontrolledDropdown,
  DropdownToggle,
  DropdownMenu,
  DropdownItem,
  Badge,
  Container,
} from "reactstrap";
import { MdClose } from "react-icons/md";
import colors from "assets/colors";
import "./Header.css";
import { useAuth } from "AuthContext";
import { useLocation } from "react-router-dom";
import { useSelector, useDispatch } from "react-redux";
import { userSelector } from "features/auth/authSelectors";
import { statusSelector } from "features/status/statusSelectors";
import { useNavigate } from "react-router-dom";
import NumberDisplay from "components/NumberDisplay";
import PartnerWithdrawalModal from "pages/PartnerWithdrawal/PartnerWithdrawalModal";
import { metaSelector } from "features/meta/metaSelectors";
import MemberProfileModal from "pages/Member/MemberProfileModal";
import { statusThunk } from "thunks/statusThunks";
import MemberWalletHistoryList from "pages/WalletHistory/WalletHistoryList";
// import axios from 'axios'
// import { toast } from 'react-toastify'
// import EventAlarm from 'components/EventAlarm'
import MailCreate from "pages/Mail/MailCreate";
import defaultAlarmSound from "assets/alarm/alarm-example.mp3";
import {
  alarmSelector,
  siteConfigSelector,
} from "features/config/configSelectors";
import { playAlarmSound, speakText } from "utils/audio";
import PartnerDepositModal from "pages/PartnerDeposit/PartnerDepositModal";
import PartnerPointModal from "pages/PartnerPoint/PartnerPointModal";
import { getPublicUrl } from "libs/functions";

const PUBLIC_URL = getPublicUrl();

function Header(props) {
  const config = useSelector(alarmSelector);
  const siteConfig = useSelector(siteConfigSelector);
  const { onLogout } = useAuth();
  const { meta } = useSelector(metaSelector);
  const currentUser = useSelector(userSelector);
  const status = useSelector(statusSelector);
  const dispatch = useDispatch();
  const location = useLocation();
  const navigate = useNavigate();

  const [isOpen, setIsOpen] = React.useState(false);
  const [isWithdrawal, setIsWithdrawal] = React.useState(false);
  const [isPoint, setIsPoint] = React.useState(false);
  const [isDeposit, setIsDeposit] = React.useState(false);
  const [isProfile, setIsProfile] = React.useState(false);
  const [isWallet, setIsWallet] = React.useState(false);
  const [isMail, setIsMail] = React.useState(false);

  const { isRoot, isHeadQuarter, isPartner, isSubPartner, isAdmin } =
    currentUser;

  const {
    deposits,
    withdrawals,
    users,
    mails,
    transfers,
    helpCenters,
    session,
    eggs,
  } = status?.data ?? {};

  const menuItems = isRoot
    ? [
        { label: "종합현황", link: "/" },
        { label: "사이트관리", link: "/site-config" },
        { label: "입금", link: "/deposit", count: deposits },
        { label: "출금", link: "/withdrawal", count: withdrawals },
        // { label: '포인트전환', link: '/transfer' },
        // { label: '파트너출금', link: '/partner-withdrawal' },
        { label: "회원", link: "/member", count: users },
        { label: "지급/회수", link: "/partner/member" },
        { label: "파트너", link: "/partner" },
        { label: "관리자", link: "/admin" },
        { label: "배팅내역", link: "/bet" },
        { label: "로스", link: "/loss" },
        { label: "쿠폰", link: "/coupon" },
        { label: "추천코드", link: "/referral" },
        { label: "쪽지", link: "/mail", count: mails },
        { label: "고객센터", link: "/help-center" },
        { label: "기록", link: "/history" },
        // { label: '정산', link: '/adjustment' },
        { label: "설정", link: "/setting" },
        { label: "API", link: "/api" },
        { label: "DEV", link: "/development" },
      ]
    : isHeadQuarter || isAdmin
    ? [
        { label: "종합현황", link: "/" },
        { label: "사이트관리", link: "/site-config" },
        { label: "입금", link: "/deposit", count: deposits },
        { label: "출금", link: "/withdrawal", count: withdrawals },
        // { label: '포인트전환', link: '/transfer' },
        // { label: '파트너출금', link: '/partner-withdrawal' },
        { label: "회원", link: "/member", count: users },
        { label: "지급/회수", link: "/partner/member" },
        { label: "파트너", link: "/partner" },
        { label: "관리자", link: "/admin" },
        { label: "배팅내역", link: "/bet" },
        { label: "로스", link: "/loss" },
        { label: "쿠폰", link: "/coupon" },
        { label: "추천코드", link: "/referral" },
        { label: "쪽지", link: "/mail", count: mails },
        { label: "고객센터", link: "/help-center" },
        { label: "기록", link: "/history" },
        // { label: '정산', link: '/adjustment' },
        { label: "설정", link: "/setting" },
      ]
    : isPartner
    ? [
        { label: "종합현황", link: "/" },
        ...(!isSubPartner
          ? [{ label: "사이트관리", link: "/site-config" }]
          : []),
        { label: "회원", link: "/member" },
        { label: "지급/회수", link: "/partner/member" },
        { label: "파트너", link: "/partner" },
        { label: "입금내역", link: "/deposit", count: deposits },
        { label: "출금내역", link: "/withdrawal", count: withdrawals },
        { label: "배팅내역", link: "/bet" },
        { label: "쪽지", link: "/mail", count: mails },
        { label: "기록", link: "/history" },
        { label: "입금요청", link: "", onClick: () => setIsDeposit(true) },
        { label: "출금요청", link: "", onClick: () => setIsWithdrawal(true) },
        { label: "포인트전환", link: "", onClick: () => setIsPoint(true) },
      ]
    : [];

  useEffect(() => {}, [dispatch]);

  useEffect(() => {
    //set interval to playAlarmSound until deposits, withdrawas and helpcenters are 0
    const interval = setInterval(() => {
      //if localhost, return
      if (window.location.hostname === "localhost") return;

      if (deposits > 0 || withdrawals > 0 || helpCenters > 0 || mails > 0) {
        playAlarmSound(defaultAlarmSound);
        dispatch(statusThunk());
      }
    }, 5000);
    return () => clearInterval(interval);
  }, [dispatch, deposits, withdrawals, helpCenters, mails]);

  return (
    <>
      <div
        style={{
          display: "block",
          background: colors.primary,
          width: "100%",
          padding: "0 10px",
        }}
      >
        <Navbar style={{ backgroundColor: colors.primary }} expand="md">
          <NavbarBrand style={{ color: colors.white, width: 150 }} href="/">
            <img
              // src={`/logo-${meta?.domain}.png`}
              src={
                siteConfig?.general?.logo
                  ? `${PUBLIC_URL}/${siteConfig?.general?.logo}`
                  : `/logo-${meta?.domain}.png`
              }
              alt="logo"
              style={{ width: "100%" }}
            />
          </NavbarBrand>
          <NavbarToggler
            onClick={() => {
              setIsOpen(!isOpen);
            }}
          />
          {
            isPartner && (
              <div className="d-flex justify-content-between align-items-center gap-2 py-1">
                <Button color="primary" onClick={() => setIsWallet(true)}>
                  보유머니 변동내역
                </Button>
                <Button color="success" onClick={() => setIsDeposit(true)}>
                  입금요청
                </Button>
                <Button color="warning" onClick={() => setIsWithdrawal(true)}>
                  출금요청
                </Button>
                <Button color="info" onClick={() => setIsPoint(true)}>
                  포인트전환
                </Button>
                <Button color="danger" onClick={() => setIsMail(true)}>
                  1:1상담작성
                </Button>
              </div>
            )
          }

          <Collapse isOpen={isOpen} navbar>
            <Nav className="ms-auto align-items-center" navbar>
              {(currentUser.isRoot || currentUser.isHeadQuarter) && (
                <NavItem className={"status-nav-item"}>
                  <span className="nav-link-header">접속현황</span>
                  <div
                    className="status-nav-link-container"
                    style={{ gridTemplateColumns: "repeat(4, 1fr)" }}
                  >
                    <div>
                      <Badge pill color="primary" className="status-badge">
                        +{session?.admin ?? 0}
                      </Badge>
                      <span
                        className="status-text"
                        onClick={() => {
                          navigate("/admin", {
                            state: {
                              params: {
                                session: "on",
                                startDate: "",
                                endDate: "",
                              },
                            },
                            replace: false,
                          });
                        }}
                      >
                        관리자
                      </span>
                    </div>
                    <div>
                      <Badge pill color="primary" className="status-badge">
                        +{session?.partner ?? 0}
                      </Badge>
                      <span
                        className="status-text"
                        onClick={() => {
                          navigate("/partner?tab=parterList", {
                            state: {
                              params: {
                                session: "on",
                                startDate: "",
                                endDate: "",
                              },
                            },
                            replace: false,
                          });
                        }}
                      >
                        파트너
                      </span>
                    </div>
                    <div>
                      <Badge pill color="primary" className="status-badge">
                        +{session?.activeMember ?? 0}
                      </Badge>
                      <span
                        className="status-text"
                        onClick={() => {
                          navigate("/member?tab=memberList", {
                            state: {
                              params: {
                                session: "on",
                                category: "",
                                startDate: "",
                                endDate: "",
                              },
                            },
                            replace: false,
                          });
                        }}
                      >
                        실회원
                      </span>
                    </div>
                    <div>
                      <Badge pill color="primary" className="status-badge">
                        +{session?.cautionMember ?? 0}
                      </Badge>
                      <span
                        className="status-text"
                        onClick={() => {
                          navigate("/member?tab=memberList", {
                            state: {
                              params: {
                                session: "on",
                                category: "caution",
                                startDate: "",
                                endDate: "",
                              },
                            },
                            replace: false,
                          });
                        }}
                      >
                        주의회원
                      </span>
                    </div>
                  </div>
                </NavItem>
              )}

              {(currentUser.isRoot || currentUser.isHeadQuarter) && (
                <NavItem className={"status-nav-item"}>
                  <span className="nav-link-header">대기현황</span>

                  <div
                    className="status-nav-link-container"
                    style={{ gridTemplateColumns: "repeat(5, 1fr)" }}
                  >
                    <div>
                      <span
                        className="status-text"
                        onClick={() => {
                          navigate("/deposit", {
                            state: {
                              params: {
                                status: "pending",
                                startDate: "",
                                endDate: "",
                              },
                            },
                            replace: true,
                          });
                        }}
                      >
                        입금대기
                      </span>
                      <Badge pill color="primary" className="status-badge">
                        +{deposits ?? 0}
                      </Badge>
                    </div>
                    <div>
                      <span
                        className="status-text"
                        onClick={() => {
                          navigate("/withdrawal", {
                            state: {
                              params: {
                                status: "pending",
                                startDate: "",
                                endDate: "",
                              },
                            },
                            replace: true,
                          });
                        }}
                      >
                        출금대기
                      </span>
                      <Badge pill color="primary" className="status-badge">
                        +{withdrawals ?? 0}
                      </Badge>
                    </div>
                    <div>
                      <span
                        className="status-text"
                        onClick={() => {
                          navigate("/transfer", {
                            state: {
                              params: {
                                status: "pending",
                                startDate: "",
                                endDate: "",
                              },
                            },
                            replace: true,
                          });
                        }}
                      >
                        전환대기
                      </span>
                      <Badge pill color="primary" className="status-badge">
                        +{transfers ?? 0}
                      </Badge>
                    </div>
                    <div>
                      <span
                        className="status-text"
                        onClick={() => {
                          navigate("/member", {
                            state: {
                              params: {
                                status: "pending",
                                startDate: "",
                                endDate: "",
                              },
                            },
                            replace: true,
                          });
                        }}
                      >
                        승인대기
                      </span>
                      <Badge pill color="primary" className="status-badge">
                        +{users ?? 0}
                      </Badge>
                    </div>
                    <div>
                      <span
                        className="status-text"
                        onClick={() => {
                          navigate("/help-center", {
                            state: {
                              params: {
                                answered: "false",
                                startDate: "",
                                endDate: "",
                              },
                            },
                            replace: true,
                          });
                        }}
                      >
                        고객센터
                      </span>
                      <Badge pill color="primary" className="status-badge">
                        +{helpCenters ?? 0}
                      </Badge>
                    </div>
                  </div>
                </NavItem>
              )}
              <NavItem className={"status-nav-item"}>
                <span className="nav-link-header">지갑 현황</span>

                <div className="d-flex">
                  {currentUser.isRoot && (
                    <div className="me-2">
                      <span>총본 알:</span>
                      <div className="status-nav-link-container type1">
                        <span className="status-text">
                          카:
                          <NumberDisplay
                            value={eggs?.root?.casino ?? 0}
                            suffix=""
                            showPrefix={false}
                            decimalPoints={0}
                          />
                        </span>
                      </div>
                      <div className="status-nav-link-container type1">
                        <span className="status-text">
                          스:
                          <NumberDisplay
                            value={eggs?.root?.sports ?? 0}
                            suffix=""
                            showPrefix={false}
                            decimalPoints={0}
                          />
                        </span>
                      </div>
                    </div>
                  )}
                  {(currentUser.isRoot ||
                    currentUser.isHeadQuarter ||
                    currentUser.isAdmin) && (
                    <div className="me-2">
                      <span>본사 알:</span>
                      <div className="status-nav-link-container type1">
                        <span className="status-text">
                          카:
                          <NumberDisplay
                            value={eggs?.hq?.casino ?? 0}
                            suffix=""
                            showPrefix={false}
                            decimalPoints={0}
                          />
                        </span>
                      </div>
                      <div className="status-nav-link-container type1">
                        <span className="status-text">
                          스:
                          <NumberDisplay
                            value={eggs?.hq?.sports ?? 0}
                            suffix=""
                            showPrefix={false}
                            decimalPoints={0}
                          />
                        </span>
                      </div>
                    </div>
                  )}
                  {(currentUser.isRoot ||
                    currentUser.isHeadQuarter ||
                    currentUser.isAdmin) && (
                    <div className="me-2">
                      <span>파트너 보유금:</span>
                      <div className="status-nav-link-container type1">
                        <span className="status-text">
                          카:
                          <NumberDisplay
                            value={eggs?.partner?.casino ?? 0}
                            suffix=""
                            showPrefix={false}
                            decimalPoints={0}
                          />
                        </span>
                      </div>
                      <div className="status-nav-link-container type1">
                        <span className="status-text">
                          스:
                          <NumberDisplay
                            value={eggs?.partner?.sports ?? 0}
                            suffix=""
                            showPrefix={false}
                            decimalPoints={0}
                          />
                        </span>
                      </div>
                    </div>
                  )}
                  {/* {
                    (currentUser.isRoot || currentUser.isHeadQuarter || currentUser.isAdmin || currentUser.isPartner) &&
                    <div className='me-2'>
                      <span>파트너 알:</span>
                      <div className='status-nav-link-container type1'>
                        <span className='status-text'>
                          카:
                          <NumberDisplay
                            value={eggs?.partner?.casino ?? 0}
                            suffix=""
                            showPrefix={false}
                            decimalPoints={0}
                          />
                        </span>
                      </div>
                      <div className='status-nav-link-container type1'>
                        <span className='status-text'>
                          스:
                          <NumberDisplay
                            value={eggs?.partner?.sports ?? 0}
                            suffix=""
                            showPrefix={false}
                            decimalPoints={0}
                          />
                        </span>
                      </div>
                    </div>
                  } */}
                  <div className="me-2">
                    <span>회원 보유금:</span>
                    <div className="status-nav-link-container type1">
                      <span className="status-text">
                        카:
                        <NumberDisplay
                          value={eggs?.member?.casino ?? 0}
                          suffix=""
                          showPrefix={false}
                          decimalPoints={0}
                        />
                      </span>
                    </div>
                    <div className="status-nav-link-container type1">
                      <span className="status-text">
                        스:
                        <NumberDisplay
                          value={eggs?.member?.sports ?? 0}
                          suffix=""
                          showPrefix={false}
                          decimalPoints={0}
                        />
                      </span>
                    </div>
                  </div>
                  {currentUser.isPartner && (
                    <div className="me-2">
                      <span>나의 보유금:</span>
                      <div className="status-nav-link-container type1">
                        <span className="status-text">
                          카:
                          <NumberDisplay
                            value={currentUser?.wallet?.casino?.balance ?? 0}
                            suffix=""
                            showPrefix={false}
                            decimalPoints={0}
                          />
                        </span>
                      </div>
                      <div className="status-nav-link-container type1">
                        <span className="status-text">
                          스:
                          <NumberDisplay
                            value={currentUser?.wallet?.sports?.balance ?? 0}
                            suffix=""
                            showPrefix={false}
                            decimalPoints={0}
                          />
                        </span>
                      </div>
                    </div>
                  )}
                  {currentUser.isPartner && (
                    <div className="me-2">
                      <span>나의 포인트:</span>
                      <div className="status-nav-link-container type1">
                        <span className="status-text">
                          카:
                          <NumberDisplay
                            value={currentUser?.wallet?.casino?.point ?? 0}
                            suffix=""
                            showPrefix={false}
                            decimalPoints={0}
                          />
                        </span>
                      </div>
                      <div className="status-nav-link-container type1">
                        <span className="status-text">
                          스:
                          <NumberDisplay
                            value={currentUser?.wallet?.sports?.point ?? 0}
                            suffix=""
                            showPrefix={false}
                            decimalPoints={0}
                          />
                        </span>
                      </div>
                    </div>
                  )}
                </div>
              </NavItem>
              <UncontrolledDropdown nav inNavbar>
                <DropdownToggle nav caret style={{ color: colors.white }}>
                  {currentUser?.username}
                  {currentUser?.nickname ? `(${currentUser?.nickname})` : ""}
                </DropdownToggle>
                <div className="d-flex justify-content-center">
                <Button color="danger" onClick={onLogout}>로그아웃</Button>
                </div>
                <DropdownMenu end style={{ right: 0 }}>
                  <DropdownItem divider />
                  {!isPartner && (
                    <DropdownItem onClick={() => setIsProfile(true)}>
                      프로필수정
                    </DropdownItem>
                  )}
                  {isPartner && (
                    <DropdownItem onClick={() => setIsDeposit(true)}>
                      입금요청
                    </DropdownItem>
                  )}
                  {isPartner && (
                    <DropdownItem onClick={() => setIsWithdrawal(true)}>
                      출금요청
                    </DropdownItem>
                  )}
                  {isPartner && (
                    <DropdownItem onClick={() => setIsPoint(true)}>
                      포인트전환
                    </DropdownItem>
                  )}
                  <DropdownItem onClick={onLogout}>로그아웃</DropdownItem>
                </DropdownMenu>
              </UncontrolledDropdown>
            </Nav>
          </Collapse>
        </Navbar>
      </div>
      <div
        style={{
          display: "block",
          background: colors.primary,
          width: "100%",
          padding: 0,
          overflowX: "auto",
        }}
      >
        <Navbar style={{ backgroundColor: colors.primary }} expand="md">
          <Collapse isOpen={isOpen} navbar>
            <Nav className="mr-auto" navbar>
              {menuItems.map((item, index) => (
                <NavItem
                  className="main-header-item"
                  key={index}
                  style={{ position: "relative" }}
                >
                  <NavLink
                    className={`page-link ${
                      location.pathname === item.link ? "active" : ""
                    }`}
                    onClick={() => {
                      if (typeof item.onClick === "function") {
                        return item.onClick();
                      }
                      return navigate(item.link);
                    }}
                  >
                    {item.label}
                    {item.count > 0 && (
                      <Badge
                        pill
                        color="danger"
                        style={{
                          position: "absolute",
                          top: "0px",
                          right: "-10px",
                          fontSize: "0.7rem",
                          zIndex: 1000,
                        }}
                      >
                        {item.count}
                      </Badge>
                    )}
                  </NavLink>
                </NavItem>
              ))}
            </Nav>
          </Collapse>
        </Navbar>
      </div>
      <PartnerWithdrawalModal
        open={isWithdrawal}
        setIsWithdrawal={setIsWithdrawal}
      />
      <PartnerDepositModal open={isDeposit} setIsDeposit={setIsDeposit} />
      <PartnerPointModal open={isPoint} setIsPoint={setIsPoint} />
      <MemberProfileModal open={isProfile} setIsProfile={setIsProfile} />

      <Modal
        size={"xl"}
        isOpen={isWallet}
        toggle={() => setIsWallet(!isWallet)}
      >
        {" "}
        <Navbar
          style={{ backgroundColor: colors.primary, padding: "1rem" }}
          expand="md"
        >
          <h5 className="m-0">{currentUser?.username}님의 캐시기록</h5>{" "}
          <div className="text-right">
            <MdClose
              className="clickable-icon"
              size={18}
              onClick={() => setIsWallet(false)}
            />
          </div>
          {/* Use the label based on the active tab */}
        </Navbar>
        <Container className="page-content" fluid style={{ padding: 30 }}>
          <MemberWalletHistoryList
            targetId={currentUser?._id}
            classification="관리자증감"
            onClose={() => {
              setIsWallet(false);
            }}
          />
        </Container>
      </Modal>

      <Modal size={"xl"} isOpen={isMail} toggle={() => setIsMail(!isMail)}>
        <MailCreate
          onClose={() => {
            setIsMail(false);
          }}
          targetUsername={currentUser?.hqInfo?.username}
          type={'partnerSent'}
        />
      </Modal>
    </>
  );
}

export default Header;
