import React from 'react'
import './NotAllowed.css'
import { Layout } from 'components'

const NotAllowed = () => {
  return (
      <div className='under-construction-container'>
        <div className='under-construction-content'>
          <h2>권한이 없습니다.</h2>
        </div>
      </div>
  )
}

export default NotAllowed
