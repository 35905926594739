import React, { lazy, useState, Suspense } from 'react'
import { Layout, UnderConstruction } from 'components'
import {
  Nav,
  NavItem,
  NavLink,
  TabContent,
  TabPane,
  Row,
  Container
} from 'reactstrap'

const PopupList = lazy(() => import('./PopupList'))
// const PopupConfig = lazy(() => import('./PopupConfig'))
// const PopupGradeConfig = lazy(() => import('./PopupGradeConfig'))
// const PopupAttendanceConfig = lazy(() => import('./PopupAttendanceConfig'))

function Popup (props) {
  return (
    <Layout>
      <Container className='page-content' fluid style={{ padding: 30 }}>
        <Row>
          <Suspense fallback={<div>Loading...</div>}>
            <PopupList />
          </Suspense>
        </Row>
      </Container>
    </Layout>
  )
}

export default Popup
