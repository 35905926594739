import React, { useState, useEffect } from 'react'
import {
  Container,
  Row,
  Col,
  Form,
  FormGroup,
  Table,
  Input,
  Button,
  Navbar
} from 'reactstrap'
import { Editor } from 'components'
import { showAlert, showSuccess } from 'utils/toast'
import dayjs from 'dayjs'
import { Loader } from 'components'
import { fetchMail } from 'api/mailApi'
import colors from 'assets/colors'
import { useParams } from 'react-router-dom'
import { MdClose } from "react-icons/md";

function MailView (props) {
  const id = props?.targetId ?? null
  const [isLoading, setIsLoading] = useState(false)
  const [error, setError] = useState(null)

  const [editorKey, setEditorKey] = useState(Date.now())
  const [mail, setMail] = useState({
    name: '',
    content: ''
  })

  useEffect(() => {}, [])

  const fetchData = async () => {
    setIsLoading(true)
    try {
      const { success, data, message } = await fetchMail(id)

      if (!success) throw new Error(message)
      if (data) {
        setMail(data)
      }
    } catch (error) {
      console.error(error)
      setError(error)
    } finally {
      setIsLoading(false)
    }
  }

  useEffect(() => {
    fetchData()
  }, [])

  return (
    <>
      <Navbar
        style={{ backgroundColor: colors.primary, padding: '1rem' }}
        expand='md'
      >
        <h5 className='m-0'>
          {'쪽지: '}
          {mail?.name}
        </h5>{' '}
        <div className="text-right">
          <MdClose
            className="clickable-icon"
            size={18}
            onClick={() => props.onClose && props.onClose()}
          />
        </div>
        {/* Use the label based on the active tab */}
      </Navbar>

      <Container className='page-content' style={{ paddingBottom: 100 }}>
        {isLoading ? (
          <Loader />
        ) : (
          <Table bordered style={{ marginTop: '2rem' }}>
            <tbody className='align-items-center'>
              <tr>
                <td
                  className='form-header text-center align-middle w-200p'
                  xs={3}
                >
                  제목
                </td>
                <td xs={9}>
                  <span>{mail?.name}</span>
                </td>
              </tr>
              <tr>
                <td className='form-header text-center align-middle ' xs={3}>
                  내용
                </td>
                <td xs={9}>
                  <div dangerouslySetInnerHTML={{ __html: mail?.content }} />
                </td>
              </tr>
            </tbody>
          </Table>
        )}

        {/* Action bar */}
        <div className={`action-bar-container show`}>
          <div className='action-bar'>
            <div className='left-actions'></div>
            <div className='right-actions'>
              {/* Right-aligned action buttons */}
              <Button
                className="me-2"
                color="warning"
                onClick={() => {
                  if (typeof props.onClose === "function") props.onClose();
                }}
              >
                닫기
              </Button>
              <Button
                className='me-2'
                color='primary'
                onClick={() => {if (typeof props.onClose === "function") props.onClose();}}
              >
                확인
              </Button>
            </div>
          </div>
        </div>
      </Container>
    </>
  )
}

export default MailView
