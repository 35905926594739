import api from './index.js'

const BASE_URL = '/admin/auth'

export const login = async credentials => {
  try {
    const response = await api.post(`${BASE_URL}/login`, credentials)
    if (response.status === 200) {
      return response.data
    } else {
      throw new Error('Login failed')
    }
  } catch (error) {
    throw new Error('Login failed')
  }
}

export const logout = async () => {
  try {
    const response = await api.post(`${BASE_URL}/logout`)
    if (response.status === 200) {
      // Handle logout success
    } else {
      throw new Error('Logout failed')
    }
  } catch (error) {
    throw new Error('Logout failed')
  }
}

export const autoLogin = async (session) => {
  try {
    const response = await api.post(`${BASE_URL}/autoLogin`, {session})
    if (response.status === 200) {
      // Handle logout success
      return response.data
    } else {
      throw new Error('autoLogin failed')
    }
  } catch (error) {
    throw new Error('autoLogin failed')
  }
}

export const checkAuth = async () => {
  try {
    const response = await api.get(`${BASE_URL}/check`)
    if (response.status === 200) {
      return response.data
    } else {
      return new Error('Auth failed')
    }
  } catch (error) {
    throw new Error('Auth failed')
  }
}

export const checkPrevilege = async (type) => {
  try {
    const response = await api.post(`${BASE_URL}/previlege`, {
      type
    })
    if (response.status === 200) {
      return response.data
    } else {
      throw new Error('Previlege Check failed')
    }
  } catch (error) {
    throw new Error('Previlege Check failed')
  }
}
