import React, { lazy, Suspense } from 'react'
import { Layout } from 'components'
import {
  Row,
  Container
} from 'reactstrap'

const NoticeList = lazy(() => import('./NoticeList'))
// const NoticeConfig = lazy(() => import('./NoticeConfig'))
// const NoticeGradeConfig = lazy(() => import('./NoticeGradeConfig'))
// const NoticeAttendanceConfig = lazy(() => import('./NoticeAttendanceConfig'))

function Notice (props) {
  return (
    <Layout>
      <Container className='page-content' fluid style={{ padding: 30 }}>
        <Row>
          <Suspense fallback={<div>Loading...</div>}>
            <NoticeList />
          </Suspense>
        </Row>
      </Container>
    </Layout>
  )
}

export default Notice
