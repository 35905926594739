import React, { lazy, useState, Suspense, useEffect } from 'react';
import './Setting.css';
import { Layout } from 'components';
import {
  Nav,
  NavItem,
  NavLink,
  TabContent,
  TabPane,
  Row,
  Container
} from 'reactstrap';
import { useLocation } from 'react-router-dom';
import { userSelector } from 'features/auth/authSelectors'
import { useDispatch, useSelector } from 'react-redux'

const SettingAlarm = lazy(() => import('./SettingAlarm'));
const Banner = lazy(() => import('../Banner/BannerList'));
const SlotBanner = lazy(() => import('../SlotBanner/SlotBannerList'));
const InlineNotice = lazy(() => import('../InlineNotice/InlineNoticeList'));
const Popup = lazy(() => import('../Popup/PopupList'));
const Faq = lazy(() => import('../Faq/FaqList'));
const Rule = lazy(() => import('../Rule/RuleList'));
const Notice = lazy(() => import('../Notice/NoticeList'));

const tabs = [
  { tab: 'notice', label: '공지 사항', component: Notice },
  { tab: 'inline', label: '한줄 공지', component: InlineNotice },
  { tab: 'banner', label: '배너', component: Banner },
  { tab: 'slotBanner', label: '슬롯배너', component: SlotBanner },
  { tab: 'popup', label: '팝업', component: Popup },
  { tab: 'faq', label: 'FAQ', component: Faq },
  { tab: 'rule', label: '베팅 규정', component: Rule },
  { tab: 'alarm', label: '알림 설정', component: SettingAlarm }
];

function Setting() {
  const currentUser = useSelector(userSelector);
  const { search } = useLocation();
  const [activeTab, setActiveTab] = useState('general');

  const toggleTab = (tab) => {
    if (activeTab !== tab) {
      setActiveTab(tab);
    }
  };

  useEffect(() => {
    const tabParam = new URLSearchParams(search).get('tab');
    const defaultTab = currentUser?.isPartner ? 'banner' : 'notice'; // Default to 'banner' if isPartner is true
    const initialTab = tabParam || defaultTab;
    setActiveTab(initialTab);
  }, [search, currentUser]);

  // Filter tabs based on currentUser.isPartner
  const filteredTabs = currentUser?.isPartner
    ? tabs.filter(({ tab }) => ['banner', 'popup'].includes(tab))
    : tabs;

  return (
    <Layout>
      <Container className="page-content" fluid style={{ padding: 30 }}>
        <Row>
          <Nav tabs>
            {filteredTabs.map(({ tab, label }) => (
              <NavItem key={tab}>
                <NavLink
                  className={activeTab === tab ? 'sub-link active' : ''}
                  onClick={() => toggleTab(tab)}
                >
                  {label}
                </NavLink>
              </NavItem>
            ))}
          </Nav>
        </Row>
        <Row>
          <TabContent activeTab={activeTab}>
            {filteredTabs.map(({ tab, component: Component }) => (
              <TabPane key={tab} tabId={tab}>
                {activeTab === tab && (
                  <Suspense fallback={<div>Loading...</div>}>
                    <Component />
                  </Suspense>
                )}
              </TabPane>
            ))}
          </TabContent>
        </Row>
      </Container>
    </Layout>
  );
}

export default Setting;


