import api from './index.js'

export const fetchApiGames = async (
  id,
  currentPage,
  pageSize,
  params,
  sortColumn,
  sortDirection
) => {
  try {
    const queryParams = new URLSearchParams(params).toString() // Convert params object to query string
    const response = await api.get(
      `/admin/api-game/${id}/?pageNumber=${currentPage}&pageSize=${pageSize}&sortColumn=${sortColumn}&sortDirection=${sortDirection}&${queryParams}`
    )

    if (response.status === 200) {
      const data = response.data
      // Handle successful response
      // ...
      return data
    } else {
      throw new Error('Failed to fetch api-game')
    }
  } catch (error) {
    // Handle error
    // ...
    throw new Error('Failed to fetch api-game')
  }
}


export const fetchApiGame = async id => {
  try {
    const response = await api.get(`/admin/api-game/edit/${id}`)

    if (response.status === 200) {
      const data = response.data
      // Handle successful response
      // ...
      return data
    } else {
      throw new Error('Failed to fetch api game')
    }
  } catch (error) {
    // Handle error
    // ...
    throw new Error('Failed to fetch api game')
  }
}


export const updateApiGame = async (id, apiData) => {
  try {
    const response = await api.put(`/admin/api-game/edit/${id}`, apiData)

    if (response.status === 200) {
      const data = response.data
      // Handle successful response
      // ...
      return data
    } else {
      throw new Error('Failed to update api game')
    }
  } catch (error) {
    // Handle error
    // ...
    throw new Error('Failed to update api game')
  }
}

export const createApiGame = async (id, apiData) => {
  try {
    const response = await api.post(`/admin/api-game/${id}`, apiData)

    if (response.status === 200) {
      const data = response.data
      // Handle successful response
      // ...
      return data
    } else {
      throw new Error('Failed to create api game')
    }
  } catch (error) {
    // Handle error
    // ...
    throw new Error('Failed to create api game')
  }
}