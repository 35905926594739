import React, { lazy, useState, Suspense, useEffect } from 'react'
import {
  Navbar,
  Nav,
  NavItem,
  NavLink,
  TabContent,
  TabPane,
  Row,
  Container
} from 'reactstrap'
import { UnderConstruction, Loader } from 'components'
import { useParams, useLocation } from 'react-router-dom'
import colors from 'assets/colors'
import { fetchUser } from 'api/userApi'

const MemberGradeConfigBasic = lazy(() => import('./MemberGradeConfigBasic'))

function MemberGradeDetail (props) {
  const { search } = useLocation()
  // const { id } = useParams()
  const id = props?.targetId
  const [activeTab, setActiveTab] = useState('tab1')
  const [user, setUser] = useState({ nickname: '' })
  const [isLoading, setIsLoading] = useState(false)
  const [error, setError] = useState(null)

  const toggleTab = tab => {
    if (activeTab !== tab) setActiveTab(tab)
  }

  useEffect(() => {
    // const tabParam = new URLSearchParams(search).get('tab')
    // const initialTab = tabParam || 'tab1'
    // setActiveTab(initialTab)
  }, [id])

  return (
    <>
      <Navbar
        style={{ backgroundColor: colors.primary, padding: '1rem' }}
        expand='md'
      >
        <h5 className='m-0'>{'등급 설정 상세'}</h5>{' '}
      </Navbar>
      <Container className='page-content' fluid style={{ padding: 30 }}>
        {isLoading && <Loader />}
        <Row>
          <Nav tabs>
            {[
              { tab: 'tab1', label: '기본정보' },
              // { tab: 'tab2', label: '스포츠 설정' },
              // { tab: 'tab3', label: '미니게임 설정' },
            ].map(({ tab, label }) => (
              <NavItem key={tab}>
                <NavLink
                  className={activeTab === tab ? 'sub-link active' : ''}
                  onClick={() => toggleTab(tab)}
                >
                  {label}
                </NavLink>
              </NavItem>
            ))}
          </Nav>
        </Row>
        <Row>
          <TabContent activeTab={activeTab}>
          {[
              { tab: 'tab1', component: <MemberGradeConfigBasic { ...props }/> },
            ].map(({ tab, component }) => (
              <TabPane key={tab} tabId={tab}>
                {activeTab === tab && ( // Only render the component when the activeTab matches the current tab
                  <Suspense fallback={<div>Loading...</div>}>
                    {component}
                  </Suspense>
                )}
              </TabPane>
            ))}
          </TabContent>
        </Row>
      </Container>
    </>
  )
}

export default MemberGradeDetail
