const initialState = {
    data: {},
    loading: false,
    error: ''
  }
  
  const statusReducer = (state = initialState, action) => {
    switch (action.type) {
      case 'STATUS_REQUEST':
        return {
          ...state,
          loading: true
        }
      case 'STATUS_SUCCESS':
        return {
          ...state,
          loading: false,
          data: action.payload,
          error: ''
        }
      case 'STATUS_FAILURE':
        return {
          ...state,
          loading: false,
          error: action.payload
        }
      default:
        return state
    }
  }
  
  export default statusReducer
  