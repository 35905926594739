// import logo from './logo.svg';
import React, { useEffect } from 'react'
import 'bootstrap/dist/css/bootstrap.min.css'
import 'react-toastify/dist/ReactToastify.css'
import './App.css'
import {
  BrowserRouter as Router,
  useLocation,
  Navigate,
  Routes,
  Route
} from 'react-router-dom'
import {
  Home,
  Login,
  Deposit,
  Withdrawal,
  Referral,
  Mail,
  Member,
  MemberEdit,
  MemberGradeConfigEdit,
  Partner,
  PartnerCreate,
  PartnerEdit,
  HelpCenter,
  MailView,
  MailCreate,
  Test,
  Faq,
  FaqCreate,
  FaqView,
  InlineNotice,
  Popup,
  PopupCreate,
  Banner,
  BannerCreate,
  Notice,
  Rule,
  Setting,
  NoticeView,
  RuleView,
  HelpCenterView,
  PopupView,
  PopupEdit,
  FaqEdit,
  BannerEdit,
  NoticeEdit,
  NoticeCreate,
  RuleEdit,
  RuleCreate,
  Admin,
  AdminCreate,
  AdminEdit,
  History,
  Bet,
  Adjustment,
  AdjustmentHistoryView,
  SiteConfig,
  SiteConfigEdit,
  SlotBannerCreate,
  SlotBannerEdit,
  Api,
  ApiEdit,
  ApiCreate,
  Development,
  BatchLogList,
  BatchWorkList,
  Transfer,
  EggHistoryView,
  ApiConfigEdit,
  NotAllowedPage,
  PartnerWithdrawal,
  AdjustmentHistoryList,
  HelpCenterTemplateCreate,
  HelpCenterTemplateEdit,
  Coupon,
  CouponCreate,
  CouponEdit,
  ApiGame,
  ApiGameList,
  ApiGameEdit,
  ApiGameCreate,
  Loss
} from './pages'
import { useDispatch, useSelector } from 'react-redux'
import { AuthProvider, useAuth } from './AuthContext'
import { isAuthSelector } from 'features/auth/authSelectors'
import { metaSelector } from 'features/meta/metaSelectors'
import { ToastContainer } from 'react-toastify';
import { showAlert } from 'utils/toast'
import { UnderConstruction, NotAllowed } from 'components'
import SiteConfigCreate from 'pages/SiteConfig/SiteConfigCreate'
import GameLogList from 'pages/Development/GameLogList'
import { configThunk, siteConfigThunk } from 'thunks/configThunks'
import { statusThunk } from 'thunks/statusThunks'
import { checkAuthThunk } from 'thunks/authThunks'
import { userSelector } from 'features/auth/authSelectors'
// import { checkPrevilege } from 'api/authApi'
import { metaSuccess } from 'features/meta/metaActions'
import { Helmet } from "react-helmet"
import PartnerMember from 'pages/Partner/PartnerMember'

const ProtectedRoute = ({ isAllowed, children }) => {
  const dispatch = useDispatch()
  const isAuth = useSelector(isAuthSelector)
  // const { checkAuth } = useAuth()
  const location = useLocation()
  const currentUser = useSelector(userSelector)

  useEffect(() => {
    const { isRoot, isHeadQuarter, isPartner, isAdmin } = currentUser

    if (isHeadQuarter) {
      dispatch(configThunk())
    }
    dispatch(siteConfigThunk())
    dispatch(checkAuthThunk())
    dispatch(statusThunk())
  }, [location])

  if (!isAuth) {
    showAlert('로그아웃 되었습니다!', 'logout')
    return <Navigate to='/login' replace state={{ from: location }} />
  }
  if(!currentUser.isRoot && !isAllowed) {
    return <Navigate to={'/403'} replace />
  }
  return children
}

function App() {
  const dispatch = useDispatch()
  const { meta } = useSelector(metaSelector)
  const currentUser = useSelector(userSelector)
  const { isRoot, isAdmin, isHeadQuarter, isPartner, config } = currentUser
  const previleges = config?.previleges ?? []
  
  useEffect(() => {
    const domainName = window.location.hostname;
    let domain, title = '';

    if (domainName.includes('goldwin77')) {
      domain ='dragon';
      title ='용벳';
    } else if (domainName.includes('cacag991') || domainName.includes('cools911')) {
      domain ='sakura';
      title ='사쿠라';
    } else if (domainName.includes('one-1004')) {
      domain ='onebet';
      title ='원벳';
    } else if (domainName.includes('smf-01')) {
      domain ='smurf';
      title ='스머프';
    } else if (domainName.includes('sth911')) {
      domain ='sth';
      title ='스텔스';
    } else if (domainName.includes('rup-777')) {
      domain ='rup';
      title ='루팡';
    } else if (domainName.includes('gg-924')) {
      domain ='gg';
      title ='GG';
    } else if (domainName.includes('sth-9999')) {
      domain ='sth';
      title ='스텔스';
    } else if (domainName.includes('mgn-7777')) {
      domain ='mgn';
      title ='MGN';
    } else if (domainName.includes('sky-3337')) {
      domain ='sky';
      title ='SKY';
    } else if (domainName.includes('asia887')) {
      domain ='asia';
      title ='ASIA';
    } else if (domainName.includes('lon8989')) {
      domain ='lon';
      title ='LONDON';
    } else if (domainName.includes('ww-1004')) {
      domain ='ww';
      title ='돈꽃';
    } else if (domainName.includes('zet36')) {
      domain ='zet';
      title ='제트';
    } else if (domainName.includes('as77as')) {
      domain ='as';
      title ='따봉';
    } else if (domainName.includes('aw135')) {
      domain ='aw';
      title ='금두꺼비';
    } else if (domainName.includes('co5858co')) {
      domain ='co';
      title ='투우';
    } else {
      domain = '';
      title = '';
    }
    dispatch(metaSuccess({ domain, title }))
  }, [])
  
  const isAllowed = (type) => {
    if(!type) return true
    if(isRoot) return true
    if(previleges && previleges.length) {
      return previleges?.includes(type)
    }
    return false
  }

  return (
    <>    
      <Helmet>
          <title>{meta?.title ?? ''} 관리자</title>
          <link rel="icon" href={`/favicon-${meta?.domain}.ico`} />
          <link rel="apple-touch-icon" href={`/favicon-${meta?.domain}`} />
      </Helmet>
    <Router>
      <AuthProvider>
        <Routes>
          {/* <Route exact path="/" element={<ProtectedRoute><Home /></ProtectedRoute>} /> */}

          <Route exact path='/login' element={<Login />} />
          <Route exact path='/403' element={<NotAllowedPage />} />
          <Route
            exact
            path='/'
            element={
              <ProtectedRoute isAllowed>
                <Home />
              </ProtectedRoute>
            }
          />
          <Route
            exact
            path='/site-config'
            element={
              <ProtectedRoute isAllowed={isAllowed('site-config/view')}>
                <SiteConfig />
              </ProtectedRoute>
            }
          />
          <Route
            exact
            path='/site-config/:id'
            element={
              <ProtectedRoute isAllowed={isAllowed('site-config/view')}>
                <SiteConfigEdit />
              </ProtectedRoute>
            }
          />
          <Route
            exact
            path='/site-config/create'
            element={
              <ProtectedRoute isAllowed={isAllowed('site-config/create')}>
                <SiteConfigCreate />
              </ProtectedRoute>
            }
          />
          <Route
            exact
            path='/deposit'
            element={
              <ProtectedRoute isAllowed={isAllowed('deposit/view')}>
                <Deposit />
              </ProtectedRoute>
            }
          />
          <Route
            exact
            path='/withdrawal'
            element={
              <ProtectedRoute isAllowed={isAllowed('withdrawal/view')}>
                <Withdrawal />
              </ProtectedRoute>
            }
          />
          <Route
            exact
            path='/referral'
            element={
              <ProtectedRoute isAllowed={isAllowed('referral/view')}>
                <Referral />
              </ProtectedRoute>
            }
          />
          <Route
            exact
            path='/mail'
            element={
              <ProtectedRoute isAllowed={isAllowed('mail/view')}>
                <Mail />
              </ProtectedRoute>
            }
          />
          <Route
            exact
            path='/casino'
            element={
              <ProtectedRoute isAllowed>
                <UnderConstruction />
              </ProtectedRoute>
            }
          />
          <Route
            exact
            path='/bet'
            element={
              <ProtectedRoute isAllowed={isAllowed('bet/view')}>
                <Bet />
              </ProtectedRoute>
            }
          />
          <Route
            exact
            path='/mail/create'
            element={
              <ProtectedRoute isAllowed={isAllowed('mail/create')}>
                <MailCreate />
              </ProtectedRoute>
            }
          />
          <Route
            exact
            path='/mail/view/:id'
            element={
              <ProtectedRoute isAllowed={isAllowed('mail/view')}>
                <MailView />
              </ProtectedRoute>
            }
          />
          <Route
            exact
            path='/member'
            element={
              <ProtectedRoute isAllowed={isAllowed('user/view')}>
                <Member />
              </ProtectedRoute>
            }
          />
          <Route
            exact
            path='/member/:id'
            element={
              <ProtectedRoute isAllowed={isAllowed('user/view')}>
                <MemberEdit />
              </ProtectedRoute>
            }
          />
          <Route
            exact
            path='/grade/:id'
            element={
              <ProtectedRoute isAllowed={isAllowed('user/view')}>
                <MemberGradeConfigEdit />
              </ProtectedRoute>
            }
          />
          <Route
            exact
            path='/partner'
            element={
              <ProtectedRoute isAllowed={isAllowed('partner/view')}>
                <Partner />
              </ProtectedRoute>
            }
          />
          <Route
            exact
            path='/partner/member'
            element={
              <ProtectedRoute isAllowed={isAllowed('partner/view')}>
                <PartnerMember />
              </ProtectedRoute>
            }
          />
          <Route
            exact
            path='/partner/edit/:id' // Add the dynamic route parameter ":id"
            element={
              <ProtectedRoute isAllowed={isAllowed('partner/view')}>
                <PartnerEdit />
              </ProtectedRoute>
            }
          />
          <Route
            exact
            path='/partner/create/:id'
            element={
              <ProtectedRoute isAllowed={isAllowed('partner/create/children')}>
                <PartnerCreate />
              </ProtectedRoute>
            }
          />
          <Route
            exact
            path='/admin'
            element={
              <ProtectedRoute isAllowed={isAllowed('admin/view')}>
                <Admin />
              </ProtectedRoute>
            }
          />
          <Route
            exact
            path='/admin/create'
            element={
              <ProtectedRoute isAllowed={isAllowed('admin/create')}>
                <AdminCreate />
              </ProtectedRoute>
            }
          />
          <Route
            exact
            path='/admin/edit/:id'
            element={
              <ProtectedRoute isAllowed={isAllowed('admin/view')}>
                <AdminEdit />
              </ProtectedRoute>
            }
          />
          <Route
            exact
            path='/adjustment'
            element={
              <ProtectedRoute isAllowed={isAllowed('adjustment/view')}>
                <Adjustment />
              </ProtectedRoute>
            }
          />
          <Route
            exact
            path='/help-center'
            element={
              <ProtectedRoute isAllowed={isAllowed('help-center/view')}>
                <HelpCenter />
              </ProtectedRoute>
            }
          />
          <Route
            exact
            path='/help-center/view/:id'
            element={
              <ProtectedRoute isAllowed={isAllowed('help-center/view')}>
                <HelpCenterView />
              </ProtectedRoute>
            }
          />
          <Route
            exact
            path='/help-center-template/create'
            element={
              <ProtectedRoute isAllowed>
                <HelpCenterTemplateCreate />
              </ProtectedRoute>
            }
          />
          <Route
            exact
            path='/help-center-template/edit/:id'
            element={
              <ProtectedRoute isAllowed>
                <HelpCenterTemplateEdit />
              </ProtectedRoute>
            }
          />
          <Route
            exact
            path='/faq'
            element={
              <ProtectedRoute isAllowed={isHeadQuarter || isAdmin}>
                <Faq />
              </ProtectedRoute>
            }
          />
          <Route
            exact
            path='/faq/create'
            element={
              <ProtectedRoute isAllowed={isHeadQuarter || isAdmin}>
                <FaqCreate />
              </ProtectedRoute>
            }
          />
          <Route
            exact
            path='/faq/edit/:id'
            element={
              <ProtectedRoute isAllowed={isHeadQuarter || isAdmin}>
                <FaqEdit />
              </ProtectedRoute>
            }
          />
          <Route
            exact
            path='/faq/view/:id'
            element={
              <ProtectedRoute isAllowed={isHeadQuarter || isAdmin}>
                <FaqView />
              </ProtectedRoute>
            }
          />
          <Route
            exact
            path='/inline-notice'
            element={
              <ProtectedRoute isAllowed={isHeadQuarter || isAdmin}>
                <InlineNotice />
              </ProtectedRoute>
            }
          />
          <Route
            exact
            path='/popup'
            element={
              <ProtectedRoute isAllowed={isHeadQuarter || isAdmin}>
                <Popup />
              </ProtectedRoute>
            }
          />
          <Route
            exact
            path='/popup/create'
            element={
              <ProtectedRoute isAllowed={isHeadQuarter || isAdmin}>
                <PopupCreate />
              </ProtectedRoute>
            }
          />
          <Route
            exact
            path='/popup/view/:id'
            element={
              <ProtectedRoute isAllowed={isHeadQuarter || isAdmin}>
                <PopupView />
              </ProtectedRoute>
            }
          />
          <Route
            exact
            path='/popup/edit/:id'
            element={
              <ProtectedRoute isAllowed={isHeadQuarter || isAdmin}>
                <PopupEdit />
              </ProtectedRoute>
            }
          />
          <Route
            exact
            path='/banner'
            element={
              <ProtectedRoute isAllowed={isHeadQuarter || isAdmin}>
                <Banner />
              </ProtectedRoute>
            }
          />
          <Route
            exact
            path='/banner/create'
            element={
              <ProtectedRoute isAllowed={isHeadQuarter || isAdmin}>
                <BannerCreate />
              </ProtectedRoute>
            }
          />
          <Route
            exact
            path='/banner/edit/:id'
            element={
              <ProtectedRoute isAllowed={isHeadQuarter || isAdmin}>
                <BannerEdit />
              </ProtectedRoute>
            }
          />
          <Route
            exact
            path='/slot-banner/create'
            element={
              <ProtectedRoute isAllowed={isHeadQuarter || isAdmin}>
                <SlotBannerCreate />
              </ProtectedRoute>
            }
          />
          <Route
            exact
            path='/slot-banner/edit/:id'
            element={
              <ProtectedRoute isAllowed={isHeadQuarter || isAdmin}>
                <SlotBannerEdit />
              </ProtectedRoute>
            }
          />
          <Route
            exact
            path='/notice'
            element={
              <ProtectedRoute isAllowed={isHeadQuarter || isAdmin}>
                <Notice />
              </ProtectedRoute>
            }
          />
          <Route
            exact
            path='/notice/create'
            element={
              <ProtectedRoute isAllowed={isHeadQuarter || isAdmin}>
                <NoticeCreate />
              </ProtectedRoute>
            }
          />
          <Route
            exact
            path='/notice/view/:id'
            element={
              <ProtectedRoute isAllowed={isHeadQuarter || isAdmin}>
                <NoticeView />
              </ProtectedRoute>
            }
          />
          <Route
            exact
            path='/notice/edit/:id'
            element={
              <ProtectedRoute isAllowed={isHeadQuarter || isAdmin}>
                <NoticeEdit />
              </ProtectedRoute>
            }
          />
          <Route
            exact
            path='/rule'
            element={
              <ProtectedRoute isAllowed={isHeadQuarter || isAdmin}>
                <Rule />
              </ProtectedRoute>
            }
          />
          <Route
            exact
            path='/rule/edit/:id'
            element={
              <ProtectedRoute isAllowed={isHeadQuarter || isAdmin}>
                <RuleEdit />
              </ProtectedRoute>
            }
          />
          <Route
            exact
            path='/rule/create'
            element={
              <ProtectedRoute isAllowed={isHeadQuarter || isAdmin}>
                <RuleCreate />
              </ProtectedRoute>
            }
          />
          <Route
            exact
            path='/rule/view/:id'
            element={
              <ProtectedRoute isAllowed={isHeadQuarter || isAdmin}>
                <RuleView />
              </ProtectedRoute>
            }
          />
          <Route
            exact
            path='/setting'
            element={
              <ProtectedRoute isAllowed={isHeadQuarter || isAdmin || isPartner}>
                <Setting />
              </ProtectedRoute>
            }
          />
          <Route
            exact
            path='/history'
            element={
              <ProtectedRoute isAllowed={isAllowed('history/view')}>
                <History />
              </ProtectedRoute>
            }
          />
          <Route
            exact
            path='/adjustment-history'
            element={
              <ProtectedRoute isAllowed={isAllowed('history/view/adjustment')}>
                <AdjustmentHistoryList />
              </ProtectedRoute>
            }
          />
          <Route
            exact
            path='/adjustment-history/view/:id'
            element={
              <ProtectedRoute isAllowed={isAllowed('history/view/adjustment')}>
                <AdjustmentHistoryView />
              </ProtectedRoute>
            }
          />
          <Route
            exact
            path='/egg-history/view/:id'
            element={
              <ProtectedRoute isAllowed={isAllowed('history/view/egg')}>
                <EggHistoryView />
              </ProtectedRoute>
            }
          />

          <Route
            exact
            path='/api'
            element={
              <ProtectedRoute isAllowed={isRoot}>
                <Api />
              </ProtectedRoute>
            }
          />

          <Route
            exact
            path='/api/:id'
            element={
              <ProtectedRoute isAllowed={isRoot}>
                <ApiEdit />                
              </ProtectedRoute>
            }
          />

          <Route
            exact
            path='/api-game/edit/:id'
            element={
              <ProtectedRoute isAllowed>
                <ApiGameEdit />
              </ProtectedRoute>
            }
          />

          <Route
            exact
            path='/api-game/create/:id'
            element={
              <ProtectedRoute isAllowed={isRoot}>
                <ApiGameCreate />
              </ProtectedRoute>
            }
          />

          <Route
            exact
            path='/api-config/:id'
            element={
              <ProtectedRoute isAllowed>
                <ApiConfigEdit />
              </ProtectedRoute>
            }
          />

          <Route
            exact
            path='/api-game/:id'
            element={
              <ProtectedRoute isAllowed>
                <ApiGameList />
              </ProtectedRoute>
            }
          />

          <Route
            exact
            path='/api/create'
            element={
              <ProtectedRoute isAllowed={isRoot}>
                <ApiCreate />
              </ProtectedRoute>
            }
          />

          <Route
            exact
            path='/development'
            element={
              <ProtectedRoute isAllowed={isRoot}>
                <Development />
              </ProtectedRoute>
            }
          />


          <Route
            exact
            path='/batch-log/:id'
            element={
              <ProtectedRoute isAllowed={isRoot}>
                { isRoot ? 
                  <BatchLogList />
                  : null
                }
              </ProtectedRoute>
            }
          />


          <Route
            exact
            path='/batch-work/:id'
            element={
              <ProtectedRoute isAllowed={isRoot}>
                { isRoot ? 
                  <BatchWorkList />
                  : null
                }
              </ProtectedRoute>
            }
          />


          <Route
            exact
            path='/game-log/:id'
            element={
              <ProtectedRoute isAllowed={isRoot}>             
                { isRoot ? 
                  <GameLogList />
                  : null
                }
              </ProtectedRoute>
            }
          />
          <Route
            exact
            path='/transfer'
            element={
              <ProtectedRoute isAllowed={isAllowed('transfer/view')}>
                <Transfer />
              </ProtectedRoute>
            }
          />
          <Route
            exact
            path='/partner-withdrawal'
            element={
              <ProtectedRoute isAllowed={isRoot || isHeadQuarter || isAdmin}>
                <PartnerWithdrawal />
              </ProtectedRoute>
            }
          />

          <Route
            exact
            path='/coupon'
            element={
              <ProtectedRoute isAllowed={isAllowed('coupon/view')}>
                <Coupon />
              </ProtectedRoute>
            }
          />

          <Route
            exact
            path='/coupon/create'
            element={
              <ProtectedRoute isAllowed={isAllowed('coupon/create')}>
                <CouponCreate />
              </ProtectedRoute>
            }
          />

          <Route
            exact
            path='/coupon/edit/:id'
            element={
              <ProtectedRoute isAllowed={isAllowed('coupon/edit')}>
                <CouponEdit />
              </ProtectedRoute>
            }
          />

          <Route
            exact
            path='/loss'
            element={
              <ProtectedRoute isAllowed={isRoot || isHeadQuarter || isAdmin}>
                <Loss />
              </ProtectedRoute>
            }
          />

          <Route
            exact
            path='/test'
            element={
              <ProtectedRoute isAllowed={isRoot}>
                <Test />
              </ProtectedRoute>
            }
          />
        </Routes>
        <ToastContainer />
      </AuthProvider>
    </Router>
    </>
  )
}

export default App
