import React, { lazy, useState, Suspense, useEffect } from 'react'
import {
  Navbar,
  Nav,
  NavItem,
  NavLink,
  TabContent,
  TabPane,
  Row,
  Container
} from 'reactstrap'
import { UnderConstruction, Loader } from 'components'
import { useParams, useLocation, useNavigate } from 'react-router-dom'
import { MdClose } from "react-icons/md";

import colors from 'assets/colors'
import { checkSiteConfigs, fetchSiteConfig, updateSiteConfig } from 'api/siteConfigApi'

const SiteConfigBasicInfo = lazy(() => import('./SiteConfigBasicInfo'))
const MemberConfig = lazy(() => import('pages/Member/MemberConfig'))
const MemberGradeConfig = lazy(() => import('pages/Member/MemberGradeConfig'))
const MemberAttendanceConfig = lazy(() => import('pages/Member/MemberAttendanceConfig'))
const DepositConfig = lazy(() => import('pages/Deposit/DepositConfig'))
const WithdrawalConfig = lazy(() => import('pages/Withdrawal/WithdrawalConfig'))
const TransferConfig = lazy(() => import('pages/Transfer/TransferConfig'))
const ApiConfig = lazy(() => import('pages/Api/ApiConfigList'))
const Banner = lazy(() => import('pages/Banner/BannerList'));
const Popup = lazy(() => import('pages/Popup/PopupList'));


function SiteConfigDetail (props) {
  const { search, pathname } = useLocation()
  // const { id } = useParams()
  const id = props?.targetId ?? null
  const navigate = useNavigate()
  const [activeTab, setActiveTab] = useState('basic')
  const [siteConfig, setSiteConfig] = useState({ nickname: '' })
  const [isLoading, setIsLoading] = useState(false)
  const [error, setError] = useState(null)
  const toggleTab = tab => {
    if (activeTab !== tab) {
      setActiveTab(tab)
      // navigate(`${pathname}?tab=${tab}`)
    }
  }

  useEffect(() => {
    // const tabParam = new URLSearchParams(search).get('tab')
    // const initialTab = tabParam || 'basic'
    // setActiveTab(initialTab)
    fetchData(id)
  }, [id])

  useEffect(() => {

  }, [])

  const fetchData = async () => {
    setIsLoading(true)
    try {
      await checkSiteConfigs(id)
      const { success, data, message } = await fetchSiteConfig(id)

      if (!success) throw new Error(message)
      setSiteConfig(data)
    } catch (error) {
      console.error(error)
      setError(error)
      // Handle error case
    } finally {
      setIsLoading(false)
    }
  }

  const tabPrefix = `${siteConfig?.general?.siteName}(${siteConfig?.general?.domainName ?? '-'}`

  // Define labels for each tab
  const tabLabels = {
    basic: `${tabPrefix})의 기본정보`,
    banner: `${tabPrefix})의 메인배너`,
    popup: `${tabPrefix})의 팝업`,
    member: `${tabPrefix})의 회원설정`,
    grade: `${tabPrefix})의 회원등급설정`,
    attendance: `${tabPrefix})의 회원출석설정`,
    deposit: `${tabPrefix})의 입금설정`,
    withdrawal: `${tabPrefix})의 출금설정`,
    transfer: `${tabPrefix})의 전환설정`,
    api: `${tabPrefix})의 API 설정`,
  }
  return (
    <>
      <Navbar
        style={{ backgroundColor: colors.primary, padding: '1rem' }}
        expand='md'
      >
        <h5 className='m-0'>{tabLabels[activeTab]}</h5>{' '}
        <div className="text-right">
          <MdClose
            className="clickable-icon"
            size={18}
            onClick={() => props.onClose && props.onClose()}
          />
        </div>
        {/* Use the label based on the active tab */}
      </Navbar>
      <Container className='page-content' fluid style={{ padding: 30 }}>
        {isLoading && <Loader />}
        <Row>
          <Nav tabs>
            {[
              { tab: 'basic', label: '기본정보' },
              { tab: 'banner', label: '메인배너' },
              { tab: 'popup', label: '팝업' },
              { tab: 'member', label: '회원설정' },
              { tab: 'grade', label: '회원등급설정' },
              { tab: 'attendance', label: '회원출석설정' },
              { tab: 'deposit', label: '입금설정' },
              { tab: 'withdrawal', label: '출금설정' },
              { tab: 'transfer', label: '전환설정' },
              { tab: 'api', label: 'API설정' },

            ].map(({ tab, label }) => (
              <NavItem key={tab}>
                <NavLink
                  className={activeTab === tab ? 'sub-link active' : ''}
                  onClick={() => toggleTab(tab)}
                >
                  {label}
                </NavLink>
              </NavItem>
            ))}
          </Nav>
        </Row>
        <Row>
          <TabContent activeTab={activeTab}>
            {[
              { tab: 'basic', component: <SiteConfigBasicInfo { ...props } /> },
              { tab: 'banner', component: <Banner { ...props } /> },
              { tab: 'popup', component: <Popup { ...props } /> },
              { tab: 'member', component: <MemberConfig { ...props }/> },
              { tab: 'grade', component: <MemberGradeConfig { ...props }/> },
              { tab: 'attendance', component: <MemberAttendanceConfig { ...props }/> },
              { tab: 'deposit', component: <DepositConfig { ...props }/> },
              { tab: 'withdrawal', component: <WithdrawalConfig { ...props }/> },
              { tab: 'transfer', component: <TransferConfig { ...props }/> },
              { tab: 'api', component: <ApiConfig { ...props }/> },
            ].map(({ tab, component }) => (
              <TabPane key={tab} tabId={tab}>
                {activeTab === tab && ( // Only render the component when the activeTab matches the current tab
                  <Suspense fallback={<div>Loading...</div>}>
                    {component}
                  </Suspense>
                )}
              </TabPane>
            ))}
          </TabContent>
        </Row>
      </Container>
    </>
  )
}

export default SiteConfigDetail
