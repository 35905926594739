import React, { lazy, useState, Suspense, useEffect } from 'react'
import './Member.css'
import { Layout } from 'components'
import {
  Nav,
  NavItem,
  NavLink,
  TabContent,
  TabPane,
  Row,
  Container
} from 'reactstrap'
import { useLocation, useNavigate } from 'react-router-dom'

const MemberList = lazy(() => import('./MemberList'))
const MemberList2 = lazy(() => import('./MemberList2'))
// const MemberConfig = lazy(() => import('./MemberConfig'))
// const MemberGradeConfig = lazy(() => import('./MemberGradeConfig'))
// const MemberAttendanceConfig = lazy(() => import('./MemberAttendanceConfig'))

function Member (props) {
  const { search, pathname } = useLocation()
  const location = useLocation()
  const navigate = useNavigate()
  const [activeTab, setActiveTab] = useState('memberList2')

  const toggleTab = tab => {
    if (activeTab !== tab) {
      setActiveTab(tab)
      // navigate(`${pathname}?tab=${tab}`)
    }
  }

  useEffect(() => {
    const tabParam = new URLSearchParams(search).get('tab')
    const initialTab = tabParam || 'memberList2'
    setActiveTab(initialTab)
  }, [location])
  

  return (
    <Layout>
      <Container className='page-content' fluid style={{ padding: 30 }}>
        <Row>
          <Nav tabs>
            {[
              { tab: 'memberList2', label: '회원정보1' },
              { tab: 'memberList', label: '회원정보2' },
            ].map(({ tab, label }) => (
              <NavItem key={tab}>
                <NavLink
                  className={activeTab === tab ? 'sub-link active' : ''}
                  onClick={() => toggleTab(tab)}
                >
                  {label}
                </NavLink>
              </NavItem>
            ))}
          </Nav>
        </Row>
        <Row>
          <TabContent activeTab={activeTab}>
            {[
              { tab: 'memberList2', component: <MemberList2 /> },
              { tab: 'memberList', component: <MemberList /> },
            ].map(({ tab, component }) => (
              <TabPane key={tab} tabId={tab}>
                {activeTab === tab && ( // Only render the component when the activeTab matches the current tab
                  <Suspense fallback={<div>Loading...</div>}>
                    {component}
                  </Suspense>
                )}
              </TabPane>
            ))}
          </TabContent>
        </Row>
      </Container>
    </Layout>
  )
}

export default Member
