import api from './index.js'

export const uploadImage = async file => {
  try {
    let url = `/admin/upload/image`

    const formData = new FormData()
    formData.append('image', file)

    const response = await api.post(url, formData)

    if (response.status === 200) {
      const data = response.data
      // Handle successful response
      // ...
      return data
    } else {
      throw new Error('Failed to upload image')
    }
  } catch (error) {
    // Handle error
    // ...
    throw new Error('Failed to upload image')
  }
}

export const uploadAudio = async file => {
  try {
    let url = `/admin/upload/audio`

    const formData = new FormData()
    formData.append('audio', file)

    const response = await api.post(url, formData)

    if (response.status === 200) {
      const data = response.data
      // Handle successful response
      // ...
      return data
    } else {
      throw new Error('Failed to upload audio')
    }
  } catch (error) {
    // Handle error
    // ...
    throw new Error('Failed to upload audio')
  }
}
