import React, { useState, useEffect } from "react"
import {
  Input,
  Table,
  Button,
  Alert,
  Container,
  Navbar,
  Modal,
} from "reactstrap"
import colors from "assets/colors"
import dayjs from "dayjs"
import "dayjs/locale/ko"
import { Loader } from "components"
import { FaSortUp, FaSortDown, FaSyncAlt, FaList, FaCog } from "react-icons/fa"
import { DatePicker } from "reactstrap-date-picker"
import { fetchApiGames } from "api/apiGameApi"
import "react-medium-image-zoom/dist/styles.css"
import { useParams } from "react-router-dom"
import PaginationComponent from "components/PaginationComponent"
import Zoom from "react-medium-image-zoom"
import NewWindow from "react-new-window"
import "react-medium-image-zoom/dist/styles.css"
import { showAlert } from "utils/toast"
import { getPublicUrl } from "libs/functions"
import ApiGameEdit from "./ApiGameEdit"
import ApiGameCreate from "./ApiGameCreate"

const PUBLIC_URL = getPublicUrl()

const columns = [
  { key: "gameId", label: "gameId", sortable: true },
  { key: "name", label: "name", sortable: true },
  { key: "image", label: "image", sortable: true },
  { key: "active", label: "active", sortable: true },
  { key: "edit", label: "설정", sortable: true },
]

function ApiGameList(props) {
  const [currentCreateWindow, setCurrentCreateWindow] = useState(null)
  const id = props?.targetId ?? null
  // Partner data
  const [apiGames, setApiGames] = useState([])
  const [isLoading, setIsLoading] = useState(false)
  const [error, setError] = useState(null)

  // Pagination
  const pagesToShow = 10
  const [currentPage, setCurrentPage] = useState(1)
  const [pageSize, setPageSize] = useState(10)
  const [totalPages, setTotalPages] = useState(1)
  const [totalCount, setTotalCount] = useState(1)

  // Sorting
  const [sortColumn, setSortColumn] = useState("createdAt")
  const [sortDirection, setSortDirection] = useState("desc")

  // Selection
  const [hoveredRow, setHoveredRow] = useState(null)
  const [createModal, setCreateModal] = useState(false)
  const [detailModal, setDetailModal] = useState(false)
  const [selectedItem, setSelectedItem] = useState(null)

  const today = dayjs().format("YYYY-MM-DD")
  const startDate = dayjs().subtract(30, "day").format("YYYY-MM-DD")

  const [params, setParams] = useState({
    startDate: "",
    endDate: "",
    searchText: "",
  })

  const resetParams = async () => {
    setParams({
      startDate: "",
      endDate: "",
      searchText: "",
    })
  }

  const refresh = async () => {
    // Refresh the partner list
    fetchData()
  }

  const fetchData = async () => {
    setIsLoading(true)
    try {
      const { success, message, data } = await fetchApiGames(
        id,
        currentPage,
        pageSize,
        params,
        sortColumn,
        sortDirection
      )

      if (!success) throw new Error(message)
      setApiGames(data.apiGames)
      setTotalPages(data.meta.totalPages)
      setTotalCount(data.meta.count)
    } catch (error) {
      console.error(error)
      setError(error)
    } finally {
      setIsLoading(false)
    }
  }

  // console.log(apiGames)

  useEffect(() => {
    fetchData()
  }, [currentPage, pageSize, sortColumn, sortDirection])

  const handleDateChange = (end, date) => {
    setParams((prevParams) => ({
      ...prevParams,
      [end]: dayjs(date).format("YYYY-MM-DD"),
    }))
  }

  const handleParamsChange = (event) => {
    const { name, value, type } = event.target

    let updatedValue = value

    // Check if the input type is a radio button
    if (type === "radio") {
      if (value === "true") {
        updatedValue = true // Set to true if value is 'true'
      } else if (value === "false") {
        updatedValue = false // Set to false if value is 'false'
      }
    }

    if (type === "number") {
      updatedValue = Number(value)
    }

    const updatedParams = { ...params }
    const nameParts = name.split(".")
    let currentParams = updatedParams
    for (let i = 0; i < nameParts.length - 1; i++) {
      if (!currentParams[nameParts[i]]) {
        currentParams[nameParts[i]] = {}
      }
      currentParams = currentParams[nameParts[i]]
    }
    currentParams[nameParts[nameParts.length - 1]] = updatedValue
    setParams(updatedParams)
  }

  const handleFormSubmit = (e) => {
    e.preventDefault()
    fetchData()
  }

  const handlePageClick = (page) => setCurrentPage(page)

  const handlePageSizeChange = (e) => {
    setCurrentPage(1)
    setPageSize(parseInt(e.target.value))
  }

  const handleSort = (column) => {
    if (sortColumn === column) {
      // If the same column is clicked again, toggle the sort direction
      setSortDirection((prevDirection) =>
        prevDirection === "asc" ? "desc" : "asc"
      )
    } else {
      // If a different column is clicked, set it as the new sort column and default to ascending sort direction
      setSortColumn(column)
      setSortDirection("asc")
    }
  }

  const renderColumnLabel = (column) => column.label

  return (
    <>
      <Navbar
        style={{ backgroundColor: colors.primary, padding: "1rem" }}
        expand="md"
      >
        <h5 className="m-0">
          {"ApiGames: "}
          {apiGames[0]?.thirdParty?.name}
        </h5>{" "}
        {/* Use the label based on the active tab */}
      </Navbar>
      <Container
        className="page-content"
        style={{ paddingBottom: 100, paddingTop: 30 }}
      >
        <div style={{ paddingBottom: 100 }}>
          {isLoading && <Loader />}
          <Table>
            <tbody className="align-items-center">
              <tr>
                <td
                  className="form-header w-100p align-middle text-center"
                  xs={3}
                >
                  검색
                </td>
                <td xs={9}>
                  <div className="d-flex align-items-center">
                    {/* <div className="me-3">
                                            <DatePicker
                                                value={params.startDate}
                                                onChange={(date) => handleDateChange('startDate', date)}
                                                className="form-control form-control-sm"
                                                placeholder="시작일"
                                                showClearButton={false}
                                                dateFormat="YYYY-MM-DD"
                                                maxDate={new Date(params.endDate).toString()}
                                            />
                                        </div>

                                        <div className="me-3">
                                            <DatePicker
                                                value={params.endDate}
                                                onChange={(date) => handleDateChange('endDate', date)}
                                                className="form-control form-control-sm"
                                                placeholder="종료일"
                                                showClearButton={false}
                                                dateFormat="YYYY-MM-DD"
                                                minDate={new Date(params.startDate).toString()}
                                            />
                                        </div> */}
                    <div className="me-3">
                      <Input
                        type="text"
                        name="searchText"
                        value={params?.searchText}
                        onChange={handleParamsChange}
                        className="form-control form-control-sm"
                        placeholder="검색어"
                      />
                    </div>
                    <Button
                      style={{
                        backgroundColor: colors.primary,
                        borderColor: colors.primary,
                      }}
                      onClick={handleFormSubmit}
                      color="primary"
                      size="sm"
                    >
                      검색
                    </Button>
                    &nbsp;
                    <Button onClick={resetParams} color="warning" size="sm">
                      초기화
                    </Button>
                  </div>
                </td>
                <td className="text-end">
                  <Button
                    color="primary"
                    onClick={() => {
                      setCreateModal(true)
                    }}
                  >
                    API-GAME 추가
                  </Button>
                </td>
              </tr>
            </tbody>
          </Table>

          <>
            <div className="search-header">
              <div>
                {`검색된 apiGames:`}
                <span className="text-danger">
                  {totalCount.toLocaleString()}
                </span>
                개
              </div>
              <div className="select-wrapper">
                <div className="refresh">
                  <button className="btn btn-link" onClick={refresh}>
                    <FaSyncAlt style={{ color: colors.primary }} />
                  </button>
                </div>

                <select
                  className="form-control"
                  onChange={handlePageSizeChange}
                  value={pageSize}
                >
                  <option value="5">5개씩 보기</option>
                  <option value="10">10개씩 보기</option>
                  <option value="20">20개씩 보기</option>
                  <option value="50">50개씩 보기</option>
                </select>
              </div>
            </div>
            {error && <Alert color="danger">{error?.message}</Alert>}

            <Table bordered responsive>
              <thead>
                <tr>
                  {columns.map((column) => (
                    <th
                      key={column.key}
                      className="fixed-width"
                      width={column.width ?? "auto"}
                      onClick={() =>
                        column.sortable ? handleSort(column.key) : {}
                      }
                    >
                      {renderColumnLabel(column)}
                      {column.sortable && sortColumn === column.key && (
                        <>
                          {sortDirection === "asc" ? (
                            <FaSortUp />
                          ) : (
                            <FaSortDown />
                          )}
                        </>
                      )}
                    </th>
                  ))}
                </tr>
              </thead>
              <tbody className="text-center align-middle">
                {apiGames.length === 0 ? (
                  <tr>
                    <td className="text-center" colSpan={columns.length}>
                      결과없음
                    </td>
                  </tr>
                ) : (
                  apiGames.map((item, index) => (
                    <tr
                      key={index}
                      className={index === hoveredRow ? "hovered" : ""}
                      onMouseEnter={() => setHoveredRow(index)}
                      onMouseLeave={() => setHoveredRow(null)}
                    >
                      {columns.map((column) => {
                        return (
                          <td key={column.key}>
                            {column.key === "gameId" && item?.gameId}

                            {column.key === "name" && item?.name}

                            {column.key === "thirdParty" &&
                              item?.thirdParty.name}

                            {column.key === "image" && (
                              <Zoom>
                                <img
                                  src={`${PUBLIC_URL}/${item?.image}`}
                                  alt="이미지"
                                  width={50}
                                  className="img-fluid"
                                />
                              </Zoom>
                            )}

                            {column.key === "active" &&
                              (item?.active ? "활성" : "비활성")}
                            {column.key === "edit" && (
                              <FaCog
                                size={20}
                                role={"button"}
                                style={{ color: colors.primary }}
                                onClick={() => {
                                  setSelectedItem(item)
                                  setDetailModal(true)
                                  // setApiGames((prevApis) =>
                                  //     prevApis.map((api) =>
                                  //         api._id === item._id
                                  //             ? { ...api, open: !api.open }
                                  //             : api
                                  //     )
                                  // )
                                }}
                              />
                            )}
                          </td>
                        )
                      })}
                    </tr>
                  ))
                )}
              </tbody>
            </Table>
            <PaginationComponent
              totalPages={totalPages}
              currentPage={currentPage}
              pagesToShow={pagesToShow}
              handlePageClick={handlePageClick}
            />
          </>

          {/* <div
                        className={`action-bar-container show`}
                    >
                        <div className="action-bar">
                            <div className="left-actions">
                            </div>
                            <div className="right-actions">
                                <Button
                                    className="me-2"
                                    color="primary"
                                    onClick={() => {
                                        setCreateModal(true)
                                        if (currentCreateWindow) currentCreateWindow.focus()
                                    }}
                                >
                                    API게임 추가
                                </Button>
                            </div>
                        </div>
                    </div> */}

          <Modal
            size={"xl"}
            isOpen={detailModal}
            toggle={() => setDetailModal(!detailModal)}
            onClosed={refresh}
          >
            <ApiGameEdit targetId={selectedItem?._id} />
          </Modal>

          <Modal
            size={"xl"}
            isOpen={createModal}
            toggle={() => setCreateModal(!createModal)}
            onClosed={refresh}
          >
            <ApiGameCreate />
          </Modal>

          {/* {createModal && (
                        <NewWindow
                            url={`/api-game/create/${id}`}
                            key={"create"}
                            title={"API 추가"}
                            features={{
                                width: 1200,
                                height: 900,
                                left: 10,
                                top: 0,
                            }}
                            copyStyles={false}
                            onOpen={(window) => {
                                setCurrentCreateWindow(window)
                            }}
                            onBlock={() => showAlert("팝업 사용을 허용해주세요!")}
                            onUnload={() => {
                                setCurrentCreateWindow(null)
                                setCreateModal(false)
                                refresh()
                            }}
                        />
                    )}

                    {apiGames.map((item, index) => {
                        let url = `/api-game/edit/${item._id}`
                        if (item.tab) {
                            url += `?tab=${item.tab}`
                        }
                        return (
                            item.open && (
                                <NewWindow
                                    key={item._id}
                                    title={item.nickname}
                                    url={url}
                                    features={{
                                        width: 1200,
                                        height: 900,
                                        left: 10,
                                        top: 0,
                                    }}
                                    copyStyles={false}
                                    onBlock={() => showAlert("팝업 사용을 허용해주세요!")}
                                    onUnload={() => {
                                        setApiGames((prevApis) => {
                                            const updatedApis = [...prevApis]
                                            updatedApis[index].open = false
                                            return updatedApis
                                        })
                                    }}
                                />
                            )
                        )
                    })} */}
        </div>
      </Container>
    </>
  )
}

export default ApiGameList
