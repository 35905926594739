import api from './index.js'
const BASE_URL = '/admin/partner-withdrawal'

export const fetchPartnerWithdrawals = async (
  currentPage,
  pageSize,
  params,
  sortColumn,
  sortDirection
) => {
  try {
    const queryParams = new URLSearchParams(params).toString()
    const response = await api.get(
      `${BASE_URL}?pageNumber=${currentPage}&pageSize=${pageSize}&sortColumn=${sortColumn}&sortDirection=${sortDirection}&${queryParams}`
    )

    if (response.status === 200) {
      const data = response.data
      // Handle successful response
      // ...
      return data
    } else {
      throw new Error('Failed to fetch withdrawals')
    }
  } catch (error) {
    // Handle error
    // ...
    throw new Error('Failed to fetch withdrawals')
  }
}

export const fetchPartnerWithdrawal = async id => {
  try {
    const response = await api.get(`${BASE_URL}/${id}`)

    if (response.status === 200) {
      const data = response.data
      // Handle successful response
      // ...
      return data
    } else {
      throw new Error('Failed to fetch withdrawal')
    }
  } catch (error) {
    // Handle error
    // ...
    throw new Error('Failed to fetch withdrawal')
  }
}

export const updatePartnerWithdrawal = async (id, withdrawaldData) => {
  try {
    const response = await api.put(`${BASE_URL}/${id}`, withdrawaldData)

    if (response.status === 200) {
      const data = response.data
      // Handle successful response
      // ...
      return data
    } else {
      throw new Error('Failed to update withdrawal status')
    }
  } catch (error) {
    // Handle error
    // ...
    throw new Error('Failed to update withdrawal status')
  }
}

export const createPartnerWithdrawal = async withdrawalData => {
  try {
    const response = await api.post(`${BASE_URL}`, withdrawalData)

    if (response.status === 200) {
      const data = response.data
      return data
    } else {
      throw new Error('Failed to create partner-withdrawal')
    }
  } catch (error) {
    // Handle error
    // ...
    throw new Error('Failed to create partner-withdrawal')
  }
}