import React, { lazy, useState, Suspense, useEffect } from 'react'
import './Bet.css'
import { Layout, NotAllowed, UnderConstruction } from 'components'
import {
  Nav,
  NavItem,
  NavLink,
  TabContent,
  TabPane,
  Row,
  Container
} from 'reactstrap'
import { useParams, useLocation, useNavigate } from 'react-router-dom'
import { useSelector } from 'react-redux'
import { userSelector } from 'features/auth/authSelectors'
import BetStatus from './BetStatus'
import BetStatusSlotList from './BetStatusSlotList'

const BetList = lazy(() => import('./BetList'))
const BetSportsList = lazy(() => import('./BetSportsList'))
const BetCasinoList = lazy(() => import('./BetCasinoList'))
const BetSlotList = lazy(() => import('./BetSlotList'))

function Bet(props) {
  const currentUser = useSelector(userSelector)

  const { search, pathname } = useLocation()
  const navigate = useNavigate()
  const [activeTab, setActiveTab] = useState('list')

  const toggleTab = tab => {
    if (activeTab !== tab) {
      setActiveTab(tab)
      // navigate(`${pathname}?tab=${tab}`)
    }
  }

  useEffect(() => {
    const tabParam = new URLSearchParams(search).get('tab')
    let initialTab = 'list'
    if(tabParam) {
      initialTab = tabParam
    }

    if (props.sub) initialTab = 'list'
    setActiveTab(initialTab)
  }, [])

  const { isRoot, previleges } = currentUser

  if ((!isRoot && !previleges.includes('bet/view'))) return <NotAllowed />

  const children = (
    <>
      <Container className='page-content' fluid style={{ padding: 30 }}>
        <Row>
          <Nav tabs>
            {[
              { tab: 'list', label: '베팅목록(슬+카)' },
              { tab: 'slotList', label: '베팅목록(슬)' },
              // { tab: 'sports', label: '스포츠' },
              { tab: 'casinoList', label: '베팅목록(카)' },
              { tab: 'status', label: '베팅현황(슬+카)' },
              { tab: 'slotStatus', label: '베팅현황(슬)' },
              // { tab: 'sports', label: '스포츠' },
              { tab: 'casinoStatus', label: '베팅현황(카)' },
            ].map(({ tab, label }) => (
              <NavItem key={tab}>
                <NavLink
                  className={activeTab === tab ? 'sub-link active' : ''}
                  onClick={() => toggleTab(tab)}
                >
                  {label}
                </NavLink>
              </NavItem>
            ))}
          </Nav>
        </Row>
        <Row>
          <TabContent activeTab={activeTab}>
            {[
              { tab: 'status', component: <BetStatus category={'all'} {...props} /> },
              { tab: 'slotStatus', component: <BetStatus category={'slot'} {...props} /> },
              { tab: 'casinoStatus', component: <BetStatus category={'casino'} {...props} /> },
              { tab: 'list', component: <BetList {...props} /> },
              { tab: 'sports', component: <BetSportsList {...props} /> },
              { tab: 'casinoList', component: <BetCasinoList {...props} /> },
              { tab: 'slotList', component: <BetSlotList {...props} /> },
            ].map(({ tab, component }) => (
              <TabPane key={tab} tabId={tab}>
                {activeTab === tab && ( // Only render the component when the activeTab matches the current tab
                  <Suspense fallback={<div>Loading...</div>}>
                    {component}
                  </Suspense>
                )}
              </TabPane>
            ))}
          </TabContent>
        </Row>
      </Container>
    </>
  )
  if (props.sub) {
    return children
  }

  return <Layout>{children}</Layout>
}

export default Bet
