export const previleges = {
    admin: ['view', 'create', 'edit', 'delete'],
    adjustment: ['view'],
    'site-config': ['view', 'create', 'edit'],
    partner: ['view', 'create/user', 'create/children', 'edit', 'edit/adjustment', 'edit/giveEgg', 'edit/takeEgg'],
    user: ['view', 'view/password', 'view/phone', 'view/bank', 'view/download', 'edit', 'edit/adjustment'],
    deposit: ['view', 'edit/status'],
    withdrawal: ['view', 'edit/status'],
    transfer: ['view', 'edit/status'],
    coupon: ['view', 'create', 'edit', 'delete'],
    referral: ['view', 'create', 'delete'],
    mail: ['view', 'create', 'delete'],
    bet: ['view'],
    'help-center': ['view'],
    history: ['view', 'view/egg', 'view/wallet', 'view/user', 'view/login', 'view/adjustment']
  }