import React, { useState, useEffect } from "react"
import "./WalletHistory.css"
import {
  Input,
  Table,
  Button,
  Alert,
} from "reactstrap"
import colors from "assets/colors"
import dayjs from "dayjs"
import { Loader, NotAllowed } from "components"
import { FaSortUp, FaSortDown, FaSyncAlt } from "react-icons/fa"
import { DatePicker } from "reactstrap-date-picker"
import { fetchWalletHistorys, deleteWalletHistory } from "api/walletHistoryApi"
import UserBadge from "components/UserBadge"
import { showAlert, showSuccess } from "utils/toast"
import { useParams } from "react-router-dom"
import { NumberDisplay } from "components"
import { gameTypes, walletCategory, roleNames } from "libs/translations"
import PaginationComponent from "components/PaginationComponent"
import { useSelector } from 'react-redux'
import { userSelector } from 'features/auth/authSelectors'

const columns = [
  { key: "roleName", label: "회원구분", sortable: false },
  { key: "category", label: "보유구분", sortable: true },
  { key: "classification", label: "분류", sortable: true },
  { key: "username", label: "아이디/닉네임", align: 'left', sortable: true },
  { key: "reason", label: "사유", sortable: true },
  { key: "diff", label: "변동", align: "right", sortable: true },
  { key: "after", label: "변동후 보유", align: "right", sortable: true },
  { key: "createdBy", label: "처리자", sortable: true },
  { key: "createdAt", label: "처리일", sortable: true },
]

function WalletHistoryList(props) {
  const id = props?.targetId
  const currentUser = useSelector(userSelector)
  // Partner data
  const [walletHistorys, setWalletHistorys] = useState([])
  const [isLoading, setIsLoading] = useState(false)
  const [error, setError] = useState(null)

  // Pagination
  const pagesToShow = 10
  const [currentPage, setCurrentPage] = useState(1)
  const [pageSize, setPageSize] = useState(10)
  const [totalPages, setTotalPages] = useState(1)
  const [totalCount, setTotalCount] = useState(1)

  // Sorting
  const [sortColumn, setSortColumn] = useState("createdAt")
  const [sortDirection, setSortDirection] = useState("desc")

  // Selection
  const [hoveredRow, setHoveredRow] = useState(null)
  const [isCheckedAll, setIsCheckedAll] = useState(false)
  const [checkedItems, setCheckedItems] = useState([])

  const today = dayjs().format('YYYY-MM-DD')
  const startDate = dayjs().subtract(30, "day").format("YYYY-MM-DD")

  const [params, setParams] = useState({
    id: id ?? '',
    startDate: today,
    endDate: today,
    searchText: "",
    type: "",
    category: "",
    classification: props?.classification ?? ""
  })

  const resetParams = async () => {
    setParams({
      type: props?.type ?? 'member',
      startDate: today,
      endDate: today,
      searchText: "",
      category: "",
      classification: props?.classification ?? ""
    })
  }

  const refresh = async () => {
    // Refresh the partner list
    fetchData(true)

    // Clear the checked items
    setCheckedItems([])
    setIsCheckedAll(false)
  }

  const fetchData = async (applyParams = true) => {
    setIsLoading(true)
    try {
      const { success, message, data } = await fetchWalletHistorys(
        currentPage,
        pageSize,
        applyParams ? params : {},
        sortColumn,
        sortDirection
      )

      if (!success) throw new Error(message)
      setWalletHistorys(data.walletHistorys)
      setTotalPages(data.meta.totalPages)
      setTotalCount(data.meta.count)
      setError(null)

    } catch (error) {
      console.error(error)
      setError(error)
    } finally {
      setIsLoading(false)
    }
  }

  useEffect(() => {
    fetchData()
  }, [currentPage, pageSize, sortColumn, sortDirection])

  const handleDateChange = (end, date) => {
    if (date === null || date === undefined) {
      setParams((prevParams) => ({
        ...prevParams,
        [end]: null, // or use a default value like an empty string ''
      }));
    } else {  
      setParams((prevParams) => ({
        ...prevParams,
        [end]: dayjs(date).format("YYYY-MM-DD"),
      }));
    }
  }

  const handleParamsChange = event => {
    const { name, value, type } = event.target

    let updatedValue = value

    // Check if the input type is a radio button
    if (type === 'radio') {
      if (value === 'true') {
        updatedValue = true // Set to true if value is 'true'
      } else if (value === 'false') {
        updatedValue = false // Set to false if value is 'false'
      }
    }

    if (type === 'number') {
      updatedValue = Number(value)
    }

    const updatedParams = { ...params }
    const nameParts = name.split('.')
    let currentParams = updatedParams
    for (let i = 0; i < nameParts.length - 1; i++) {
      if (!currentParams[nameParts[i]]) {
        currentParams[nameParts[i]] = {}
      }
      currentParams = currentParams[nameParts[i]]
    }
    currentParams[nameParts[nameParts.length - 1]] = updatedValue
    setParams(updatedParams)
  }

  const handleFormSubmit = (e) => {
    e.preventDefault()
    fetchData(true)
  }

  const handlePageClick = (page) => setCurrentPage(page)

  const handlePageSizeChange = (e) => {
    setCurrentPage(1)
    setPageSize(parseInt(e.target.value))
  }

  const handleSort = (column) => {
    if (sortColumn === column) {
      // If the same column is clicked again, toggle the sort direction
      setSortDirection((prevDirection) =>
        prevDirection === "asc" ? "desc" : "asc"
      )
    } else {
      // If a different column is clicked, set it as the new sort column and default to ascending sort direction
      setSortColumn(column)
      setSortDirection("asc")
    }
  }

  const handleCheckAll = () => {
    setIsCheckedAll(!isCheckedAll)
    setCheckedItems(isCheckedAll ? [] : walletHistorys.map((item) => item._id))
  }

  const handleCheckItem = (itemId) => {
    if (checkedItems.includes(itemId)) {
      setCheckedItems((prevCheckedItems) =>
        prevCheckedItems.filter((id) => id !== itemId)
      )
    } else {
      setCheckedItems((prevCheckedItems) => [...prevCheckedItems, itemId])
    }
  }

  const renderColumnLabel = (column) => column.label

  const { isRoot, previleges } = currentUser

  if((!isRoot && !previleges.includes('history/view/wallet'))) return <NotAllowed />

  return (
    <div style={{ paddingBottom: 100 }}>
      {isLoading && <Loader />}

      <Table>
        <tbody className="align-items-center">
          <tr>
            <td className="form-header w-100p align-middle text-center" xs={3}>
              검색
            </td>
            <td xs={9}>
              <div className="d-flex align-items-center">
                <div className="me-3">
                  <DatePicker
                    value={params.startDate}
                    onChange={(date) => handleDateChange('startDate', date)}
                    className="form-control form-control-sm"
                    placeholder="시작일"
                    showClearButton={false}
                    dateFormat="YYYY-MM-DD"
                    maxDate={new Date(params.endDate).toString()}
                  />
                </div>

                <div className="me-3">
                  <DatePicker
                    value={params.endDate}
                    onChange={(date) => handleDateChange('endDate', date)}
                    className="form-control form-control-sm"
                    placeholder="종료일"
                    showClearButton={false}
                    dateFormat="YYYY-MM-DD"
                    minDate={new Date(params.startDate).toString()}
                  />
                </div>

                <div className="me-3">
                  <Input
                    type="select"
                    name="type"
                    className="input-sm"
                    value={params?.type}
                    onChange={handleParamsChange}
                  >
                    <option value="">유형</option>
                    <option value="casino">카지노</option>
                    <option value="sports">스포츠</option>
                    <option value="profit">수익금</option>
                  </Input>
                </div>

                <div className="me-3">
                  <Input
                    type="select"
                    name="category"
                    className="input-sm"
                    value={params?.category}
                    onChange={handleParamsChange}
                  >
                    <option value="">구분</option>
                    <option value="balance">보유금</option>
                    <option value="point">포인트</option>
                    <option value="profit">수익금</option>
                  </Input>
                </div>

                <div className="me-3">
                  <Input
                    type="select"
                    name="classification"
                    className="input-sm"
                    value={params?.classification}
                    onChange={handleParamsChange}
                  >
                    <option value="">분류</option>
                    <option value="베팅">베팅</option>
                    <option value="입금">입금</option>
                    <option value="출금">출금</option>
                    <option value="가입">가입</option>
                    <option value="쿠폰">쿠폰</option>
                    <option value="출석체크">출석체크</option>
                    <option value="관리자증감">관리자증감</option>
                    <option value="보유금전환">보유금전환</option>
                    <option value="포인트전환">포인트전환</option>
                  </Input>
                </div>

                <div className="me-3">
                  <Input
                    type="text"
                    name="searchText"
                    value={params?.searchText}
                    onChange={handleParamsChange}
                    className="form-control form-control-sm"
                    placeholder="검색어"
                  />
                </div>

                <Button
                  style={{
                    backgroundColor: colors.primary,
                    borderColor: colors.primary,
                  }}
                  onClick={handleFormSubmit}
                  color="primary"
                  size="sm"
                >
                  검색
                </Button>
                &nbsp;
                <Button
                  onClick={resetParams}
                  color="warning"
                  size="sm"
                >
                  초기화
                </Button>
              </div>
            </td>
          </tr>
        </tbody>
      </Table>

      <>
        <div className="search-header">
          <div>
            {`검색된 기록: `}
            <span className="text-danger">{totalCount.toLocaleString()}</span>개
          </div>
          <div className="select-wrapper">
            <div className="refresh">
              <button className="btn btn-link" onClick={refresh}>
                <FaSyncAlt style={{ color: colors.primary }} />
              </button>
            </div>

            <select
              className="form-control"
              onChange={handlePageSizeChange}
              value={pageSize}
            >
              <option value="5">5개씩 보기</option>
              <option value="10">10개씩 보기</option>
              <option value="20">20개씩 보기</option>
              <option value="50">50개씩 보기</option>
            </select>
          </div>
        </div>
        {error && <Alert color="danger">{error?.message}</Alert>}

        <Table bordered responsive>
          <thead>
            <tr>
              {columns.map((column) => (
                <th
                  key={column.key}
                  className="fixed-width"
                  width={column.width ?? "auto"}
                  onClick={() =>
                    column.sortable ? handleSort(column.key) : {}
                  }
                >
                  {column.key === "checkbox" && (
                    <input
                      type="checkbox"
                      checked={isCheckedAll}
                      onChange={handleCheckAll}
                    />
                  )}
                  {renderColumnLabel(column)}
                  {column.sortable && sortColumn === column.key && (
                    <>
                      {sortDirection === "asc" ? <FaSortUp /> : <FaSortDown />}
                    </>
                  )}
                </th>
              ))}
            </tr>
          </thead>
          <tbody className="text-center">
            {walletHistorys.length === 0 ? (
              <tr>
                <td className="text-center" colSpan={columns.length}>
                  결과없음
                </td>
              </tr>
            ) : (
              walletHistorys.map((item, index) => (
                <tr
                  key={index}
                  className={`${index === hoveredRow ? "hovered" : ""} ${item.type === "casino" ? "row-casino" : item.type === "sports" ? "row-sports" : ""}`}
                  onMouseEnter={() => setHoveredRow(index)}
                  onMouseLeave={() => setHoveredRow(null)}
                >
                  {columns.map((column) => (
                    <td
                      key={column.key}
                      className={`${column.align === "right" ? "text-end " :
                          column.align === "left" ? "text-start" : ""
                        }`}
                    >
                      {column.key === "checkbox" && (
                        <input
                          type="checkbox"
                          checked={checkedItems.includes(item?._id)}
                          onChange={() => handleCheckItem(item?._id)}
                        />
                      )}

                      {column.key === "roleName" && (
                        <span>{roleNames[item?.user?.roleName]}</span>
                      )}

                      {column.key === "category" && (
                        <>
                          <span className={item.type === 'casino' ? 'casino-text' : 'sports-text'}>{gameTypes[item?.type]}</span>
                          &nbsp;
                          <span>{walletCategory[item?.category]}</span>
                        </>
                      )}
                      {column.key === "classification" && (
                        <span>{item?.classification}</span>
                      )}
                      {column.key === "username" && (
                        <UserBadge user={item?.user} />
                      )}

                      {column.key === "reason" && <span>{item?.reason}</span>}
                      {column.key === "diff" && (
                        <span>
                          {
                            item.category === 'point' ?
                              <NumberDisplay
                                value={item?.diff}
                                suffix="P"
                                showPrefix={true}
                                decimalPoints={0}
                              /> :
                              <NumberDisplay
                                value={item?.diff}
                                suffix="원"
                                showPrefix={true}
                                decimalPoints={0}
                              />
                          }
                        </span>
                      )}

                      {column.key === "after" && (
                        <span>
                          {
                            item.category === 'point' ?
                              <NumberDisplay
                                value={item?.after}
                                suffix="P"
                                showPrefix={true}
                                decimalPoints={0}
                              /> :
                              <NumberDisplay
                                value={item?.after}
                                suffix="원"
                                showPrefix={true}
                                decimalPoints={0}
                              />
                          }
                        </span>
                      )}

                      {column.key === "createdBy" && (
                        item?.access?.createdBy?._id ?
                          <UserBadge user={item?.access?.createdBy} />
                          : '시스템'
                      )}
                      {column.key === "createdAt" && (
                        <span>
                          {item?.createdAt ? `${dayjs(item?.createdAt).format(
                            "YYYY-MM-DD HH:mm:ss"
                          )}` : '-'}
                        </span>
                      )}
                    </td>
                  ))}
                </tr>
              ))
            )}
          </tbody>
        </Table>
        <PaginationComponent
          totalPages={totalPages}
          currentPage={currentPage}
          pagesToShow={pagesToShow}
          handlePageClick={handlePageClick}
        />
      </>
    </div>
  )
}

export default WalletHistoryList
