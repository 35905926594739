import api from './index.js'

export const fetchCoupons = async (
  currentPage,
  pageSize,
  params,
  sortColumn,
  sortDirection
) => {
  try {
    const queryParams = new URLSearchParams(params).toString() // Convert params object to query string
    const response = await api.get(
      `/admin/coupon?pageNumber=${currentPage}&pageSize=${pageSize}&sortColumn=${sortColumn}&sortDirection=${sortDirection}&${queryParams}`
    )

    if (response.status === 200) {
      const data = response.data
      // Handle successful response
      // ...
      return data
    } else {
      throw new Error('Failed to fetch coupons')
    }
  } catch (error) {
    // Handle error
    // ...
    throw new Error('Failed to fetch coupons')
  }
}
export const fetchCoupon = async id => {
  try {
    const response = await api.get(`/admin/coupon/${id}`)

    if (response.status === 200) {
      const data = response.data
      // Handle successful response
      // ...
      return data
    } else {
      throw new Error('Failed to fetch coupon')
    }
  } catch (error) {
    // Handle error
    // ...
    throw new Error('Failed to fetch coupon')
  }
}

export const updateCoupon = async (id, couponData) => {
  try {
    const response = await api.put(`/admin/coupon/${id}`, couponData)

    if (response.status === 200) {
      const data = response.data
      // Handle successful response
      // ...
      return data
    } else {
      throw new Error('Failed to update coupon')
    }
  } catch (error) {
    // Handle error
    // ...
    throw new Error('Failed to update coupon')
  }
}

export const createCoupon = async couponData => {
  try {
    const response = await api.post('/admin/coupon', couponData)

    if (response.status === 200) {
      const data = response.data
      // Handle successful response
      // ...
      return data
    } else {
      throw new Error('Failed to create coupon')
    }
  } catch (error) {
    // Handle error
    // ...
    throw new Error('Failed to create coupon')
  }
}

export const deleteCoupon = async id => {
  try {
    const response = await api.delete(`/admin/coupon/${id}`)

    if (response.status === 200) {
      const data = response.data
      // Handle successful response
      // ...
      return data
    } else {
      throw new Error('Failed to delete coupon')
    }
  } catch (error) {
    // Handle error
    // ...
    throw new Error('Failed to delete coupon')
  }
}
