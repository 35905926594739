import React, { lazy, useState, Suspense } from 'react'
import { Layout, UnderConstruction } from 'components'
import {
  Row,
  Container
} from 'reactstrap'

const BannerList = lazy(() => import('./BannerList'))
// const BannerConfig = lazy(() => import('./BannerConfig'))
// const BannerGradeConfig = lazy(() => import('./BannerGradeConfig'))
// const BannerAttendanceConfig = lazy(() => import('./BannerAttendanceConfig'))

function Banner (props) {
  return (
    <Layout>
      <Container className='page-content' fluid style={{ padding: 30 }}>
        <Row>
          <Suspense fallback={<div>Loading...</div>}>
            <BannerList />
          </Suspense>
        </Row>
      </Container>
    </Layout>
  )
}

export default Banner
