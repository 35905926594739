import React, { useState, useEffect } from "react"
import { Input, Alert, Table, Button } from "reactstrap"
import colors from "assets/colors"
import dayjs from "dayjs"
import { Loader, NumberDisplay } from "components"
import { FaSyncAlt, FaSortUp, FaSortDown, FaBook } from "react-icons/fa"
import { DatePicker } from "reactstrap-date-picker"
import { fetchBets, revokeBet } from "api/betApi"
import { showAlert, showSuccess } from "utils/toast"
import NewWindow from "react-new-window"
import { useLocation } from "react-router-dom"
import UserBadge from "components/UserBadge"
import { betStatuses, betOutcomes } from "libs/translations"
import PaginationComponent from "components/PaginationComponent"

const columns = [
  { key: "checkbox", label: "", sortable: false },
  { key: "username", label: "아이디/닉네임", align: "left", sortable: true },
  { key: "provider", label: "게임사", sortable: true },
  { key: "category", label: "분류", sortable: true },
  { key: "game", label: "게임명", sortable: true },
  { key: "updatedAt", label: "베팅시간", sortable: true },
  { key: "betAmount", label: "베팅금액", align: "right", sortable: true },
  { key: "profitAmount", label: "당첨금", align: "right", sortable: true },
  // { key: "settleAmount", label: "최종정산금", align: "right", sortable: true },
  { key: "flag.bet", label: "베팅상태", sortable: true },
  // { key: "outcome", label: "베팅결과", sortable: true },
  { key: "action", label: "액션", sortable: true },
]
function LossListList(props) {
  const id = props?.targetId
  const location = useLocation()

  useEffect(() => {
    if (location?.state?.refresh) refresh()
  }, [location.state])

  const [bets, setBets] = useState([])
  const pagesToShow = 10
  const [updateFlag, setUpdateFlag] = useState(false)
  const [currentPage, setCurrentPage] = useState(1)
  const [pageSize, setPageSize] = useState(10)
  const [totalPages, setTotalPages] = useState(1)
  const [totalCount, setTotalCount] = useState(1)
  const [sortColumn, setSortColumn] = useState("createdAt")
  const [sortDirection, setSortDirection] = useState("desc")
  const [hoveredRow, setHoveredRow] = useState(null)
  const [isCheckedAll, setIsCheckedAll] = useState(false)
  const [checkedItems, setCheckedItems] = useState([])
  const [isLoading, setIsLoading] = useState(false)
  const [error, setError] = useState(null)

  const today = dayjs().format("YYYY-MM-DD")
  const startDate = dayjs().subtract(30, "day").format("YYYY-MM-DD")

  const [params, setParams] = useState({
    id: id ?? "",
    category: props.category || "",
    loss: props?.loss ?? false,
    startDate: today,
    endDate: today,
    searchText: "",
  })

  const resetParams = async () => {
    setParams({
      id: id ?? "",
      category: props.category || "",
      loss: props?.loss ?? false,
      startDate: today,
      endDate: today,
      searchText: "",
    })
  }

  useEffect(() => {
    setUpdateFlag(true)
  }, [])

  useEffect(() => {
    if (updateFlag) {
      refresh()
    }
  }, [updateFlag])

  useEffect(() => {
    setParams({
      id: id ?? "",
      category: props.category || "",
      loss: props?.loss ?? false,
      startDate: today,
      endDate: today,
      searchText: "",
    })
    setUpdateFlag(true)
  }, [props.targetId])

  const refresh = async () => {
    fetchData(true)
    setError(null)
    setUpdateFlag(false)
    setCheckedItems([])
    setIsCheckedAll(false)
  }

  const fetchData = async (applyParams = true) => {
    setIsLoading(true)
    try {
      const { success, data } = await fetchBets(
        currentPage,
        pageSize,
        applyParams ? params : {},
        sortColumn,
        sortDirection
      )

      if (!success) new Error("Something is wrong")
      setBets(data.bets)
      setTotalPages(data.meta.totalPages)
      setTotalCount(data.meta.count)
    } catch (error) {
      console.error(error)
      setError(error.message)
    } finally {
      setIsLoading(false)
    }
  }

  useEffect(() => {
    fetchData()
  }, [currentPage, pageSize, sortColumn, sortDirection])

  const handleDateChange = (end, date) => {
    setParams((prevParams) => ({
      ...prevParams,
      [end]: dayjs(date).format("YYYY-MM-DD"),
    }))
  }

  const handleParamsChange = (event) => {
    const { name, value, type } = event.target

    let updatedValue = value

    // Check if the input type is a radio button
    if (type === "radio") {
      if (value === "true") {
        updatedValue = true // Set to true if value is 'true'
      } else if (value === "false") {
        updatedValue = false // Set to false if value is 'false'
      }
    }

    if (type === "number") {
      updatedValue = Number(value)
    }

    const updatedParams = { ...params }
    const nameParts = name.split(".")
    let currentParams = updatedParams
    for (let i = 0; i < nameParts.length - 1; i++) {
      if (!currentParams[nameParts[i]]) {
        currentParams[nameParts[i]] = {}
      }
      currentParams = currentParams[nameParts[i]]
    }
    currentParams[nameParts[nameParts.length - 1]] = updatedValue
    setParams(updatedParams)
  }

  const handleFormSubmit = (e) => {
    e.preventDefault()
    fetchData(true)
  }

  const handlePageClick = (page) => setCurrentPage(page)

  const handlePageSizeChange = (e) => {
    setCurrentPage(1)
    setPageSize(parseInt(e.target.value))
  }

  const handleSort = (column) => {
    if (sortColumn === column) {
      // If the same column is clicked again, toggle the sort direction
      setSortDirection((prevDirection) =>
        prevDirection === "asc" ? "desc" : "asc"
      )
    } else {
      // If a different column is clicked, set it as the new sort column and default to ascending sort direction
      setSortColumn(column)
      setSortDirection("asc")
    }
  }

  const handleCheckItem = (itemId) => {
    if (checkedItems.includes(itemId)) {
      setCheckedItems((prevCheckedItems) =>
        prevCheckedItems.filter((id) => id !== itemId)
      )
    } else {
      setCheckedItems((prevCheckedItems) => [...prevCheckedItems, itemId])
    }
  }

  const handleCheckAll = () => {
    setIsCheckedAll(!isCheckedAll)
    setCheckedItems(isCheckedAll ? [] : bets.map((item) => item._id))
  }

  const renderColumnLabel = (column) => {
    if (column.key === "dates" || column.key === "ips") {
      return column.label.split("/").map((label, index) => (
        <React.Fragment key={index}>
          {label}
          <br />
        </React.Fragment>
      ))
    } else {
      return column.label
    }
  }

  const renderOutcome = (outcome) => {
    let color = "inherit"

    switch (outcome) {
      case 2:
        break
      case 3:
        color = "red"
        break
      case 4:
        color = "blue"
        break
      case 5:
        color = "blue"
        break
      case 6:
        color = "red"
        break
    }

    return <span style={{ color: color }}>{betOutcomes[outcome] ?? "-"}</span>
  }

  const handleUndo = async (items) => {
    setIsLoading(true)

    try {
      if (items.length === 0) {
        showAlert("대상을 선택해주세요!")
        return
      }

      const promises = items.map(async (userId) => {
        const { data, message, success } = await revokeBet(userId, { flag: { loss: false } })
        if (!success) {
          throw new Error(message)
        }
      })

      await Promise.all(promises)

      showSuccess("복구되었습니다!")

      refresh()
    } catch(error) {
      console.error("Status update failed:", error)
      showAlert(error.message)

    }

    setIsLoading(false)   
    // if(window.confirm('복구하시겠습니까?')) {

    // }
  }

  return (
    <div style={{ paddingBottom: 100 }}>
      {isLoading && <Loader />}
      <Table>
        <tbody className="align-items-center">
          <tr>
            <td className="form-header w-100p align-middle text-center" xs={3}>
              검색
            </td>
            <td xs={9}>
              <div className="d-flex align-items-center">
                <div className="me-3">
                  <DatePicker
                    value={params.startDate}
                    onChange={(date) => handleDateChange("startDate", date)}
                    className="form-control form-control-sm"
                    placeholder="시작일"
                    showClearButton={false}
                    dateFormat="YYYY-MM-DD"
                    maxDate={new Date(params.endDate).toString()}
                  />
                </div>
                <div className="me-3">
                  <DatePicker
                    value={params.endDate}
                    onChange={(date) => handleDateChange("endDate", date)}
                    className="form-control form-control-sm"
                    placeholder="종료일"
                    showClearButton={false}
                    dateFormat="YYYY-MM-DD"
                    minDate={new Date(params.startDate).toString()}
                  />
                </div>
                <div className="me-3">
                  <Input
                    type="text"
                    name="searchText"
                    value={params?.searchText}
                    onChange={handleParamsChange}
                    className="form-control form-control-sm"
                    placeholder="검색어"
                  />
                </div>
                <Button
                  style={{
                    backgroundColor: colors.primary,
                    borderColor: colors.primary,
                  }}
                  onClick={handleFormSubmit}
                  color="primary"
                  size="sm"
                >
                  검색
                </Button>
                &nbsp;
                <Button onClick={resetParams} color="warning" size="sm">
                  초기화
                </Button>
              </div>
            </td>
          </tr>
        </tbody>
      </Table>

      <>
        <div className="search-header">
          <div>
            {`검색된 로스 베팅: `}
            <span className="text-danger">{totalCount.toLocaleString()}</span>개
          </div>
          <div className="select-wrapper">
            <div className="refresh">
              <button className="btn btn-link" onClick={fetchData}>
                <FaSyncAlt style={{ color: colors.primary }} />
              </button>
            </div>
            <select
              className="form-control"
              onChange={handlePageSizeChange}
              value={pageSize}
            >
              <option value="5">5개씩 보기</option>
              <option value="10">10개씩 보기</option>
              <option value="20">20개씩 보기</option>
              <option value="50">50개씩 보기</option>
            </select>
          </div>
        </div>
        {error && <Alert color="danger">{error?.message}</Alert>}
        <Table bordered responsive>
          <thead>
            <tr>
              {columns.map(
                (column) =>
                  !column.sub && (
                    <th
                      key={column.key}
                      className="fixed-width"
                      onClick={() =>
                        column.sortable ? handleSort(column.key) : {}
                      }
                      // rowSpan={column.key === 'betList' ? 1 : 2}
                      // colSpan={column.key === 'betList' ? columns.filter(column => column.sub).length : 1}
                    >
                      {column.key === "checkbox" && (
                        <input
                          type="checkbox"
                          checked={isCheckedAll}
                          onChange={handleCheckAll}
                        />
                      )}
                      {renderColumnLabel(column)}
                      {column.sortable && sortColumn === column.key && (
                        <>
                          {sortDirection === "asc" ? (
                            <FaSortUp />
                          ) : (
                            <FaSortDown />
                          )}
                        </>
                      )}
                    </th>
                  )
              )}
            </tr>
            {/* <tr>
              {columns.filter(column => column.sub).map((column) => column.sub && (
                <th
                  key={column.key}
                  className="fixed-width"
                >
                  {column.label}
                </th>
              ))}
            </tr> */}
          </thead>
          <tbody className="text-center">
            {bets.map((item, index) => (
              <React.Fragment key={index}>
                <tr
                  key={index}
                  className={`${index === hoveredRow ? "hovered" : ""} 
                  ${
                    item.category === "카지노"
                      ? "row-casino"
                      : item.category === "스포츠"
                      ? "row-sports"
                      : item.category === "슬롯"
                      ? "row-slot"
                      : ""
                  }`}
                  onMouseEnter={() => setHoveredRow(index)}
                  onMouseLeave={() => setHoveredRow(null)}
                >
                  {columns
                    .filter((column) => !column.group)
                    .map((column) => {
                      return (
                        <td
                          key={column.label}
                          className={`${
                            column.align === "right"
                              ? "text-end "
                              : column.align === "left"
                              ? "text-start"
                              : ""
                          }`}
                          style={{ whiteSpace: "break-spaces" }}
                          // rowSpan={column.sub ? 5 : 2}
                          // rowSpan={column.sub ? 1 : item?.detail?.detailList?.length}
                        >
                          {column.key === "checkbox" && (
                            <input
                              type="checkbox"
                              checked={checkedItems.includes(item?._id)}
                              onChange={() => handleCheckItem(item?._id)}
                            />
                          )}
                          {column.key === "username" && (
                            <span
                              role="button"
                              onClick={() => {
                                setBets((prevBets) =>
                                  prevBets.map((bet) =>
                                    bet?._id === item?._id
                                      ? { ...bet, open: !bet.open }
                                      : bet
                                  )
                                )
                              }}
                            >
                              <UserBadge user={item?.user} />
                            </span>
                          )}
                          {column.key === "category" && item?.category}
                          {column.key === "provider" &&
                            `${item?.provider}/${
                              item?.detail?.providerCode ?? "-"
                            }`}
                          {column.key === "game" &&
                            `${item?.detail?.gameName ?? "-"}`}
                          {column.key === "updatedAt" &&
                            (item?.provider === "pmgame"
                              ? dayjs(Number(item?.detail?.createTime)).format(
                                  "YYYY-MM-DD HH:mm:ss"
                                )
                              : item?.createdAt
                              ? dayjs(item?.createdAt).format(
                                  "YYYY-MM-DD HH:mm:ss"
                                )
                              : "-")}

                          {column.key === "seriesValue" &&
                            item?.detail?.seriesValue}

                          {column.key === "marketType" &&
                          item?.detail?.detailList?.length
                            ? item.detail.detailList[0].marketType
                            : null}

                          {column.key === "sportName" &&
                          item?.detail?.detailList?.length
                            ? item.detail.detailList[0].sportName
                            : null}

                          {column.key === "matchName" &&
                          item?.detail?.detailList?.length
                            ? item.detail.detailList[0].matchName
                            : null}
                          {column.key === "matchInfo" &&
                          item?.detail?.detailList?.length
                            ? item.detail.detailList[0].matchInfo
                            : null}

                          {column.key === "playName" &&
                          item?.detail?.detailList?.length
                            ? item.detail.detailList[0].playName
                            : null}
                          {column.key === "playOptionName" &&
                          item?.detail?.detailList?.length
                            ? item.detail.detailList[0].playOptionName
                            : null}

                          {column.key === "oddFinally" &&
                          item?.detail?.detailList?.length
                            ? item.detail.detailList[0].oddFinally
                            : null}

                          {column.key === "betCount" &&
                          item?.detail &&
                          item?.detail?.betCount
                            ? item.detail.betCount.toLocaleString() + "회"
                            : ""}
                          {column.key === "betAmount" && (
                            <NumberDisplay
                              value={item?.betAmount}
                              suffix="원"
                            />
                          )}

                          {column.key === "profitAmount" && (
                            <NumberDisplay
                              showPrefix
                              useNegativeColor
                              usePositiveColor
                              value={item?.resultAmount - item?.betAmount}
                              suffix="원"
                            />
                          )}
                          {column.key === "settleAmount" && (
                            <NumberDisplay
                              showPrefix
                              value={item?.resultAmount}
                              suffix="원"
                            />
                          )}
                          {column.key === "flag.bet" &&
                            (item?.flag.bet ? "베팅완료" : "결과대기중")}
                          {column.key === "action" &&
                            <Button
                              onClick={() => {
                                handleUndo([item._id])
                              }}
                            >복구</Button>  
                          }
                        </td>
                      )
                    })}
                </tr>
              </React.Fragment>
            ))}
          </tbody>
        </Table>
        <PaginationComponent
          totalPages={totalPages}
          currentPage={currentPage}
          pagesToShow={pagesToShow}
          handlePageClick={handlePageClick}
        />
        {/* Action bar */}
        <div
          className={`action-bar-container ${checkedItems.length ? "show" : ""}`}
          >
          <div className="action-selected">
            총 {checkedItems.length.toLocaleString()}개 선택됨
          </div>
  
          <div className="action-bar">
            <div className="left-actions">
            </div>
            <div className="right-actions">
              <Button
                className="me-2"
                onClick={()=>handleUndo(checkedItems)}
                // color="primary"
              >
                복구
              </Button>
            </div>
          </div>
        </div>
      </>
    </div>
  )
}

export default LossListList
