import { showAlert } from 'utils/toast';
import {
  loginRequest,
  loginSuccess,
  loginFailure,
  logoutRequest,
  logoutSuccess,
  logoutFailure,
  checkAuthRequest,
  checkAuthSuccess,
  checkAuthFailure
} from '../features/auth/authActions'
import { login, logout, checkAuth, autoLogin } from 'api/authApi'

export const loginThunk = credentials => async (dispatch, getState) => {
  try {
    const { success, data, message} = await login(credentials)
    if (success) {
      await dispatch(loginRequest())
      await dispatch(loginSuccess(data))      
    } else {
      await dispatch(loginFailure())
      showAlert(message)
      throw new Error('Login failed')
    }
  } catch (error) {
    console.error('Login failed:', error)
    // Dispatch an error action if needed
    dispatch(loginFailure(error))
  }
}

export const autoLoginThunk = session => async (dispatch, getState) => {
  try {
    const { success, data, message} = await autoLogin(session)
    if (success) {
      dispatch(loginRequest())
      dispatch(loginSuccess(data))      
    } else {
      dispatch(loginFailure())
      showAlert(message)
      throw new Error('Login failed')
    }
  } catch (error) {
    console.error('Login failed:', error)
    // Dispatch an error action if needed
    dispatch(loginFailure(error))
  }
}

export const logoutThunk = () => async (dispatch, getState) => {
  try {
    dispatch(logoutRequest())
    await logout()
    dispatch(logoutSuccess())
  } catch (error) {
    console.error('Logout failed:', error)
    // Dispatch an error action if needed
    dispatch(logoutFailure(error))
  }
}

export const checkAuthThunk = () => async (dispatch, getState) => {
  try {
    const {success, data, message} = await checkAuth()
    // console.log('CHECK AUTH THUNK')
    if (success) {
      dispatch(checkAuthRequest())
      if(data.config) {
        data.previleges = data?.config?.previleges ?? []
      }
      dispatch(checkAuthSuccess(data))
    } else {
      dispatch(checkAuthFailure())
    }
  } catch (error) {
    console.error('Authentication check failed:', error)
    // Dispatch an error action if needed
    dispatch(checkAuthFailure(error))
  }
}
