import React, { lazy, Suspense } from 'react'
import { Layout } from 'components'
import {
  Row,
  Container
} from 'reactstrap'

const AdminList = lazy(() => import('./AdminList'))

function Admin (props) {
  return (
    <Layout>
      <Container className='page-content' fluid style={{ padding: 30 }}>
        <Row>
          <Suspense fallback={<div>Loading...</div>}>
            <AdminList />
          </Suspense>
        </Row>
      </Container>
    </Layout>
  )
}

export default Admin
