export const depositStatuses = {
    pending: "신청",
    waiting: "대기",
    completed: "완료",
    rejected: "취소",
}

export const depositTypes = {
    casino: '카지노',
    sports: '스포츠'
}

export const withdrawalStatuses = {
    pending: "신청",
    waiting: "대기",
    completed: "완료",
    rejected: "취소",
}

export const transferStatuses = {
    pending: "신청",
    waiting: "대기",
    completed: "완료",
    rejected: "취소",
}

export const withdrawalTypes = {
    casino: '카지노',
    sports: '스포츠'
}

export const gameTypes = {
    casino: '카지노',
    sports: '스포츠'
}

export const userCategories = {
    caution : '주의',
    general: '일반',
    interest: '관심',
    vip: '우수'
}

export const userStatuses = {
    pending : '신청',
    waiting: '대기',
    active: '정상',
    suspended: '정지',
    withdrawn: '탈퇴'
}

export const userCouponStatuses = {
    deposit: '지급',
    withdrawn: '회수',
    used: '사용완료'
}

export const roleNames = {
    member: '일반회원',
    admin: '관리자',
    partner: '파트너',
    headQuarter: '본사'
}

export const walletCategory = {
    point: '포인트',
    balance: '보유금',
    coupon: '쿠폰',
    profit: '수익금'
}

export const userHistoryClassification = {
    parent: '추천인',
    username: '아이디',
    nickname: '닉네임',
    category: '회원분류',
    status: '가입상태',
    phone: '연락처',

    accountNumber: '계좌번호',
    bankName: '은행명',
    accountHolder: '예금주',
    attendance: '출석체크',

    grade: '등급',
    password: '비밀번호',
    withdrawalPassword: '출금비밀번호',

    memo: '메모',

    'casino.gameAccess': '카지노',
    'sports.gameAccess': '스포츠',
    'slot.gameAccess': '슬롯',
    'casino.dailyFirstDeposit': '[카지노]첫충전 포인트',
    'sports.dailyFirstDeposit': '[슬롯]첫충전 포인트',
    'slot.dailyFirstDeposit': '[스포츠]첫충전 포인트',
    'casino.everyDeposit': '[카지노]매충전 포인트',
    'sports.everyDeposit': '[슬롯]매충전 포인트',
    'slot.everyDeposit': '[스포츠]매충전 포인트',

    duplicateIpCheck: '중복아이피',

    'percentages.difference': '입출차액',
    'percentages.casino': '카지노정산',
    'percentages.sports': '스포츠정산',
    'percentages.minigame': '미니게임정산',
    'method': '정산방식',

    'previleges.createChildren': '하부 생성 권한',

    'rollings.casino.deposit': '카지노 입금 롤링',
    'rollings.casino.coupon': '카지노 쿠폰 롤링',
    'rollings.sports.deposit': '스포츠 입금 롤링',
    'rollings.sports.coupon': '스포츠 쿠폰 롤링'
}

export const previlegeNames = {
    'adjustment': "정산",
    'adjustment/view': "정산 보기",

    'admin': "관리자",
    'admin/view': '관리자 보기',
    'admin/create': '관리자 생성',
    'admin/edit': '관리자 수정',
    'admin/delete': '관리자 삭제',

    'site-config': '사이트',
    'site-config/view': '사이트 보기',
    'site-config/create': '사이트 생성',
    'site-config/edit': '사이트 수정',

    'partner': '파트너',
    'partner/view': '파트너 보기',
    'partner/create/user': '파트너 하부회원 생성',
    'partner/create/children': '파트너 하부 생성',
    'partner/edit': '파트너 수정',
    'partner/edit/adjustment': '파트너 정산 설정',
    'partner/edit/giveProfit': '파트너 수익금 지급',
    'partner/edit/takeProfit': '파트너 수익금 회수',
    'partner/edit/giveEgg': '파트너 알 지급',
    'partner/edit/takeEgg': '파트너 알 회수',

    'user': '회원',
    'user/view': '회원 보기',
    'user/view/password': '회원 비밀번호 보기',
    'user/view/download': '회원 다운로드',
    'user/view/phone': '회원 연락처 보기',
    'user/view/bank': '회원 계좌 보기',
    'user/edit': '회원 수정',
    'user/edit/adjustment': '회원 콤프 설정',
    'user/edit/phone': '회원 연락처 수정',
    'user/edit/status': '회원 상태 수정',
    'user/edit/attendance': '회원 출석',
    'user/edit/bank': '회원 계좌 수정',
    'user/edit/parent': '추천인 수정',
    'user/edit/rollings': '롤링 수정',

    'deposit': '입금',
    'deposit/view': '입금 보기',
    'deposit/edit/status': '입금 처리',

    'withdrawal': '출금',
    'withdrawal/view': '출금 보기',
    'withdrawal/edit/status': '출금 처리',

    'transfer': '전환',
    'transfer/view': '전환 보기',
    'transfer/edit/status': '전환 처리',

    'coupon': '쿠폰',
    'coupon/view': '쿠폰 보기',
    'coupon/create': '쿠폰 생성',
    'coupon/edit': '쿠폰 수정',
    'coupon/delete': '쿠폰 삭제',

    'referral': '추천코드',
    'referral/view': '추천코드 보기',
    'referral/create': '추천코드 생성',
    'referral/delete': '추천코드 삭제',

    'mail': '쪽지',
    'mail/view': '쪽지 보기',
    'mail/create': '쪽지 발송',
    'mail/delete': '쪽지 삭제',

    'bet': '베팅',
    'bet/view': '베팅내역 보기',


    'help-center': '고객센터',
    'help-center/view': '고객센터 보기',

    'history': '기록',
    'history/view': '기록 보기',
    'history/view/egg': '알 기록 보기',
    'history/view/wallet': '캐시 기록 보기',
    'history/view/user': '변경 기록 보기',
    'history/view/login': '접속 기록 보기',
    'history/view/adjustment': '파트너 정산 기록 보기',
  }

export const betStatuses = {
    0: '처리예정', // to settle
    1: '완료', // settled
    2: '취소', // cancel (manual)
    3: '확인예정', // to confirm
    4: '리스크거부', // Risk reject
    5: '무효처리', // void (cancel)
}

export const betOutcomes = {
    2: '무승부',
    3: '낙첨',
    4: '당첨',
    5: '부분당첨',
    6: '부분낙첨',
}

export const status = {
    active : '활성',
    inactive: '비활성'
}