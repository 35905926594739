import React, { useState, useEffect } from "react";
import "./Mail.css";
import { Input, Table, Button, Alert, Modal } from "reactstrap";
import colors from "assets/colors";
import dayjs from "dayjs";
import { Loader } from "components";
import { FaSortUp, FaSortDown, FaSyncAlt, FaEnvelope } from "react-icons/fa";
import { DatePicker } from "reactstrap-date-picker";
import { fetchMails, deleteMail, readMail } from "api/mailApi";
import { showAlert, showSuccess } from "utils/toast";
import NewWindow from "react-new-window";
import { useLocation } from "react-router-dom";
import UserBadge from "components/UserBadge";
import PaginationComponent from "components/PaginationComponent";
import { getPublicUrl } from "libs/functions";
import MailView from "./MailView";
import { statusThunk } from 'thunks/statusThunks'
import MailCreate from "./MailCreate";
import { useDispatch, useSelector } from 'react-redux'
import { checkPrevilege } from 'api/authApi'

const PUBLIC_URL = getPublicUrl();

// const columns = [
//   { key: "checkbox", label: "", sortable: false },
//   { key: "recipients", label: "수신", sortable: true },
//   { key: "title", label: "제목", width: "30%", sortable: true },
//   { key: "createdAt", label: "발송일", sortable: true },
//   { key: "readAt", label: "읽은시간", sortable: true },
// ];

function MailPartnerReceivedList(props) {
  const location = useLocation();
  const dispatch = useDispatch()

  useEffect(() => {
    if (location?.state?.refresh) refresh();
  }, [location.state]);

  const getColumns = (type) => {
    if (type === "partnerReceived") {
      return [
        { key: "checkbox", label: "", sortable: false },
        { key: "sender", label: "발신", sortable: true }, // Sender for partnerReceived
        { key: "title", label: "제목", width: "30%", sortable: true },
        { key: "createdAt", label: "발송일", sortable: true },
        { key: "readAt", label: "읽은시간", sortable: true },
      ];
    }
    return [
      { key: "checkbox", label: "", sortable: false },
      { key: "recipients", label: "수신", sortable: true }, // Recipients for other types
      { key: "title", label: "제목", width: "30%", sortable: true },
      { key: "createdAt", label: "발송일", sortable: true },
      { key: "readAt", label: "읽은시간", sortable: true },
    ];
  };

  const columns = getColumns(props.type);

  // Partner data
  const [mails, setMails] = useState([]);
  const [isLoading, setIsLoading] = useState(false);
  const [error, setError] = useState(null);

  // Pagination
  const pagesToShow = 10;
  const [currentPage, setCurrentPage] = useState(1);
  const [pageSize, setPageSize] = useState(10);
  const [totalPages, setTotalPages] = useState(1);
  const [totalCount, setTotalCount] = useState(1);

  // Sorting
  const [sortColumn, setSortColumn] = useState("createdAt");
  const [sortDirection, setSortDirection] = useState("desc");

  // Selection
  const [hoveredRow, setHoveredRow] = useState(null);
  const [isCheckedAll, setIsCheckedAll] = useState(false);
  const [checkedItems, setCheckedItems] = useState([]);

  const [createModal, setCreateModal] = useState(false);
  const [detailModal, setDetailModal] = useState(false);
  const [selectedItem, setSelectedItem] = useState(null);

  const today = dayjs().format("YYYY-MM-DD");
  const startDate = dayjs().subtract(30, "day").format("YYYY-MM-DD");

  const [params, setParams] = useState({
    type: props?.type ?? "member",
    // startDate: today,
    // endDate: today,
    searchText: "",
  });

  const resetParams = async () => {
    setParams({
      type: props?.type ?? "member",
      // startDate: today,
      // endDate: today,
      searchText: "",
    });
  };

  const refresh = async () => {
    fetchData(true);
    setCheckedItems([]);
    setIsCheckedAll(false);
    setSelectedItem(null);
    dispatch(statusThunk())
  };

  const fetchData = async (applyParams = true) => {
    setIsLoading(true);
    try {
      const { success, message, data } = await fetchMails(
        currentPage,
        pageSize,
        applyParams ? params : {},
        sortColumn,
        sortDirection
      );

      if (!success) throw new Error(message);
      setMails(data.mails);
      setTotalPages(data.meta.totalPages);
      setTotalCount(data.meta.count);
      setError(null);
    } catch (error) {
      console.error(error);
      setError(error);
    } finally {
      setIsLoading(false);
    }
  };

  // console.log(mails)

  useEffect(() => {
    fetchData();
  }, [currentPage, pageSize, sortColumn, sortDirection]);

  const handleDateChange = (end, date) => {
    if (date === null || date === undefined) {
      setParams((prevParams) => ({
        ...prevParams,
        [end]: null, // or use a default value like an empty string ''
      }));
    } else {
      setParams((prevParams) => ({
        ...prevParams,
        [end]: dayjs(date).format("YYYY-MM-DD"),
      }));
    }
  };

  const handleParamsChange = (event) => {
    const { name, value, type } = event.target;

    let updatedValue = value;

    // Check if the input type is a radio button
    if (type === "radio") {
      if (value === "true") {
        updatedValue = true; // Set to true if value is 'true'
      } else if (value === "false") {
        updatedValue = false; // Set to false if value is 'false'
      }
    }

    if (type === "number") {
      updatedValue = Number(value);
    }

    const updatedParams = { ...params };
    const nameParts = name.split(".");
    let currentParams = updatedParams;
    for (let i = 0; i < nameParts.length - 1; i++) {
      if (!currentParams[nameParts[i]]) {
        currentParams[nameParts[i]] = {};
      }
      currentParams = currentParams[nameParts[i]];
    }
    currentParams[nameParts[nameParts.length - 1]] = updatedValue;
    setParams(updatedParams);
  };

  const handleFormSubmit = (e) => {
    e.preventDefault();
    fetchData(true);
  };

  const handlePageClick = (page) => setCurrentPage(page);

  const handlePageSizeChange = (e) => {
    setCurrentPage(1);
    setPageSize(parseInt(e.target.value));
  };

  const handleMailClick = (mail) => {
    setSelectedItem(mail);
    setDetailModal(true);
    // if (currentViewWindow) currentViewWindow.focus();
  };

  const handleSort = (column) => {
    if (sortColumn === column) {
      // If the same column is clicked again, toggle the sort direction
      setSortDirection((prevDirection) =>
        prevDirection === "asc" ? "desc" : "asc"
      );
    } else {
      // If a different column is clicked, set it as the new sort column and default to ascending sort direction
      setSortColumn(column);
      setSortDirection("asc");
    }
  };

  const handleCheckAll = () => {
    setIsCheckedAll(!isCheckedAll);
    setCheckedItems(isCheckedAll ? [] : mails.map((item) => item._id));
  };

  const handleCheckItem = (itemId) => {
    if (checkedItems.includes(itemId)) {
      setCheckedItems((prevCheckedItems) =>
        prevCheckedItems.filter((id) => id !== itemId)
      );
    } else {
      setCheckedItems((prevCheckedItems) => [...prevCheckedItems, itemId]);
    }
  };

  const renderColumnLabel = (column) => column.label;

  const handleCheckDelete = async () => {
    if (!window.confirm("삭제하시겠습니까?")) return;
    if (!checkedItems.length) {
      showAlert("삭제할 대상을 선택해주세요!");
    }

    setIsLoading(true);

    try {
      
      let { success, message } = await checkPrevilege("mail/delete");
            
      if (!success) throw new Error(message);

      const selectedMailIds = checkedItems;
      if (selectedMailIds.length === 0) {
        showAlert("대상을 선택해주세요!");
        return;
      }

      const promises = selectedMailIds.map(async (mailId) => {
        const { data, message, success } = await deleteMail(mailId);
        if (!success) {
          throw new Error(message);
        }
      });

      await Promise.all(promises);

      showSuccess("삭제되었습니다!");

      refresh();
    } catch (error) {
      console.log(error);
      console.error("Failed to delete mail:", error);
      showAlert(error.message);
    }

    setIsLoading(false);
  };

  const renderRecipient = (item) => {
    if (item.type === "member") {
      if (item.category === "individual") {
        return <UserBadge user={item.individual} />;
      }

      if (item.category === "grade") {
        return item.grade.map(({ grade, iconImage }) => (
          <img
            alt="hi"
            key={grade}
            className="me-1"
            width={20}
            src={`${PUBLIC_URL}/${iconImage}`}
          />
        ));
      }

      if (item.category === "children") {
        return (
          <>
            <img
              alt="hi"
              className="me-1"
              width={20}
              src={`${PUBLIC_URL}/icons/number-p.png`}
            />
            {item.partner.username} ({item.partner.nickname})
          </>
        );
      }

      if (item.category === "all") {
        return `전체`;
      }
    }

    if (item.type === "partner") {
      if (item.category === "individual") {
        return <UserBadge user={item.individual} />;
      }

      if (item.category === "all") {
        return `전체`;
      }
    }
    return null;
  };

  const handleMailIconClick = (mail) => {
    if (!mail) {
      return;
    }
    // Example action: Open the mail creation modal pre-filled with the user's information
    setSelectedItem(mail);
    setCreateModal(true);
  };

  const handleMarkAsRead = async () => {
    if (!checkedItems.length) {
      showAlert("읽음 처리할 대상을 선택해주세요!");
      return;
    }
  
    setIsLoading(true);
  
    try {
      const promises = checkedItems.map(async (mailId) => {
        // Make an API call to mark mail as read
        const { success, message } = await readMail(mailId, { read: true})
  
        if (!success) throw new Error(message);
      });
  
      await Promise.all(promises);
  
      // Show success message
      showSuccess("선택된 쪽지가 읽음 처리되었습니다.");
  
      // Refresh data to reflect changes
      refresh();
    } catch (error) {
      console.error("Failed to mark mails as read:", error);
      showAlert("읽음 처리에 실패했습니다.");
    } finally {
      setIsLoading(false);
    }
  };

  return (
    <div style={{ paddingBottom: 100 }}>
      {isLoading && <Loader />}

      <Table>
        <tbody className="align-items-center">
          <tr>
            <td className="form-header w-100p align-middle text-center" xs={3}>
              검색
            </td>
            <td xs={9}>
              <div className="d-flex align-items-center">
                <div className="me-3">
                  <DatePicker
                    value={params.startDate}
                    onChange={(date) => handleDateChange("startDate", date)}
                    className="form-control form-control-sm"
                    placeholder="시작일"
                    showClearButton={false}
                    dateFormat="YYYY-MM-DD"
                    maxDate={new Date(params.endDate).toString()}
                  />
                </div>
                <div className="me-3">
                  <DatePicker
                    value={params.endDate}
                    onChange={(date) => handleDateChange("endDate", date)}
                    className="form-control form-control-sm"
                    placeholder="종료일"
                    showClearButton={false}
                    dateFormat="YYYY-MM-DD"
                    minDate={new Date(params.startDate).toString()}
                  />
                </div>
                <div className="me-3">
                  <Input
                    type="text"
                    name="searchText"
                    value={params?.searchText}
                    onChange={handleParamsChange}
                    className="form-control form-control-sm"
                    placeholder="검색어"
                  />
                </div>
                <Button
                  style={{
                    backgroundColor: colors.primary,
                    borderColor: colors.primary,
                  }}
                  onClick={handleFormSubmit}
                  color="primary"
                  size="sm"
                >
                  검색
                </Button>
                &nbsp;
                <Button onClick={resetParams} color="warning" size="sm">
                  초기화
                </Button>
              </div>
            </td>
          </tr>
        </tbody>
      </Table>

      <>
        <div className="search-header">
          <div>
            {`검색된 쪽지: `}
            <span className="text-danger">{totalCount.toLocaleString()}</span>개
          </div>
          <div className="select-wrapper">
            <div className="refresh">
              <button className="btn btn-link" onClick={refresh}>
                <FaSyncAlt style={{ color: colors.primary }} />
              </button>
            </div>

            <select
              className="form-control"
              onChange={handlePageSizeChange}
              value={pageSize}
            >
              <option value="5">5개씩 보기</option>
              <option value="10">10개씩 보기</option>
              <option value="20">20개씩 보기</option>
              <option value="50">50개씩 보기</option>
            </select>
          </div>
        </div>
        {error && <Alert color="danger">{error?.message}</Alert>}

        <Table bordered responsive>
          <thead>
            <tr>
              {columns.map((column) => (
                <th
                  key={column.key}
                  className="fixed-width"
                  width={column.width ?? "auto"}
                  onClick={() =>
                    column.sortable ? handleSort(column.key) : {}
                  }
                >
                  {column.key === "checkbox" && (
                    <input
                      type="checkbox"
                      checked={isCheckedAll}
                      onChange={handleCheckAll}
                    />
                  )}
                  {renderColumnLabel(column)}
                  {column.sortable && sortColumn === column.key && (
                    <>
                      {sortDirection === "asc" ? <FaSortUp /> : <FaSortDown />}
                    </>
                  )}
                </th>
              ))}
            </tr>
          </thead>
          <tbody className="text-center">
            {mails.length === 0 ? (
              <tr>
                <td className="text-center" colSpan={columns.length}>
                  결과없음
                </td>
              </tr>
            ) : (
              mails.map((item, index) => (
                <tr
                  key={index}
                  className={`${index === hoveredRow ? "hovered" : ""} ${
                    (item?.mails[0]?.read === false) ? "unread-mail" : ""
                  }`}
                  onMouseEnter={() => setHoveredRow(index)}
                  onMouseLeave={() => setHoveredRow(null)}
                >
                  {columns.map((column) => (
                    <td key={column.key}>
                      {column.key === "checkbox" && (
                        <input
                          type="checkbox"
                          checked={checkedItems.includes(item?._id)}
                          onChange={() => handleCheckItem(item?._id)}
                        />
                      )}

                      {column.key === "recipients" && (
                        <span
                        // role="button"
                        // onClick={() => handlePartnerClick(item?._id, "basic")}
                        >
                          {renderRecipient(item)}
                        </span>
                      )}

                      {column.key === "title" && (
                        <span
                          role="button"
                          onClick={() => handleMailClick(item)}
                        >
                          {item?.name}
                          {(item?.mails[0]?.read === false) && (
                            <span
                              style={{
                                marginLeft: "8px",
                                fontSize: "0.8rem",
                                color: "red",
                                fontWeight: "bold",
                              }}
                            >
                              [NEW]
                            </span>
                          )}
                        </span>
                      )}

                      {column.key === "sender" && (
                        <div className="d-flex align-items-center justify-content-center">
                          <UserBadge user={item?.createdBy} />
                          <button
                            className="btn btn-link p-0 ms-2"
                            onClick={() => handleMailIconClick(item)}
                            title="쪽지보내기"
                            style={{ fontSize: "1rem", color: colors.primary }}
                          >
                            <FaEnvelope />
                          </button>
                        </div>
                      )}

                      {column.key === "sendCount" && (
                        <span>{item?.recipients?.length}</span>
                      )}
                      {column.key === "readAt" && (
                        <span>
                          {item?.mails?.[0]?.readAt
                            ? dayjs(item.mails[0].readAt).format(
                                "YYYY-MM-DD HH:mm:ss"
                              )
                            : "-"}
                        </span>
                      )}

                      {column.key === "createdAt" && (
                        <span>
                          {item?.createdAt
                            ? dayjs(item.createdAt).format(
                                "YYYY-MM-DD HH:mm:ss"
                              )
                            : "N/A"}
                        </span>
                      )}
                    </td>
                  ))}
                </tr>
              ))
            )}
          </tbody>
        </Table>
        <PaginationComponent
          totalPages={totalPages}
          currentPage={currentPage}
          pagesToShow={pagesToShow}
          handlePageClick={handlePageClick}
        />
      </>

      {/* Action bar */}
      <div className={`action-bar-container show`}>
        <div className="action-bar">
        <div className="left-actions">
          <Button
            className={'me-2'}
            color="success"
            onClick={handleMarkAsRead}
            disabled={!checkedItems.length}
          >
            선택 읽음 처리
          </Button>
          <Button
            color="danger"
            onClick={handleCheckDelete}
            disabled={!checkedItems.length}
          >
            선택 삭제
          </Button>
        </div>
          <div className="right-actions">
            {/* Right-aligned action buttons */}
            <Button
              color="primary"
              onClick={() => {
                setCreateModal(true);
                // if (currentCreateWindow) currentCreateWindow.focus();
              }}
            >
              쪽지쓰기
            </Button>
          </div>
        </div>
      </div>

      <Modal
        size={"xl"}
        isOpen={detailModal}
        toggle={() => setDetailModal(!detailModal)}
        onClosed={refresh}
      >
        <MailView
          onClose={() => setDetailModal(false)}
          targetId={selectedItem?._id}
          type={props?.type}
        />
      </Modal>

      <Modal
        size={"xl"}
        isOpen={createModal}
        toggle={() => setCreateModal(!createModal)}
        onClosed={refresh}
      >
        <MailCreate 
          onClose={() => {
            setCreateModal(false);
            refresh();
          }} 
          targetUsername={selectedItem?.createdBy?.username}
          type={props?.type} 
        />
      </Modal>
    </div>
  );
}

export default MailPartnerReceivedList;
