import React, { useState, useEffect } from 'react'
import './Home.css'
import { Layout, NumberDisplay } from 'components'
import {
  Card,
  Button,
  CardHeader,
  CardFooter,
  CardBody,
  Row,
  Col,
  Alert,
  Table,
  Container
} from 'reactstrap'
import {
  FaSyncAlt,
} from "react-icons/fa"
import colors from 'assets/colors'
import dayjs from 'dayjs'
import { fetchDashboard } from 'api/dashboardApi'
import { DatePicker } from "reactstrap-date-picker"
import { Loader } from 'components'

function Home(props) {
  const [isLoading, setIsLoading] = useState(false)
  const today = dayjs()
  const startDate = today.subtract(7, 'day').format('YYYY-MM-DD')
  const [error, setError] = useState(null)

  const [params, setParams] = useState({
    startDate: today.format('YYYY-MM-DD'),
    endDate: today.format('YYYY-MM-DD')
  })

  const refresh = async () => {
    fetchData()
  }

  const [dashboard, setDashboard] = useState({
    admin: {},
    partner: {},
    member: {},
    current: {}
  })

  const fetchData = async () => {
    setIsLoading(true)
    try {
      const { success, message, data } = await fetchDashboard(params)

      if (!success) throw new Error(message)
      setDashboard(data)
    } catch (error) {
      console.error(error)
      setError(error)
    } finally {
      setIsLoading(false)
    }
  }

  const handleStartDateChange = (date) => {
    const formattedDate = new Date(date).toISOString().split("T")[0]
    console.log(formattedDate)
    setParams((prevParams) => ({
      ...prevParams,
      startDate: formattedDate,
    }))
  }

  const handleEndDateChange = (date) => {
    const formattedDate = new Date(date).toISOString().split("T")[0]
    setParams((prevParams) => ({
      ...prevParams,
      endDate: formattedDate,
    }))
  }

  const handleFormSubmit = (e) => {
    e.preventDefault()
    fetchData()
  }

  useEffect(() => {
    fetchData()
  }, [])

  return (
    <Layout>
      <Table>
        <tbody className="align-items-center">
          <tr>
            <td xs={9}>
              <div className="d-flex align-items-center justify-content-center">
                <div className="me-3">
                  <DatePicker
                    value={params.startDate}
                    onChange={handleStartDateChange}
                    className="form-control form-control-sm"
                    placeholder="시작일"
                    showClearButton={false}
                    dateFormat="YYYY-MM-DD"
                    maxDate={new Date(params.endDate).toString()}
                  />
                </div>

                <div className="me-3">
                  <DatePicker
                    value={params.endDate}
                    onChange={handleEndDateChange}
                    className="form-control form-control-sm"
                    placeholder="종료일"
                    showClearButton={false}
                    dateFormat="YYYY-MM-DD"
                    minDate={new Date(params.startDate).toString()}
                  />
                </div>

                <Button
                  style={{
                    backgroundColor: colors.primary,
                    borderColor: colors.primary,
                  }}
                  onClick={handleFormSubmit}
                  color="primary"
                  size="sm"
                >
                  검색
                </Button>

                <button className="btn btn-link" onClick={refresh}>
                  <FaSyncAlt style={{ color: colors.primary }} />
                </button>
              </div>
            </td>
          </tr>
        </tbody>
      </Table>
      <Container style={{ padding: 10 }}>
        {isLoading && <Loader />}
        {error && <Alert color='danger'>{'에러'}</Alert>}
        {/* <Row className='d-flex justify-content-center p-2'>
          <Button
            color='primary'
            style={{ textAlign: 'center', width: 200 }}
            onClick={refresh}
          >
            새로고침
          </Button>
        </Row> */}
        <Row>
          <Col xs='12' sm='6' md='4' lg='3'>
            <Card>
              <CardHeader
                style={{ backgroundColor: colors.primary, color: colors.white }}
              >
                관리자 현황
              </CardHeader>
              <CardBody>
                <div className='card-body-index'>
                  <span>신규 관리자</span>
                  <NumberDisplay
                    value={dashboard?.admin?.new}
                    suffix='명'
                  />
                </div>
                <div className='card-body-index'>
                  <span>탈퇴 관리자</span>
                  <NumberDisplay
                    value={dashboard?.admin?.withdrawn}
                    suffix='명'
                  />
                </div>
              </CardBody>
              <CardFooter>
                <div className='card-body-index'>
                  <span>전체</span>
                  <NumberDisplay
                    value={dashboard?.admin?.total}
                    suffix='명'
                  />
                </div>
              </CardFooter>
            </Card>
          </Col>

          <Col xs='12' sm='6' md='4' lg='3'>
            <Card>
              <CardHeader
                style={{ backgroundColor: colors.primary, color: colors.white }}
              >
                파트너 현황
              </CardHeader>
              <CardBody>
                <div className='card-body-index'>
                  <span>신규 파트너</span>
                  <NumberDisplay
                    value={dashboard?.partner?.new}
                    suffix='명'
                  />
                </div>
                <div className='card-body-index'>
                  <span>탈퇴 파트너</span>
                  <NumberDisplay
                    value={dashboard?.partner?.withdrawn}
                    suffix='명'
                  />
                </div>
              </CardBody>
              <CardFooter>
                <div className='card-body-index'>
                  <span>전체</span>
                  <NumberDisplay
                    value={dashboard?.partner?.total}
                    suffix='명'
                  />
                </div>
              </CardFooter>
            </Card>
          </Col>

          <Col xs='12' sm='6' md='4' lg='3'>
            <Card>
              <CardHeader
                style={{ backgroundColor: colors.primary, color: colors.white }}
              >
                회원 현황
              </CardHeader>
              <CardBody>
                <div className='card-body-index'>
                  <span>신규 회원</span>
                  <NumberDisplay
                    value={dashboard?.member?.new}
                    suffix='명'
                  />
                </div>
                <div className='card-body-index'>
                  <span>탈퇴 회원</span>
                  <NumberDisplay
                    value={dashboard?.member?.withdrawn}
                    suffix='명'
                  />
                </div>
              </CardBody>
              <CardFooter>
                <div className='card-body-index'>
                  <span>전체</span>
                  <NumberDisplay
                    value={dashboard?.member?.total}
                    suffix='명'
                  />
                </div>
              </CardFooter>
            </Card>
          </Col>

          <Col xs='12' sm='6' md='4' lg='3'>
            <Card>
              <CardHeader
                style={{ backgroundColor: colors.primary, color: colors.white }}
              >
                현재 접속 현황
              </CardHeader>
              <CardBody>
                <div className='card-body-index'>
                  <span>관리자</span>
                  <NumberDisplay
                    value={dashboard?.session?.admin}
                    suffix='명'
                  />
                </div>
                <div className='card-body-index'>
                  <span>파트너</span>
                  <NumberDisplay
                    value={dashboard?.session?.partner}
                    suffix='명'
                  />
                </div>
                <div className='card-body-index'>
                  <span>실회원</span>
                  <NumberDisplay
                    value={dashboard?.session?.activeMember}
                    suffix='명'
                  />
                </div>
                <div className='card-body-index'>
                  <span>주의회원</span>
                  <NumberDisplay
                    value={dashboard?.session?.cautionMember}
                    suffix='명'
                  />
                </div>
              </CardBody>
              <CardFooter>
                <div className='card-body-index'>
                  <span>전체</span>
                  <NumberDisplay
                    value={dashboard?.session?.total}
                    suffix='명'
                  />
                </div>
              </CardFooter>
            </Card>
          </Col>

          <Col xs='12' sm='6' md='4' lg='3'>
            <Card>
              <CardHeader
                style={{ backgroundColor: colors.primary, color: colors.white }}
              >
                입출 현황
              </CardHeader>
              <CardBody>
                <div className='card-body-index'>
                  <span>입금</span>
                  <NumberDisplay
                    value={dashboard?.depositWithdrawData?.deposit}
                    suffix='원'
                  />
                </div>
                <div className='card-body-index'>
                  <span>출금</span>
                  <NumberDisplay
                    value={dashboard?.depositWithdrawData?.memberWithdraw}
                    suffix='원'
                  />
                </div>
                {/* <div className='card-body-index'>
                  <span>파트너 출금</span>
                  <NumberDisplay
                    value={dashboard?.depositWithdrawData?.partnerWithdraw}
                    suffix='원'
                  />
                </div> */}
              </CardBody>
              <CardFooter>
                <div className='card-body-index'>
                  <span>입출차액</span>
                  <NumberDisplay
                    value={dashboard?.depositWithdrawData?.difference}
                    suffix='원'
                  />
                </div>
              </CardFooter>
            </Card>
          </Col>

          <Col xs='12' sm='6' md='4' lg='3'>
            <Card>
              <CardHeader
                style={{ backgroundColor: colors.primary, color: colors.white }}
              >
                회원 보유금 [카지노]
              </CardHeader>
              <CardBody>
                <div className='card-body-index'>
                  <span>보유금</span>
                  <NumberDisplay
                    value={dashboard?.casinoBalanceData?.balance}
                    suffix='원'
                  />
                </div>
                <div className='card-body-index'>
                  <span>관리자 증가 보유금</span>
                  <NumberDisplay
                    value={dashboard?.casinoBalanceData?.adminIncBalance}
                    suffix='원'
                  />
                </div>
                <div className='card-body-index'>
                  <span>관리자 감소 보유금</span>
                  <NumberDisplay
                    value={dashboard?.casinoBalanceData?.adminDecBalance}
                    suffix='원'
                  />
                </div>
                <div className='card-body-index'>
                  <span>포인트 전환 보유금</span>
                  <NumberDisplay
                    value={dashboard?.casinoBalanceData?.balanceConversion}
                    suffix='원'
                  />
                </div>
              </CardBody>
            </Card>
          </Col>

          <Col xs='12' sm='6' md='4' lg='3'>
            <Card>
              <CardHeader
                style={{ backgroundColor: colors.primary, color: colors.white }}
              >
                회원 보유금 [스포츠]
              </CardHeader>
              <CardBody>
                <div className='card-body-index'>
                  <span>보유금</span>
                  <NumberDisplay
                    value={dashboard?.sportsBalanceData?.balance}
                    suffix='원'
                  />
                </div>
                <div className='card-body-index'>
                  <span>관리자 증가 보유금</span>
                  <NumberDisplay
                    value={dashboard?.sportsBalanceData?.adminIncBalance}
                    suffix='원'
                  />
                </div>
                <div className='card-body-index'>
                  <span>관리자 감소 보유금</span>
                  <NumberDisplay
                    value={dashboard?.sportsBalanceData?.adminDecBalance}
                    suffix='원'
                  />
                </div>
                <div className='card-body-index'>
                  <span>포인트 전환 보유금</span>
                  <NumberDisplay
                    value={dashboard?.sportsBalanceData?.balanceConversion}
                    suffix='원'
                  />
                </div>
              </CardBody>
            </Card>
          </Col>

          <Col xs='12' sm='6' md='4' lg='3'>
            <Card>
              <CardHeader
                style={{ backgroundColor: colors.primary, color: colors.white }}
              >
                회원 포인트 [카지노]
              </CardHeader>
              <CardBody>
                <div className='card-body-index'>
                  <span>보유 포인트</span>
                  <NumberDisplay
                    value={dashboard?.casinoPointData?.point}
                    suffix='P'
                  />
                </div>
                <div className='card-body-index'>
                  <span>관리자 증가 포인트</span>
                  <NumberDisplay
                    value={dashboard?.casinoPointData?.adminIncPoint}
                    suffix='P'
                  />
                </div>
                <div className='card-body-index'>
                  <span>관리자 감소 포인트</span>
                  <NumberDisplay
                    value={dashboard?.casinoPointData?.adminDecPoint}
                    suffix='P'
                  />
                </div>
                <div className='card-body-index'>
                  <span>시스템 증감 포인트</span>
                  <NumberDisplay
                    value={dashboard?.casinoPointData?.systemPoint}
                    suffix='P'
                  />
                </div>
              </CardBody>
            </Card>
          </Col>

          <Col xs='12' sm='6' md='4' lg='3'>
            <Card>
              <CardHeader
                style={{ backgroundColor: colors.primary, color: colors.white }}
              >
                회원 포인트 [스포츠]
              </CardHeader>
              <CardBody>
                <div className='card-body-index'>
                  <span>보유 포인트</span>
                  <NumberDisplay
                    value={dashboard?.sportsPointData?.point}
                    suffix='P'
                  />
                </div>
                <div className='card-body-index'>
                  <span>관리자 증가 포인트</span>
                  <NumberDisplay
                    value={dashboard?.sportsPointData?.adminIncPoint}
                    suffix='P'
                  />
                </div>
                <div className='card-body-index'>
                  <span>관리자 감소 포인트</span>
                  <NumberDisplay
                    value={dashboard?.sportsPointData?.adminDecPoint}
                    suffix='P'
                  />
                </div>
                <div className='card-body-index'>
                  <span>시스템 증감 포인트</span>
                  <NumberDisplay
                    value={dashboard?.sportsPointData?.systemPoint}
                    suffix='P'
                  />
                </div>
              </CardBody>
            </Card>
          </Col>

          {/* <Col xs='12' sm='6' md='4' lg='3'>
            <Card>
              <CardHeader
                style={{ backgroundColor: colors.primary, color: colors.white }}
              >
                파트너 수익금
              </CardHeader>
              <CardBody>
                <div className='card-body-index'>
                  <span>보유 수익금</span>
                  <NumberDisplay
                    value={dashboard?.profitData?.profit}
                    suffix='원'
                  />
                </div>
                <div className='card-body-index'>
                  <span>관리자 증가 수익금</span>
                  <NumberDisplay
                    value={dashboard?.profitData?.adminIncProfit}
                    suffix='원'
                  />
                </div>
                <div className='card-body-index'>
                  <span>관리자 감소 수익금</span>
                  <NumberDisplay
                    value={dashboard?.profitData?.adminDecProfit}
                    suffix='원'
                  />
                </div>
                <div className='card-body-index'>
                  <span>시스템 증감 수익금</span>
                  <NumberDisplay
                    value={dashboard?.profitData?.systemProfit}
                    suffix='원'
                  />
                </div>
              </CardBody>
            </Card>
          </Col> */}

        </Row>
      </Container>
    </Layout>
  )
}

export default Home
