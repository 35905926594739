import api from './index.js'
const BASE_URL = '/admin/partner-point'

export const createPartnerPoint = async pointData => {
  try {
    const response = await api.post(`${BASE_URL}`, pointData)

    if (response.status === 200) {
      const data = response.data
      return data
    } else {
      throw new Error('Failed to create partner-point')
    }
  } catch (error) {
    // Handle error
    // ...
    throw new Error('Failed to create partner-point')
  }
}