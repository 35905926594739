import React, { useState, useEffect } from 'react'
import {
  Container,
  Row,
  Col,
  Form,
  Table,
  Label,
  Input,
  Button,
  Navbar
} from 'reactstrap'
import { Editor } from 'components'
import { showAlert, showSuccess } from 'utils/toast'
import dayjs from 'dayjs'
import { Loader } from 'components'
import { fetchPopup, updatePopup } from 'api/popupApi'
import colors from 'assets/colors'
import { useParams } from 'react-router-dom'
import { getPublicUrl } from 'libs/functions'

const PUBLIC_URL = getPublicUrl()

function PopupView (props) {
  const { id } = useParams()
  const [isLoading, setIsLoading] = useState(false)
  const [error, setError] = useState(null)

  const [editorKey, setEditorKey] = useState(Date.now())
  const [popup, setPopup] = useState({
    name: '',
    image: '',
    mobileImage: '',
    active: true,
    link: '',
    showAt: null,
    hideAt: null,
    position: 0
  })

  useEffect(() => {}, [])

  const fetchData = async () => {
    setIsLoading(true)
    try {
      const { success, data, message } = await fetchPopup(id)

      if (!success) throw new Error(message)
      if (data) {
        setPopup(data)
      }
    } catch (error) {
      console.error(error)
      setError(error)
    } finally {
      setIsLoading(false)
    }
  }

  useEffect(() => {
    fetchData()
  }, [])

  console.log(popup)

  return (
    <>
      <Navbar
        style={{ backgroundColor: colors.primary, padding: '1rem' }}
        expand='md'
      >
        <h5 className='m-0'>
          {'팝업:'} {popup?.name}
        </h5>{' '}
        {/* Use the label based on the active tab */}
      </Navbar>

      <Container className='page-content' style={{ paddingBottom: 100 }}>
        {isLoading ? (
          <Loader />
        ) : (
          <Table bordered style={{ marginTop: '2rem' }}>
            <tbody className='align-items-center'>
              <tr>
                <td className='form-header text-center w-200p' xs={3}>
                  제목
                </td>
                <td xs={9}>
                  <span>{popup?.name}</span>
                </td>
              </tr>
              <tr>
                <td className='form-header text-center' xs={3}>
                  순서
                </td>
                <td xs={9}>
                  <span>{popup?.position}</span>
                </td>
              </tr>
              <tr>
                <td className='form-header text-center' xs={3}>
                  PC이미지
                </td>
                <td xs={9}>
                  <img
                    src={`${PUBLIC_URL}/${popup.image}`}
                    width={30}
                    alt='Icon'
                    className='img-fluid me-3'
                  />
                </td>
              </tr>
              <tr>
                <td className='form-header text-center' xs={3}>
                  모바일 이미지
                </td>
                <td xs={9}>
                  <img
                    src={`${PUBLIC_URL}/${popup.mobileImage}`}
                    width={30}
                    alt='Icon'
                    className='img-fluid me-3'
                  />
                </td>
              </tr>
              <tr>
                <td className='form-header text-center' xs={3}>
                  활성여부
                </td>
                <td xs={9}>
                  <span>{popup?.active ? '활성' : '비활성'}</span>
                </td>
              </tr>
              <tr>
                <td className='form-header text-center' xs={3}>
                  링크
                </td>
                <td xs={9}>
                  <span>{popup?.link}</span>
                </td>
              </tr>
              <tr>
                <td className='form-header text-center' xs={3}>
                  게시시작일
                </td>
                <td xs={9}>
                  <span>
                    {popup?.showAt
                      ? dayjs(popup?.showAt).format('YYYY-MM-DD HH:mm:ss')
                      : '-'}
                  </span>
                </td>
              </tr>
              <tr>
                <td className='form-header text-center' xs={3}>
                  게시종료일
                </td>
                <td xs={9}>
                  <span>
                    {popup?.hideAt
                      ? dayjs(popup?.hideAt).format('YYYY-MM-DD HH:mm:ss')
                      : '-'}
                  </span>
                </td>
              </tr>
            </tbody>
          </Table>
        )}

        {/* Action bar */}
        <div className={`action-bar-container show`}>
          <div className='action-bar'>
            <div className='left-actions'></div>

            <div className='right-actions'>
              {/* Right-aligned action buttons */}
              <Button color='primary' onClick={() => window.close()}>
                확인
              </Button>
            </div>
          </div>
        </div>
      </Container>
    </>
  )
}

export default PopupView
