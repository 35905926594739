import api from './index.js'

export const fetchStatus = async (id, target) => {
  try {
    const response = await api.get(`/admin/status`)

    if (response.status === 200) {
      const data = response.data
      return data
    } else {
      throw new Error('Failed to fetch status')
    }
  } catch (error) {
    // Handle error
    // ...
    throw new Error('Failed to fetch status')
  }
}



