import React, { lazy, Suspense } from 'react'
import { Layout } from 'components'
import {
  Row,
  Container
} from 'reactstrap'

const RuleList = lazy(() => import('./RuleList'))
// const RuleConfig = lazy(() => import('./RuleConfig'))
// const RuleGradeConfig = lazy(() => import('./RuleGradeConfig'))
// const RuleAttendanceConfig = lazy(() => import('./RuleAttendanceConfig'))

function Rule (props) {
  return (
    <Layout>
      <Container className='page-content' fluid style={{ padding: 30 }}>
        <Row>
          <Suspense fallback={<div>Loading...</div>}>
            <RuleList />
          </Suspense>
        </Row>
      </Container>
    </Layout>
  )
}

export default Rule
