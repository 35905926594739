import api from './index.js'

export const fetchEggHistorys = async (
  currentPage,
  pageSize,
  params,
  sortColumn,
  sortDirection
) => {
  try {
    const queryParams = new URLSearchParams(params).toString() // Convert params object to query string
    const response = await api.get(
      `/admin/egg-history?pageNumber=${currentPage}&pageSize=${pageSize}&sortColumn=${sortColumn}&sortDirection=${sortDirection}&${queryParams}`
    )

    if (response.status === 200) {
      const data = response.data
      // Handle successful response
      // ...
      return data
    } else {
      throw new Error('Failed to fetch egg-historys')
    }
  } catch (error) {
    // Handle error
    // ...
    throw new Error('Failed to fetch egg-historys')
  }
}
export const fetchEggHistory = async id => {
  try {
    const response = await api.get(`/admin/egg-history/${id}`)

    if (response.status === 200) {
      const data = response.data
      // Handle successful response
      // ...
      return data
    } else {
      throw new Error('Failed to fetch egg-history')
    }
  } catch (error) {
    // Handle error
    // ...
    throw new Error('Failed to fetch egg-history')
  }
}

export const updateEggHistory = async (id, walletHistoryData) => {
  try {
    const response = await api.put(`/admin/egg-history/${id}`, walletHistoryData)

    if (response.status === 200) {
      const data = response.data
      // Handle successful response
      // ...
      return data
    } else {
      throw new Error('Failed to update egg-history')
    }
  } catch (error) {
    // Handle error
    // ...
    throw new Error('Failed to update egg-history')
  }
}

export const createEggHistory = async walletHistoryData => {
  try {
    const response = await api.post('/admin/egg-history', walletHistoryData)

    if (response.status === 200) {
      const data = response.data
      // Handle successful response
      // ...
      return data
    } else {
      throw new Error('Failed to create egg-history')
    }
  } catch (error) {
    // Handle error
    // ...
    throw new Error('Failed to create egg-history')
  }
}

export const deleteEggHistory = async id => {
  try {
    const response = await api.delete(`/admin/egg-history/${id}`)

    if (response.status === 200) {
      const data = response.data
      // Handle successful response
      // ...
      return data
    } else {
      throw new Error('Failed to delete egg-history')
    }
  } catch (error) {
    // Handle error
    // ...
    throw new Error('Failed to delete egg-history')
  }
}
