import React, { lazy, useState, Suspense } from "react";
import "./Mail.css";
import { useSelector, useDispatch } from "react-redux";
import { statusSelector } from "features/status/statusSelectors";
import { Layout, UnderConstruction } from "components";
import {
  Nav,
  NavItem,
  NavLink,
  TabContent,
  TabPane,
  Row,
  Container,
  Badge,
} from "reactstrap";
import MailPartnerReceivedList from "./MailPartnerReceivedList";
import MailPartnerSentList from "./MailPartnerSentList";

const MailList = lazy(() => import("./MailList"));
// const MailConfig = lazy(() => import('./MailConfig'))
// const MailGradeConfig = lazy(() => import('./MailGradeConfig'))
// const MailAttendanceConfig = lazy(() => import('./MailAttendanceConfig'))

function Mail(props) {
  const status = useSelector(statusSelector);
  const [activeTab, setActiveTab] = useState("partnerReceivedMail");

  const {
    mails,
  } = status?.data ?? {};

  const toggleTab = (tab) => {
    if (activeTab !== tab) setActiveTab(tab);
  };


  return (
    <Layout>
      <Container className="page-content" fluid style={{ padding: 30 }}>
        <Row>
          <Nav tabs>
            {[
              {
                tab: "partnerReceivedMail",
                label: "파트너로부터 받은 쪽지",
                badge: mails,
              },
              { tab: "partnerSentMail", label: "파트너에게 보낸 쪽지" },
              { tab: "memberMail", label: "회원에게 보낸 쪽지" },
              // { tab: 'quickMail', label: '간편쪽지' },
            ].map(({ tab, label, badge }) => (
              <NavItem key={tab} style={{ position: "relative" }}>
                <NavLink
                  className={activeTab === tab ? "sub-link active" : ""}
                  onClick={() => toggleTab(tab)}
                >
                  {label}
                  {badge > 0 && (
                    <Badge
                      pill
                      color="danger"
                      style={{
                        position: "absolute",
                        top: "0px",
                        right: "-10px",
                        fontSize: "0.7rem",
                        zIndex: 1000,
                      }}
                    >
                      {badge}
                    </Badge>
                  )}
                </NavLink>
              </NavItem>
            ))}
          </Nav>
        </Row>
        <Row>
          <TabContent activeTab={activeTab}>
            {[
              {
                tab: "partnerReceivedMail",
                component: <MailPartnerReceivedList type={"partnerReceived"} />,
              },
              {
                tab: "partnerSentMail",
                component: <MailPartnerSentList type={"partnerSent"} />,
              },
              { tab: "memberMail", component: <MailList type={"member"} /> },
            ].map(({ tab, component }) => (
              <TabPane key={tab} tabId={tab}>
                {activeTab === tab && ( // Only render the component when the activeTab matches the current tab
                  <Suspense fallback={<div>Loading...</div>}>
                    {component}
                  </Suspense>
                )}
              </TabPane>
            ))}
          </TabContent>
        </Row>
      </Container>
    </Layout>
  );
}

export default Mail;
