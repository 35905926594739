import React, { useState, useEffect } from "react"
import "./Loss.css"
import { Button, Table, Input, Modal, ModalHeader } from "reactstrap"
import { FaUserFriends, FaExclamationCircle } from "react-icons/fa"
import { Loader } from "components"
import { IoMdArrowDropright } from "react-icons/io"
import TreeView, { flattenTree } from "react-accessible-treeview"
import { useSelector, useDispatch } from "react-redux"
import { userSelector } from "features/auth/authSelectors"
import { createPartner, fetchPartnerTree, deletePartner } from "api/partnerApi"
import { showAlert } from "utils/toast"
import LossSettingList from "./LossSettingList"
import { roleNames } from "libs/translations"
import colors from "assets/colors"

const ArrowIcon = ({ isOpen, className }) => {
  const baseClass = "arrow"
  const classes =
    baseClass +
    " " +
    (isOpen ? `${baseClass}--open` : `${baseClass}--closed`) +
    " " +
    className
  return <IoMdArrowDropright className={classes} />
}

function LossSetting(props) {
  const [isLoading, setIsLoading] = useState(false)
  const currentUser = useSelector(userSelector)

  const rootNode = {
    id: 0,
    name: "God",
    parent: null,
    children: [currentUser.isAdmin ? currentUser.hq._id : currentUser._id],
  }
  const { isRoot, previleges } = currentUser
  const [data, setData] = useState([])
  const [selectedParent, setSelectedParent] = useState(
    currentUser.isAdmin ? currentUser.hq._id : currentUser._id
  )
  const [searchText, setSearchText] = useState("")
  const [expandedIds, setExpandedIds] = useState([])
  const [selectedIds, setSelectedIds] = useState([])

  const refresh = async () => {
    // Refresh the partner list
    fetchData()
  }

  useEffect(() => {
    refresh()
  }, [])

  const fetchData = async () => {
    setIsLoading(true)
    try {
      const { data } = await fetchPartnerTree()
      const updatedData = [rootNode, ...data] // Add rootNode as the first element
      setData(updatedData)
      // setExpandedIds([data[1].id])
    } catch (error) {
      console.log("Failed to fetch partner tree:", error.message)
    }
    setIsLoading(false)
  }

  const findNodeByUsername = (nodes, username) => {
    for (const node of nodes) {
      if (node?.name === username) {
        return node
      }

      if (node.children) {
        const foundNode = findNodeByUsername(node.children, node.id)
        if (foundNode) {
          return foundNode
        }
      }
    }
    return null
  }

  const findAncestors = (tree, targetId, ancestors = []) => {
    for (const node of tree) {
      if (node.id === targetId) {
        return ancestors
      } else if (node.children && node.children.includes(targetId)) {
        return findAncestors(tree, node.id, [...ancestors, node.id])
      }
    }
    return null
  }

  const handleSearch = () => {
    const foundNode = findNodeByUsername(data, searchText)
    if (foundNode) {
      const parentsPath = findAncestors(data, foundNode.id)
      setExpandedIds([...parentsPath])
      setSelectedIds([foundNode.id])
      setSelectedParent(foundNode.id)
    } else {
      // Notify the user if the node was not found
      showAlert("해당 파트너가 존재하지 않습니다")
    }
  }
  const displayRoleName = ({role, parentPath}) => {
    let level = parentPath.split('/').length

    if(role === 'root') {
      level = 0
    }

    if (level === 0) {
      return "알본사"
    } else if (level === 1) {
      return "영업본사"
    } else if (level === 2) {
      return "대본"
    } else if (level === 3) {
      return "부본"
    } else if (level === 4) {
      return "총판"
    } else if (level === 5) {
      return "매장"
    } else {
      return "파트너"
    }
  }
  return (
    <>
      {isLoading && <Loader />}
      <Table>
        <tbody className="align-items-center">
          <tr>
            <td className="form-header w-100p align-middle text-center" xs={3}>
              검색
            </td>
            <td xs={9}>
              <div className="d-flex align-items-center">
                <div className="me-3">
                  <Input
                    type="text"
                    id="searchTextInput"
                    value={searchText}
                    onChange={(e) => setSearchText(e.target.value)}
                    className="form-control form-control-sm"
                    placeholder="파트너 아이디"
                  />
                </div>

                <Button
                  style={{
                    backgroundColor: colors.primary,
                    borderColor: colors.primary,
                  }}
                  onClick={handleSearch}
                  color="primary"
                  size="sm"
                >
                  파트너 검색
                </Button>
              </div>
            </td>
          </tr>
        </tbody>
      </Table>

      <div className="d-flex">
        {isLoading && <Loader />}
        <div className="directory">
          {data && data.length && (
            <TreeView
              data={data}
              aria-label="directory tree"
              // defaultExpandedIds={[data[1].id]}
              expandedIds={expandedIds}
              selectedIds={selectedIds}
              nodeRenderer={({
                element,
                isBranch,
                isExpanded,
                getNodeProps,
                handleExpand,
                handleSelect,
                level,
              }) => {
                return (
                  <div className="d-flex align-items-center justify-content-between">
                    <div
                      className="d-flex align-items-center"
                      style={{ paddingLeft: 20 * (level - 1), marginBottom: 5 }}
                    >
                      {isBranch && (
                        <Button
                          onClick={(e) => {
                            handleExpand(element.id)
                          }}
                        >
                          <ArrowIcon
                            style={{ zIndex: 100 }}
                            isOpen={isExpanded}
                          />
                        </Button>
                      )}
                      <div
                        {...getNodeProps(element, level)}
                        onClick={(e) => {
                          setSelectedParent(element.id)
                          handleSelect(element.id)
                        }}
                        style={{ padding: 5 }}
                      >
                        <span className={`partner-${level}`}>
                          { displayRoleName(element.data) }
                        </span>
                        &nbsp;
                        {element.data.username}({element.data.nickname}) &nbsp;
                        {element.data.role !== "member" && (
                          <span>
                            <FaUserFriends /> {element.children.length}
                          </span>
                        )}
                      </div>
                    </div>

                    <div className="directory-btn">
                      <>
                      </>
                    </div>
                  </div>
                )
              }}
            />
          )}
        </div>

        <div className="partner-list">
          <LossSettingList
            search={false}
            members={false}
            parent={selectedParent}
            onRefresh={refresh}
          />
        </div>
      </div>
    </>
  )
}

export default LossSetting
