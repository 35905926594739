export const checkPrevilege = (previleges, type) => {
    return previleges.find(p => p === type)
}

export function getApiUrl() {
  const hostName = window?.location?.hostname;

  if (hostName.includes("localhost")) {
    return "http://localhost:4000";
  }

  // Replace 'admin.', 'www.', or nothing with 'api.' to construct the API URL
  const apiUrl = hostName.replace(/^admin\.|^www\./, "api.");
  return `https://${apiUrl}`;
}

export function getSocketUrl() {
    const hostName = window?.location?.origin;
  
    if(hostName.includes("localhost")) {
      return "http://localhost:4000/admin"
    }
  
    const socketUrl = window.location.origin.replace("admin", "api") + "/admin"
    return socketUrl;
}

export function getPublicUrl() {
    // const hostName = window?.location?.origin;
  
    // if(hostName.includes("localhost")) {
    //   return "http://localhost:4000/static"
    // }
  
    // const staticUrl = window.location.origin.replace("admin", "api") + "/static"
    const staticUrl = "https://img.cacag991.com"
    return staticUrl;
}