import {
  SOCKET_CONNECT,
  SOCKET_DISCONNECT,
  SOCKET_RECEIVE_DATA
} from './socketActions'

const initialState = {
  socket: null,
  connected: false,
  data: null
}

const socketReducer = (state = initialState, action) => {
  switch (action.type) {
    case SOCKET_CONNECT:
      return { ...state, connected: true, socket: action.payload }
    case SOCKET_DISCONNECT:
      return { ...state, connected: false, data: null }
    case SOCKET_RECEIVE_DATA:
      return { ...state, data: action.payload }
    default:
      return state
  }
}

export default socketReducer
