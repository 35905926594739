import React, { useState, useEffect, useRef } from 'react'
import {
  Container,
  Table,
  Button,
  Navbar
} from 'reactstrap'
import { Editor, NumberDisplay, UnderConstruction } from 'components'
import { showAlert, showSuccess } from 'utils/toast'
import dayjs from 'dayjs'
import { Loader } from 'components'
import { fetchAdjustmentHistory } from 'api/adjustmentHistoryApi'
import colors from 'assets/colors'
import { useParams } from 'react-router-dom'
import UserBadge from 'components/UserBadge'
import { betStatuses, betOutcomes } from 'libs/translations'


const renderHistory = (history) => {
  if (history.classification === '입금') {
    if (history.ref && history.ref.deposit) {
      const { deposit } = history?.ref

      if (deposit) {
        return (

          <Table bordered style={{ marginTop: '2rem' }}>
            <tbody className='align-items-center'>
              <tr>
                <td className='form-header text-center w-200p'>
                  분류
                </td>
                <td >
                  입금
                </td>
                <td className='form-header text-center w-200p'>
                  아이디/닉네임
                </td>
                <td >
                  <UserBadge user={deposit?.access?.createdBy} />
                </td>
              </tr>
              <tr>
                <td className='form-header text-center'>
                  신청금액
                </td>
                <td>
                  <NumberDisplay
                    value={deposit?.amount}
                    suffix={'원'}
                  />
                </td>
                <td className='form-header text-center'>
                  처리후 보유
                </td>
                <td>
                  <NumberDisplay
                    value={deposit?.balanceAfter}
                    suffix={'원'}
                  />
                </td>
              </tr>
              <tr>
                <td className='form-header text-center w-200p'>
                  입출차액
                </td>
                <td >
                  <NumberDisplay
                    value={deposit?.balanceDiff}
                    suffix={'원'}
                    showPrefix
                    usePositiveColor
                    useNegativeColor
                  />
                </td>
                <td className='form-header text-center w-200p'>
                  지급 포인트
                </td>
                <td >
                  {deposit?.point}
                </td>
              </tr>
              <tr>
                <td className='form-header text-center w-200p'>
                  입금자명
                </td>
                <td>
                  {deposit?.account?.accountHolder}
                </td>
                <td className='form-header text-center w-200p'>
                  포인트 지급 사유
                </td>
                <td>
                  {deposit?.pointReason}
                </td>
              </tr>
              <tr>
                <td className='form-header text-center'>
                  신청일/처리일
                </td>
                <td >
                  {dayjs(deposit?.access?.completedAt).format('YYYY-MM-DD HH:mm:ss')}
                </td>
                <td className='form-header text-center'>
                  처리자
                </td>
                <td >
                  <UserBadge user={deposit?.access?.completedBy} />
                </td>
              </tr>
              <tr>
                <td className='form-header text-center'>
                  메모
                </td>
                <td colSpan={3}>
                  {deposit?.memo}
                </td>
              </tr>
            </tbody>
          </Table>
        )
      }
    }

  }

  if (history.classification === '출금') {
    if (history.ref && history.ref.withdrawal) {
      const { withdrawal } = history?.ref

      if (withdrawal) {
        return (
          <Table bordered style={{ marginTop: '2rem' }}>
            <tbody className='align-items-center'>
              <tr>
                <td className='form-header text-center w-200p'>
                  분류
                </td>
                <td >
                  출금
                </td>
                <td className='form-header text-center w-200p'>
                  아이디/닉네임
                </td>
                <td >
                  <UserBadge user={withdrawal?.access?.createdBy} />
                </td>
              </tr>
              <tr>
                <td className='form-header text-center'>
                  신청금액
                </td>
                <td>
                  <NumberDisplay
                    value={withdrawal?.amount}
                    suffix={'원'}
                  />
                </td>
                <td className='form-header text-center'>
                  처리후 보유
                </td>
                <td>
                  <NumberDisplay
                    value={withdrawal?.balanceAfter}
                    suffix={'원'}
                  />
                </td>
              </tr>
              <tr>
                <td className='form-header text-center w-200p'>
                  입출차액
                </td>
                <td colSpan={3}>
                  <NumberDisplay
                    value={withdrawal?.balanceDiff}
                    suffix={'원'}
                    showPrefix
                    usePositiveColor
                    useNegativeColor
                  />
                </td>
              </tr>
              <tr>
                <td className='form-header text-center w-200p'>
                  계좌정보
                </td>
                <td colSpan={3}>
                  {withdrawal?.account?.bankName}/
                  {withdrawal?.account?.accountHolder}/
                  {withdrawal?.account?.accountNumber}
                </td>
              </tr>
              <tr>
                <td className='form-header text-center'>
                  카지노
                </td>
                <td>
                  <NumberDisplay
                    value={withdrawal?.rollings?.casino}
                    suffix={'%'}
                  />
                </td>
                <td className='form-header text-center'>
                  스포츠
                </td>
                <td>
                  <NumberDisplay
                    value={withdrawal?.rollings?.sports}
                    suffix={'%'}
                  />
                </td>
              </tr>
              <tr>
                <td className='form-header text-center'>
                  신청일/처리일
                </td>
                <td >
                  {dayjs(withdrawal?.access?.completedAt).format('YYYY-MM-DD HH:mm:ss')}
                </td>
                <td className='form-header text-center'>
                  처리자
                </td>
                <td >
                  <UserBadge user={withdrawal?.access?.completedBy} />
                </td>
              </tr>
              <tr>
                <td className='form-header text-center'>
                  메모
                </td>
                <td colSpan={3}>
                  {withdrawal?.memo}
                </td>
              </tr>
            </tbody>
          </Table>
        )
      }

    }
  }

  if (history.classification === '베팅') {

    if (history.ref && history.ref.betting) {
      const { betting } = history?.ref

      if (betting) {

        return (
          <Table bordered style={{ marginTop: '2rem' }}>
            <tbody className='align-items-center'>
              <tr>
                <td className='form-header text-center w-200p'>
                  분류
                </td>
                <td >
                  베팅
                </td>
                <td className='form-header text-center w-200p'>
                  아이디/닉네임
                </td>
                <td >
                  <UserBadge user={betting?.user} />
                </td>
              </tr>
              <tr>
                <td className='form-header text-center w-200p'>
                  게임사/분류
                </td>
                <td >
                  {betting?.provider}/{betting?.category}
                </td>
                <td className='form-header text-center w-200p'>
                  게임명
                </td>
                <td >
                  {betting?.provider === 'pmgame' ?
                    '-'
                    : betting?.detail?.gameName
                  }
                </td>
              </tr>
              <tr>
                <td className='form-header text-center w-200p'>
                  베팅시간
                </td>
                <td >
                  {betting?.provider === 'pmgame' ?
                    dayjs(betting?.detail?.createTime).format('YYYY-MM-DD HH:mm:ss')
                    : dayjs(betting?.createdAt).format('YYYY-MM-DD HH:mm:ss')
                  }
                </td>
                <td className='form-header text-center'>
                  베팅금액
                </td>
                <td >
                  <NumberDisplay
                    value={betting?.betAmount}
                    suffix="원"
                  />
                </td>
              </tr>
              <tr>
                <td className='form-header text-center w-200p'>
                  당첨금
                </td>
                <td >
                  <NumberDisplay
                    showPrefix
                    useNegativeColor
                    usePositiveColor
                    value={betting?.resultAmount - betting?.betAmount}
                    suffix="원"
                  />
                </td>
                <td className='form-header text-center w-200p'>
                  최종정산금
                </td>
                <td >

                  <NumberDisplay
                    showPrefix
                    value={betting?.resultAmount}
                    suffix="원"
                  />
                </td>
              </tr>
            </tbody>
          </Table>
        )

      }

    }
  }

  // if (history.classification === '파트너출금') {
  //   return ('hi')
  // }

  if (history.classification === '관리자증감') {

    if (history.ref && history.admin) {
      const { admin } = history?.ref

      if (admin) {

        return (
          <Table bordered style={{ marginTop: '2rem' }}>
            <tbody className='align-items-center'>
              <tr>
                <td className='form-header text-center w-200p'>
                  분류
                </td>
                <td >
                  관리자증감
                </td>
                <td className='form-header text-center w-200p'>
                  아이디/닉네임
                </td>
                <td >
                  <UserBadge user={history?.parnter} />
                </td>
              </tr>
              <tr>
                <td className='form-header text-center'>
                  증감금액
                </td>
                <td>
                  <NumberDisplay
                    value={history?.profit}
                    showPrefix
                    useNegativeColor
                    usePositiveColor
                    suffix={'원'}
                  />
                </td>
                <td className='form-header text-center'>
                  처리후 보유
                </td>
                <td>
                  <NumberDisplay
                    value={history?.after}
                    suffix={'원'}
                  />
                </td>
              </tr>
              <tr>
                <td className='form-header text-center'>
                  처리일
                </td>
                <td >
                  {dayjs(history?.createdAt).format('YYYY-MM-DD HH:mm:ss')}
                </td>
                <td className='form-header text-center'>
                  처리자
                </td>
                <td >
                  <UserBadge user={admin} />
                </td>
              </tr>
            </tbody>
          </Table>
        )

      }

    }
  }


  return <UnderConstruction />
}

function AdjustmentHistoryView(props) {
  const id = props?.targetId ?? null
  const editorRef = useRef()
  const [isLoading, setIsLoading] = useState(false)
  const [error, setError] = useState(null)
  const [adjustmentHistory, setAdjustmentHistory] = useState({
    name: '',
    inquiry: '',
    answer: ''
  })

  useEffect(() => { }, [])

  const fetchData = async () => {
    setIsLoading(true)
    try {
      const { success, data, message } = await fetchAdjustmentHistory(id)

      if (!success) throw new Error(message)
      if (data) {
        setAdjustmentHistory(data)
      }
      setError(null)
    } catch (error) {
      console.error(error)
      setError(error)
    } finally {
      setIsLoading(false)
    }
  }

  useEffect(() => {
    fetchData()
  }, [])

  console.log(adjustmentHistory)

  return (
    <>
      <Navbar
        style={{ backgroundColor: colors.primary, padding: '1rem' }}
        expand='md'
      >
        <h5 className='m-0'>
          {adjustmentHistory?.partner?.username}({adjustmentHistory?.partner?.nickname})의 {dayjs(adjustmentHistory?.createdAt).format('YY/MM/DD HH:mm:ss')} {'정산상세'}
        </h5>{' '}
        {/* Use the label based on the active tab */}
      </Navbar>

      <Container className='page-content' style={{ paddingBottom: 100 }}>
        {isLoading ? (
          <Loader />
        ) : renderHistory(adjustmentHistory)}

        {/* Action bar */}
        {/* <div className={`action-bar-container show`}>
          <div className='action-bar'>
            <div className='left-actions'>
              <Button
                color='warning'
                onClick={() => window.close()}
              >
                닫기
              </Button>
            </div>
          </div>
        </div> */}
      </Container>
    </>
  )
}

export default AdjustmentHistoryView
