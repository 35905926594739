import React, { lazy, useState, Suspense, useEffect } from 'react'
import './Deposit.css'
import { Layout, NotAllowed } from 'components'
import {
  Nav,
  NavItem,
  NavLink,
  TabContent,
  TabPane,
  Row,
  Container
} from 'reactstrap'
import { useParams, useLocation, useNavigate } from 'react-router-dom'
import { useSelector } from 'react-redux'
import { userSelector } from 'features/auth/authSelectors'

const DepositList = lazy(() => import('./DepositList'))
const DepositConfig = lazy(() => import('./DepositConfig'))

function Deposit(props) {
  const currentUser = useSelector(userSelector)

  const { search, pathname } = useLocation()
  const navigate = useNavigate()
  const [activeTab, setActiveTab] = useState('list')

  const toggleTab = tab => {
    if (activeTab !== tab) {
      setActiveTab(tab)
      // navigate(`${pathname}?tab=${tab}`)
    }
  }

  useEffect(() => {
    const tabParam = new URLSearchParams(search).get('tab')
    let initialTab = tabParam || 'list'

    if (props.sub) initialTab = 'list'
    setActiveTab(initialTab)
  }, [])

  const { isRoot, previleges } = currentUser

  if ((!isRoot && !previleges.includes('deposit/view'))) return <NotAllowed />

  const children = (
    <>

      <Container className='page-content' fluid style={{ padding: 30 }}>
        <Row>
          <Nav tabs>
            {[
              { tab: 'list', label: '입금' },
              // { tab: 'configuration', label: '입금 설정' },
              // { tab: 'tab3', label: '가상 입금 설정' }
            ].map(({ tab, label }) => (
              <NavItem key={tab}>
                <NavLink
                  className={activeTab === tab ? 'sub-link active' : ''}
                  onClick={() => toggleTab(tab)}
                >
                  {label}
                </NavLink>
              </NavItem>
            ))}
          </Nav>
        </Row>
        <Row>
          <TabContent activeTab={activeTab}>
            {[
              { tab: 'list', component: <DepositList /> },
              // { tab: 'configuration', component: <DepositConfig /> },
              // { tab: 'tab3', component: <UnderConstruction /> }
            ].map(({ tab, component }) => (
              <TabPane key={tab} tabId={tab}>
                {activeTab === tab && ( // Only render the component when the activeTab matches the current tab
                  <Suspense fallback={<div>Loading...</div>}>
                    {component}
                  </Suspense>
                )}
              </TabPane>
            ))}
          </TabContent>
        </Row>
      </Container>
    </>
  )
  if (props.sub) {
    return children
  }

  return <Layout>{children}</Layout>
}

export default Deposit
