import React from 'react'
import './Loader.css'
import { Spinner } from 'reactstrap'

function Loader (props) {
  return (
    <div className='loader-overlay'>
      <div className='loader-container'>
        <Spinner color='primary' />
      </div>
    </div>
  )
}

export default Loader
