import api from './index.js'

export const fetchBanners = async (
  currentPage,
  pageSize,
  params,
  sortColumn,
  sortDirection
) => {
  try {
    const queryParams = new URLSearchParams(params).toString() // Convert params object to query string
    const response = await api.get(
      `/admin/slot-banner?pageNumber=${currentPage}&pageSize=${pageSize}&sortColumn=${sortColumn}&sortDirection=${sortDirection}&${queryParams}`
    )

    if (response.status === 200) {
      const data = response.data
      // Handle successful response
      // ...
      return data
    } else {
      throw new Error('Failed to fetch banners')
    }
  } catch (error) {
    // Handle error
    // ...
    throw new Error('Failed to fetch banners')
  }
}
export const fetchBanner = async id => {
  try {
    const response = await api.get(`/admin/slot-banner/${id}`)

    if (response.status === 200) {
      const data = response.data
      // Handle successful response
      // ...
      return data
    } else {
      throw new Error('Failed to fetch slot-banner')
    }
  } catch (error) {
    // Handle error
    // ...
    throw new Error('Failed to fetch slot-banner')
  }
}

export const updateBanner = async (id, bannerData) => {
  try {
    const response = await api.put(`/admin/slot-banner/${id}`, bannerData)

    if (response.status === 200) {
      const data = response.data
      // Handle successful response
      // ...
      return data
    } else {
      throw new Error('Failed to update slot-banner')
    }
  } catch (error) {
    // Handle error
    // ...
    throw new Error('Failed to update slot-banner')
  }
}

export const createBanner = async bannerData => {
  try {
    const response = await api.post('/admin/slot-banner', bannerData)

    if (response.status === 200) {
      const data = response.data
      // Handle successful response
      // ...
      return data
    } else {
      throw new Error('Failed to create slot-banner')
    }
  } catch (error) {
    // Handle error
    // ...
    throw new Error('Failed to create slot-banner')
  }
}

export const deleteBanner = async id => {
  try {
    const response = await api.delete(`/admin/slot-banner/${id}`)

    if (response.status === 200) {
      const data = response.data
      // Handle successful response
      // ...
      return data
    } else {
      throw new Error('Failed to delete slot-banner')
    }
  } catch (error) {
    // Handle error
    // ...
    throw new Error('Failed to delete slot-banner')
  }
}
