import React, { useState } from 'react';
import {
  Input,
  Button,
  Modal,
  ModalHeader,
  ModalBody,
  ModalFooter,
  CloseButton,
  Table
} from 'reactstrap';
import { showAlert, showSuccess } from 'utils/toast';
import { Loader } from 'components';
import { userSelector } from 'features/auth/authSelectors';
import { useSelector } from 'react-redux';
import { createPartnerWithdrawal } from 'api/partnerWithdrawalApi';

function PartnerWithdrawalModal(props) {
  const currentUser = useSelector(userSelector);
  const [type, setType] = useState('casino');
  const [amount, setAmount] = useState(null);
  const [isLoading, setIsLoading] = useState(false);

  const handleAll = () => {
    setAmount(Math.max(10000, currentUser?.wallet?.casino?.balance ?? 0));
  };

  const handleSave = async () => {
    if (isNaN(parseFloat(amount))) {
      return showAlert('숫자만 입력 가능합니다');
    }

    if (currentUser?.wallet?.casino?.balance <= 0) {
      return showAlert('보유금이 부족합니다');
    }

    if (amount < 10000) {
      return showAlert('출금 금액은 최소 10,000원 이상이어야 합니다.');
    }

    if (Number(amount) > currentUser?.wallet?.casino?.balance) {
      return showAlert('보유 보유금을 초과할 수 없습니다');
    }

    setIsLoading(true);

    try {
      const { success, message } = await createPartnerWithdrawal({ type, amount });

      if (!success) throw new Error(message);

      showSuccess('저장되었습니다!');
      setAmount(null);
      props.setIsWithdrawal(false);
    } catch (error) {
      console.error('Failed to update alarm config:', error);
      showAlert(error.message);
    }

    setIsLoading(false);
  };

  const quickAmountButtons = [10000, 50000, 100000, 1000000];

  return (
    <Modal
      backdrop={true}
      centered={true}
      isOpen={props.open}
      toggle={() => props.setIsWithdrawal(!props.open)}
    >
      <ModalHeader
        className="primary-background"
        close={
          <CloseButton
            onClick={() => props.setIsWithdrawal(false)}
            variant="white"
          />
        }
      >
        파트너 출금 요청
      </ModalHeader>
      <ModalBody>
        {isLoading && <Loader />}
        <Table bordered responsive style={{ marginTop: '2rem' }}>
          <tbody className="align-items-center">
            <tr>
              <td className="form-header text-center" colSpan={2}>
                현재 보유금: {currentUser?.wallet?.casino?.balance?.toLocaleString()}원
                &nbsp;&nbsp;
                <Button color="warning" onClick={handleAll}>
                  전액
                </Button>
              </td>
            </tr>
            <tr>
              <td className="form-header text-center align-middle">지갑 유형</td>
              <td>카지노</td>
            </tr>
            <tr>
              <td className="form-header text-center align-middle">요청 금액</td>
              <td>
                <Input
                  type="number"
                  className="form-control-sm input-sm"
                  placeholder="요청 금액"
                  value={amount ?? ''}
                  onChange={(e) => {
                    const inputAmount = parseInt(e.target.value, 10);
                    setAmount(isNaN(inputAmount) ? 0 : inputAmount);
                  }}
                />
                <small className="text-muted">최소 금액: 10,000원</small>
              </td>
            </tr>
          </tbody>
        </Table>
        <div className="d-flex justify-content-around mt-3">
          {quickAmountButtons.map((value) => (
            <Button
              key={value}
              color="secondary"
              className="btn-sm"
              onClick={() => setAmount((prev) => prev + value)}
            >
              +{value.toLocaleString()}원
            </Button>
          ))}
          <Button color="danger" className="btn-sm" onClick={() => setAmount(0)}>
            초기화
          </Button>
        </div>
      </ModalBody>
      <ModalFooter>
        <Button color="primary" onClick={handleSave}>
          요청
        </Button>{' '}
        <Button color="secondary" onClick={() => props.setIsWithdrawal(false)}>
          취소
        </Button>
      </ModalFooter>
    </Modal>
  );
}

export default PartnerWithdrawalModal;

