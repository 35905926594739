export const fetchUsersRequest = () => ({
  type: 'FETCH_USERS_REQUEST'
})

export const fetchUsersSuccess = (users, meta, params) => ({
  type: 'FETCH_USERS_SUCCESS',
  payload: {
    users,
    meta,
    params
  }
})

export const fetchUsersFailure = error => ({
  type: 'FETCH_USERS_FAILURE',
  payload: error
})
