import api from './index.js'

export const fetchRules = async (
  currentPage,
  pageSize,
  params,
  sortColumn,
  sortDirection
) => {
  try {
    const queryParams = new URLSearchParams(params).toString() // Convert params object to query string
    const response = await api.get(
      `/admin/rule?pageNumber=${currentPage}&pageSize=${pageSize}&sortColumn=${sortColumn}&sortDirection=${sortDirection}&${queryParams}`
    )

    if (response.status === 200) {
      const data = response.data
      // Handle successful response
      // ...
      return data
    } else {
      throw new Error('Failed to fetch rules')
    }
  } catch (error) {
    // Handle error
    // ...
    throw new Error('Failed to fetch rules')
  }
}
export const fetchRule = async id => {
  try {
    const response = await api.get(`/admin/rule/${id}`)

    if (response.status === 200) {
      const data = response.data
      // Handle successful response
      // ...
      return data
    } else {
      throw new Error('Failed to fetch rule')
    }
  } catch (error) {
    // Handle error
    // ...
    throw new Error('Failed to fetch rule')
  }
}

export const updateRule = async (id, ruleData) => {
  try {
    const response = await api.put(`/admin/rule/${id}`, ruleData)

    if (response.status === 200) {
      const data = response.data
      // Handle successful response
      // ...
      return data
    } else {
      throw new Error('Failed to update rule')
    }
  } catch (error) {
    // Handle error
    // ...
    throw new Error('Failed to update rule')
  }
}

export const createRule = async ruleData => {
  try {
    const response = await api.post('/admin/rule', ruleData)

    if (response.status === 200) {
      const data = response.data
      // Handle successful response
      // ...
      return data
    } else {
      throw new Error('Failed to create rule')
    }
  } catch (error) {
    // Handle error
    // ...
    throw new Error('Failed to create rule')
  }
}

export const deleteRule = async id => {
  try {
    const response = await api.delete(`/admin/rule/${id}`)

    if (response.status === 200) {
      const data = response.data
      // Handle successful response
      // ...
      return data
    } else {
      throw new Error('Failed to delete rule')
    }
  } catch (error) {
    // Handle error
    // ...
    throw new Error('Failed to delete rule')
  }
}
