import React, { useState, useEffect } from "react"
import "./Bet.css"
import { Input, Table, Button, Alert, Modal, ModalHeader } from "reactstrap"
import dayjs from "dayjs"
import { Loader } from "components"
import colors from "assets/colors"
import { DatePicker } from "reactstrap-date-picker"
import { fetchPartners, fetchPartnersBetInfo, updatePartner } from "api/partnerApi"
import { useLocation } from "react-router-dom"

const columns = [
  { key: "username", label: "아이디/닉네임", sortable: false },
  // { key: "totalDeposit", label: "총입금", sortable: false },
  // { key: "totalWithdrawal", label: "총출금", sortable: false },
  { key: "totalBet", label: "총베팅", sortable: false },
  { key: "totalWin", label: "총당첨", sortable: false },
  { key: "totalDiff", label: "총베팅-총당첨", sortable: false },
  // { key: "rolling", label: "롤링%", sortable: false },
  { key: "totalRolling", label: "총롤링", sortable: false },
  { key: "betProfit", label: "베팅 손익", sortable: false },
  { key: "egg", label: "보유알", sortable: false },
  { key: "profit", label: "보유수익금", sortable: false },
]

const subColumns = [
  { key: "username", label: "아이디/닉네임", sortable: false },
  // { key: "totalDeposit", label: "총입금", sortable: false },
  // { key: "totalWithdrawal", label: "총출금", sortable: false },
  { key: "totalBet", label: "총베팅", sortable: false },
  { key: "totalWin", label: "총당첨", sortable: false },
  { key: "totalDiff", label: "총베팅-총당첨", sortable: false },
  // { key: "rolling", label: "롤링%", sortable: false },
  { key: "totalRolling", label: "총롤링", sortable: false },
  { key: "betProfit", label: "베팅 손익", sortable: false },
  { key: "egg", label: "보유알", sortable: false },
  { key: "profit", label: "보유수익금", sortable: false },
]

BetStatusSlotList.defaultProps = {
  search: true,
}

function BetStatusSlotList(props) {
  const location = useLocation()
  const [updateFlag, setUpdateFlag] = useState(false)
  // Partner data
  const [partner, setPartner] = useState(null)
  const [children, setChildren] = useState([])
  const [totals, setTotals] = useState(null)
  const [isLoading, setIsLoading] = useState(false)
  const [error, setError] = useState(null)

  const [hoveredRow, setHoveredRow] = useState(null)

  // const today = dayjs().format('YYYY-MM-DD')
  // const startDate = dayjs().subtract(30, "day").format("YYYY-MM-DD")
  const today = dayjs().format("YYYY-MM-DD")

  const defaultParams = {
    category: props?.category ?? "all",
    parent: props?.parent ?? "",
    // startDate: dayjs().subtract(120, "day").format("YYYY-MM-DD"),
    startDate: today,
    endDate: today,
    startTime: "00:00",
    endTime: "23:59",
  }

  const [params, setParams] = useState(defaultParams)

  const resetParams = async () => {
    setParams(defaultParams)
  }

  useEffect(() => {
    setParams({
      ...defaultParams,
      ...location?.state?.params,
    })
    setUpdateFlag(true)
  }, [location])

  useEffect(() => {
    if (location?.state?.refresh) {
      setUpdateFlag(true)
    }
  }, [location])

  useEffect(() => {
    setUpdateFlag(true)
  }, [])

  useEffect(() => {
    if (updateFlag) {
      refresh()
    }
  }, [updateFlag])

  useEffect(() => {
    setParams({
      ...defaultParams,
      parent: props?.parent ?? "",
      ...location?.state?.params,
    })
    setUpdateFlag(true)
  }, [props.parent])

  const refresh = async () => {
    fetchData(true)
    setError(null)
    setUpdateFlag(false)
  }

  const fetchData = async (applyParams = true) => {
    setIsLoading(true)
    try {
      const { success, data } = await fetchPartnersBetInfo(
        applyParams ? params : {}
      )

      if (!success) throw new Error("Something is wrong")
      setPartner(data?.partner ?? null)
      setChildren(data?.children ?? [])
      console.log(data)
      const totals = calculateTotals(data?.children ?? [])
      setTotals(totals)
    } catch (error) {
      console.error(error)
      setError(error)
    } finally {
      setIsLoading(false)
    }
  }

  const calculateTotals = (children) => {
    let totalDeposits = 0
    let totalWithdrawals = 0
    let totalNetDeposits = 0

    let totalSlotBets = 0
    let totalSlotWins = 0
    let totalSlotBetDiffs = 0
    let totalSlotRollings = 0
    let totalSlotLosings = 0
    let totalSlotNetProfits = 0

    let totalCasinoBets = 0
    let totalCasinoWins = 0
    let totalCasinoBetDiffs = 0
    let totalCasinoRollings = 0
    let totalCasinoLosings = 0
    let totalCasinoNetProfits = 0

    for (const item of children) {
      totalDeposits += item?.deposits || 0
      totalWithdrawals += item?.withdrawals || 0
      totalNetDeposits += item?.netDeposits || 0

      totalSlotBets += item?.slotBets || 0
      totalSlotWins += item?.slotWins || 0
      totalSlotBetDiffs += item?.slotBetDiffs || 0

      totalSlotRollings += item?.slotRollings || 0
      totalSlotLosings += item?.slotLosings || 0
      totalSlotNetProfits += item?.slotNetProfits || 0

      totalCasinoBets += item?.casinoBets || 0
      totalCasinoWins += item?.casinoWins || 0
      totalCasinoBetDiffs += item?.casinoBetDiffs || 0
      totalCasinoRollings += item?.casinoRollings || 0
      totalCasinoLosings += item?.casinoLosings || 0
      totalCasinoNetProfits += item?.casinoNetProfits || 0
    }

    return {
      totalDeposits,
      totalWithdrawals,
      totalNetDeposits,
      totalSlotBets,
      totalSlotWins,
      totalSlotBetDiffs,
      totalSlotRollings,
      totalSlotLosings,
      totalSlotNetProfits,
      totalCasinoBets,
      totalCasinoWins,
      totalCasinoBetDiffs,
      totalCasinoRollings,
      totalCasinoLosings,
      totalCasinoNetProfits,
    }
  }

  const handleDateChange = (end, date) => {
    if (date === null || date === undefined) {
      setParams((prevParams) => ({
        ...prevParams,
        [end]: null, // or use a default value like an empty string ''
      }));
    } else {  
      setParams((prevParams) => ({
        ...prevParams,
        [end]: dayjs(date).format("YYYY-MM-DD"),
      }));
    }
  }

  const handleTimeChange = (end, time) => {
    if (time === null || time === undefined) {
      setParams((prevParams) => ({
        ...prevParams,
        [end]: null, // or use a default value like an empty string ''
      }));
    } else {  
      setParams((prevParams) => ({
        ...prevParams,
        [end]: time,
      }));
    }
  }

  const renderColumnLabel = (column) => {
    if (column.key === "access.registeredAt" || column.key === "registeredIp") {
      return column.label.split("/").map((label, index) => (
        <React.Fragment key={index}>
          {label}
          <br />
        </React.Fragment>
      ))
    } else {
      return column.label
    }
  }

  function formatNumber(value) {
    return Number((Number(value) || 0).toFixed(0)).toLocaleString()
  }

  //   console.log(partner)
  //   console.log(children)
  return (
    <div style={{ padding: 5, paddingBottom: 100 }}>
      {isLoading && <Loader />}
      <Table>
        <tbody className="align-items-center">
          <tr>
            <td className="form-header w-100p align-middle text-center" xs={3}>
              검색
            </td>
            <td xs={9}>
              <div className="d-flex align-items-center">
                <div className="me-3">
                  <DatePicker
                    value={params.startDate}
                    onChange={(date) => handleDateChange("startDate", date)}
                    className="form-control form-control-sm"
                    placeholder="시작일"
                    showClearButton={false}
                    dateFormat="YYYY-MM-DD"
                    maxDate={new Date(params.endDate).toString()}
                  />
                </div>
                <div className="me-3">
                  <Input
                    type="time"
                    name="startTime"
                    value={params?.startTime}
                    onChange={(e) =>
                      handleTimeChange("startTime", e.target.value)
                    }
                    className="form-control form-control-sm"
                  />
                </div>
                <div className="me-3">~</div>
                <div className="me-3">
                  <DatePicker
                    value={params.endDate}
                    onChange={(date) => handleDateChange("endDate", date)}
                    className="form-control form-control-sm"
                    placeholder="종료일"
                    showClearButton={false}
                    dateFormat="YYYY-MM-DD"
                    minDate={new Date(params.startDate).toString()}
                  />
                </div>
                <div className="me-3">
                  <Input
                    type="time"
                    name="endTime"
                    value={params?.endTime}
                    onChange={(e) =>
                      handleTimeChange("endTime", e.target.value)
                    }
                    className="form-control form-control-sm"
                  />
                </div>
                <Button
                  style={{
                    backgroundColor: colors.primary,
                    borderColor: colors.primary,
                  }}
                  onClick={(e) => {
                    e.preventDefault()
                    fetchData(true)
                  }}
                  color="primary"
                  size="sm"
                >
                  검색
                </Button>
                &nbsp;
                <Button onClick={resetParams} color="warning" size="sm">
                  초기화
                </Button>
              </div>
            </td>
          </tr>
        </tbody>
      </Table>
      <>
        <h5 className="d-flex justify-content-between">
          {partner?.username}({partner?.nickname}) 정보
          <div>
          </div>
        </h5>
        {error && <Alert color="danger">{error?.message}</Alert>}
        <Table bordered>
          <thead>
            <tr>
              {columns.map((column) => (
                <th key={column.key} className="fixed-width">
                  {renderColumnLabel(column)}
                </th>
              ))}
            </tr>
          </thead>
          <tbody className="text-center">
            {!partner ? (
              <tr>
                <td className="text-center" colSpan={subColumns.length}>
                  결과없음
                </td>
              </tr>
            ) : (
              <tr>
                <td>
                  {partner?.username}({partner?.nickname})
                </td>

                <td>
                  {formatNumber(partner?.casinoBets + partner?.slotBets)}
                </td>
                <td>
                  {formatNumber(partner?.casinoWins + partner?.slotWins)}
                </td>
                <td>
                  {formatNumber(partner?.casinoBetDiffs + partner?.slotBetDiffs)}
                </td>
                <td>
                  {formatNumber(partner?.casinoRollings + partner?.slotRollings)}
                </td>
                <td>
                  {formatNumber(partner?.casinoNetProfits + partner?.slotNetProfits)}
                </td>
                <td>
                  <div>{formatNumber(partner?.egg?.casino)}</div>
                  {/* <div>스포츠: {formatNumber(partner?.egg?.sports)}</div> */}
                </td>
                <td>{formatNumber(partner?.profit)}</td>
              </tr>
            )}
          </tbody>
        </Table>

        <div className="p-1" />

        <h5>
          {partner?.username}({partner?.nickname})의 하부 파트너 정보
        </h5>
        <Table bordered>
          <thead>
            <tr>
              {subColumns.map((column) => (
                <th key={column.key} className="fixed-width">
                  {renderColumnLabel(column)}
                </th>
              ))}
            </tr>
          </thead>
          <tbody className="text-center">
            {children.length === 0 ? (
              <tr>
                <td className="text-center" colSpan={subColumns.length}>
                  결과없음
                </td>
              </tr>
            ) : (
              children.map((item, index) => (
                <tr
                  key={index}
                  className={index === hoveredRow ? "hovered" : ""}
                  onMouseEnter={() => setHoveredRow(index)}
                  onMouseLeave={() => setHoveredRow(null)}
                >
                  <td>
                    {item?.username}({item?.nickname})
                  </td>
                  <td>
                    {formatNumber(item?.casinoBets + item?.slotBets)}
                  </td>
                  <td>
                    {formatNumber(item?.casinoWins + item?.slotWins)}
                  </td>
                  <td>
                    {formatNumber(item?.casinoBetDiffs + item?.slotBetDiffs)}
                  </td>
                  <td>
                    {formatNumber(item?.casinoRollings + item?.slotRollings)}
                  </td>
                  <td>
                    {formatNumber(item?.casinoNetProfits + item?.slotNetProfits)}
                  </td>
                  <td>
                    <div>{formatNumber(item?.egg?.casino)}</div>
                  </td>
                  <td>
                    <div>{formatNumber(item?.profit)}</div>
                  </td>
                </tr>
              ))
            )}
          </tbody>
        </Table>
      </>
    </div>
  )
}

export default BetStatusSlotList
