export const alarmRequest = () => ({
  type: 'ALARM_REQUEST'
})

export const alarmSuccess = config => ({
  type: 'ALARM_SUCCESS',
  payload: config
})

export const alarmFailure = error => ({
  type: 'ALARM_FAILURE',
  payload: error
})

export const siteConfigRequest = () => ({
  type: 'SITE_CONFIG_REQUEST'
})

export const siteConfigSuccess = config => ({
  type: 'SITE_CONFIG_SUCCESS',
  payload: config
})

export const siteConfigFailure = error => ({
  type: 'SITE_CONFIG_FAILURE',
  payload: error
})