import React, { useState, useEffect } from "react"
import "./Loss.css"
import { Input, Table, Button, Alert, Modal } from "reactstrap"
import dayjs from "dayjs"
import { Loader } from "components"
import colors from "assets/colors"
import { DatePicker } from "reactstrap-date-picker"
import {
  fetchPartners,
  fetchPartnersLossInfo,
  updatePartner,
} from "api/partnerApi"
import { useLocation } from "react-router-dom"
import LossSettingModal from "./LossSettingModal"

const columns = [
  { key: "username", label: "아이디/닉네임", sortable: false },
  { key: "slotRollings", label: "전일 슬롯베팅", sortable: false },
  { key: "slotLosings", label: "전일 슬롯당첨", sortable: false },
  { key: "casinoRollings", label: "전일 슬롯롤링", sortable: false },
  { key: "casinoLosing", label: "슬롯 로스 %", sortable: false },

  { key: "slotWins", label: "전일 카지노베팅", sortable: false },
  { key: "slotLoses", label: "전일 카지노당첨", sortable: false },
  { key: "casinoBets", label: "전일 카지노롤링", sortable: false },
  { key: "casinoLoses", label: "카지노 로스 %", sortable: false },
]

const subColumns = [
  { key: "username", label: "아이디/닉네임", sortable: false },
  { key: "slotRollings", label: "전일 슬롯베팅", sortable: false },
  { key: "slotLosings", label: "전일 슬롯당첨", sortable: false },
  { key: "casinoRollings", label: "전일 슬롯롤링", sortable: false },
  { key: "casinoLosing", label: "슬롯 로스 %", sortable: false },

  { key: "slotWins", label: "전일 카지노베팅", sortable: false },
  { key: "slotLoses", label: "전일 카지노당첨", sortable: false },
  { key: "casinoBets", label: "전일 카지노롤링", sortable: false },
  { key: "casinoLoses", label: "카지노 로스 %", sortable: false },
]

LossSettingList.defaultProps = {
  search: true,
}

function LossSettingList(props) {
  const location = useLocation()
  const [updateFlag, setUpdateFlag] = useState(false)
  // Partner data
  const [partner, setPartner] = useState(null)
  const [children, setChildren] = useState([])
  const [isLoading, setIsLoading] = useState(false)
  const [error, setError] = useState(null)

  const [selectedItem, setSelectedItem] = useState(null)
  const [category, setCategory] = useState(null)

  const [updateModal, setUpdateModal] = useState(false)

  const [hoveredRow, setHoveredRow] = useState(null)
  const today = dayjs().format("YYYY-MM-DD")

  const defaultParams = {
    parent: props?.parent ?? "",
    startDate: today,
    endDate: today,
    startTime: "00:00",
    endTime: "23:59",
  }

  const [params, setParams] = useState(defaultParams)

  useEffect(() => {
    setParams({
      ...defaultParams,
      ...location?.state?.params,
    })
    setUpdateFlag(true)
  }, [location])

  useEffect(() => {
    if (location?.state?.refresh) {
      setUpdateFlag(true)
    }
  }, [location])

  useEffect(() => {
    setUpdateFlag(true)
  }, [])

  useEffect(() => {
    if (updateFlag) {
      refresh()
    }
  }, [updateFlag])

  useEffect(() => {
    setParams({
      ...defaultParams,
      parent: props?.parent ?? "",
      ...location?.state?.params,
    })
    setUpdateFlag(true)
  }, [props.parent])

  const refresh = async () => {
    fetchData(true)
    setError(null)
    setUpdateFlag(false)
  }

  const fetchData = async (applyParams = true) => {
    setIsLoading(true)
    try {
      const { success, data } = await fetchPartnersLossInfo(
        applyParams ? params : {}
      )

      if (!success) throw new Error("Something is wrong")
      setPartner(data?.partner ?? null)
      setChildren(data?.children ?? [])
    } catch (error) {
      console.error(error)
      setError(error)
    } finally {
      setIsLoading(false)
    }
  }

  const renderColumnLabel = (column) => {
    if (column.key === "access.registeredAt" || column.key === "registeredIp") {
      return column.label.split("/").map((label, index) => (
        <React.Fragment key={index}>
          {label}
          <br />
        </React.Fragment>
      ))
    } else {
      return column.label
    }
  }

  function formatNumber(value) {
    return Number((Number(value) || 0).toFixed(0)).toLocaleString()
  }

  return (
    <div style={{ padding: 5, paddingBottom: 100 }}>
      {isLoading && <Loader />}

      <>
        <h5 className="d-flex justify-content-between">
          {partner?.username}({partner?.nickname}) 로스 정보
          <div></div>
        </h5>
        {error && <Alert color="danger">{error?.message}</Alert>}
        <Table bordered>
          <thead>
            <tr>
              {columns.map((column) => (
                <th key={column.key} className="fixed-width">
                  {renderColumnLabel(column)}
                </th>
              ))}
            </tr>
          </thead>
          <tbody className="text-center">
            {!partner ? (
              <tr>
                <td className="text-center" colSpan={subColumns.length}>
                  결과없음
                </td>
              </tr>
            ) : (
              <tr>
                <td>
                  {partner?.username}({partner?.nickname})
                </td>

                <td>{formatNumber(partner?.slotBets)}</td>
                <td>{formatNumber(partner?.slotWins)}</td>
                <td>{formatNumber(partner?.slotRollings)}</td>
                <td>
                  {formatNumber(partner?.config?.loss?.slot)}%
                  <div className="mt-1">
                    <Button
                      className="me-1"
                      color="warning"
                      size="sm"
                      onClick={() => {
                        setSelectedItem(partner)
                        setCategory("slot")
                        setUpdateModal(!updateModal)
                      }}
                    >
                      로스 조정
                    </Button>
                  </div>
                </td>

                <td>{formatNumber(partner?.casinoBets)}</td>
                <td>{formatNumber(partner?.casinoWins)}</td>

                <td>{formatNumber(partner?.casinoRollings)}</td>
                <td>
                  {formatNumber(partner?.config?.loss?.casino)}%
                  <div className="mt-1">
                    <Button
                      className="me-1"
                      color="warning"
                      size="sm"
                      onClick={() => {
                        setSelectedItem(partner)
                        setCategory("casino")
                        setUpdateModal(!updateModal)
                      }}
                    >
                      로스 조정
                    </Button>
                  </div>
                </td>
              </tr>
            )}
          </tbody>
        </Table>

        <div className="p-1" />

        <h5>
          {partner?.username}({partner?.nickname})의 하부 파트너 정보
        </h5>
        <Table bordered>
          <thead>
            <tr>
              {subColumns.map((column) => (
                <th key={column.key} className="fixed-width">
                  {renderColumnLabel(column)}
                </th>
              ))}
            </tr>
          </thead>
          <tbody className="text-center">
            {children.length === 0 ? (
              <tr>
                <td className="text-center" colSpan={subColumns.length}>
                  결과없음
                </td>
              </tr>
            ) : (
              children.map((item, index) => (
                <tr
                  key={index}
                  className={index === hoveredRow ? "hovered" : ""}
                  onMouseEnter={() => setHoveredRow(index)}
                  onMouseLeave={() => setHoveredRow(null)}
                >
                  <td>
                    {item?.username}({item?.nickname})
                  </td>
                  <td>
                    <div>{formatNumber(item?.slotBets)}</div>
                  </td>
                  <td>
                    <div>{formatNumber(item?.slotWins)}</div>
                  </td>
                  <td>
                    <div>{formatNumber(item?.slotRollings)}</div>
                  </td>
                  <td>
                    {formatNumber(item?.config?.loss?.slot)}%
                    <div className="mt-1">
                      <Button
                        className="me-1"
                        color="warning"
                        size="sm"
                        onClick={() => {
                          setSelectedItem(item)
                          setCategory("slot")
                          setUpdateModal(!updateModal)
                        }}
                      >
                        로스 조정
                      </Button>
                    </div>
                  </td>
                  <td>{formatNumber(item?.casinoBets)}</td>
                  <td>{formatNumber(item?.casinoWins)}</td>

                  <td>{formatNumber(item?.casinoRollings)}</td>
                  <td>
                    {formatNumber(item?.config?.loss?.casino)}%
                    <div className="mt-1">
                      <Button
                        className="me-1"
                        color="warning"
                        size="sm"
                        onClick={() => {
                          setSelectedItem(item)
                          setCategory("casino")
                          setUpdateModal(!updateModal)
                        }}
                      >
                        로스 조정
                      </Button>
                    </div>
                  </td>
                </tr>
              ))
            )}
          </tbody>
        </Table>
      </>

      <Modal
        size={"md"}
        isOpen={updateModal}
        toggle={() => setUpdateModal(!updateModal)}
        onClosed={refresh}
      >
        <LossSettingModal
          targetId={selectedItem?._id}
          category={category}
          onClose={() => setUpdateModal(false)}
          {...props}
        />
      </Modal>
    </div>
  )
}

export default LossSettingList
