import api from './index.js'

export const fetchDashboard = async (params) => {
  try {
    const queryParams = new URLSearchParams(params).toString() // Convert params object to query string
    const response = await api.get(
      `/admin/dashboard?${queryParams}`
    )

    if (response.status === 200) {
      const data = response.data
      return data
    } else {
      throw new Error('Failed to fetch dashboard')
    }
  } catch (error) {
    throw new Error('Failed to fetch dashboard')
  }
}
