import React, { lazy, useState, Suspense, useEffect } from "react";
import "./PartnerEdit.css";
import {
  Navbar,
  Nav,
  NavItem,
  NavLink,
  TabContent,
  TabPane,
  Row,
  Container,
} from "reactstrap";
import { UnderConstruction, Loader } from "components";
import { useParams, useLocation, useNavigate } from "react-router-dom";
import { MdClose } from "react-icons/md";
import colors from "assets/colors";
import { fetchUser } from "api/userApi";

const PartnerBasicInfo = lazy(() => import("./PartnerBasicInfo"));
const PartnerPrevilegeConfig = lazy(() => import("./PartnerPrevilegeConfig"));
const PartnerAdjustmentConfig = lazy(() => import("./PartnerAdjustmentConfig"));
const PartnerWithdrawalList = lazy(() =>
  import("../../PartnerWithdrawal/PartnerWithdrawalList")
);
const PartnerMemberList = lazy(() => import("../../Member/MemberList"));
const PartnerUserCouponList = lazy(() =>
  import("../../UserCoupon/UserCouponList")
);
const PartnerUserHistoryList = lazy(() =>
  import("../../UserHistory/UserHistoryList")
);
const PartnerEggHistoryList = lazy(() =>
  import("../../EggHistory/EggHistoryList")
);
const PartnerLoginAccessList = lazy(() =>
  import("../../LoginAccess/LoginAccessList")
);
const PartnerReferralList = lazy(() => import("../../Referral/ReferralList"));
const PartnerAdjustmentHistoryList = lazy(() =>
  import("../../AdjustmentHistory/AdjustmentHistoryList")
);
const PartnerWalletHistoryList = lazy(() =>
  import("../../WalletHistory/WalletHistoryList")
);
const PartnerMailCreate = lazy(() => import("./PartnerMailCreate"));

function PartnerDetail(props) {
  const { search, pathname } = useLocation();
  // const { id } = useParams()
  const id = props?.targetId ?? null;
  const navigate = useNavigate();
  const [activeTab, setActiveTab] = useState("basic");
  const [user, setUser] = useState({ nickname: "" });
  const [isLoading, setIsLoading] = useState(false);
  const [error, setError] = useState(null);
  const toggleTab = (tab) => {
    if (activeTab !== tab) {
      setActiveTab(tab);
      // navigate(`${pathname}?tab=${tab}`)
    }
  };

  useEffect(() => {
    // const tabParam = new URLSearchParams(search).get('tab')
    // const initialTab = tabParam || 'basic'
    // setActiveTab(initialTab)
    fetchData(id);
  }, [id]);

  useEffect(() => {}, []);

  const fetchData = async () => {
    setIsLoading(true);
    try {
      const { success, data, message } = await fetchUser(id, [
        "username nickname role",
      ]);

      if (!success) throw new Error(message);
      setUser(data);
    } catch (error) {
      console.error(error);
      setError(error);
    } finally {
      setIsLoading(false);
    }
  };

  const titleName = `${user.username}(${user.nickname})`;
  const titleRole =
    user.roleName === "root"
      ? "총본"
      : user.roleName === "headQuarter"
      ? "본사"
      : "파트너";

  const tabPrefix = `${titleName} ${titleRole}`;
  const tabLabels = {
    basic: `${tabPrefix} 기본 정보`,
    previlegeConfig: `${tabPrefix} 권한설정`,
    adjustmentConfig: `${tabPrefix} 정산설정`,
    withdrawalHistory: `${tabPrefix} 출금`,
    childrenList: `${tabPrefix} 보유회원`,
    userCouponList: `${tabPrefix} 쿠폰기록`,
    eggHistory: `${tabPrefix} 알기록`,
    adjustmentHistory: `${tabPrefix} 정산기록`,
    walletHistory: `${tabPrefix} 캐시기록`,
    partnerHistory: `${tabPrefix} 변경기록`,
    accessHistory: `${tabPrefix} 접속기록`,
    // referralCode: `${titleName} ${titleRole} 추천코드`,
    mail: `${tabPrefix}에게 쪽지보내기`,
  };
  return (
    <>
      <Navbar
        style={{ backgroundColor: colors.primary, padding: "1rem" }}
        expand="md"
      >
        <h5 className="m-0">{tabLabels[activeTab]}</h5>{" "}
        <div className="text-right">
          <MdClose
            className="clickable-icon"
            size={18}
            onClick={() => props.onClose && props.onClose()}
          />
        </div>
        {/* Use the label based on the active tab */}
      </Navbar>
      <Container className="page-content" fluid style={{ padding: 30 }}>
        {isLoading && <Loader />}
        <Row>
          <Nav tabs>
            {[
              { tab: "basic", label: "기본정보" },
              { tab: "previlegeConfig", label: "권한설정" },
              { tab: "adjustmentConfig", label: "정산설정" },
              { tab: "withdrawalHistory", label: "파트너 출금" },
              { tab: "childrenList", label: "보유회원" },
              { tab: "userCouponList", label: "쿠폰내역" },
              { tab: "eggHistory", label: "알기록" },
              { tab: "adjustmentHistory", label: "정산기록" },

              { tab: "walletHistory", label: "캐시기록" },
              { tab: "partnerHistory", label: "변경기록" },
              { tab: "accessHistory", label: "접속기록" },
              // { tab: 'referralCode', label: '추천코드' },
              { tab: "mail", label: "쪽지보내기" },
            ]
            .filter(({ tab }) => {
              const isInTarget =
                props.targetTabs?.includes("*") || props.targetTabs?.includes(tab); // Include if "*" or explicitly in targetTabs
              const isExcluded = props.excludeTabs?.includes(tab); // Exclude if in excludeTabs
              return isInTarget && !isExcluded; // Include if in targetTabs and not excluded
            })
              .map(({ tab, label }) => (
                <NavItem key={tab}>
                  <NavLink
                    className={activeTab === tab ? "sub-link active" : ""}
                    onClick={() => toggleTab(tab)}
                  >
                    {label}
                  </NavLink>
                </NavItem>
              ))}
          </Nav>
        </Row>
        <Row>
          <TabContent activeTab={activeTab}>
            {[
              { tab: "basic", component: <PartnerBasicInfo {...props} /> },
              {
                tab: "previlegeConfig",
                component: <PartnerPrevilegeConfig {...props} />,
              },
              {
                tab: "adjustmentConfig",
                component: <PartnerAdjustmentConfig {...props} />,
              },
              {
                tab: "withdrawalHistory",
                component: <PartnerWithdrawalList {...props} />,
              },
              {
                tab: "childrenList",
                component: <PartnerMemberList {...props} />,
              },
              {
                tab: "userCouponList",
                component: <PartnerUserCouponList {...props} />,
              },
              {
                tab: "eggHistory",
                component: <PartnerEggHistoryList {...props} />,
              },
              {
                tab: "adjustmentHistory",
                component: <PartnerAdjustmentHistoryList {...props} />,
              },
              {
                tab: "walletHistory",
                component: <PartnerWalletHistoryList {...props} />,
              },
              {
                tab: "partnerHistory",
                component: <PartnerUserHistoryList {...props} />,
              },
              {
                tab: "accessHistory",
                component: <PartnerLoginAccessList {...props} />,
              },
              {
                tab: "referralCode",
                component: <PartnerReferralList {...props} />,
              },
              { tab: "mail", component: <PartnerMailCreate user={user} /> },
            ].map(({ tab, component }) => (
              <TabPane key={tab} tabId={tab}>
                {activeTab === tab && ( // Only render the component when the activeTab matches the current tab
                  <Suspense fallback={<div>Loading...</div>}>
                    {component}
                  </Suspense>
                )}
              </TabPane>
            ))}
          </TabContent>
        </Row>
      </Container>
    </>
  );
}

export default PartnerDetail;
