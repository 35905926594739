const initialState = {
  alarm: {},
  siteConfig: {},
  loading: false,
  error: ''
}

const configReducer = (state = initialState, action) => {
  switch (action.type) {
    case 'ALARM_REQUEST':
      return {
        ...state,
        loading: true
      }
    case 'ALARM_SUCCESS':
      return {
        ...state,
        loading: false,
        alarm: action.payload,
        error: ''
      }
    case 'ALARM_FAILURE':
      return {
        ...state,
        loading: false,
        error: action.payload
      }
    case 'SITE_CONFIG_REQUEST':
      return {
        ...state,
        loading: true
      }
    case 'SITE_CONFIG_SUCCESS':
      return {
        ...state,
        loading: false,
        siteConfig: action.payload,
        error: ''
      }
    case 'SITE_CONFIG_FAILURE':
      return {
        ...state,
        loading: false,
        error: action.payload
      }
    default:
      return state
  }
}

export default configReducer
