import React, { lazy, useState, useEffect, Suspense } from 'react'
import './Partner.css'
import { Layout } from 'components'
import {
  Nav,
  NavItem,
  NavLink,
  TabContent,
  TabPane,
  Row,
  Container
} from 'reactstrap'
import { useParams, useLocation, useNavigate } from 'react-router-dom'

const PartnerList = lazy(() => import('./PartnerList'))
const PartnerTree = lazy(() => import('./Partner/PartnerTree'))
const PartnerAdjustmentTree = lazy(() => import('./PartnerAdjustment/PartnerAdjustmentTree'))
const PartnerMemberTree = lazy(() => import('./PartnerMember/PartnerMemberTree'))

function Partner (props) {
  const { search, pathname } = useLocation()
  const location = useLocation()
  const navigate = useNavigate()
  const [activeTab, setActiveTab] = useState('parterList')

  const toggleTab = tab => {
    if (activeTab !== tab) {
      setActiveTab(tab)
      // navigate(`${pathname}?tab=${tab}`)
    }
  }

  useEffect(() => {
    const tabParam = new URLSearchParams(search).get('tab')
    const initialTab = tabParam || 'parterList'
    setActiveTab(initialTab)
  }, [location])
  return (
    <Layout>
      <Container className='page-content' fluid style={{ padding: 30 }}>
        <Row>
          <Nav tabs>
            {/* <NavItem>
              <NavLink
                className={activeTab === 'parterMemberTree' ? 'sub-link active' : ''}
                onClick={() => toggleTab('parterMemberTree')}
              >
                파트너 회원 트리
              </NavLink>
            </NavItem> */}
            <NavItem>
              <NavLink
                className={activeTab === 'parterList' ? 'sub-link active' : ''}
                onClick={() => toggleTab('parterList')}
              >
                파트너 목록
              </NavLink>
            </NavItem>
            <NavItem>
              <NavLink
                className={activeTab === 'parterTree' ? 'sub-link active' : ''}
                onClick={() => toggleTab('parterTree')}
              >
                파트너 트리
              </NavLink>
            </NavItem>
            <NavItem>
              <NavLink
                className={activeTab === 'parterMemberTree' ? 'sub-link active' : ''}
                onClick={() => toggleTab('parterMemberTree')}
              >
                파트너회원 트리
              </NavLink>
            </NavItem>
            <NavItem>
              <NavLink
                className={activeTab === 'partnerAdjustment' ? 'sub-link active' : ''}
                onClick={() => toggleTab('partnerAdjustment')}
              >
                파트너 카+슬 통합 정산
              </NavLink>
            </NavItem>
          </Nav>
        </Row>
        <Row>
          <TabContent className='partner-tab-content' activeTab={activeTab}>
            {activeTab === 'parterTree' && (
              <TabPane tabId='parterTree'>
                <Suspense fallback={<div>Loading...</div>}>
                  <PartnerTree />
                </Suspense>
              </TabPane>
            )}
            {activeTab === 'parterMemberTree' && (
              <TabPane tabId='parterMemberTree'>
                <Suspense fallback={<div>Loading...</div>}>
                  <PartnerMemberTree />
                </Suspense>
              </TabPane>
            )}
            {activeTab === 'parterList' && (
              <TabPane tabId='parterList'>
                <Suspense fallback={<div>Loading...</div>}>
                  <PartnerList />
                </Suspense>
              </TabPane>
            )}
            {activeTab === 'partnerAdjustment' && (
              <TabPane tabId='partnerAdjustment'>
                <Suspense fallback={<div>Loading...</div>}>
                  <PartnerAdjustmentTree />
                </Suspense>
              </TabPane>
            )}
          </TabContent>
        </Row>
      </Container>
    </Layout>
  )
}

export default Partner
