import React, { useState } from "react"
import { getPublicUrl } from 'libs/functions'

const PUBLIC_URL = getPublicUrl()

const UserBadge = ({ user, nameOnly = false, parents = true }) => {
  const [imageExists, setImageExists] = useState(true)
  const handleImageError = () => {
    setImageExists(false)
  }
  return (
    <>
  {parents &&
    user?.parents?.map((parent, index) => (
      <div key={`parent-${index}`}>
        {/* {index > 0 && <br />} */}
        {Array(index + 1).join('   ')} {/* Use three spaces for each level */}
        └─ {parent?.username}({parent?.nickname})
      </div>
    ))}
  <div>
    {(imageExists && !nameOnly) ? (
      <img
        src={`${PUBLIC_URL}/${user?.gradeConfig?.iconImage}`}
        width={25}
        className="me-1"
        alt="avatar"
        onError={handleImageError}
      />
    ) :
      !nameOnly ?
        (
          <span>[{user?.gradeConfig?.grade ?? 'NA'}]&nbsp;</span>
        ) : null}
    {user?.username ?? ""}
    {user?.nickname ? `(${user?.nickname})` : ""}
  </div>
</>

  )
}

export default UserBadge