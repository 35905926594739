import React from 'react'
import './UnderConstruction.css'

const UnderConstruction = () => {
  return (
    <div className='under-construction-container'>
      <div className='under-construction-content'>
        <h2>준비중입니다</h2>
        <p>죄송합니다. 이 페이지는 현재 준비 중입니다.</p>
      </div>
    </div>
  )
}

export default UnderConstruction
