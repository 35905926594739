import 'react-app-polyfill/stable'
import React from 'react'
import ReactDOM from 'react-dom/client'
import { Provider } from 'react-redux'
// import store from './store';
import './index.css'
import App from './App'
import reportWebVitals from './reportWebVitals'

import { PersistGate } from 'redux-persist/integration/react'
import { store, persistor } from './store'

const root = ReactDOM.createRoot(document.getElementById('root'))
root.render(
  <PersistGate loading={null} persistor={persistor}>
    <Provider store={store}>
      <App />
    </Provider>
  </PersistGate>
)

{
  /* <React.StrictMode>
    <PersistGate loading={null} persistor={persistor}>
      <Provider store={store}>
        <App />
      </Provider>
    </PersistGate>
  </React.StrictMode> */
}

// If you want to start measuring performance in your app, pass a function
// to log results (for example: reportWebVitals(console.log))
// or send to an analytics endpoint. Learn more: https://bit.ly/CRA-vitals
reportWebVitals()
