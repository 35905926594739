import React, { useEffect, useRef, useState } from 'react';
import Iframe from 'react-iframe'

function MyEditor() {

  return (
    <Iframe url="https://user-pc-bw4.sportxxx278gwf4.com/?token=8214c5b4b8e94e51382e7b6d5e1daa5b52b20910&gr=common&tm=2&lg=en&mk=EU&ignore_iframe_pc=1"
        // width="640px"
        // height="320px"
        width="100%"
        height="2000px"
        id=""
        className=""
        display="block"
        allow="autoplay"
        position="relative"/>
  );
}

export default MyEditor;
