import React, { useState, useEffect } from 'react'
import {
    Container,
    Table,
    Label,
    Input,
    Button,
    Navbar
} from 'reactstrap'
import { showAlert, showSuccess } from 'utils/toast'
import { Loader } from 'components'
import { createApi } from 'api/apiApi'
import colors from 'assets/colors'
import { uploadImage } from 'api/uploadApi'
import { DatePicker } from 'reactstrap-date-picker'
import { useParams } from 'react-router-dom'
import Zoom from 'react-medium-image-zoom'
import 'react-medium-image-zoom/dist/styles.css'
import { getPublicUrl } from 'libs/functions'

const PUBLIC_URL = getPublicUrl()

function ApiCreate(props) {
    const { id } = useParams()
    const [isLoading, setIsLoading] = useState(false)
    const [error, setError] = useState(null)

    const [editorKey, setEditorKey] = useState(Date.now())
    const [api, setApi] = useState({
        name: '',
        provider: 'ximax',
        type: 'casino',
        thirdPartyCode: '',
        logo: '',
        logo2: '',
        background: '',
        orderKey: 0,
        display: false,
        maintenance: {
            active: false
        }
    })

    useEffect(() => { }, [])

    const refresh = () => {
        setApi({
            name: '',
            provider: 'ximax',
            type: 'casino',
            thirdPartyCode: '',
            logo: '',
            logo2: '',
            background: '',
            orderKey: 0,
            display: false,
            maintenance: {
                active: false
            }
        })
        setEditorKey(Date.now())
    }

    const handleInputChange = event => {
        const { name, value, type } = event.target

        let updatedValue = value

        // Check if the input type is a radio button
        if (type === 'radio') {
            if (value === 'true') {
                updatedValue = true // Set to true if value is 'true'
            } else if (value === 'false') {
                updatedValue = false // Set to false if value is 'false'
            }
        }

        if (type === 'number') {
            updatedValue = Number(value)
        }

        const updatedBanner = { ...api }
        const nameParts = name.split('.')
        let currentBanner = updatedBanner
        for (let i = 0; i < nameParts.length - 1; i++) {
            if (!currentBanner[nameParts[i]]) {
                currentBanner[nameParts[i]] = {}
            }
            currentBanner = currentBanner[nameParts[i]]
        }
        currentBanner[nameParts[nameParts.length - 1]] = updatedValue
        setApi(updatedBanner)
    }

    const handleSave = async () => {

        if(!api.logo) {
            return showAlert('로고 이미지를 선택해주세요')
        }

        if(!api.logo2) {
            return showAlert('로고2 이미지를 선택해주세요')
        }

        setIsLoading(true)
        try {
            const { success, message } = await createApi(api)

            if (!success) throw new Error(message)

            showSuccess('수정되었습니다!')
            refresh()
        } catch (error) {
            console.error('Failed to create api:', error)
            showAlert(error.message)
        }

        setIsLoading(false)
    }

    const handleFileSelect = async (event, target) => {
        const file = event.target.files[0]

        const result = await uploadImage(file)

        setApi(prevBanner => ({
            ...prevBanner,
            [target]: `${result.link}`
        }))
    }
    console.log(api)
    return (
        <>
            <Navbar
                style={{ backgroundColor: colors.primary, padding: '1rem' }}
                expand='md'
            >
                <h5 className='m-0'>
                    {'API 추가'}
                </h5>{' '}
                {/* Use the label based on the active tab */}
            </Navbar>

            <Container className='page-content' style={{ paddingBottom: 100 }}>
                {isLoading && <Loader />}
                <Table bordered style={{ marginTop: '2rem' }}>
                    <tbody className='align-items-center'>
                        <tr>
                            <td className='form-header text-center align-middle w-200p'>
                                이름
                            </td>
                            <td>
                                <Input
                                    type='text'
                                    className='form-control-sm input-sm'
                                    placeholder='제목'
                                    name='name'
                                    value={api?.name}
                                    onChange={handleInputChange}
                                />
                            </td>
                        </tr>
                        <tr>
                            <td className='form-header text-center align-middle w-200p'>
                                제공사
                            </td>
                            <td>
                                <Input
                                    type='text'
                                    className='form-control-sm input-sm'
                                    placeholder='제공사'
                                    name='provider'
                                    value={api?.provider}
                                    onChange={handleInputChange}
                                    disabled
                                />
                            </td>
                        </tr>
                        <tr>
                            <td className='form-header text-center align-middle w-200p'>
                                유형
                            </td>
                            <td>
                                <Input
                                    type="select"
                                    name="type"
                                    className="input-sm"
                                    value={api?.type}
                                    onChange={handleInputChange}
                                >
                                    <option value="casino">카지노</option>
                                    <option value="slot">슬롯</option>
                                </Input>
                            </td>
                        </tr>
                        <tr>
                            <td className='form-header text-center align-middle'>
                                서드파티 코드
                            </td>
                            <td>
                                <div className='d-flex align-items-center'>
                                    <div className='me-3'>
                                        <Input
                                            type='number'
                                            className='form-control-sm input-sm'
                                            placeholder='코드'
                                            name='thirdPartyCode'
                                            value={api?.thirdPartyCode}
                                            onChange={handleInputChange}
                                        />
                                    </div>
                                </div>
                            </td>
                        </tr>

                        <tr>
                            <td className='form-header text-center align-middle'>
                                순서
                            </td>
                            <td>
                                <div className='d-flex align-items-center'>
                                    <div className='me-3'>
                                        <Input
                                            type='number'
                                            className='form-control-sm input-sm'
                                            placeholder='순서'
                                            name='orderKey'
                                            value={api?.orderKey}
                                            onChange={handleInputChange}
                                        />
                                    </div>
                                </div>
                            </td>
                        </tr>

                        <tr>
                            <td className='form-header text-center align-middle'>
                                로고 이미지
                            </td>
                            <td>
                                <div className='d-flex align-items-center'>
                                    <div className='me-3'>
                                        {api?.logo ? (
                                            <Zoom>
                                                <img
                                                    src={`${PUBLIC_URL}/${api?.logo}`}
                                                    alt='PC이미지'
                                                    width={50}
                                                    className='img-fluid'
                                                />
                                            </Zoom>
                                        ) : (
                                            '이미지 없음'
                                        )}
                                        <div style={{ padding: 5 }} />
                                        <input
                                            type='file'
                                            accept='image/*'
                                            onChange={e => handleFileSelect(e, 'logo')}
                                        />
                                    </div>
                                </div>
                            </td>
                        </tr>

                        <tr>
                            <td className='form-header text-center align-middle'>
                                로고2 이미지
                            </td>
                            <td>
                                <div className='d-flex align-items-center'>
                                    <div className='me-3'>
                                        {api?.logo2 ? (
                                            <Zoom>
                                                <img
                                                    src={`${PUBLIC_URL}/${api?.logo2}`}
                                                    alt='모바일 이미지'
                                                    width={50}
                                                    className='img-fluid'
                                                />
                                            </Zoom>
                                        ) : (
                                            '이미지 없음'
                                        )}
                                        <div style={{ padding: 5 }} />
                                        <input
                                            type='file'
                                            accept='image/*'
                                            onChange={e => handleFileSelect(e, 'logo2')}
                                        />
                                    </div>
                                </div>
                            </td>
                        </tr>

                        {
                            api?.type === 'casino' ?


                                <tr>
                                    <td className='form-header text-center align-middle'>
                                        배경 이미지
                                    </td>
                                    <td>
                                        <div className='d-flex align-items-center'>
                                            <div className='me-3'>
                                                {api?.background ? (
                                                    <Zoom>
                                                        <img
                                                            src={`${PUBLIC_URL}/${api?.background}`}
                                                            alt='모바일 이미지'
                                                            width={50}
                                                            className='img-fluid'
                                                        />
                                                    </Zoom>
                                                ) : (
                                                    '이미지 없음'
                                                )}
                                                <div style={{ padding: 5 }} />
                                                <input
                                                    type='file'
                                                    accept='image/*'
                                                    onChange={e => handleFileSelect(e, 'background')}
                                                />
                                            </div>
                                        </div>
                                    </td>
                                </tr>
                                : null
                        }
                        <tr>
                            <td className='form-header text-center align-middle'>
                                노출여부
                            </td>
                            <td>
                                <Label
                                    className='mb-0 mr-2'
                                    style={{ marginRight: '1rem', padding: 0 }}
                                >
                                    <Input
                                        type='radio'
                                        name='display'
                                        value={false}
                                        checked={!api?.display}
                                        onChange={handleInputChange}
                                    />{' '}
                                    비활성
                                </Label>
                                <Label
                                    className='mb-0 mr-2'
                                    style={{ marginRight: '3rem', padding: 0 }}
                                >
                                    <Input
                                        type='radio'
                                        name='display'
                                        value={true}
                                        checked={api?.display}
                                        onChange={handleInputChange}
                                    />{' '}
                                    활성
                                </Label>
                            </td>
                        </tr>

                        <tr>
                            <td className='form-header text-center align-middle'>
                                점검여부
                            </td>
                            <td>
                                <Label
                                    className='mb-0 mr-2'
                                    style={{ marginRight: '1rem', padding: 0 }}
                                >
                                    <Input
                                        type='radio'
                                        name='maintenance.active'
                                        value={false}
                                        checked={!api?.maintenance?.active}
                                        onChange={handleInputChange}
                                    />{' '}
                                    비활성
                                </Label>
                                <Label
                                    className='mb-0 mr-2'
                                    style={{ marginRight: '3rem', padding: 0 }}
                                >
                                    <Input
                                        type='radio'
                                        name='maintenance.active'
                                        value={true}
                                        checked={api?.maintenance?.active}
                                        onChange={handleInputChange}
                                    />{' '}
                                    활성
                                </Label>
                            </td>
                        </tr>

                    </tbody>
                </Table>

                {/* Action bar */}
                <div className={`action-bar-container show`}>
                    <div className='action-bar'>
                        <div className='left-actions'>
                        </div>
                        <div className='right-actions'>
                            {/* Right-aligned action buttons */}
                            <Button className='me-2' color='primary' onClick={handleSave}>
                                저장
                            </Button>
                        </div>
                    </div>
                </div>
            </Container>
        </>
    )
}

export default ApiCreate
