import React, { forwardRef, useEffect, useImperativeHandle, useState } from 'react';

const MyEditor = forwardRef((props, ref) => {
    const [editor, setEditor] = useState()
    useImperativeHandle(ref, () => ({
        getContent() {
            if(editor && editor.oAppRef && editor.oAppRef.getById) {
                return editor.oAppRef.getById["editorTxt"].getIR()
            }
        },
        setContent(content) {            
            if(editor && editor.oAppRef && editor.oAppRef.getById) {
                return editor.oAppRef.getById["editorTxt"].exec("PASTE_HTML", [content])
            }
        }
    }));

    const initializeEditor = () => {
        const { nhn } = window;
        if (nhn && nhn.husky) {
            let result = nhn.husky.EZCreator.createInIFrame({
                oAppRef: [],
                elPlaceHolder: 'editorTxt',
                sSkinURI: '/smarteditor/SmartEditor2Skin.html',
                fOnAppLoad: function () {
                    //예제 코드
                    setEditor(result)
                    result.oAppRef.getById["editorTxt"].exec("PASTE_HTML", [props?.value])
                },
                fCreator: 'createSEditor2'
            });
        }
    };
    useEffect(() => {
        initializeEditor();

        return () => {
            if (typeof window !== 'undefined') {
                const parentElement = window.document.getElementById("smarteditor");
                const iframeElement = parentElement?.querySelector("iframe");

                if (iframeElement) {
                    iframeElement.remove();
                }

            }
        };
    }, [props.value]);

    // useEffect(() => {


    //     // Clean up the event listener when the component unmounts
    //     return () => {
    //       iframe1.removeEventListener('keyup', handleKeyUp);
    //     };
    //   }, [editor]);

    const getContent = () => {
        var sHTML = editor.oAppRef.getById["editorTxt"].getIR();
        console.log(sHTML)
    };

    const setContent = (content) => {
        var sHTML = editor.oAppRef.getById["editorTxt"].exec("PASTE_HTML", [content])
        console.log(sHTML)
    };
    const handleSave = () => {
        var sHTML = editor.oAppRef.getById["editorTxt"].getIR();
        console.log(sHTML)
    };

    return (
        <div id="smarteditor">
            <textarea name="editorTxt" id="editorTxt" rows="20" cols="10" style={{ width: '100%', height: 500 }}></textarea>
            {/* <button onClick={handleSave}>Save</button> */}
        </div>
    );
})

export default MyEditor;