import { combineReducers } from 'redux';
import authReducer from './auth/authReducer';
import userReducer from './user/userReducer';
import socketReducer from './socket/socketReducer';
import configReducer from './config/configReducer';
import statusReducer from './status/statusReducer';
import metaReducer from './meta/metaReducer';

const rootReducer = combineReducers({
  auth: authReducer,
  user: userReducer,
  socket: socketReducer,
  config: configReducer,
  status: statusReducer,
  meta: metaReducer
});

export default rootReducer;
