import React, { useState, useEffect } from 'react'
import './AdjustmentHistory.css'
import {
  Input,
  Table,
  Button,
  Alert,
  Modal
} from 'reactstrap'
import colors from 'assets/colors'
import dayjs from 'dayjs'
import { Loader, NotAllowed } from 'components'
import { FaSortUp, FaSortDown, FaSyncAlt, FaCog, FaBook } from 'react-icons/fa'
import { DatePicker } from 'reactstrap-date-picker'
import {
  fetchAdjustmentHistorys,
  deleteAdjustmentHistory
} from 'api/adjustmentHistoryApi'
import { showAlert, showSuccess } from 'utils/toast'
import NewWindow from 'react-new-window'
import UserBadge from 'components/UserBadge'
import { useLocation, useParams } from 'react-router-dom'
import PaginationComponent from "components/PaginationComponent"
import { useSelector } from 'react-redux'
import { userSelector } from 'features/auth/authSelectors'
import AdjustmentHistoryView from './AdjustmentHistoryView'


const columns = [
  { key: 'classification', label: '분류', sortable: true },
  { key: 'partner', label: '파트너', align: 'left', sortable: true },
  { key: 'member', label: '회원', align: 'left', sortable: true },
  { key: 'game', label: '종목', sortable: true },
  { key: 'amount', label: '금액', align: 'right', sortable: true },
  { key: 'reason', label: '정산방식/사유', sortable: true },
  { key: 'settlement', label: '정산금', align: 'right', sortable: true },

  { key: 'subPartner', label: '하부', align: 'left', sortable: true },
  { key: 'subPartnerReason', label: '하부정산방식', sortable: true },
  {
    key: 'subPartnerSettlement',
    label: '하부정산금',
    align: 'right',
    sortable: true
  },

  { key: 'profit', label: '수익금', align: 'right', sortable: true },
  { key: 'after', label: '보유금', align: 'right', sortable: true },
  { key: 'createdAt', label: '처리일', sortable: true },
  { key: 'detail', label: '자세히' }
]

function AdjustmentHistoryList(props) {
  const id = props?.targetId ?? null
  const location = useLocation()
  const currentUser = useSelector(userSelector)

  useEffect(() => {
    if (location?.state?.refresh) refresh()
  }, [location.state])

  const [currentViewWindow, setCurrentViewWindow] = useState(null)
  // Partner data
  const [adjustmentHistorys, setAdjustmentHistorys] = useState([])
  const [isLoading, setIsLoading] = useState(false)
  const [error, setError] = useState(null)

  // Pagination
  const pagesToShow = 10
  const [currentPage, setCurrentPage] = useState(1)
  const [pageSize, setPageSize] = useState(10)
  const [totalPages, setTotalPages] = useState(1)
  const [totalCount, setTotalCount] = useState(1)

  // Sorting
  const [sortColumn, setSortColumn] = useState('createdAt')
  const [sortDirection, setSortDirection] = useState('desc')

  // Selection
  const [hoveredRow, setHoveredRow] = useState(null)
  const [isCheckedAll, setIsCheckedAll] = useState(false)
  const [checkedItems, setCheckedItems] = useState([])

  const [selectedItem, setSelectedItem] = useState(null)
  const [detailModal, setDetailModal] = useState(false)

  const today = dayjs().format('YYYY-MM-DD')
  const startDate = dayjs().subtract(30, "day").format("YYYY-MM-DD")
  
  const [params, setParams] = useState({
    id: id ?? '',
    startDate: today,
    endDate: today,
    searchText: ''
  })

  const resetParams = async () => {
    setParams({
      id: id ?? '',
      startDate: today,
      endDate: today,
      searchText: ''
    })
  }

  const refresh = async () => {
    // Refresh the partner list
    fetchData()

    // Clear the checked items
    setCheckedItems([])
    setIsCheckedAll(false)
  }

  const fetchData = async (applyParams = true) => {
    setIsLoading(true)
    try {
      const { success, message, data } = await fetchAdjustmentHistorys(
        currentPage,
        pageSize,
        applyParams ? params : {},
        sortColumn,
        sortDirection
      )

      if (!success) throw new Error(message)
      setAdjustmentHistorys(data.adjustmentHistorys)
      setTotalPages(data.meta.totalPages)
      setTotalCount(data.meta.count)
    } catch (error) {
      console.error(error)
      setError(error)
    } finally {
      setIsLoading(false)
    }
  }

  // console.log(adjustmentHistorys)

  useEffect(() => {
    fetchData()
  }, [currentPage, pageSize, sortColumn, sortDirection])

  const handleDateChange = (end, date) => {
    if (date === null || date === undefined) {
      setParams((prevParams) => ({
        ...prevParams,
        [end]: null, // or use a default value like an empty string ''
      }));
    } else {  
      setParams((prevParams) => ({
        ...prevParams,
        [end]: dayjs(date).format("YYYY-MM-DD"),
      }));
    }
  }

  const handleParamsChange = event => {
    const { name, value, type } = event.target

    let updatedValue = value

    // Check if the input type is a radio button
    if (type === 'radio') {
      if (value === 'true') {
        updatedValue = true // Set to true if value is 'true'
      } else if (value === 'false') {
        updatedValue = false // Set to false if value is 'false'
      }
    }

    if (type === 'number') {
      updatedValue = Number(value)
    }

    const updatedParams = { ...params }
    const nameParts = name.split('.')
    let currentParams = updatedParams
    for (let i = 0; i < nameParts.length - 1; i++) {
      if (!currentParams[nameParts[i]]) {
        currentParams[nameParts[i]] = {}
      }
      currentParams = currentParams[nameParts[i]]
    }
    currentParams[nameParts[nameParts.length - 1]] = updatedValue
    setParams(updatedParams)
  }

  const handleFormSubmit = e => {
    e.preventDefault()
    fetchData(true)
  }

  const handlePageClick = page => setCurrentPage(page)

  const handlePageSizeChange = e => {
    setCurrentPage(1)
    setPageSize(parseInt(e.target.value))
  }

  const handleSort = column => {
    if (sortColumn === column) {
      // If the same column is clicked again, toggle the sort direction
      setSortDirection(prevDirection =>
        prevDirection === 'asc' ? 'desc' : 'asc'
      )
    } else {
      // If a different column is clicked, set it as the new sort column and default to ascending sort direction
      setSortColumn(column)
      setSortDirection('asc')
    }
  }

  const handleCheckAll = () => {
    setIsCheckedAll(!isCheckedAll)
    setCheckedItems(
      isCheckedAll ? [] : adjustmentHistorys.map(item => item._id)
    )
  }

  const handleCheckItem = itemId => {
    if (checkedItems.includes(itemId)) {
      setCheckedItems(prevCheckedItems =>
        prevCheckedItems.filter(id => id !== itemId)
      )
    } else {
      setCheckedItems(prevCheckedItems => [...prevCheckedItems, itemId])
    }
  }

  const renderColumnLabel = column => column.label

  function formatAmountWithWon(item) {
    if (!item) return null

    const { classification } = item

    if (classification === '베팅') {
      return item?.ref?.betting?.betAmount?.toLocaleString() + '원'
    } else if (classification === '입금') {
      return item?.ref?.deposit?.amount?.toLocaleString() + '원'
    } else if (classification === '출금') {
      return item?.ref?.withdrawal?.amount?.toLocaleString() + '원'
    } else if (classification === '관리자증감') {
      return item?.profit?.toLocaleString() + '원'
    } else {
      return null
    }
  }

  return (
    <div style={{ paddingBottom: 100 }}>
      {isLoading && <Loader />}

      <Table>
        <tbody className='align-items-center'>
          <tr>
            <td className='form-header w-100p align-middle text-center' xs={3}>
              검색
            </td>
            <td xs={9}>
              <div className='d-flex align-items-center'>
                <div className='me-3'>
                  <DatePicker
                    value={params.startDate}
                    onChange={(date) => handleDateChange('startDate', date)}
                    className='form-control form-control-sm'
                    placeholder='시작일'
                    showClearButton={false}
                    dateFormat='YYYY-MM-DD'
                    maxDate={new Date(params.endDate).toString()}
                  />
                </div>

                <div className='me-3'>
                  <DatePicker
                    value={params.endDate}
                    onChange={(date) => handleDateChange('endDate', date)}
                    className='form-control form-control-sm'
                    placeholder='종료일'
                    showClearButton={false}
                    dateFormat='YYYY-MM-DD'
                    minDate={new Date(params.startDate).toString()}
                  />
                </div>

                <div className='me-3'>
                  <Input
                    type="text"
                    name="searchText"
                    value={params?.searchText}
                    onChange={handleParamsChange}
                    className="form-control form-control-sm"
                    placeholder="검색어"
                  />
                </div>

                <Button
                  style={{
                    backgroundColor: colors.primary,
                    borderColor: colors.primary
                  }}
                  onClick={handleFormSubmit}
                  color='primary'
                  size='sm'
                >
                  검색
                </Button>
                &nbsp;
                <Button
                  onClick={resetParams}
                  color="warning"
                  size="sm"
                >
                  초기화
                </Button>
              </div>
            </td>
          </tr>
        </tbody>
      </Table>

      <>
        <div className='search-header'>
          <div>
            {`검색된 정산 기록: `}
            <span className='text-danger'>{totalCount.toLocaleString()}</span>개
          </div>
          <div className='select-wrapper'>
            <div className='refresh'>
              <button className='btn btn-link' onClick={refresh}>
                <FaSyncAlt style={{ color: colors.primary }} />
              </button>
            </div>

            <select
              className='form-control'
              onChange={handlePageSizeChange}
              value={pageSize}
            >
              <option value='5'>5개씩 보기</option>
              <option value='10'>10개씩 보기</option>
              <option value='20'>20개씩 보기</option>
              <option value='50'>50개씩 보기</option>
            </select>
          </div>
        </div>
        {error && <Alert color='danger'>{error?.message}</Alert>}

        <Table bordered responsive>
          <thead>
            <tr>
              {columns.map(column => (
                <th
                  key={column.key}
                  className='fixed-width'
                  width={column.width ?? 'auto'}
                  onClick={() =>
                    column.sortable ? handleSort(column.key) : {}
                  }
                >
                  {column.key === 'checkbox' && (
                    <input
                      type='checkbox'
                      checked={isCheckedAll}
                      onChange={handleCheckAll}
                    />
                  )}
                  {renderColumnLabel(column)}
                  {column.sortable && sortColumn === column.key && (
                    <>
                      {sortDirection === 'asc' ? <FaSortUp /> : <FaSortDown />}
                    </>
                  )}
                </th>
              ))}
            </tr>
          </thead>
          <tbody className='text-center'>
            {adjustmentHistorys.length === 0 ? (
              <tr>
                <td className='text-center' colSpan={columns.length}>
                  결과없음
                </td>
              </tr>
            ) : (
              adjustmentHistorys.map((item, index) => (
                <tr
                  key={index}
                  className={`${index === hoveredRow ? 'hovered ' : ''
                    }align-middle`}
                  onMouseEnter={() => setHoveredRow(index)}
                  onMouseLeave={() => setHoveredRow(null)}
                >
                  {columns.map(column => (
                    <td
                      key={column.key}
                      className={`${column.align === 'right'
                          ? 'text-end '
                          : column.align === 'left'
                            ? 'text-start'
                            : ''
                        }`}
                    >
                      {column.key === 'checkbox' && (
                        <input
                          type='checkbox'
                          checked={checkedItems.includes(item?._id)}
                          onChange={() => handleCheckItem(item?._id)}
                        />
                      )}

                      {column.key === 'classification' && (
                        <span>{item?.classification}</span>
                      )}

                      {column.key === 'partner' && (
                        <span role='button' onClick={() => { }}>
                          <UserBadge user={item.partner} />
                        </span>
                      )}

                      {column.key === 'member' &&
                        (item.user ? (
                          <span role='button' onClick={() => { }}>
                            <UserBadge user={item.user} />
                          </span>
                        ) : null)}

                      {column.key === 'game' &&
                        (item?.classification === '베팅'
                          ? item?.ref?.betting?.category
                          : null)}

                      {column.key === 'amount' && formatAmountWithWon(item)}

                      {column.key === 'reason' && <span>{item?.reason}</span>}
                      {column.key === 'settlement' && (
                        <span>{item?.settlement ? item?.settlement.toLocaleString() + '원' : ''}</span>
                      )}

                      {column.key === 'subPartner' && item?.subPartner && (
                        <span role='button' onClick={() => { }}>
                          <UserBadge user={item.subPartner} />
                        </span>
                      )}

                      {column.key === 'subPartnerReason' && (
                        <span>{item?.subPartnerReason}</span>
                      )}

                      {column.key === 'subPartnerSettlement' && (
                        <span>
                          {item?.subPartnerSettlement ? item.subPartnerSettlement.toLocaleString() + '원' : ''}
                        </span>
                      )}

                      {column.key === 'profit' && (
                        <span>{item?.profit ? item?.profit.toLocaleString() + '원' : ''}</span>
                      )}

                      {column.key === 'after' && (
                        <span>{item?.after ? item?.after.toLocaleString() + '원' : ''}</span>
                      )}

                      {column.key === 'createdAt' && (
                        <span>
                          {`${dayjs(item?.createdAt).format(
                            'YYYY-MM-DD HH:mm:ss'
                          )}`}
                        </span>
                      )}


                      {column.key === 'detail' && (
                        <FaBook role='button' 
                        style={{ color: colors.primary }} 
                        onClick={() => {
                          setSelectedItem(item)
                          setDetailModal(!detailModal)
                        }} />
                      )}
                    </td>
                  ))}
                </tr>
              ))
            )}
          </tbody>
        </Table>
        <PaginationComponent
          totalPages={totalPages}
          currentPage={currentPage}
          pagesToShow={pagesToShow}
          handlePageClick={handlePageClick}
        />
      </>      

      <Modal
        size={"lg"}
        isOpen={detailModal}
        toggle={() => setDetailModal(!detailModal)}
        onClosed={refresh}
      >
        <AdjustmentHistoryView targetId={selectedItem?._id} />
      </Modal>
    </div>
  )
}

export default AdjustmentHistoryList
