import api from './index.js'

export const fetchPopups = async (
  currentPage,
  pageSize,
  params,
  sortColumn,
  sortDirection
) => {
  try {
    const queryParams = new URLSearchParams(params).toString() // Convert params object to query string
    const response = await api.get(
      `/admin/popup?pageNumber=${currentPage}&pageSize=${pageSize}&sortColumn=${sortColumn}&sortDirection=${sortDirection}&${queryParams}`
    )

    if (response.status === 200) {
      const data = response.data
      // Handle successful response
      // ...
      return data
    } else {
      throw new Error('Failed to fetch popups')
    }
  } catch (error) {
    // Handle error
    // ...
    throw new Error('Failed to fetch popups')
  }
}
export const fetchPopup = async id => {
  try {
    const response = await api.get(`/admin/popup/${id}`)

    if (response.status === 200) {
      const data = response.data
      // Handle successful response
      // ...
      return data
    } else {
      throw new Error('Failed to fetch popup')
    }
  } catch (error) {
    // Handle error
    // ...
    throw new Error('Failed to fetch popup')
  }
}

export const updatePopup = async (id, popupData) => {
  try {
    const response = await api.put(`/admin/popup/${id}`, popupData)

    if (response.status === 200) {
      const data = response.data
      // Handle successful response
      // ...
      return data
    } else {
      throw new Error('Failed to update popup')
    }
  } catch (error) {
    // Handle error
    // ...
    throw new Error('Failed to update popup')
  }
}

export const createPopup = async popupData => {
  try {
    const response = await api.post('/admin/popup', popupData)

    if (response.status === 200) {
      const data = response.data
      // Handle successful response
      // ...
      return data
    } else {
      throw new Error('Failed to create popup')
    }
  } catch (error) {
    // Handle error
    // ...
    throw new Error('Failed to create popup')
  }
}

export const deletePopup = async id => {
  try {
    const response = await api.delete(`/admin/popup/${id}`)

    if (response.status === 200) {
      const data = response.data
      // Handle successful response
      // ...
      return data
    } else {
      throw new Error('Failed to delete popup')
    }
  } catch (error) {
    // Handle error
    // ...
    throw new Error('Failed to delete popup')
  }
}
