import React, { lazy, useState, useEffect, Suspense } from 'react'
import './Loss.css'
import { Layout } from 'components'
import {
  Nav,
  NavItem,
  NavLink,
  TabContent,
  TabPane,
  Row,
  Container
} from 'reactstrap'
import { useLocation } from 'react-router-dom'
import LossSetting from './LossSetting'
import LossList from './LossList'

function Loss (props) {
  const location = useLocation()
  const [activeTab, setActiveTab] = useState('lossSetting')

  const toggleTab = tab => {
    if (activeTab !== tab) {
      setActiveTab(tab)
    }
  }

  useEffect(() => {
  }, [location])
  return (
    <Layout>
      <Container className='page-content' fluid style={{ padding: 30 }}>
        <Row>
          <Nav tabs>
            <NavItem>
              <NavLink
                className={activeTab === 'lossSetting' ? 'sub-link active' : ''}
                onClick={() => toggleTab('lossSetting')}
              >
                로스 설정
              </NavLink>
            </NavItem>
            <NavItem>
              <NavLink
                className={activeTab === 'parterList' ? 'sub-link active' : ''}
                onClick={() => toggleTab('parterList')}
              >
                로스 목록(슬)
              </NavLink>
            </NavItem>
            <NavItem>
              <NavLink
                className={activeTab === 'partnerAdjustment' ? 'sub-link active' : ''}
                onClick={() => toggleTab('partnerAdjustment')}
              >
                로스 목록(카)
              </NavLink>
            </NavItem>
          </Nav>
        </Row>
        <Row>
          <TabContent className='partner-tab-content' activeTab={activeTab}>
            {activeTab === 'lossSetting' && (
              <TabPane tabId='lossSetting'>
                <Suspense fallback={<div>Loading...</div>}>
                  <LossSetting />
                </Suspense>
              </TabPane>
            )}
            {activeTab === 'parterList' && (
              <TabPane tabId='parterList'>
                <Suspense fallback={<div>Loading...</div>}>
                  <LossList category="슬롯" loss />
                </Suspense>
              </TabPane>
            )}
            {activeTab === 'partnerAdjustment' && (
              <TabPane tabId='partnerAdjustment'>
                <Suspense fallback={<div>Loading...</div>}>
                  <LossList category="카지노" loss />

                </Suspense>
              </TabPane>
            )}
          </TabContent>
        </Row>
      </Container>
    </Layout>
  )
}

export default Loss
