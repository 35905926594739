import React, { useState, useEffect, useRef } from 'react'
import { Container, Label, Table, Input, Button, Navbar } from 'reactstrap'
import { Editor } from 'components'
import { showAlert, showSuccess } from 'utils/toast'
import { Loader } from 'components'
import { fetchNotice, updateNotice } from 'api/noticeApi'
import colors from 'assets/colors'
import { useParams } from 'react-router-dom'
import { MdClose } from "react-icons/md";

function NoticeEdit (props) {
  const editorRef = useRef()
  const id = props?.targetId ?? null
  const [isLoading, setIsLoading] = useState(false)
  const [error, setError] = useState(null)

  const [editorKey, setEditorKey] = useState(Date.now())
  const [notice, setNotice] = useState({
    name: '',
    content: '',
    active: false,
    significant: false
  })

  useEffect(() => {}, [])

  const fetchData = async () => {
    setIsLoading(true)
    try {
      const { success, data, message } = await fetchNotice(id)

      if (!success) throw new Error(message)
      if (data) {
        setNotice(data)
      }
    } catch (error) {
      console.error(error)
      setError(error)
    } finally {
      setIsLoading(false)
    }
  }

  useEffect(() => {
    fetchData()
    setEditorKey(Date.now())
  }, [])

  const refresh = () => {
    fetchData()
    setEditorKey(Date.now())
  }
  //   console.log(notice)
  const handleInputChange = event => {
    const { name, value, type } = event.target

    let updatedValue = value

    // Check if the input type is a radio button
    if (type === 'radio') {
      if (value === 'true') {
        updatedValue = true // Set to true if value is 'true'
      } else if (value === 'false') {
        updatedValue = false // Set to false if value is 'false'
      }
    }

    if (type === 'number') {
      updatedValue = Number(value)
    }

    const updatedNotice = { ...notice }
    const nameParts = name.split('.')
    let currentNotice = updatedNotice
    for (let i = 0; i < nameParts.length - 1; i++) {
      if (!currentNotice[nameParts[i]]) {
        currentNotice[nameParts[i]] = {}
      }
      currentNotice = currentNotice[nameParts[i]]
    }
    currentNotice[nameParts[nameParts.length - 1]] = updatedValue
    setNotice(updatedNotice)
  }

  const handleSave = async () => {
    setIsLoading(true)

    try {
      const content = editorRef?.current?.getContent()

      const { success, message } = await updateNotice(notice._id, {...notice, content: content})

      if (!success) throw new Error(message)

      showSuccess('수정되었습니다!')
      refresh()
    } catch (error) {
      console.error('Failed to update notice:', error)
      showAlert(error.message)
    }

    setIsLoading(false)
  }

  return (
    <>
      <Navbar
        style={{ backgroundColor: colors.primary, padding: '1rem' }}
        expand='md'
      >
        <h5 className='m-0'>
          {'공지사항 수정: '}
          {notice.name}{' '}
        </h5>{' '}
        <div className="text-right">
          <MdClose
            className="clickable-icon"
            size={18}
            onClick={() => props.onClose && props.onClose()}
          />
        </div>
        {/* Use the label based on the active tab */}
      </Navbar>

      <Container className='page-content' style={{ paddingBottom: 100 }}>
        {isLoading ? (
          <Loader />
        ) : (
          <Table bordered style={{ marginTop: '2rem' }}>
            <tbody className='align-items-center'>
              <tr>
                <td className='form-header text-center w-200p' xs={3}>
                  분류
                </td>
                <td xs={9}>
                  <Label
                    className='mb-0 mr-2'
                    style={{ marginRight: '1rem', padding: 0 }}
                  >
                    <Input
                      type='radio'
                      name='significant'
                      value={false}
                      checked={!notice?.significant}
                      onChange={handleInputChange}
                    />{' '}
                    일반
                  </Label>
                  <Label
                    className='mb-0 mr-2'
                    style={{ marginRight: '3rem', padding: 0 }}
                  >
                    <Input
                      type='radio'
                      name='significant'
                      value={true}
                      checked={notice?.significant}
                      onChange={handleInputChange}
                    />{' '}
                    중요
                  </Label>
                </td>
              </tr>
              <tr>
                <td className='form-header text-center w-200p' xs={3}>
                  활성여부
                </td>
                <td xs={9}>
                  <Label
                    className='mb-0 mr-2'
                    style={{ marginRight: '1rem', padding: 0 }}
                  >
                    <Input
                      type='radio'
                      name='active'
                      value={false}
                      checked={!notice?.active}
                      onChange={handleInputChange}
                    />{' '}
                    비활성
                  </Label>
                  <Label
                    className='mb-0 mr-2'
                    style={{ marginRight: '3rem', padding: 0 }}
                  >
                    <Input
                      type='radio'
                      name='active'
                      value={true}
                      checked={notice?.active}
                      onChange={handleInputChange}
                    />{' '}
                    활성
                  </Label>
                </td>
              </tr>
              <tr>
                <td className='form-header text-center w-200p' xs={3}>
                  제목
                </td>
                <td xs={9}>
                  <Input
                    type='text'
                    className='form-control-sm input-sm'
                    placeholder='제목'
                    name='name'
                    value={notice?.name}
                    onChange={handleInputChange}
                  />
                </td>
              </tr>
              <tr>
                <td className='form-header text-center' xs={3}>
                  내용
                </td>
                <td xs={9}>
                  <Editor
                    ref={editorRef}
                    key={editorKey}
                    value={notice?.content ?? ''}
                    onContentChange={value =>
                      handleInputChange({
                        target: { name: 'content', value: value }
                      })
                    }
                  />
                </td>
              </tr>
            </tbody>
          </Table>
        )}

        {/* Action bar */}
        <div className={`action-bar-container show`}>
          <div className='action-bar'>
            <div className='left-actions'></div>
            <div className='right-actions'>
              {/* Right-aligned action buttons */}
              <Button
                className="me-2"
                color="warning"
                onClick={() => {
                  if (typeof props.onClose === "function") props.onClose();
                }}
              >
                닫기
              </Button>
              <Button className='me-2' color='primary' onClick={handleSave}>
                저장
              </Button>
            </div>
          </div>
        </div>
      </Container>
    </>
  )
}

export default NoticeEdit
