import { showAlert } from "utils/toast"
import {
  statusRequest,
  statusSuccess,
  statusFailure,
} from "../features/status/statusActions"
import { fetchStatus } from "api/statusApi"

export const statusThunk = () => async (dispatch, getState) => {
  try {
    const { success, data, message } = await fetchStatus()

    if (success) {
      dispatch(statusRequest())
      dispatch(statusSuccess(data))
    } else {
      dispatch(statusFailure())
      showAlert(message)
      throw new Error("Status failed")
    }
  } catch (error) {
    console.error("Status failed:", error)
    dispatch(statusFailure(error))
  }
}
