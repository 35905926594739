export const metaRequest = () => ({
  type: 'META_REQUEST'
})

export const metaSuccess = config => ({
  type: 'META_SUCCESS',
  payload: config
})

export const metaFailure = error => ({
  type: 'META_FAILURE',
  payload: error
})