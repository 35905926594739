import { toast } from 'react-toastify'

const options = {
  position: 'top-center',
  autoClose: 2000,
  hideProgressBar: false,
  closeOnClick: true,
  pauseOnHover: true,
  draggable: true,
  progress: undefined,
  theme: 'dark'
}

export function showAlert(message, key) {
  const toastOptions = key ? { ...options, toastId: key } : options;
  toast.error(message, toastOptions);
}

export function showSuccess(message, key) {
  const toastOptions = key ? { ...options, toastId: key } : options;
  toast.success(message, toastOptions);
}
