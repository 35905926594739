import React, { useState, useEffect } from 'react'
import {
  Container,
  Table,
  Input,
  Button,
  Navbar
} from 'reactstrap'
import { showAlert, showSuccess } from 'utils/toast'
import { Loader } from 'components'
import { createSiteConfig } from 'api/siteConfigApi'
import colors from 'assets/colors'

function SiteConfigCreate(props) {
  const [isLoading, setIsLoading] = useState(false)
  const [error, setError] = useState(null)

  const [editorKey, setEditorKey] = useState(Date.now())
  const [siteConfig, setSiteConfig] = useState({
    owner: '',
    domainName: '',
    siteName: '',
  })

  useEffect(() => { }, [])

  const refresh = () => {
    setSiteConfig({
      owner: '',
      domainName: '',
      siteName: '',
    })
    setEditorKey(Date.now())
  }

  const handleInputChange = event => {
    const { name, value, type } = event.target

    let updatedValue = value

    // Check if the input type is a radio button
    if (type === 'radio') {
      if (value === 'true') {
        updatedValue = true // Set to true if value is 'true'
      } else if (value === 'false') {
        updatedValue = false // Set to false if value is 'false'
      }
    }

    if (type === 'number') {
      updatedValue = Number(value)
    }

    const updatedBanner = { ...siteConfig }
    const nameParts = name.split('.')
    let currentBanner = updatedBanner
    for (let i = 0; i < nameParts.length - 1; i++) {
      if (!currentBanner[nameParts[i]]) {
        currentBanner[nameParts[i]] = {}
      }
      currentBanner = currentBanner[nameParts[i]]
    }
    currentBanner[nameParts[nameParts.length - 1]] = updatedValue
    setSiteConfig(updatedBanner)
  }

  const handleSave = async () => {

    setIsLoading(true)

    try {
      const { success, message } = await createSiteConfig(siteConfig)

      if (!success) throw new Error(message)

      showSuccess('저장되었습니다!')
      refresh()
    } catch (error) {
      console.error('Failed to create user siteConfig:', error)
      showAlert(error.message)
    }

    setIsLoading(false)
  }

  console.log(siteConfig)
  return (
    <>
      <Navbar
        style={{ backgroundColor: colors.primary, padding: '1rem' }}
        expand='md'
      >
        <h5 className='m-0'>{'사이트 생성'}</h5>{' '}
        {/* Use the label based on the active tab */}
      </Navbar>

      <Container className='page-content' style={{ paddingBottom: 100 }}>
        {isLoading ? <Loader /> :
          <Table bordered style={{ marginTop: '2rem' }}>
            <tbody className='align-items-center'>
              <tr>
                <td className='form-header text-center align-middle w-200p'>
                  사이트명
                </td>
                <td>
                  <Input
                    className="input-sm"
                    type="text"
                    name="siteName"
                    value={siteConfig?.siteName ?? ""}
                    onChange={handleInputChange}
                  />
                </td>
              </tr>
              <tr>
                <td className='form-header text-center align-middle'>
                  도메인명
                </td>
                <td>
                  <Input
                    className="input-sm"
                    type="text"
                    name="domainName"
                    value={siteConfig?.domainName ?? ""}
                    onChange={handleInputChange}
                  />
                </td>
              </tr>

              <tr>
                <td className='form-header text-center align-middle'>
                  소유자
                </td>
                <td>
                  <Input
                    className="input-sm"
                    type="text"
                    name="owner"
                    value={siteConfig?.owner ?? ""}
                    onChange={handleInputChange}
                  />
                </td>
              </tr>
            </tbody>
          </Table>
        }

        {/* Action bar */}
        <div className={`action-bar-container show`}>
          <div className='action-bar'>
            <div className='left-actions'></div>
            <div className='right-actions'>
              {/* Right-aligned action buttons */}
              <Button className='me-2' color='primary' onClick={handleSave}>
                저장
              </Button>
            </div>
          </div>
        </div>
      </Container>
    </>
  )
}

export default SiteConfigCreate
