import React, { useState, useEffect, useRef } from 'react'
import {
  Container,
  Table,
  Input,
  Button,
  Navbar,
  Label
} from 'reactstrap'
import { Editor } from 'components'
import { showAlert, showSuccess } from 'utils/toast'
import { Loader } from 'components'
import { createRule, fetchRule, updateRule } from 'api/ruleApi'
import colors from 'assets/colors'
import { MdClose } from "react-icons/md";

function RuleCreate (props) {
  const [isLoading, setIsLoading] = useState(false)
  const [error, setError] = useState(null)
  const editorRef = useRef()

  const [editorKey, setEditorKey] = useState(Date.now())
  const [rule, setRule] = useState({
    name: '',
    content: '',
    active: false
  })

  useEffect(() => {}, [])

  const refresh = () => {
    setRule({
      name: '',
      content: '',
      active: false
    })
    setEditorKey(Date.now())
  }
//   console.log(rule)
  const handleInputChange = event => {
    const { name, value, type } = event.target

    let updatedValue = value

    // Check if the input type is a radio button
    if (type === 'radio') {
      if (value === 'true') {
        updatedValue = true // Set to true if value is 'true'
      } else if (value === 'false') {
        updatedValue = false // Set to false if value is 'false'
      }
    }

    if (type === 'number') {
      updatedValue = Number(value)
    }

    const updatedRule = { ...rule }
    const nameParts = name.split('.')
    let currentRule = updatedRule
    for (let i = 0; i < nameParts.length - 1; i++) {
      if (!currentRule[nameParts[i]]) {
        currentRule[nameParts[i]] = {}
      }
      currentRule = currentRule[nameParts[i]]
    }
    currentRule[nameParts[nameParts.length - 1]] = updatedValue
    setRule(updatedRule)
  }

  const handleSave = async () => {
    setIsLoading(true)

    try {
      const content = editorRef?.current?.getContent()

      const { success, message } = await createRule({...rule, content: content})

      if (!success) throw new Error(message)

      showSuccess('생성되었습니다!')
      refresh()
    } catch (error) {
      console.error('Failed to update rule:', error)
      showAlert(error.message)
    }

    setIsLoading(false)
  }

  return (
    <>
      <Navbar
        style={{ backgroundColor: colors.primary, padding: '1rem' }}
        expand='md'
      >
        <h5 className='m-0'>{'베팅규정 작성'}</h5>{' '}
        {/* Use the label based on the active tab */}
        <div className="text-right">
          <MdClose
            className="clickable-icon"
            size={18}
            onClick={() => props.onClose && props.onClose()}
          />
        </div>
      </Navbar>

      <Container className='page-content' style={{ paddingBottom: 100 }}>
        {isLoading ? (
          <Loader />
        ) : (
          <Table bordered style={{ marginTop: '2rem' }}>
            <tbody className='align-items-center'>
              <tr>
                <td className='form-header text-center w-200p' xs={3}>
                  제목
                </td>
                <td xs={9}>
                  <Input
                    type='text'
                    className='form-control-sm input-sm'
                    placeholder='제목'
                    name='name'
                    value={rule?.name}
                    onChange={handleInputChange}
                  />
                </td>
              </tr>
              <tr>
                <td className='form-header text-center w-200p' xs={3}>
                  활성여부
                </td>
                <td xs={9}>
                  <Label
                    className='mb-0 mr-2'
                    style={{ marginRight: '1rem', padding: 0 }}
                  >
                    <Input
                      type='radio'
                      name='active'
                      value={false}
                      checked={!rule?.active}
                      onChange={handleInputChange}
                    />{' '}
                    비활성
                  </Label>
                  <Label
                    className='mb-0 mr-2'
                    style={{ marginRight: '3rem', padding: 0 }}
                  >
                    <Input
                      type='radio'
                      name='active'
                      value={true}
                      checked={rule?.active}
                      onChange={handleInputChange}
                    />{' '}
                    활성
                  </Label>
                </td>
              </tr>
              <tr>
                <td className='form-header text-center' xs={3}>
                  내용
                </td>
                <td xs={9}>
                  <Editor
                    ref={editorRef}
                    key={editorKey}
                    value={rule?.content ?? ''}
                    onContentChange={value =>
                      handleInputChange({
                        target: { name: 'content', value: value }
                      })
                    }
                  />
                </td>
              </tr>
            </tbody>
          </Table>
        )}

        {/* Action bar */}
        <div className={`action-bar-container show`}>
          <div className='action-bar'>
            <div className='left-actions'></div>
            <div className='right-actions'>
              {/* Right-aligned action buttons */}
              <Button
                className="me-2"
                color="warning"
                onClick={() => {
                  if (typeof props.onClose === "function") props.onClose();
                }}
              >
                닫기
              </Button>
              <Button className='me-2' color='primary' onClick={handleSave}>
                저장
              </Button>
            </div>
          </div>
        </div>
      </Container>
    </>
  )
}

export default RuleCreate
