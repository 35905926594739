import React, { useState } from 'react';
import {
  Input,
  Button,
  Modal,
  ModalHeader,
  ModalBody,
  ModalFooter,
  CloseButton,
  Table
} from 'reactstrap';
import { showAlert, showSuccess } from 'utils/toast';
import { Loader } from 'components';
import { userSelector } from 'features/auth/authSelectors';
import { useSelector, useDispatch } from 'react-redux';
import { createPartnerPoint } from 'api/partnerPointApi';
import { checkAuthThunk } from 'thunks/authThunks';
import { statusThunk } from 'thunks/statusThunks';

function PartnerPointModal(props) {
  const dispatch = useDispatch();
  const currentUser = useSelector(userSelector);
  const [type, setType] = useState('casino');
  const [amount, setAmount] = useState(null);
  const [isLoading, setIsLoading] = useState(false);

  const handleAll = () => {
    setAmount(currentUser?.wallet?.casino?.point ?? 0);
  };

  const handleSave = async () => {
    if (isNaN(parseFloat(amount))) {
      return showAlert('숫자만 입력 가능합니다');
    }

    if (currentUser?.wallet?.casino?.point <= 0) {
      return showAlert('포인트가 부족합니다');
    }

    if (Number(amount) > currentUser?.wallet?.casino?.point) {
      return showAlert('보유 포인트를 초과할 수 없습니다');
    }

    setIsLoading(true);

    try {
      const { success, message } = await createPartnerPoint({ type, amount });

      if (!success) throw new Error(message);

      dispatch(checkAuthThunk());
      dispatch(statusThunk());

      showSuccess('저장되었습니다!');
      setAmount(null);
      props.setIsPoint(false);
    } catch (error) {
      console.error('Failed to update alarm config:', error);
      showAlert(error.message);
    }

    setIsLoading(false);
  };

  const quickAmountButtons = [10000, 50000, 100000, 1000000];

  return (
    <Modal
      backdrop={true}
      centered={true}
      isOpen={props.open}
      toggle={() => props.setIsPoint(!props.open)}
    >
      <ModalHeader
        className="primary-background"
        close={
          <CloseButton
            onClick={() => props.setIsPoint(false)}
            variant="white"
          />
        }
      >
        포인트 전환
      </ModalHeader>
      <ModalBody>
        {isLoading && <Loader />}
        <Table bordered responsive style={{ marginTop: '2rem' }}>
          <tbody className="align-items-center">
            <tr>
              <td className="form-header text-center" colSpan={2}>
                현재 포인트: {currentUser?.wallet?.casino?.point?.toLocaleString()}P
                &nbsp;&nbsp;
                <Button color="warning" onClick={handleAll}>
                  전체
                </Button>
              </td>
            </tr>
            <tr>
              <td className="form-header text-center align-middle">지갑 유형</td>
              <td>카지노</td>
            </tr>
            <tr>
              <td className="form-header text-center align-middle">전환 포인트</td>
              <td>
                <Input
                  type="number"
                  className="form-control-sm input-sm"
                  placeholder="전환 포인트"
                  value={amount ?? ''}
                  onChange={(e) => {
                    const inputAmount = parseInt(e.target.value, 10);
                    setAmount(isNaN(inputAmount) ? 0 : inputAmount);
                  }}
                />
              </td>
            </tr>
          </tbody>
        </Table>
        <div className="d-flex justify-content-around mt-3">
          {quickAmountButtons.map((value) => (
            <Button
              key={value}
              color="secondary"
              className="btn-sm"
              onClick={() => setAmount((prev) => (prev ?? 0) + value)}
            >
              +{value.toLocaleString()}P
            </Button>
          ))}
          <Button color="danger" className="btn-sm" onClick={() => setAmount(0)}>
            초기화
          </Button>
        </div>
      </ModalBody>
      <ModalFooter>
        <Button color="primary" onClick={handleSave}>
          전환
        </Button>{' '}
        <Button color="secondary" onClick={() => props.setIsPoint(false)}>
          취소
        </Button>
      </ModalFooter>
    </Modal>
  );
}

export default PartnerPointModal;

