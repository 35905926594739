import api from './index.js'

export const fetchHelpCenters = async (
  currentPage,
  pageSize,
  params,
  sortColumn,
  sortDirection
) => {
  try {
    const queryParams = new URLSearchParams(params).toString() // Convert params object to query string
    const response = await api.get(
      `/admin/help-center?pageNumber=${currentPage}&pageSize=${pageSize}&sortColumn=${sortColumn}&sortDirection=${sortDirection}&${queryParams}`
    )

    if (response.status === 200) {
      const data = response.data
      // Handle successful response
      // ...
      return data
    } else {
      throw new Error('Failed to fetch help-centers')
    }
  } catch (error) {
    // Handle error
    // ...
    throw new Error('Failed to fetch help-centers')
  }
}
export const fetchHelpCenter = async id => {
  try {
    const response = await api.get(`/admin/help-center/${id}`)

    if (response.status === 200) {
      const data = response.data
      // Handle successful response
      // ...
      return data
    } else {
      throw new Error('Failed to fetch help-center')
    }
  } catch (error) {
    // Handle error
    // ...
    throw new Error('Failed to fetch help-center')
  }
}

export const updateHelpCenter = async (id, helpData) => {
  try {
    const response = await api.put(`/admin/help-center/${id}`, helpData)

    if (response.status === 200) {
      const data = response.data
      // Handle successful response
      // ...
      return data
    } else {
      throw new Error('Failed to update help-center')
    }
  } catch (error) {
    // Handle error
    // ...
    throw new Error('Failed to update help-center')
  }
}

export const createHelpCenter = async helpData => {
  try {
    const response = await api.post('/admin/help-center', helpData)

    if (response.status === 200) {
      const data = response.data
      // Handle successful response
      // ...
      return data
    } else {
      throw new Error('Failed to create help-center')
    }
  } catch (error) {
    // Handle error
    // ...
    throw new Error('Failed to create help-center')
  }
}

export const deleteHelpCenter = async id => {
  try {
    const response = await api.delete(`/admin/help-center/${id}`)

    if (response.status === 200) {
      const data = response.data
      // Handle successful response
      // ...
      return data
    } else {
      throw new Error('Failed to delete help-center')
    }
  } catch (error) {
    // Handle error
    // ...
    throw new Error('Failed to delete help-center')
  }
}
