import React, { lazy, useState, Suspense, useEffect } from 'react'
import './MemberEdit.css'
import {
  Navbar,
  Nav,
  NavItem,
  NavLink,
  TabContent,
  TabPane,
  Row,
  Container
} from 'reactstrap'
import { UnderConstruction, Loader } from 'components'
import { useParams, useLocation, useNavigate } from 'react-router-dom'
import { MdClose } from "react-icons/md";

import colors from 'assets/colors'
import { fetchUser } from 'api/userApi'

const MemberBasicInfo = lazy(() => import('./MemberBasicInfo'))
const MemberAdjustmentConfig = lazy(() => import('./MemberAdjustmentConfig'))
const MemberDepositList = lazy(() => import('../../Deposit/DepositList'))
const MemberWithdrawalHistory = lazy(() => import('../../Withdrawal/WithdrawalList'))
const MemberTransferHistory = lazy(() => import('../../Transfer/TransferList'))
const MemberBetHistory = lazy(() => import('../../Bet/BetList'))
const MemberUserCouponHistory = lazy(() => import('../../UserCoupon/UserCouponList'))
// const MemberMailCreate = lazy(() => import('./MemberMailCreate'))
const MemberMailCreate = lazy(() => import('./MemberMailCreate'))
const MemberWalletHistoryList = lazy(() => import('../../WalletHistory/WalletHistoryList'))
const MemberUserHistoryList = lazy(() => import('../../UserHistory/UserHistoryList'))
const MemberLoginAccessList = lazy(() => import('../../LoginAccess/LoginAccessList'))
const MemberAttendance = lazy(() => import('./MemberAttendance'))

function MemberDetail (props) {
  const { search, pathname } = useLocation()
  // const { id } = useParams()
  const id = props?.targetId
  const navigate = useNavigate()
  const [activeTab, setActiveTab] = useState('basic')
  const [user, setUser] = useState({ nickname: '' })
  const [isLoading, setIsLoading] = useState(false)
  const [error, setError] = useState(null)
  const toggleTab = tab => {
    if (activeTab !== tab) {
      setActiveTab(tab)
      // navigate(`${pathname}?tab=${tab}`)
    }
  }

  // console.log('here', props)

  useEffect(() => {
    // const tabParam = new URLSearchParams(search).get('tab')
    // const initialTab = 'basic'
    // setActiveTab(initialTab)
    fetchData(id)
  }, [id])

  useEffect(() => {

  }, [])

  const fetchData = async () => {
    setIsLoading(true)
    try {
      const { success, data, message } = await fetchUser(id, [
        'username nickname role'
      ])

      // console.log(success, data)
      if (!success) throw new Error(message)
      setUser(data)
    } catch (error) {
      console.error(error)
      setError(error)
      // Handle error case
    } finally {
      setIsLoading(false)
    }
  }

  // Define labels for each tab
  const tabLabels = {
    basic: `${user.username}(${user.nickname})님의 기본정보`,
    // adjustment: `${user.username}(${user.nickname})님의 콤프설정`,
    depositHistory: `${user.username}(${user.nickname})님의 입금내역`,
    withdrawalHistory: `${user.username}(${user.nickname})님의 출금내역`,
    tranferHistory: `${user.username}(${user.nickname})님의 전환내역`,
    betHistory: `${user.username}(${user.nickname})님의 베팅내역`,
    couponHistory: `${user.username}(${user.nickname})님의 쿠폰내역`,
    walletHistory: `${user.username}(${user.nickname})님의 캐시기록`,
    userHistory: `${user.username}(${user.nickname})님의 변경기록`,
    accessHistory: `${user.username}(${user.nickname})님의 접속기록`,
    attendance: `${user.username}(${user.nickname})님의 출석체크`,
    mail: `${user.username}(${user.nickname})님에게 쪽지보내기`
  }

  return (
    <>
      <Navbar
        style={{ backgroundColor: colors.primary, padding: '1rem' }}
        expand='md'
      >
        <h5 className='m-0'>{tabLabels[activeTab]}</h5>{' '}
        <div className="text-right">
          <MdClose
            className="clickable-icon"
            size={18}
            onClick={() => props.onClose && props.onClose()}
          />
        </div>
        {/* Use the label based on the active tab */}
      </Navbar>
      <Container className='page-content' fluid style={{ padding: 30 }}>
        {isLoading && <Loader />}
        <Row>
          <Nav tabs>
            {[
              { tab: 'basic', label: '기본정보' },
              { tab: 'adjustment', label: '콤프설정' },
              { tab: 'depositHistory', label: '입금내역' },
              { tab: 'withdrawalHistory', label: '출금내역' },
              { tab: 'transferHistory', label: '전환내역' },
              { tab: 'betHistory', label: '베팅내역' },
              { tab: 'couponHistory', label: '쿠폰내역' },
              { tab: 'walletHistory', label: '캐시기록' },
              { tab: 'userHistory', label: '변경기록' },
              { tab: 'accessHistory', label: '접속기록' },
              { tab: 'attendance', label: '출석체크' },
              { tab: 'mail', label: '쪽지보내기' }
            ]
            .filter(
              ({ tab }) =>
                props?.targetTabs?.includes(tab) ||
                props?.targetTabs?.includes("*")
            )
            .map(({ tab, label }) => (
              <NavItem key={tab}>
                <NavLink
                  className={activeTab === tab ? 'sub-link active' : ''}
                  onClick={() => toggleTab(tab)}
                >
                  {label}
                </NavLink>
              </NavItem>
            ))}
          </Nav>
        </Row>
        <Row>
          <TabContent activeTab={activeTab}>
            {[
              { tab: 'basic', component: <MemberBasicInfo {...props} /> },
              { tab: 'adjustment', component: <MemberAdjustmentConfig  {...props}/> },
              { tab: 'depositHistory', component: <MemberDepositList  {...props} sub/> },
              { tab: 'withdrawalHistory', component: <MemberWithdrawalHistory  {...props} sub/> },
              { tab: 'transferHistory', component: <MemberTransferHistory  {...props} sub/> },
              { tab: 'betHistory', component: <MemberBetHistory  {...props} sub /> },
              { tab: 'couponHistory', component: <MemberUserCouponHistory {...props} sub /> },
              { tab: 'walletHistory', component: <MemberWalletHistoryList {...props} /> },
              { tab: 'userHistory', component: <MemberUserHistoryList {...props} /> },
              { tab: 'accessHistory', component: <MemberLoginAccessList {...props} /> },
              { tab: 'attendance', component: <MemberAttendance {...props} /> },
              { tab: 'mail', component: <MemberMailCreate user={user} /> }
            ].map(({ tab, component }) => (
              <TabPane key={tab} tabId={tab}>
                {activeTab === tab && ( // Only render the component when the activeTab matches the current tab
                  <Suspense fallback={<div>Loading...</div>}>
                    {component}
                  </Suspense>
                )}
              </TabPane>
            ))}
          </TabContent>
        </Row>
      </Container>
    </>
  )
}

export default MemberDetail
