import React from 'react'
import { Layout, NotAllowed } from 'components'

const NotAuthorized = () => {
  return (
    <Layout>
        <NotAllowed />
    </Layout>
  )
}

export default NotAuthorized
