import React, { useState, useEffect } from "react"
import { Container, Table, Label, Input, Button, Navbar } from "reactstrap"
import { showAlert, showSuccess } from "utils/toast"
import { Loader } from "components"
import { createPartner } from "api/partnerApi"
import colors from "assets/colors"
import { useParams } from "react-router-dom"
import { fetchUser } from "api/userApi"
import { fetchDefaultSiteConfig } from "api/siteConfigApi"
import { getPublicUrl } from 'libs/functions'

const PUBLIC_URL = getPublicUrl()

function PartnerCreate(props) {
  // const { props.targetId } = useParams()
  const [user, setUser] = useState({ nickname: "" })
  const [depositConfig, setDepositConfig] = useState({ bankList: [] })

  const [isLoading, setIsLoading] = useState(false)
  const [error, setError] = useState(null)
  const [partner, setPartner] = useState({
    parent: props.targetId,
    username: "",
    nickname: "",
    phone: "",
    account: {
      bankName: "",
      accountNumber: "",
      accountHolder: "",
      withdrawalPassword: "",
    },
    password: "",
  })

  useEffect(() => {
    fetchData()
  }, [])

  const fetchData = async () => {
    setIsLoading(true)
    try {
      let success, data, message
      ({ success, data, message } = await fetchUser(props.targetId, [
        "username nickname role site",
      ]))

      if (!success) throw new Error(message)

      if (data) {
        setUser(data)
      }

      ({ success, data, message } = await fetchDefaultSiteConfig())

      if (!success) throw new Error(message)

      if (data) {
        setDepositConfig(data?.deposit)
      }
      setError(null)
    } catch (error) {
      console.error(error)
      setError(error)
      // Handle error case
    } finally {
      setIsLoading(false)
    }
  }

  const refresh = () => {
    setPartner({
      username: "",
      nickname: "",
      phone: "",
      account: {
        bankName: "",
        accountNumber: "",
        accountHolder: "",
        withdrawalPassword: "",
      },
      password: "",
    })
  }

  const handleInputChange = (event) => {
    const { name, value, type } = event.target

    let updatedValue = value

    // Check if the input type is a radio button
    if (type === "radio") {
      if (value === "true") {
        updatedValue = true // Set to true if value is 'true'
      } else if (value === "false") {
        updatedValue = false // Set to false if value is 'false'
      }
    }

    if (type === "number") {
      updatedValue = Number(value)
    }

    const updatedPartner = { ...partner }
    const nameParts = name.split(".")
    let currentPartner = updatedPartner
    for (let i = 0; i < nameParts.length - 1; i++) {
      if (!currentPartner[nameParts[i]]) {
        currentPartner[nameParts[i]] = {}
      }
      currentPartner = currentPartner[nameParts[i]]
    }
    currentPartner[nameParts[nameParts.length - 1]] = updatedValue
    setPartner(updatedPartner)
  }

  const handleSave = async () => {
    if (!partner.username) {
      showAlert("아이디를 입력하세요")
      return
    }

    if (!partner.nickname) {
      showAlert("닉네임을 입력하세요")
      return
    }

    if (!partner.phone) {
      showAlert("연락처를 입력하세요")
      return
    }

    if (!partner.account.bankName) {
      showAlert("은행명을 입력하세요")
      return
    }

    if (!partner.account.accountNumber) {
      showAlert("계좌번호를 입력하세요")
      return
    }

    if (!partner.account.accountHolder) {
      showAlert("예금주를 입력하세요")
      return
    }

    if (!partner.password) {
      showAlert("비밀번호를 입력하세요")
      return
    }

    if (!partner.account.withdrawalPassword) {
      showAlert("출금비밀번호를 입력하세요")
      return
    }

    setIsLoading(true)

    try {
      const { success, message } = await createPartner(partner)

      if (!success) throw new Error(message)

      showSuccess("저장되었습니다!")
      refresh()
    } catch (error) {
      console.error("Failed to create partner:", error)
      showAlert(error.message)
    }

    setIsLoading(false)
  }

  return (
    <>
      <Navbar
        style={{ backgroundColor: colors.primary, padding: "1rem" }}
        expand="md"
      >
        <h5 className="m-0">
          {user && `${user.username}(${user.nickname})`}의 파트너 생성
        </h5>{" "}
      </Navbar>

      <Container className="page-content" style={{ paddingBottom: 100 }}>
        {isLoading ? (
          <Loader />
        ) : (
          <Table bordered style={{ marginTop: "2rem" }}>
            <tbody className="align-items-center">
              <tr>
                <td className="form-header text-center align-middle w-200p">
                  아이디
                </td>
                <td>
                  <Input
                    type="text"
                    className="form-control-sm input-sm"
                    placeholder="아이디"
                    name="username"
                    value={partner?.username}
                    onChange={handleInputChange}
                  />
                </td>

                <td className="form-header text-center align-middle w-200p">
                  닉네임
                </td>
                <td>
                  <Input
                    type="text"
                    className="form-control-sm input-sm"
                    placeholder="닉네임"
                    name="nickname"
                    value={partner?.nickname}
                    onChange={handleInputChange}
                  />
                </td>
              </tr>

              <tr>
                <td className="form-header text-center align-middle w-200p">
                  연락처
                </td>
                <td>
                  <Input
                    type="text"
                    className="form-control-sm input-sm"
                    placeholder="연락처"
                    name="phone"
                    value={partner?.phone}
                    onChange={handleInputChange}
                  />
                </td>

                <td className="form-header text-center align-middle w-200p">
                  은행명
                </td>
                <td>
                  <Input
                    type="select"
                    className="input-sm"
                    name="account.bankName"
                    value={partner?.account?.bankName ?? ""}
                    onChange={handleInputChange}
                  >
                    <option>선택</option>
                    {depositConfig?.bankList
                      .filter((bank) => bank.enabled)
                      .map((bank) => (
                        <option key={bank._id} value={bank?.name}>
                          {bank?.name}
                        </option>
                      ))}
                  </Input>
                </td>
              </tr>

              <tr>
                <td className="form-header text-center align-middle w-200p">
                  계좌번호
                </td>
                <td>
                  <Input
                    type="text"
                    className="form-control-sm input-sm"
                    placeholder="계좌번호"
                    name="account.accountNumber"
                    value={partner?.account?.accountNumber}
                    onChange={handleInputChange}
                  />
                </td>

                <td className="form-header text-center align-middle w-200p">
                  예금주
                </td>
                <td>
                  <Input
                    type="text"
                    className="form-control-sm input-sm"
                    placeholder="예금주"
                    name="account.accountHolder"
                    value={partner?.account?.accountHolder}
                    onChange={handleInputChange}
                  />
                </td>
              </tr>

              <tr>
                <td className="form-header text-center align-middle w-200p">
                  비밀번호
                </td>
                <td>
                  <Input
                    type="text"
                    className="form-control-sm input-sm"
                    placeholder="비밀번호"
                    name="password"
                    value={partner?.password}
                    onChange={handleInputChange}
                  />
                </td>

                <td className="form-header text-center align-middle w-200p">
                  출금비밀번호
                </td>
                <td>
                  <Input
                    type="text"
                    className="form-control-sm input-sm"
                    placeholder="출금비밀번호"
                    name="account.withdrawalPassword"
                    value={partner?.account?.withdrawalPassword}
                    onChange={handleInputChange}
                  />
                </td>
              </tr>
            </tbody>
          </Table>
        )}

        {/* Action bar */}
        <div className={`action-bar-container show`}>
          <div className="action-bar">
            <div className="left-actions"></div>
            <div className="right-actions">
              {/* Right-aligned action buttons */}
              <Button className="me-2" color="primary" onClick={handleSave}>
                저장
              </Button>
            </div>
          </div>
        </div>
      </Container>
    </>
  )
}

export default PartnerCreate
