import React, { useState } from 'react';
import {
  Input,
  Button,
  Modal,
  ModalHeader,
  ModalBody,
  ModalFooter,
  CloseButton,
  Table
} from 'reactstrap';
import { showAlert, showSuccess } from 'utils/toast';
import { Loader } from 'components';
import { userSelector } from 'features/auth/authSelectors';
import { useSelector } from 'react-redux';
import { createDepositAccountRequest } from "api/mailApi"
import { createPartnerDeposit } from 'api/partnerDepositApi';

function PartnerDepositModal(props) {
  const currentUser = useSelector(userSelector);
  const [type, setType] = useState('casino');
  const [amount, setAmount] = useState(0);
  const [name, setName] = useState('');
  const [isLoading, setIsLoading] = useState(false);

  const handleSave = async () => {
    // if (!name) {
    //   return showAlert('입금자명을 입력해주세요');
    // }

    if (isNaN(parseFloat(amount))) {
      return showAlert('숫자만 입력 가능합니다');
    }

    if (amount < 10000) {
      return showAlert('요청 금액은 최소 10,000원 이상이어야 합니다.');
    }

    setIsLoading(true);

    try {
      const { success, message } = await createPartnerDeposit({ type, name, amount });

      if (!success) throw new Error(message);

      showSuccess('저장되었습니다!');
      setName('');
      setAmount(0);
      props.setIsDeposit(false);
    } catch (error) {
      console.error('Failed to update alarm config:', error);
      showAlert(error.message);
    }

    setIsLoading(false);
  };

  const handleDepositAccountRequest = async () => {
    if(!window.confirm('입금계좌를 문의하시겠습니까?')) return;

    setIsLoading(true);

    try {
      const { success, message } = await createDepositAccountRequest();

      if (!success) throw new Error(message);

      showSuccess('요청이 발송되었습니다!');
    } catch (error) {
      console.error('Failed to send mail:', error);
      showAlert(error.message);
    }

    setIsLoading(false);
  };

  const quickAmountButtons = [10000, 50000, 100000, 1000000];

  return (
    <Modal
      backdrop={true}
      centered={true}
      isOpen={props.open}
      toggle={() => props.setIsDeposit(!props.open)}
    >
      <ModalHeader
        className="primary-background"
        close={
          <CloseButton
            onClick={() => props.setIsDeposit(false)}
            variant="white"
          />
        }
      >
        파트너 입금 요청
      </ModalHeader>
      <ModalBody>
        {isLoading && <Loader />}
        <div className="text-center">
          <Button
            color="info"
            className="btn-sm"
            onClick={handleDepositAccountRequest}
          >
            입금계좌문의
          </Button>
        </div>
        <Table bordered responsive style={{ marginTop: '2rem' }}>
          <tbody className="align-items-center">
            <tr>
              <td className="form-header text-center align-middle">지갑 유형</td>
              <td>카지노</td>
            </tr>
            <tr>
              <td className="form-header text-center align-middle">입금자명</td>
              <td>                
                <Input
                  type="text"
                  className="form-control-sm input-sm"
                  placeholder="입금자명"
                  value={name}
                  onChange={(e) => {
                    setName(e.target.value);
                  }}
                />
              </td>
            </tr>
            <tr>
              <td className="form-header text-center align-middle">요청 금액</td>
              <td>
                <Input
                  type="number"
                  className="form-control-sm input-sm"
                  placeholder="요청 금액"
                  value={amount}
                  onChange={(e) => {
                    const inputAmount = parseInt(e.target.value, 10);
                    setAmount(isNaN(inputAmount) ? 0 : inputAmount);
                  }}
                />
                <small className="text-muted">최소 금액: 10,000원</small>
              </td>
            </tr>
          </tbody>
        </Table>
        <div className="d-flex justify-content-around mt-3">
          {quickAmountButtons.map((value) => (
            <Button
              key={value}
              color="secondary"
              className="btn-sm"
              onClick={() => setAmount((prev) => prev + value)}
            >
              +{value.toLocaleString()}원
            </Button>
          ))}
          <Button color="danger" className="btn-sm" onClick={() => setAmount(0)}>
            초기화
          </Button>
        </div>
      </ModalBody>
      <ModalFooter>
        <Button color="primary" onClick={handleSave}>
          요청
        </Button>{' '}
        <Button color="secondary" onClick={() => props.setIsDeposit(false)}>
          취소
        </Button>
      </ModalFooter>
    </Modal>
  );
}

export default PartnerDepositModal;

