import { showAlert } from "utils/toast"
import {
  alarmRequest,
  alarmSuccess,
  alarmFailure,
  siteConfigRequest,
  siteConfigSuccess,
  siteConfigFailure,
} from "../features/config/configActions"
import { fetchGlobalConfig } from "api/globalConfigApi"
import { fetchCurrentSiteConfig } from "api/siteConfigApi"

export const configThunk = () => async (dispatch, getState) => {
  try {
    const { auth } = getState()
    const { success, data, message } = await fetchGlobalConfig()
    if (success) {
      dispatch(alarmRequest())
      // Dispatch regular actions to update the state
      dispatch(alarmSuccess(data?.alarm))

      // Perform additional logic if needed
      // Access the current state using getState()
      //const state = getState()
      //console.log(state)
    } else {
      dispatch(alarmFailure())
      showAlert(message)
      throw new Error("Config failed")
    }
  } catch (error) {
    console.error("Config failed:", error)
    // Dispatch an error action if needed
    dispatch(alarmFailure(error))
  }
}

export const siteConfigThunk = () => async (dispatch, getState) => {
  try {
    const { success, data, message } = await fetchCurrentSiteConfig()
    if (success) {
      dispatch(siteConfigRequest())
      // Dispatch regular actions to update the state
      dispatch(siteConfigSuccess(data))

      // Perform additional logic if needed
      // Access the current state using getState()
      //const state = getState()
      //console.log(state)
    } else {
      dispatch(siteConfigFailure())
      showAlert(message)
      throw new Error("Config failed")
    }
  } catch (error) {
    console.error("Config failed:", error)
    // Dispatch an error action if needed
    dispatch(siteConfigFailure(error))
  }
}
