import api from './index.js'

export const fetchAdmins = async (
  currentPage,
  pageSize,
  params,
  sortColumn,
  sortDirection
) => {
  try {
    const queryParams = new URLSearchParams(params).toString() // Convert params object to query string
    const response = await api.get(
      `/admin/admin?pageNumber=${currentPage}&pageSize=${pageSize}&sortColumn=${sortColumn}&sortDirection=${sortDirection}&${queryParams}`
    )

    if (response.status === 200) {
      const data = response.data
      // Handle successful response
      // ...
      return data
    } else {
      throw new Error('Failed to fetch admins')
    }
  } catch (error) {
    // Handle error
    // ...
    throw new Error('Failed to fetch admins')
  }
}

export const fetchAdmin = async id => {
  try {
    const response = await api.get(`/admin/admin/${id}`)

    if (response.status === 200) {
      const data = response.data
      // Handle successful response
      // ...
      return data
    } else {
      throw new Error('Failed to fetch admin')
    }
  } catch (error) {
    // Handle error
    // ...
    throw new Error('Failed to fetch admin')
  }
}

export const updateAdmin = async (id, adminData) => {
  try {
    const response = await api.put(`/admin/admin/${id}`, adminData)

    if (response.status === 200) {
      const data = response.data
      // Handle successful response
      // ...
      return data
    } else {
      throw new Error('Failed to update admin')
    }
  } catch (error) {
    // Handle error
    // ...
    throw new Error('Failed to update admin')
  }
}

export const updateAdminConfig = async (id, adminData) => {
  try {
    const response = await api.put(`/admin/admin/config/${id}`, adminData)

    if (response.status === 200) {
      const data = response.data
      // Handle successful response
      // ...
      return data
    } else {
      throw new Error('Failed to update admin')
    }
  } catch (error) {
    // Handle error
    // ...
    throw new Error('Failed to update admin')
  }
}

export const createAdmin = async adminData => {
  try {
    const response = await api.post('/admin/admin', adminData)

    if (response.status === 200) {
      const data = response.data
      // Handle successful response
      // ...
      return data
    } else {
      throw new Error('Failed to create admin')
    }
  } catch (error) {
    // Handle error
    // ...
    throw new Error('Failed to create admin')
  }
}

export const deleteAdmin = async id => {
  try {
    const response = await api.delete(`/admin/admin/${id}`)

    if (response.status === 200) {
      const data = response.data
      // Handle successful response
      // ...
      return data
    } else {
      throw new Error('Failed to delete admin')
    }
  } catch (error) {
    // Handle error
    // ...
    throw new Error('Failed to delete admin')
  }
}
