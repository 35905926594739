import api from './index.js'

export const fetchGlobalConfig = async (id, target) => {
    try {
        const response = await api.get(`/admin/global-config`)

        if (response.status === 200) {
            const data = response.data
            return data
        } else {
            throw new Error('Failed to fetch global-config')
        }
    } catch (error) {
        // Handle error
        // ...
        throw new Error('Failed to fetch global-config')
    }
}

export const updateGlobalConfig = async (configData) => {
    try {
        const response = await api.put(`/admin/global-config`, configData);

        if (response.status === 200) {
            const data = response.data;
            return data;
        } else {
            throw new Error('Failed to update global-config');
        }
    } catch (error) {
        // Handle error
        // ...
        throw new Error('Failed to update global-config');
    }
}



