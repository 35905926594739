import api from './index.js'

export const fetchFaqs = async (
  currentPage,
  pageSize,
  params,
  sortColumn,
  sortDirection
) => {
  try {
    const queryParams = new URLSearchParams(params).toString() // Convert params object to query string
    const response = await api.get(
      `/admin/faq?pageNumber=${currentPage}&pageSize=${pageSize}&sortColumn=${sortColumn}&sortDirection=${sortDirection}&${queryParams}`
    )

    if (response.status === 200) {
      const data = response.data
      // Handle successful response
      // ...
      return data
    } else {
      throw new Error('Failed to fetch faqs')
    }
  } catch (error) {
    // Handle error
    // ...
    throw new Error('Failed to fetch faqs')
  }
}
export const fetchFaq = async id => {
  try {
    const response = await api.get(`/admin/faq/${id}`)

    if (response.status === 200) {
      const data = response.data
      // Handle successful response
      // ...
      return data
    } else {
      throw new Error('Failed to fetch faq')
    }
  } catch (error) {
    // Handle error
    // ...
    throw new Error('Failed to fetch faq')
  }
}

export const updateFaq = async (id, faqData) => {
  try {
    const response = await api.put(`/admin/faq/${id}`, faqData)

    if (response.status === 200) {
      const data = response.data
      // Handle successful response
      // ...
      return data
    } else {
      throw new Error('Failed to update faq')
    }
  } catch (error) {
    // Handle error
    // ...
    throw new Error('Failed to update faq')
  }
}

export const createFaq = async faqData => {
  try {
    const response = await api.post('/admin/faq', faqData)

    if (response.status === 200) {
      const data = response.data
      // Handle successful response
      // ...
      return data
    } else {
      throw new Error('Failed to create faq')
    }
  } catch (error) {
    // Handle error
    // ...
    throw new Error('Failed to create faq')
  }
}

export const deleteFaq = async id => {
  try {
    const response = await api.delete(`/admin/faq/${id}`)

    if (response.status === 200) {
      const data = response.data
      // Handle successful response
      // ...
      return data
    } else {
      throw new Error('Failed to delete faq')
    }
  } catch (error) {
    // Handle error
    // ...
    throw new Error('Failed to delete faq')
  }
}
