import api from './index.js'

export const fetchBets = async (
  currentPage,
  pageSize,
  params,
  sortColumn,
  sortDirection
) => {
  try {
    const queryParams = new URLSearchParams(params).toString() // Convert params object to query string
    const response = await api.get(
      `/admin/bet?pageNumber=${currentPage}&pageSize=${pageSize}&sortColumn=${sortColumn}&sortDirection=${sortDirection}&${queryParams}`
    )

    if (response.status === 200) {
      const data = response.data
      // Handle successful response
      // ...
      return data
    } else {
      throw new Error('Failed to fetch bets')
    }
  } catch (error) {
    // Handle error
    // ...
    throw new Error('Failed to fetch bets')
  }
}
export const fetchBet = async id => {
  try {
    const response = await api.get(`/admin/bet/${id}`)

    if (response.status === 200) {
      const data = response.data
      // Handle successful response
      // ...
      return data
    } else {
      throw new Error('Failed to fetch bet')
    }
  } catch (error) {
    // Handle error
    // ...
    throw new Error('Failed to fetch bet')
  }
}
export const fetchBetHistory = async id => {
  try {
    const response = await api.get(`/admin/bet/history/${id}`)

    if (response.status === 200) {
      const data = response.data
      // Handle successful response
      // ...
      return data
    } else {
      throw new Error('Failed to fetch bet history')
    }
  } catch (error) {
    // Handle error
    // ...
    throw new Error('Failed to fetch bet history')
  }
}

export const updateBet = async (id, betData) => {
  try {
    const response = await api.put(`/admin/bet/${id}`, betData)

    if (response.status === 200) {
      const data = response.data
      // Handle successful response
      // ...
      return data
    } else {
      throw new Error('Failed to update bet')
    }
  } catch (error) {
    // Handle error
    // ...
    throw new Error('Failed to update bet')
  }
}

export const revokeBet = async (id, betData) => {
  try {
    const response = await api.put(`/admin/bet/revoke/${id}`, betData)

    if (response.status === 200) {
      const data = response.data
      // Handle successful response
      // ...
      return data
    } else {
      throw new Error('Failed to update bet')
    }
  } catch (error) {
    // Handle error
    // ...
    throw new Error('Failed to update bet')
  }
}

export const createBet = async betData => {
  try {
    const response = await api.post('/admin/bet', betData)

    if (response.status === 200) {
      const data = response.data
      // Handle successful response
      // ...
      return data
    } else {
      throw new Error('Failed to create bet')
    }
  } catch (error) {
    // Handle error
    // ...
    throw new Error('Failed to create bet')
  }
}

export const deleteBet = async id => {
  try {
    const response = await api.delete(`/admin/bet/${id}`)

    if (response.status === 200) {
      const data = response.data
      // Handle successful response
      // ...
      return data
    } else {
      throw new Error('Failed to delete bet')
    }
  } catch (error) {
    // Handle error
    // ...
    throw new Error('Failed to delete bet')
  }
}
