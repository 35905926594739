import React, { lazy, useState, Suspense } from 'react'
import { Layout, UnderConstruction } from 'components'
import {
  Nav,
  NavItem,
  NavLink,
  TabContent,
  TabPane,
  Row,
  Container
} from 'reactstrap'

const InlineNoticeList = lazy(() => import('./InlineNoticeList'))
// const InlineNoticeConfig = lazy(() => import('./InlineNoticeConfig'))
// const InlineNoticeGradeConfig = lazy(() => import('./InlineNoticeGradeConfig'))
// const InlineNoticeAttendanceConfig = lazy(() => import('./InlineNoticeAttendanceConfig'))

function InlineNotice (props) {
  return (
    <Layout>
      <Container className='page-content' fluid style={{ padding: 30 }}>
        <Row>
          <Suspense fallback={<div>Loading...</div>}>
            <InlineNoticeList />
          </Suspense>
        </Row>
      </Container>
    </Layout>
  )
}

export default InlineNotice
