const initialState = {
  users: [],
  loading: false,
  error: null,  
  meta: {
    currentPage: 1,
    pageSize: 10,
    totalPages: 1,
    sortColumn: 'createdAt',
    sortDirection: 'desc'
  }
};

const userReducer = (state = initialState, action) => {
  switch (action.type) {
    case 'FETCH_USERS_REQUEST':
      return {
        ...state,
        loading: true
      };
    case 'FETCH_USERS_SUCCESS':
      return {
        ...state,
        loading: false,
        users: action.payload.users,
        meta: action.payload.meta,
        params: action.payload.params,
        error: null,
      };
    case 'FETCH_USERS_FAILURE':
      return {
        ...state,
        loading: false,
        error: action.payload
      };
    default:
      return state;
  }
};

export default userReducer;
