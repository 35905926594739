const colors = {
    primary: '#28333e',
    white: '#fff',
    secondary: '#ff9800',
    success: '#4caf50',
    info: '#00bcd4',
    warning: '#ffeb3b',
    danger: '#f44336',
    light: '#f8f9fa',
    dark: '#343a40',
};

export default colors;