export const SOCKET_CONNECT = 'SOCKET_CONNECT'
export const SOCKET_DISCONNECT = 'SOCKET_DISCONNECT'
export const SOCKET_RECEIVE_DATA = 'SOCKET_RECEIVE_DATA'

export const socketConnect = (socket) => ({ type: SOCKET_CONNECT, payload: socket })
export const socketDisconnect = () => ({ type: SOCKET_DISCONNECT })
export const socketReceiveData = data => ({
  type: SOCKET_RECEIVE_DATA,
  payload: data
})
