import React, { useState, useEffect } from "react"
import { Container, Input, Button, Navbar, Table } from "reactstrap"
import { showAlert, showSuccess } from "utils/toast"
import { Loader } from "components"
import { useSelector } from "react-redux"
import { userSelector } from "features/auth/authSelectors"
import colors from "assets/colors"
import { fetchPartner, updatePartnerLoss } from "api/partnerApi"
import { checkPrevilege } from "api/authApi"
import { MdClose } from "react-icons/md";

function LossSettingModal(props) {
  const currentUser = useSelector(userSelector)
  const [isLoading, setIsLoading] = useState(false)
  const [error, setError] = useState(null)
  const [partner, setPartner] = useState(null)
  const [loss, setLoss] = useState(null)

  const fetchData = async () => {
    setIsLoading(true)
    try {
      let success, data, message
      ;({ success, data, message } = await fetchPartner(props?.targetId))
      if (!success) throw new Error(message)
      if (data) {
        setPartner(data)
        setLoss({
          value: data?.config?.loss?.[props.category] ?? 0,
          applyAll: true,
          category: props.category,
        })
      }
      setError(null)
    } catch (error) {
      console.error(error)
      setError(error)
    } finally {
      setIsLoading(false)
    }
  }

  useEffect(() => {
    fetchData()
  }, [])

  const handleButton = (direction) => {
    let value = loss?.value
    if (direction === "up") {
      value += 5
    } else {
      value -= 5
    }
    if (value < 0) value = 0
    if (value > 100) value = 100

    setLoss({
      ...loss,
      value: value,
    })
  }

  const handleSave = async () => {
    setIsLoading(true)
    try {
      let success, message
      // ;({ success, message } = await checkPrevilege("partner/edit"))

      // if (!success) throw new Error(message)
      ;({ success, message } = await updatePartnerLoss(
        partner?.config?._id,
        loss
      ))

      if (!success) throw new Error(message)

      showSuccess("저장되었습니다!")

      if (typeof props.onClose === "function") {
        props.onClose()
      }
    } catch (error) {
      console.error("Failed to update partner loss:", error)
      showAlert(error.message)
    }
    setIsLoading(false)
  }

  const checkAllowed = (target) => {
    const { isRoot, previleges } = currentUser
    if (isRoot) return false
    return !previleges.includes(target)
  }

  return (
    <>
      <Navbar
        style={{ backgroundColor: colors.primary, padding: "1rem" }}
        expand="md"
      >
        <h5 className="m-0">
          {partner?.username}({partner?.nickname}){" "}
          {props.category === "slot" ? "슬롯" : "카지노"} 로스 조정
        </h5>{" "}
                <div className="text-right">
          <MdClose
            className="clickable-icon"
            size={18}
            onClick={() => props.onClose && props.onClose()}
          />
        </div>
      </Navbar>
      <Container style={{ paddingBottom: 100 }}>
        {isLoading ? (
          <Loader />
        ) : (
          <Table bordered style={{ marginTop: "2rem" }}>
            <tbody className="align-items-center">
              <tr>
                <td className="form-header align-middle w-200p" xs={3}>
                  로스율
                </td>
                <td className="align-middle">
                  <div className="d-flex align-items-center justify-content-between">
                    <Button color="danger" onClick={() => handleButton("down")}>
                      -5%
                    </Button>
                    <span>{loss?.value?.toLocaleString()}%</span>
                    <Button color="primary" onClick={() => handleButton("up")}>
                      +5%
                    </Button>
                  </div>
                </td>
              </tr>

              <tr>
                <td className="form-header align-middle w-200p" xs={3}>
                  하부 파트너
                </td>
                <td>
                  <div className="d-flex align-items-center">
                    <div className="d-flex align-items-center me-3">
                      {" "}
                      <Input
                        className="input-sm"
                        type="radio"
                        name="direction"
                        value="increment"
                        checked={loss?.applyAll}
                        onChange={(e) => {
                          setLoss({ ...loss, applyAll: true })
                        }}
                        disabled={checkAllowed("partner/edit/giveProfit")}
                      />{" "}
                      <span>일괄 적용</span>
                    </div>

                    <div className="d-flex align-items-center me-3">
                      {" "}
                      <Input
                        className="input-sm"
                        type="radio"
                        name="direction"
                        value="decrement"
                        checked={!loss?.applyAll}
                        onChange={(e) => {
                            setLoss({ ...loss, applyAll: false })
                        }}
                        disabled={checkAllowed("partner/edit/takeProfit")}
                      />{" "}
                      <span>해당 파트너만 적용</span>
                    </div>
                  </div>
                </td>
              </tr>
            </tbody>
          </Table>
        )}

        <div className={`action-bar-container show`}>
          <div className="action-bar">
            <div className="left-actions"></div>
            <div className="right-actions">
              <Button className="me-2" color="warning" onClick={() => {
                if(typeof props.onClose === 'function') props.onClose()
              }}>
                닫기
              </Button>
              <Button className="me-2" color="primary" onClick={handleSave}>
                저장
              </Button>
            </div>
          </div>
        </div>
      </Container>
      ` `
    </>
  )
}

export default LossSettingModal
