export const loginRequest = () => ({
  type: 'LOGIN_REQUEST'
})

export const loginSuccess = users => ({
  type: 'LOGIN_SUCCESS',
  payload: users
})

export const loginFailure = error => ({
  type: 'LOGIN_FAILURE',
  payload: error
})

export const logoutRequest = () => ({
  type: 'LOGOUT_REQUEST'
})

export const logoutSuccess = users => ({
  type: 'LOGOUT_SUCCESS',
  payload: users
})

export const logoutFailure = error => ({
  type: 'LOGOUT_FAILURE',
  payload: error
})

export const checkAuthRequest = () => ({
  type: 'CHECK_AUTH_REQUEST'
})

export const checkAuthSuccess = users => ({
  type: 'CHECK_AUTH_SUCCESS',
  payload: users
})

export const checkAuthFailure = error => ({
  type: 'CHECK_AUTH_FAILURE',
  payload: error
})
