import React, { useEffect } from "react"
import { Button } from "reactstrap"
import { useNavigate } from "react-router-dom"
import dayjs from "dayjs"

function EventAlarm(props) {
  const navigate = useNavigate()

  useEffect(() => {}, [])

  const handleOnClick = async () => {
    const link = props?.link || '/'
    navigate(link, { state: { refresh: true }})
  }

  return (
    <div onClick={handleOnClick}>
        <div><small>{dayjs().format('YY-MM-DD HH:mm')}</small></div>
        <span>{
            props.message || '알람이 도착했습니다'
        }</span>
    </div>
  )
}

export default EventAlarm
