import api from './index.js'

export const fetchWalletHistorys = async (
  currentPage,
  pageSize,
  params,
  sortColumn,
  sortDirection
) => {
  try {
    const queryParams = new URLSearchParams(params).toString() // Convert params object to query string
    const response = await api.get(
      `/admin/wallet-history?pageNumber=${currentPage}&pageSize=${pageSize}&sortColumn=${sortColumn}&sortDirection=${sortDirection}&${queryParams}`
    )

    if (response.status === 200) {
      const data = response.data
      // Handle successful response
      // ...
      return data
    } else {
      throw new Error('Failed to fetch wallet-historys')
    }
  } catch (error) {
    // Handle error
    // ...
    throw new Error('Failed to fetch wallet-historys')
  }
}
export const fetchWalletHistory = async id => {
  try {
    const response = await api.get(`/admin/wallet-history/${id}`)

    if (response.status === 200) {
      const data = response.data
      // Handle successful response
      // ...
      return data
    } else {
      throw new Error('Failed to fetch wallet-history')
    }
  } catch (error) {
    // Handle error
    // ...
    throw new Error('Failed to fetch wallet-history')
  }
}

export const updateWalletHistory = async (id, walletHistoryData) => {
  try {
    const response = await api.put(`/admin/wallet-history/${id}`, walletHistoryData)

    if (response.status === 200) {
      const data = response.data
      // Handle successful response
      // ...
      return data
    } else {
      throw new Error('Failed to update wallet-history')
    }
  } catch (error) {
    // Handle error
    // ...
    throw new Error('Failed to update wallet-history')
  }
}

export const createWalletHistory = async walletHistoryData => {
  try {
    const response = await api.post('/admin/wallet-history', walletHistoryData)

    if (response.status === 200) {
      const data = response.data
      // Handle successful response
      // ...
      return data
    } else {
      throw new Error('Failed to create wallet-history')
    }
  } catch (error) {
    // Handle error
    // ...
    throw new Error('Failed to create wallet-history')
  }
}

export const deleteWalletHistory = async id => {
  try {
    const response = await api.delete(`/admin/wallet-history/${id}`)

    if (response.status === 200) {
      const data = response.data
      // Handle successful response
      // ...
      return data
    } else {
      throw new Error('Failed to delete wallet-history')
    }
  } catch (error) {
    // Handle error
    // ...
    throw new Error('Failed to delete wallet-history')
  }
}
