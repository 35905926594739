import { legacy_createStore as createStore, applyMiddleware } from 'redux';
import { composeWithDevTools } from 'redux-devtools-extension';
import { persistStore, persistReducer } from 'redux-persist';
import storage from 'redux-persist/lib/storage';
import thunk from 'redux-thunk';
import rootReducer from './features';
import thunks from './thunks';

const persistConfig = {
  key: 'ka',
  storage,
  // Whitelist the reducers you want to persist (e.g., 'auth')
  whitelist: ['auth'],
  version: 3
};

const persistedReducer = persistReducer(persistConfig, rootReducer);

const store = createStore(
  persistedReducer,
  //applyMiddleware(thunk.withExtraArgument(thunks)),
  composeWithDevTools(applyMiddleware(thunk))
);

const persistor = persistStore(store);
// const storedVersion = parseInt(localStorage.getItem('ka_version'), 10)

// Check if the version has changed
// console.log(localStorage.getItem('ka_version'))
// if (storedVersion !== persistConfig.version) {
//   persistor.purge();
// }
export {store, persistor};
