import React, { useState, useEffect } from 'react'
import {
  Container,
  Input,
  Button,
  Modal,
  ModalHeader,
  ModalBody,
  ModalFooter,
  CloseButton,
  Table
} from 'reactstrap'
import { showAlert, showSuccess } from 'utils/toast'
import { Loader } from 'components'
import colors from 'assets/colors'
import { useDispatch, useSelector } from 'react-redux'
import { userSelector } from 'features/auth/authSelectors'
import { updateProfile } from 'api/profileApi'
import { checkAuthThunk } from 'thunks/authThunks'
import { statusThunk } from 'thunks/statusThunks'

function MemberProfileModal(props) {
  const dispatch = useDispatch()

  const currentUser = useSelector(userSelector)

  const [user, setUser] = useState({
    username: currentUser?.username || '',
    nickname: currentUser?.nickname || '',
    password: currentUser?.password || '',
    repassword: currentUser?.password || ''
  })

  useEffect(() => {
    setUser({
      username: currentUser?.username || '',
      nickname: currentUser?.nickname || '',
      password: currentUser?.password || '',
      repassword: currentUser?.password || ''
    })
  }, [currentUser])

  const [isLoading, setIsLoading] = useState(false)

  const handleSave = async () => {
    const { username, nickname, password, repassword } = user

    if (!nickname) {
      return showAlert('닉네임을 입력하세요');
    }

    if (!password) {
        return showAlert('비밀번호를 입력하세요');
    }

    if (password !== repassword) {
        return showAlert('비밀번호가 일치하지 않습니다');
    }

    setIsLoading(true)

    try {
      const { success, message } = await updateProfile(user)

      if (!success) throw new Error(message)

      showSuccess('저장되었습니다!')
      dispatch(checkAuthThunk())
      dispatch(statusThunk())
      props.setIsProfile(false)
    } catch (error) {
      console.error('Failed to update alarm config:', error)
      showAlert(error.message)
    }

    setIsLoading(false)
  }

  return (
    <Modal
      backdrop={true}
      centered={true}
      isOpen={props.open}
      toggle={() => props.setIsProfile(!props.open)}
    >
      <ModalHeader
        className="primary-background"
        close={
          <CloseButton
            onClick={() => props.setIsProfile(false)}
            variant="white"
          />
        }
      >
        프로필 수정
      </ModalHeader>
      <ModalBody>
        {
          isLoading && <Loader />
        }
        <Table bordered responsive style={{ marginTop: '2rem' }}>
          <tbody className='align-items-center'>
            <tr>
              <td className='form-header align-middle' >
                아이디
              </td>
              <td>
                <span>
                    {user?.username}
                </span>
              </td>
            </tr>
            <tr>
              <td className='form-header align-middle' >
                닉네임
              </td>
              <td>
                <Input
                  type='text'
                  className='form-control-sm input-sm'
                  placeholder='닉네임'
                  value={user?.nickname}
                  onChange={e => {
                    setUser({
                        ...user,
                        nickname: e.target.value
                    })
                  }}
                />
              </td>
            </tr>
            <tr>
              <td className='form-header align-middle' >
                비밀번호
              </td>
              <td>
                <Input
                  type='password'
                  className='form-control-sm input-sm'
                  placeholder='비밀번호'
                  value={user?.password}
                  onChange={e => {
                    setUser({
                        ...user,
                        password: e.target.value
                    })
                  }}
                />
              </td>
            </tr>
            <tr>
              <td className='form-header align-middle' >
                비밀번호 확인
              </td>
              <td>
                <Input
                  type='password'
                  className='form-control-sm input-sm'
                  placeholder='비밀번호 확인'
                  value={user?.repassword}
                  onChange={e => {
                    setUser({
                        ...user,
                        repassword: e.target.value
                    })
                  }}
                />
              </td>
            </tr>
          </tbody>
        </Table>
      </ModalBody>
      <ModalFooter>
        <Button color="primary" onClick={handleSave}>
          수정
        </Button>{" "}
        <Button color="secondary" onClick={() => props.setIsProfile(false)}>
          취소
        </Button>
      </ModalFooter>
    </Modal>
  )
}

export default MemberProfileModal
