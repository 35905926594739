import React, { useState, useEffect } from "react";
import {
  Container,
  Row,
  Col,
  Form,
  Table,
  Label,
  Input,
  Button,
  Navbar,
} from "reactstrap";
import dayjs from "dayjs";
import { Loader } from "components";
import { fetchFaq, updateFaq } from "api/faqApi";
import colors from "assets/colors";
import { useParams } from "react-router-dom";
import { MdClose } from "react-icons/md";

function FaqView(props) {
  const { id } = useParams();
  const [isLoading, setIsLoading] = useState(false);
  const [error, setError] = useState(null);

  const [editorKey, setEditorKey] = useState(Date.now());
  const [faq, setFaq] = useState({
    name: "",
    content: "",
  });

  useEffect(() => {}, []);

  const fetchData = async () => {
    setIsLoading(true);
    try {
      const { success, data, message } = await fetchFaq(id);

      if (!success) throw new Error(message);
      if (data) {
        setFaq(data);
      }
    } catch (error) {
      console.error(error);
      setError(error);
    } finally {
      setIsLoading(false);
    }
  };

  useEffect(() => {
    fetchData();
  }, []);

  return (
    <>
      <Navbar
        style={{ backgroundColor: colors.primary, padding: "1rem" }}
        expand="md"
      >
        <h5 className="m-0">
          {"FAQ:"} {faq?.name}
        </h5>{" "}
        {/* Use the label based on the active tab */}
        <div className="text-right">
          <MdClose
            className="clickable-icon"
            size={18}
            onClick={() => props.onClose && props.onClose()}
          />
        </div>
      </Navbar>

      <Container className="page-content" style={{ paddingBottom: 100 }}>
        {isLoading ? (
          <Loader />
        ) : (
          <Table bordered style={{ marginTop: "2rem" }}>
            <tbody className="align-items-center">
              <tr>
                <td className="form-header text-center w-200p" xs={3}>
                  작성일
                </td>
                <td xs={9}>
                  {dayjs(faq?.access?.createdAt).format("YYYY-MM-DD HH:mm:ss")}
                </td>
              </tr>
              <tr>
                <td className="form-header text-center" xs={3}>
                  작성자
                </td>
                <td xs={9}>
                  {faq?.author?.username}
                  &nbsp;({faq?.author?.nickname})
                </td>
              </tr>
              <tr>
                <td className="form-header text-center" xs={3}>
                  제목
                </td>
                <td xs={9}>{faq?.name}</td>
              </tr>
              <tr>
                <td className="form-header text-center" xs={3}>
                  내용
                </td>
                <td xs={9}>
                  <div dangerouslySetInnerHTML={{ __html: faq?.content }} />
                </td>
              </tr>
            </tbody>
          </Table>
        )}

        {/* Action bar */}
        <div className={`action-bar-container show`}>
          <div className="action-bar">
            <div className="left-actions"></div>

            <div className="right-actions">
              {/* Right-aligned action buttons */}
              <Button
                className="me-2"
                color="warning"
                onClick={() => {
                  if (typeof props.onClose === "function") props.onClose();
                }}
              >
                닫기
              </Button>
              <Button color="primary" onClick={() => window.close()}>
                확인
              </Button>
            </div>
          </div>
        </div>
      </Container>
    </>
  );
}

export default FaqView;
