import api from './index.js'

export const fetchSiteConfigs = async (
  currentPage,
  pageSize,
  params,
  sortColumn,
  sortDirection
) => {
  try {
    const queryParams = new URLSearchParams(params).toString() // Convert params object to query string
    const response = await api.get(
      `/admin/site-config?pageNumber=${currentPage}&pageSize=${pageSize}&sortColumn=${sortColumn}&sortDirection=${sortDirection}&${queryParams}`
    )

    if (response.status === 200) {
      const data = response.data
      // Handle successful response
      // ...
      return data
    } else {
      throw new Error('Failed to fetch site-configs')
    }
  } catch (error) {
    // Handle error
    // ...
    throw new Error('Failed to fetch site-configs')
  }
}
export const fetchSiteConfig = async (id) => {
  try {
    let url = `/admin/site-config/${id}`
    
    const response = await api.get(url)

    if (response.status === 200) {
      const data = response.data
      return data
    } else {
      throw new Error('Failed to fetch site-config')
    }
  } catch (error) {
    // Handle error
    // ...
    throw new Error('Failed to fetch site-config')
  }
}

export const fetchDefaultSiteConfig = async (id, target) => {
  try {
      const response = await api.get(`/admin/site-config/default`)

      if (response.status === 200) {
          const data = response.data
          return data
      } else {
          throw new Error('Failed to fetch default site-config')
      }
  } catch (error) {
      // Handle error
      // ...
      throw new Error('Failed to fetch default site-config')
  }
}

export const fetchCurrentSiteConfig = async (id, target) => {
  try {
      const response = await api.get(`/admin/site-config/current`)

      if (response.status === 200) {
          const data = response.data
          return data
      } else {
          throw new Error('Failed to fetch current site-config')
      }
  } catch (error) {
      // Handle error
      // ...
      throw new Error('Failed to fetch current site-config')
  }
}

export const fetchSiteGradeConfig = async (id) => {
  try {
    let url = `/admin/site-config/grade/${id}`
    
    const response = await api.get(url)

    if (response.status === 200) {
      const data = response.data
      return data
    } else {
      throw new Error('Failed to fetch site-grade config')
    }
  } catch (error) {
    // Handle error
    // ...
    throw new Error('Failed to fetch site-grade config')
  }
}

export const updateSiteConfig = async (id, target, siteConfigData) => {
  try {
    const response = await api.put(`/admin/site-config/${id}?target=${target}`, siteConfigData)

    if (response.status === 200) {
      const data = response.data
      // Handle successful response
      // ...
      return data
    } else {
      throw new Error('Failed to update site-config')
    }
  } catch (error) {
    // Handle error
    // ...
    throw new Error('Failed to update site-config')
  }
}

export const checkSiteConfigs = async (id) => {
  try {
    let url = `/admin/site-config/check/${id}`
    
    const response = await api.get(url)

    if (response.status === 200) {
      const data = response.data
      return data
    } else {
      throw new Error('Failed to check site-config')
    }
  } catch (error) {
    // Handle error
    // ...
    throw new Error('Failed to check site-config')
  }
}


export const updateSiteConfigOwner = async (id, siteConfigData) => {
  try {
    const response = await api.put(`/admin/site-config/owner/${id}`, siteConfigData)

    if (response.status === 200) {
      const data = response.data
      // Handle successful response
      // ...
      return data
    } else {
      throw new Error('Failed to update site-config owner')
    }
  } catch (error) {
    // Handle error
    // ...
    throw new Error('Failed to update site-config owner')
  }
}

export const updateSiteGradeConfig = async (id, siteConfigData) => {
  try {
    const response = await api.put(`/admin/site-config/grade/${id}`, siteConfigData)

    if (response.status === 200) {
      const data = response.data
      // Handle successful response
      // ...
      return data
    } else {
      throw new Error('Failed to update site-grade config')
    }
  } catch (error) {
    // Handle error
    // ...
    throw new Error('Failed to update site-grade config')
  }
}

export const createSiteConfig = async siteConfigData => {
  try {
    const response = await api.post('/admin/site-config', siteConfigData)

    if (response.status === 200) {
      const data = response.data
      // Handle successful response
      // ...
      return data
    } else {
      throw new Error('Failed to create site-config')
    }
  } catch (error) {
    // Handle error
    // ...
    throw new Error('Failed to create site-config')
  }
}

export const deleteSiteConfig = async id => {
  try {
    const response = await api.delete(`/admin/site-config/${id}`)

    if (response.status === 200) {
      const data = response.data
      // Handle successful response
      // ...
      return data
    } else {
      throw new Error('Failed to delete site-config')
    }
  } catch (error) {
    // Handle error
    // ...
    throw new Error('Failed to delete site-config')
  }
}
