const initialState = {
  isAuth: false,
  user: {},
  loading: false,
  error: ''
}

const authReducer = (state = initialState, action) => {
  switch (action.type) {
    case 'LOGIN_REQUEST':
      return {
        ...state,
        loading: true
      }
    case 'LOGIN_SUCCESS':
      return {
        ...state,
        loading: false,
        isAuth: true,
        user: action.payload,
        error: ''
      }
    case 'LOGIN_FAILURE':
      return {
        ...state,
        loading: false,
        isAuth: false,
        error: action.payload
      }
    case 'LOGOUT_REQUEST':
      return {
        ...state,
        loading: true
      }
    case 'LOGOUT_SUCCESS':
      return {
        ...state,
        loading: false,
        isAuth: false,
        user: {},
        error: ''
      }
    case 'LOGOUT_FAILURE':
      return {
        ...state,
        loading: false,
        isAuth: false,
        error: action.payload
      }
    case 'CHECK_AUTH_REQUEST':
      return {
        ...state,
        loading: true
      }
    case 'CHECK_AUTH_SUCCESS':
      return {
        ...state,
        loading: false,
        isAuth: true,
        user: action.payload,
        error: ''
      }
    case 'CHECK_AUTH_FAILURE':
      return {
        ...state,
        loading: false,
        isAuth: false,
        error: action.payload
      }
    default:
      return state
  }
}

export default authReducer
