import React, { lazy, useState, Suspense, useEffect } from 'react'
import './History.css'
import { Layout, UnderConstruction } from 'components'
import {
  Nav,
  NavItem,
  NavLink,
  TabContent,
  TabPane,
  Row,
  Container
} from 'reactstrap'
import { useParams, useLocation, useNavigate } from 'react-router-dom'

// const Banner = lazy(() => import('../Banner/BannerList'))
const WalletHistory = lazy(() => import('../WalletHistory/WalletHistoryList'))
const UserHistory = lazy(() => import('../UserHistory/UserHistoryList'))
const LoginAccess = lazy(() => import('../LoginAccess/LoginAccessList'))
const AdjustmentHistory = lazy(() => import('../AdjustmentHistory/AdjustmentHistoryList'))
const EggHistory = lazy(() => import('../EggHistory/EggHistoryList'))

function History (props) {
  const { search, pathname } = useLocation()
  const navigate = useNavigate()
  const [activeTab, setActiveTab] = useState('general')

  const toggleTab = tab => {
    if (activeTab !== tab) {
      setActiveTab(tab)
      // navigate(`${pathname}?tab=${tab}`)
    }
  }

  useEffect(() => {
    const tabParam = new URLSearchParams(search).get('tab')
    const initialTab = tabParam || 'eggHistory'
    setActiveTab(initialTab)
  }, [])

  return (
    <Layout>
      <Container className='page-content' fluid style={{ padding: 30 }}>
        <Row>
          <Nav tabs>
            {[
              { tab: 'eggHistory', label: '알 기록' },
              { tab: 'walletHistory', label: '캐시 기록' },
              { tab: 'userHistory', label: '변경 기록' },
              { tab: 'loginAccess', label: '접속 기록' },
              { tab: 'adjustmentHistory', label: '파트너 정산 기록' }
            ].map(({ tab, label }) => (
              <NavItem key={tab}>
                <NavLink
                  className={activeTab === tab ? 'sub-link active' : ''}
                  onClick={() => toggleTab(tab)}
                >
                  {label}
                </NavLink>
              </NavItem>
            ))}
          </Nav>
        </Row>
        <Row>
          <TabContent activeTab={activeTab}>
            {[
              { tab: 'eggHistory', component: <EggHistory /> },
              { tab: 'walletHistory', component: <WalletHistory /> },
              { tab: 'userHistory', component: <UserHistory /> },
              { tab: 'loginAccess', component: <LoginAccess /> },
              { tab: 'adjustmentHistory', component: <AdjustmentHistory /> },
            ].map(({ tab, component }) => (
              <TabPane key={tab} tabId={tab}>
                {activeTab === tab && ( // Only render the component when the activeTab matches the current tab
                  <Suspense fallback={<div>Loading...</div>}>
                    {component}
                  </Suspense>
                )}
              </TabPane>
            ))}
          </TabContent>
        </Row>
      </Container>
    </Layout>
  )
}

export default History
