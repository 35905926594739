import React, { useState, useEffect, useRef } from 'react'
import {
  Container,
  Row,
  Col,
  Form,
  Table,
  Label,
  Input,
  Button,
  Navbar
} from 'reactstrap'
import { Editor } from 'components'
import { showAlert, showSuccess } from 'utils/toast'
import dayjs from 'dayjs'
import { Loader } from 'components'
import { fetchHelpCenter, updateHelpCenter } from 'api/helpCenterApi'
import colors from 'assets/colors'
import { useParams } from 'react-router-dom'
import HelpCenterTemplateList from 'pages/HelpCenterTemplate/HelpCenterTemplateList'
import { MdClose } from "react-icons/md";

function HelpCenterView(props) {
  const id = props?.targetId ?? null
  const editorRef = useRef()
  const [isLoading, setIsLoading] = useState(false)
  const [error, setError] = useState(null)
  const [help, setHelp] = useState({
    name: '',
    inquiry: '',
    answer: ''
  })

  useEffect(() => { }, [])

  const fetchData = async () => {
    setIsLoading(true)
    try {
      const { success, data, message } = await fetchHelpCenter(id)

      if (!success) throw new Error(message)
      if (data) {
        setHelp(data)
      }
      setError(null)
    } catch (error) {
      console.error(error)
      setError(error)
    } finally {
      setIsLoading(false)
    }
  }

  useEffect(() => {
    fetchData()
  }, [])

  const handleSave = async () => {
    setIsLoading(true)

    try {
      const content = editorRef?.current?.getContent()

      const { success, message } = await updateHelpCenter(help._id, { ...help, answer: content })

      if (!success) throw new Error(message)

      showSuccess('등록되었습니다!')
      fetchData()
    } catch (error) {
      console.error('Failed to update user banner:', error)
      showAlert(error.message)
    }

    setIsLoading(false)
  }

  const handleContentInsert = ({content}) => {
    editorRef?.current?.setContent(content)
  }

  return (
    <>
      <Navbar
        style={{ backgroundColor: colors.primary, padding: '1rem' }}
        expand='md'
      >
        <h5 className='m-0'>
          {'쪽지:'} {help?.name}
        </h5>{' '}
        <div className="text-right">
          <MdClose
            className="clickable-icon"
            size={18}
            onClick={() => props.onClose && props.onClose()}
          />
        </div>
        {/* Use the label based on the active tab */}
      </Navbar>

      <Container className='page-content' style={{ paddingBottom: 100 }}>
        {isLoading ? (
          <Loader />
        ) : (
          <div className='d-flex'>


            <Table bordered style={{ marginTop: '2rem', width: '80%', marginRight: 10 }}>
              <tbody className='align-items-center'>
                <tr>
                  <td className='form-header text-center w-200p' xs={3}>
                    작성일
                  </td>
                  <td>
                    {dayjs(help?.access?.createdAt).format('YYYY-MM-DD HH:mm:ss')}
                  </td>
                </tr>
                <tr>
                  <td className='form-header text-center' xs={3}>
                    작성자
                  </td>
                  <td>
                    {
                      help?.access?.createdBy ?
                        `${help?.access?.createdBy?.username} (${help?.access?.createdBy?.nickname})`
                        : `비회원`
                    }
                  </td>
                </tr>
                <tr>
                  <td className='form-header text-center' xs={3}>
                    제목
                  </td>
                  <td>{help?.name}</td>
                </tr>
                <tr>
                  <td className='form-header text-center' xs={3}>
                    내용
                  </td>
                  <td>
                    <div dangerouslySetInnerHTML={{ __html: help?.inquiry }} />
                  </td>
                </tr>
                <tr>
                  <td className='form-header text-center' xs={3}>
                    답변
                  </td>
                  <td>
                    <Editor ref={editorRef} value={help?.answer ?? ''} />
                  </td>
                </tr>
              </tbody>
            </Table>
            <div style={{marginTop: '2rem', width: '35%'}}>
              <HelpCenterTemplateList onClick={handleContentInsert}/>

            </div>
          </div>

        )}

        {/* Action bar */}
        <div className={`action-bar-container show`}>
          <div className='action-bar'>
            <div className='left-actions'>
            </div>

            <div className='right-actions'>
              <Button
                className="me-2"
                color='warning'
                onClick={() => {
                  if (typeof props.onClose === "function") props.onClose();
                }}
              >
                닫기
              </Button>
              {/* Right-aligned action buttons */}
              <Button
                color='primary'
                onClick={handleSave}
              >
                답글 등록
              </Button>
            </div>
          </div>
        </div>
      </Container>
    </>
  )
}

export default HelpCenterView
