import React, { useState, useEffect, useRef } from "react"
import { Container, Table, Input, Button, Navbar, Label } from "reactstrap"
import { Editor } from "components"
import { showAlert, showSuccess } from "utils/toast"
import { Loader } from "components"
import { createCoupon, fetchCoupon, updateCoupon } from "api/couponApi"
import colors from "assets/colors"
import { MdClose } from "react-icons/md";
import { useParams } from "react-router-dom"

function CouponEdit(props) {
  const id = props?.targetId ?? null
  const [isLoading, setIsLoading] = useState(false)
  const [error, setError] = useState(null)
  const editorRef = useRef()

  const [editorKey, setEditorKey] = useState(Date.now())
  const [coupon, setCoupon] = useState({
    status: "active",
    type: "casino",
    amount: 0,
    name: "",
    rollings: {
      standalone: 0,
      deposit: 0,
    },
    period: 0,
  })

  const fetchData = async () => {
    setIsLoading(true)
    try {
      const { success, data, message } = await fetchCoupon(id)

      if (!success) throw new Error(message)
      if (data) {
        setCoupon(data)
      }
    } catch (error) {
      console.error(error)
      setError(error)
    } finally {
      setIsLoading(false)
    }
  }

  useEffect(() => {
    fetchData()
  }, [])

  const refresh = () => {
    setCoupon({
      status: "active",
      type: "casino",
      amount: 0,
      name: "",
      rollings: {
        standalone: 0,
        deposit: 0,
      },
      period: 0,
    })
  }

  const handleInputChange = (event) => {
    const { name, value, type } = event.target

    let updatedValue = value

    // Check if the input type is a radio button
    if (type === "radio") {
      if (value === "true") {
        updatedValue = true // Set to true if value is 'true'
      } else if (value === "false") {
        updatedValue = false // Set to false if value is 'false'
      }
    }

    if (type === "number") {
      updatedValue = Number(value)
    }

    const updatedCoupon = { ...coupon }
    const nameParts = name.split(".")
    let currentCoupon = updatedCoupon
    for (let i = 0; i < nameParts.length - 1; i++) {
      if (!currentCoupon[nameParts[i]]) {
        currentCoupon[nameParts[i]] = {}
      }
      currentCoupon = currentCoupon[nameParts[i]]
    }
    currentCoupon[nameParts[nameParts.length - 1]] = updatedValue
    setCoupon(updatedCoupon)
  }

  const handleSave = async () => {
    if (!coupon.name) {
      return showAlert("제목을 입력하세요")
    }
    setIsLoading(true)

    try {
      const { success, message } = await updateCoupon(coupon._id, coupon)

      if (!success) throw new Error(message)

      showSuccess("수정되었습니다!")
      refresh()
    } catch (error) {
      console.error("Failed to update coupon:", error)
      showAlert(error.message)
    }

    setIsLoading(false)
  }

  return (
    <>
      <Navbar
        style={{ backgroundColor: colors.primary, padding: "1rem" }}
        expand="md"
      >
        <h5 className="m-0">{"쿠폰 수정"}</h5>{" "}
        {/* Use the label based on the active tab */}
        <div className="text-right">
          <MdClose
            className="clickable-icon"
            size={18}
            onClick={() => props.onClose && props.onClose()}
          />
        </div>
      </Navbar>

      <Container className="page-content" style={{ paddingBottom: 100 }}>
        {isLoading ? (
          <Loader />
        ) : (
          <Table bordered style={{ marginTop: "2rem" }}>
            <tbody className="align-items-center">
              <tr>
                <td className="form-header text-center w-200p">제목</td>
                <td>
                  <Input
                    type="text"
                    className="form-control-sm input-sm"
                    placeholder="제목"
                    name="name"
                    value={coupon?.name}
                    onChange={handleInputChange}
                  />
                </td>
              </tr>
              <tr>
                <td className="form-header text-center w-200p">상태</td>
                <td>
                  <Label
                    className="mb-0 mr-2"
                    style={{ marginRight: "1rem", padding: 0 }}
                  >
                    <Input
                      type="radio"
                      name="status"
                      value={"inactive"}
                      checked={coupon?.status === "inactive"}
                      onChange={handleInputChange}
                    />{" "}
                    비활성
                  </Label>
                  <Label
                    className="mb-0 mr-2"
                    style={{ marginRight: "3rem", padding: 0 }}
                  >
                    <Input
                      type="radio"
                      name="status"
                      value={"active"}
                      checked={coupon?.status === "active"}
                      onChange={handleInputChange}
                    />{" "}
                    활성
                  </Label>
                </td>
              </tr>
              <tr>
                <td className="form-header text-center w-200p">유형</td>
                <td>
                  <Label
                    className="mb-0 mr-2"
                    style={{ marginRight: "1rem", padding: 0 }}
                  >
                    <Input
                      type="radio"
                      name="type"
                      value={"casino"}
                      checked={coupon?.type === "casino"}
                      onChange={handleInputChange}
                    />{" "}
                    카지노
                  </Label>
                  <Label
                    className="mb-0 mr-2"
                    style={{ marginRight: "3rem", padding: 0 }}
                  >
                    <Input
                      type="radio"
                      name="type"
                      value={"sports"}
                      checked={coupon?.type === "sports"}
                      onChange={handleInputChange}
                    />{" "}
                    스포츠
                  </Label>
                </td>
              </tr>
              <tr>
                <td className="form-header text-center">금액(P)</td>
                <td>
                  <Input
                    className="input-sm"
                    type="number"
                    name="amount"
                    value={coupon.amount}
                    onChange={handleInputChange}
                  />
                </td>
              </tr>
              <tr>
                <td className="form-header text-center align-middle">
                  롤링 조건
                </td>
                <td className="align-middle">
                  <div className="d-flex align-items-center mb-2">
                    <span>롤링: &nbsp;</span>
                    <div>
                      <Input
                        className="input-sm"
                        type="number"
                        name="rollings.deposit"
                        value={coupon?.rollings?.deposit}
                        onChange={handleInputChange}
                      />
                    </div>
                    <span>%</span>
                  </div>
                  <div className="d-flex align-items-center">
                    <span>단독 사용시 롤링: &nbsp;</span>
                    <div>
                      <Input
                        className="input-sm"
                        type="number"
                        name="rollings.standalone"
                        value={coupon?.rollings?.standalone}
                        onChange={handleInputChange}
                      />
                    </div>
                    <span>%</span>
                  </div>
                </td>
              </tr>
              <tr>
                <td className="form-header text-center align-middle">
                  발급 후 만료기간
                </td>
                <td className="align-middle">
                  <div className="d-flex align-items-center mb-2">
                    <div>
                      <Input
                        className="input-sm"
                        type="number"
                        name="period"
                        value={coupon?.period}
                        onChange={handleInputChange}
                      />
                    </div>
                    <span>일</span>
                  </div>
                </td>
              </tr>
            </tbody>
          </Table>
        )}

        {/* Action bar */}
        <div className={`action-bar-container show`}>
          <div className="action-bar">
            <div className="left-actions"></div>
            <div className="right-actions">
              {/* Right-aligned action buttons */}
              <Button
                className="me-2"
                color="warning"
                onClick={() => {
                  if (typeof props.onClose === "function") props.onClose();
                }}
              >
                닫기
              </Button>
              <Button className="me-2" color="primary" onClick={handleSave}>
                저장
              </Button>
            </div>
          </div>
        </div>
      </Container>
    </>
  )
}

export default CouponEdit
