import React, { useState, useEffect } from 'react'
import {
    Container,
    Table,
    Label,
    Input,
    Button,
    Navbar
} from 'reactstrap'
import { showAlert, showSuccess } from 'utils/toast'
import { Loader } from 'components'
import { fetchApiConfig, updateApiConfig } from 'api/apiConfigApi'
import colors from 'assets/colors'
import { uploadImage } from 'api/uploadApi'
import { DatePicker } from 'reactstrap-date-picker'
import { useParams } from 'react-router-dom'
import Zoom from 'react-medium-image-zoom'
import 'react-medium-image-zoom/dist/styles.css'
import { getPublicUrl } from 'libs/functions'

const PUBLIC_URL = getPublicUrl()

function ApiConfigEdit(props) {
    const { id } = useParams()
    const [isLoading, setIsLoading] = useState(false)
    const [error, setError] = useState(null)

    const [editorKey, setEditorKey] = useState(Date.now())
    const [api, setApi] = useState({
        background: '',
        orderKey: 0,
        display: false,
    })

    useEffect(() => { }, [])

    const fetchData = async () => {
        setIsLoading(true)
        try {
            const { success, data, message } = await fetchApiConfig(id)

            if (!success) throw new Error(message)
            if (data) {
                setApi(data)
            }
        } catch (error) {
            console.error(error)
            setError(error)
        } finally {
            setIsLoading(false)
        }
    }

    useEffect(() => {
        fetchData()
    }, [])

    const refresh = () => {
        fetchData()
        setEditorKey(Date.now())
    }

    const handleInputChange = event => {
        const { name, value, type } = event.target

        let updatedValue = value

        // Check if the input type is a radio button
        if (type === 'radio') {
            if (value === 'true') {
                updatedValue = true // Set to true if value is 'true'
            } else if (value === 'false') {
                updatedValue = false // Set to false if value is 'false'
            }
        }

        if (type === 'number') {
            updatedValue = Number(value)
        }

        const updatedBanner = { ...api }
        const nameParts = name.split('.')
        let currentBanner = updatedBanner
        for (let i = 0; i < nameParts.length - 1; i++) {
            if (!currentBanner[nameParts[i]]) {
                currentBanner[nameParts[i]] = {}
            }
            currentBanner = currentBanner[nameParts[i]]
        }
        currentBanner[nameParts[nameParts.length - 1]] = updatedValue
        setApi(updatedBanner)
    }

    const handleSave = async () => {
        setIsLoading(true)
        try {
            const { success, message } = await updateApiConfig(api._id, api)

            if (!success) throw new Error(message)

            showSuccess('수정되었습니다!')
            refresh()
        } catch (error) {
            console.error('Failed to update user api:', error)
            showAlert(error.message)
        }

        setIsLoading(false)
    }

    const handleFileSelect = async (event, target) => {
        const file = event.target.files[0]

        const result = await uploadImage(file)

        setApi(prevBanner => ({
            ...prevBanner,
            [target]: `${result.link}`
        }))
    }

    const handleDateChange = (date, target) => {
        console.log(date)
        let formattedDate
        if (date) {
            formattedDate = new Date(date).toISOString().split('T')[0]
        }
        setApi(prevParams => ({
            ...prevParams,
            [target]: formattedDate
        }))
    }
    console.log(api)
    return (
        <>
            <Navbar
                style={{ backgroundColor: colors.primary, padding: '1rem' }}
                expand='md'
            >
                <h5 className='m-0'>
                    {'API 수정:'} {api?.name}
                </h5>{' '}
                {/* Use the label based on the active tab */}
            </Navbar>

            <Container className='page-content' style={{ paddingBottom: 100 }}>
                {isLoading && <Loader />}
                <Table bordered style={{ marginTop: '2rem' }}>
                    <tbody className='align-items-center'>
                        <tr>
                            <td className='form-header text-center align-middle w-200p'>
                                이름
                            </td>
                            <td>
                                <span>{api?.thirdParty?.name}</span>
                            </td>
                        </tr>
                        <tr>
                            <td className='form-header text-center align-middle w-200p'>
                                제공사
                            </td>
                            <td>
                                <span>{api?.thirdParty?.provider}</span>
                            </td>
                        </tr>
                        <tr>
                            <td className='form-header text-center align-middle w-200p'>
                                유형
                            </td>
                            <td>
                                <span>{api?.thirdParty?.type}</span>
                            </td>
                        </tr>

                        <tr>
                            <td className='form-header text-center align-middle'>
                                순서
                            </td>
                            <td>
                                <div className='d-flex align-items-center'>
                                    <div className='me-3'>
                                        <Input
                                            type='number'
                                            className='form-control-sm input-sm'
                                            placeholder='순서'
                                            name='orderKey'
                                            value={api?.orderKey}
                                            onChange={handleInputChange}
                                        />
                                    </div>
                                </div>
                            </td>
                        </tr>


                        <tr>
                            <td className='form-header text-center align-middle'>
                                배경 이미지
                            </td>
                            <td>
                                <div className='d-flex align-items-center'>
                                    <div className='me-3'>
                                        {api?.background ? (
                                            <Zoom>
                                                <img
                                                    src={`${PUBLIC_URL}/${api?.background}`}
                                                    alt='모바일 이미지'
                                                    width={50}
                                                    className='img-fluid'
                                                />
                                            </Zoom>
                                        ) : (
                                            '이미지 없음'
                                        )}
                                        <div style={{ padding: 5 }} />
                                        <input
                                            type='file'
                                            accept='image/*'
                                            onChange={e => handleFileSelect(e, 'background')}
                                        />
                                    </div>
                                </div>
                            </td>
                        </tr>
                        <tr>
                            <td className='form-header text-center align-middle'>
                                전시여부
                            </td>
                            <td>
                                <Label
                                    className='mb-0 mr-2'
                                    style={{ marginRight: '1rem', padding: 0 }}
                                >
                                    <Input
                                        type='radio'
                                        name='display'
                                        value={false}
                                        checked={!api?.display}
                                        onChange={handleInputChange}
                                    />{' '}
                                    미전시
                                </Label>
                                <Label
                                    className='mb-0 mr-2'
                                    style={{ marginRight: '3rem', padding: 0 }}
                                >
                                    <Input
                                        type='radio'
                                        name='display'
                                        value={true}
                                        checked={api?.display}
                                        onChange={handleInputChange}
                                    />{' '}
                                    전시
                                </Label>
                            </td>
                        </tr>

                    </tbody>
                </Table>

                {/* Action bar */}
                <div className={`action-bar-container show`}>
                    <div className='action-bar'>
                        <div className='left-actions'>
                        </div>
                        <div className='right-actions'>
                            {/* Right-aligned action buttons */}
                            <Button className='me-2' color='primary' onClick={handleSave}>
                                저장
                            </Button>
                        </div>
                    </div>
                </div>
            </Container>
        </>
    )
}

export default ApiConfigEdit
