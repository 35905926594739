import api from './index.js'

export const fetchMails = async (
  currentPage,
  pageSize,
  params,
  sortColumn,
  sortDirection
) => {
  try {
    const queryParams = new URLSearchParams(params).toString() // Convert params object to query string
    const response = await api.get(
      `/admin/mail?pageNumber=${currentPage}&pageSize=${pageSize}&sortColumn=${sortColumn}&sortDirection=${sortDirection}&${queryParams}`
    )

    if (response.status === 200) {
      const data = response.data
      // Handle successful response
      // ...
      return data
    } else {
      throw new Error('Failed to fetch mails')
    }
  } catch (error) {
    // Handle error
    // ...
    throw new Error('Failed to fetch mails')
  }
}
export const fetchMail = async id => {
  try {
    const response = await api.get(`/admin/mail/${id}`)

    if (response.status === 200) {
      const data = response.data
      // Handle successful response
      // ...
      return data
    } else {
      throw new Error('Failed to fetch mail')
    }
  } catch (error) {
    // Handle error
    // ...
    throw new Error('Failed to fetch mail')
  }
}

export const updateMail = async (id, mailData) => {
  try {
    const response = await api.put(`/admin/mail/${id}`, mailData)

    if (response.status === 200) {
      const data = response.data
      // Handle successful response
      // ...
      return data
    } else {
      throw new Error('Failed to update mail')
    }
  } catch (error) {
    // Handle error
    // ...
    throw new Error('Failed to update mail')
  }
}

export const readMail = async (id, mailData) => {
  try {
    const response = await api.put(`/admin/mail/${id}/read`, mailData)

    if (response.status === 200) {
      const data = response.data
      // Handle successful response
      // ...
      return data
    } else {
      throw new Error('Failed to update mail')
    }
  } catch (error) {
    // Handle error
    // ...
    throw new Error('Failed to update mail')
  }
}

export const createMail = async mailData => {
  try {
    const response = await api.post('/admin/mail', mailData)

    if (response.status === 200) {
      const data = response.data
      // Handle successful response
      // ...
      return data
    } else {
      throw new Error('Failed to create mail')
    }
  } catch (error) {
    // Handle error
    // ...
    throw new Error('Failed to create mail')
  }
}

export const createDepositAccountRequest = async () => {
  try {
    const response = await api.post('/admin/mail/depositAccount')

    if (response.status === 200) {
      const data = response.data
      // Handle successful response
      // ...
      return data
    } else {
      throw new Error('Failed to create deposit account request')
    }
  } catch (error) {
    // Handle error
    // ...
    throw new Error('Failed to create deposit account request')
  }
}

export const deleteMail = async id => {
  try {
    const response = await api.delete(`/admin/mail/${id}`)

    if (response.status === 200) {
      const data = response.data
      // Handle successful response
      // ...
      return data
    } else {
      throw new Error('Failed to delete mail')
    }
  } catch (error) {
    // Handle error
    // ...
    throw new Error('Failed to delete mail')
  }
}
