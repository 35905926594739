import axios from 'axios'

// const API_URL = process.env.REACT_APP_API_URL || 'http://localhost:4000'

function getApiUrl() {
  const hostName = window?.location?.hostname;

  if (hostName.includes("localhost")) {
    return "http://localhost:4000";
  }

  // Replace 'admin.', 'www.', or nothing with 'api.' to construct the API URL
  const apiUrl = hostName.replace(/^admin\.|^www\./, "api.");
  console.log({apiUrl});
  return `https://${apiUrl}`;
}


const API_URL = getApiUrl();

// Create an instance of Axios with your desired configuration
const api = axios.create({
  baseURL: API_URL,
  withCredentials: true
})

// Add an interceptor to handle unauthorized responses globally
api.interceptors.response.use(
  response => response,
  error => {
    if (error.response && error.response.status === 401) {
      console.log('Unauthorized Access')
    }
    return Promise.reject(error)
  }
)

export default api
