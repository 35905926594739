import api from './index.js'

export const fetchApis = async (
  currentPage,
  pageSize,
  params,
  sortColumn,
  sortDirection
) => {
  try {
    const queryParams = new URLSearchParams(params).toString() // Convert params object to query string
    const response = await api.get(
      `/admin/api?pageNumber=${currentPage}&pageSize=${pageSize}&sortColumn=${sortColumn}&sortDirection=${sortDirection}&${queryParams}`
    )

    if (response.status === 200) {
      const data = response.data
      // Handle successful response
      // ...
      return data
    } else {
      throw new Error('Failed to fetch apis')
    }
  } catch (error) {
    // Handle error
    // ...
    throw new Error('Failed to fetch apis')
  }
}

export const fetchPureApis = async (
  currentPage,
  pageSize,
  params,
  sortColumn,
  sortDirection
) => {
  try {
    const queryParams = new URLSearchParams(params).toString() // Convert params object to query string
    const response = await api.get(
      `/admin/api/pure`
    )

    if (response.status === 200) {
      const data = response.data
      // Handle successful response
      // ...
      return data
    } else {
      throw new Error('Failed to fetch apis')
    }
  } catch (error) {
    // Handle error
    // ...
    throw new Error('Failed to fetch apis')
  }
}

export const fetchApi = async id => {
  try {
    const response = await api.get(`/admin/api/${id}`)

    if (response.status === 200) {
      const data = response.data
      // Handle successful response
      // ...
      return data
    } else {
      throw new Error('Failed to fetch api')
    }
  } catch (error) {
    // Handle error
    // ...
    throw new Error('Failed to fetch api')
  }
}

export const updateApi = async (id, apiData) => {
  try {
    const response = await api.put(`/admin/api/${id}`, apiData)

    if (response.status === 200) {
      const data = response.data
      // Handle successful response
      // ...
      return data
    } else {
      throw new Error('Failed to update api')
    }
  } catch (error) {
    // Handle error
    // ...
    throw new Error('Failed to update api')
  }
}

export const createApi = async apiData => {
  try {
    const response = await api.post('/admin/api', apiData)

    if (response.status === 200) {
      const data = response.data
      // Handle successful response
      // ...
      return data
    } else {
      throw new Error('Failed to create api')
    }
  } catch (error) {
    // Handle error
    // ...
    throw new Error('Failed to create api')
  }
}
