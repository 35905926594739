import React, { useState } from 'react';
import {
  Pagination,
  PaginationItem,
  PaginationLink,
  Input,
  Button,
} from "reactstrap";

const PaginationComponent = ({ totalPages, currentPage, pagesToShow, handlePageClick }) => {
  const [inputPage, setInputPage] = useState("");

  const startPage = Math.floor((currentPage - 1) / pagesToShow) * pagesToShow + 1;
  const nextPageStart = Math.ceil(currentPage / pagesToShow) * pagesToShow + 1;
  const prevPageStart = Math.floor((currentPage - 1) / pagesToShow) * pagesToShow;

  const handleInputChange = (e) => {
    setInputPage(e.target.value);
  };

  const handleInputSubmit = () => {
    const pageNum = parseInt(inputPage, 10);
    if (!isNaN(pageNum) && pageNum >= 1 && pageNum <= totalPages) {
      handlePageClick(pageNum);
      setInputPage("");
    }
  };

  return (
    <div>
      <Pagination className="d-flex justify-content-center align-items-center">
        {/* First Page Button */}
        <PaginationItem disabled={currentPage === 1}>
          <PaginationLink first onClick={() => handlePageClick(1)} />
        </PaginationItem>

        {/* Previous Page Group Button */}
        <PaginationItem disabled={currentPage <= 1}>
          <PaginationLink previous onClick={() => handlePageClick(prevPageStart)} />
        </PaginationItem>

        {/* Page Numbers */}
        {Array.from({ length: Math.min(pagesToShow, totalPages - startPage + 1) }).map((_, i) => {
          const pageNumber = startPage + i;
          return (
            <PaginationItem key={pageNumber} active={pageNumber === currentPage}>
              <PaginationLink onClick={() => handlePageClick(pageNumber)}>
                {pageNumber}
              </PaginationLink>
            </PaginationItem>
          );
        })}

        {/* Next Page Group Button */}
        <PaginationItem disabled={currentPage >= totalPages || startPage + pagesToShow > totalPages}>
          <PaginationLink next onClick={() => handlePageClick(nextPageStart)} />
        </PaginationItem>

        {/* Last Page Button */}
        <PaginationItem disabled={currentPage === totalPages}>
          <PaginationLink last onClick={() => handlePageClick(totalPages)} />
        </PaginationItem>
      </Pagination>

      {/* Input Field for Direct Page Navigation */}
      <div className="d-flex justify-content-center align-items-center mt-2">
        <Input
          type="number"
          value={inputPage}
          onChange={handleInputChange}
          placeholder="페이지 번호"
          className="w-25"
        />
        <Button color="primary" onClick={handleInputSubmit} className="ml-2">
          이동
        </Button>
      </div>
    </div>
  );
};

export default PaginationComponent;
