import React from 'react';
import './Layout.css';
import { Header } from 'components';

function Layout(props) {
  return (
    <>
      <Header />
      {props.children}
    </>
  );
}

export default Layout;
