import React, { useState, useEffect } from 'react'
import { Container, Row, Col, Form, Button, Navbar, Table } from 'reactstrap'
import { Loader } from 'components'
import { fetchNotice } from 'api/noticeApi'
import colors from 'assets/colors'
import { useParams } from 'react-router-dom'

function NoticeView (props) {
  const { id } = useParams()
  const [isLoading, setIsLoading] = useState(false)
  const [error, setError] = useState(null)

  const [notice, setNotice] = useState({
    name: '',
    content: ''
  })

  useEffect(() => {}, [])

  const fetchData = async () => {
    setIsLoading(true)
    try {
      const { success, data, message } = await fetchNotice(id)

      if (!success) throw new Error(message)
      if (data) {
        setNotice(data)
      }
    } catch (error) {
      console.error(error)
      setError(error)
    } finally {
      setIsLoading(false)
    }
  }

  useEffect(() => {
    fetchData()
  }, [])

  return (
    <>
      <Navbar
        style={{ backgroundColor: colors.primary, padding: '1rem' }}
        expand='md'
      >
        <h5 className='m-0'>{'공지사항 상세: '}{notice?.name}</h5>
        
        {/* Use the label based on the active tab */}
      </Navbar>

      <Container className='page-content' style={{ paddingBottom: 100 }}>
        {isLoading ? (
          <Loader />
        ) : (
          <Table bordered style={{ marginTop: '2rem' }}>
            <tbody className='align-items-center'>
              <tr>
                <td className='form-header text-center w-200p' xs={3}>
                  분류
                </td>
                <td xs={9}>
                  <span>{notice?.significant ? '중요' : '일반'}</span>
                </td>
              </tr>
              <tr>
                <td className='form-header text-center' xs={3}>
                  제목
                </td>
                <td xs={9}>
                  <span>{notice?.name}</span>
                </td>
              </tr>
              <tr>
                <td className='form-header text-center' xs={3}>
                  내용
                </td>
                <td xs={9}>
                  <div dangerouslySetInnerHTML={{ __html: notice?.content }} />
                </td>
              </tr>
            </tbody>
          </Table>
        )}

        {/* Action bar */}
        <div className={`action-bar-container show`}>
          <div className='action-bar'>
            <div className='left-actions'></div>
            <div className='right-actions'>
              {/* Right-aligned action buttons */}
              <Button
                className='me-2'
                color='primary'
                onClick={() => window.close()}
              >
                확인
              </Button>
            </div>
          </div>
        </div>
      </Container>
    </>
  )
}

export default NoticeView
