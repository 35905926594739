import api from './index.js'

export const fetchUsers = async (
  currentPage,
  pageSize,
  params,
  sortColumn,
  sortDirection
) => {
  try {
    const queryParams = new URLSearchParams(params).toString() // Convert params object to query string
    const response = await api.get(
      `/admin/user?pageNumber=${currentPage}&pageSize=${pageSize}&sortColumn=${sortColumn}&sortDirection=${sortDirection}&${queryParams}`
    )

    if (response.status === 200) {
      const data = response.data
      // Handle successful response
      // ...
      return data
    } else {
      throw new Error('Failed to fetch users')
    }
  } catch (error) {
    // Handle error
    // ...
    throw new Error('Failed to fetch users')
  }
}

export const fetchUsers2 = async (
  currentPage,
  pageSize,
  params,
  sortColumn,
  sortDirection
) => {
  try {
    const queryParams = new URLSearchParams(params).toString() // Convert params object to query string
    const response = await api.get(
      `/admin/user/v2?pageNumber=${currentPage}&pageSize=${pageSize}&sortColumn=${sortColumn}&sortDirection=${sortDirection}&${queryParams}`
    )

    if (response.status === 200) {
      const data = response.data
      // Handle successful response
      // ...
      return data
    } else {
      throw new Error('Failed to fetch users')
    }
  } catch (error) {
    // Handle error
    // ...
    throw new Error('Failed to fetch users')
  }
}

export const fetchUser = async (id, fields) => {
  try {
    let url = `/admin/user/${id}`

    if (fields) {
      const fieldsParam = fields.join(',')
      url += `?fields=${fieldsParam}`
    }

    const response = await api.get(url)

    if (response.status === 200) {
      const data = response.data
      // Handle successful response
      // ...
      return data
    } else {
      throw new Error('Failed to fetch user')
    }
  } catch (error) {
    // Handle error
    // ...
    throw new Error('Failed to fetch user')
  }
}

export const checkDuplicate = async (field, value) => {
  try {
    const response = await api.post('/admin/user/check-duplicate', {
      [field]: value,
    });

    if (response.status === 200) {
      const data = response.data;
      return field === 'username' ? data.usernameExists : data.nicknameExists;
    } else {
      throw new Error('Failed to check duplicates');
    }
  } catch (error) {
    console.error('Error checking duplicates:', error);
    throw new Error('Failed to check duplicates');
  }
};

export const updateUser = async (id, userData) => {
  try {
    const response = await api.put(`/admin/user/${id}`, userData)

    if (response.status === 200) {
      const data = response.data
      // Handle successful response
      // ...
      return data
    } else {
      throw new Error('Failed to update user')
    }
  } catch (error) {
    // Handle error
    // ...
    throw new Error('Failed to update user')
  }
}

export const updateUserAttendance = async (id, attendanceData) => {
  try {
    const response = await api.put(`/admin/user/attendance/${id}`, attendanceData)

    if (response.status === 200) {
      const data = response.data
      // Handle successful response
      // ...
      return data
    } else {
      throw new Error('Failed to update user')
    }
  } catch (error) {
    // Handle error
    // ...
    throw new Error('Failed to update user')
  }
}

export const killUser = async (id) => {
  try {
    const response = await api.post(`/admin/user/kill/${id}`)

    if (response.status === 200) {
      const data = response.data
      // Handle successful response
      // ...
      return data
    } else {
      throw new Error('Failed to kill user')
    }
  } catch (error) {
    // Handle error
    // ...
    throw new Error('Failed to kill user')
  }
}

export const fetchUserConfig = async id => {
  try {
    const response = await api.get(`/admin/user/config/${id}`)

    if (response.status === 200) {
      const data = response.data
      // Handle successful response
      // ...
      return data
    } else {
      throw new Error('Failed to fetch user')
    }
  } catch (error) {
    // Handle error
    // ...
    throw new Error('Failed to fetch user')
  }
}

export const updateUserWallet = async (id, userData) => {
  try {
    const response = await api.put(`/admin/user/wallet/${id}`, userData)

    if (response.status === 200) {
      const data = response.data
      // Handle successful response
      // ...
      return data
    } else {
      throw new Error('Failed to update user')
    }
  } catch (error) {
    // Handle error
    // ...
    throw new Error('Failed to update user')
  }
}

export const updateUserRollings = async (id, userData) => {
  try {
    const response = await api.put(`/admin/user/rollings/${id}`, userData)

    if (response.status === 200) {
      const data = response.data
      // Handle successful response
      // ...
      return data
    } else {
      throw new Error('Failed to update user')
    }
  } catch (error) {
    // Handle error
    // ...
    throw new Error('Failed to update user')
  }
}

export const createUserCoupon = async (id, userData) => {
  try {
    const response = await api.put(`/admin/user/coupon/${id}`, userData)

    if (response.status === 200) {
      const data = response.data
      return data
    } else {
      throw new Error('Failed to update user coupon')
    }
  } catch (error) {
    // Handle error
    // ...
    throw new Error('Failed to update user coupon')
  }
}

export const updateUserParent = async (id, userData) => {
  try {
    const response = await api.put(`/admin/user/parent/${id}`, userData)

    if (response.status === 200) {
      const data = response.data
      // Handle successful response
      // ...
      return data
    } else {
      throw new Error('Failed to update user')
    }
  } catch (error) {
    // Handle error
    // ...
    throw new Error('Failed to update user')
  }
}

export const updateUserConfig = async (id, userData) => {
  try {
    const response = await api.put(`/admin/user/config/${id}`, userData)

    if (response.status === 200) {
      const data = response.data
      // Handle successful response
      // ...
      return data
    } else {
      throw new Error('Failed to update user config')
    }
  } catch (error) {
    // Handle error
    // ...
    throw new Error('Failed to update user config')
  }
}

export const hardDeleteUser = async id => {
  try {
    const response = await api.delete(`/admin/user/${id}`)

    if (response.status === 200) {
      const data = response.data
      // Handle successful response
      // ...
      return data
    } else {
      throw new Error('Failed to delete user')
    }
  } catch (error) {
    // Handle error
    // ...
    throw new Error('Failed to delete user')
  }
}
