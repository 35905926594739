import React, { useState, useEffect } from 'react'
import { Container, Table, Label, Input, Button, Navbar } from 'reactstrap'
import { showAlert, showSuccess } from 'utils/toast'
import dayjs from 'dayjs'
import { Loader } from 'components'
import { createAdmin } from 'api/adminApi'
import colors from 'assets/colors'
import { previlegeNames } from "libs/translations"
import { previleges } from 'libs/constants'
import { checkPrevilege } from "api/authApi"

function AdminCreate(props) {
  const [isLoading, setIsLoading] = useState(false)
  const [error, setError] = useState(null)

  const [editorKey, setEditorKey] = useState(Date.now())
  const [admin, setAdmin] = useState({
    username: '',
    password: '',
    repassword: '',
    previleges: [],
    memo: ''
  })

  useEffect(() => { }, [])

  const refresh = () => {
    setAdmin({
      username: '',
      password: '',
      repassword: '',
      previleges: [],
      memo: ''
    })
    setEditorKey(Date.now())
  }

  const handleInputChange = event => {
    const { name, value, type, } = event.target

    let updatedValue = value

    // Check if the input type is a radio button
    if (type === 'radio') {
      if (value === 'true') {
        updatedValue = true // Set to true if value is 'true'
      } else if (value === 'false') {
        updatedValue = false // Set to false if value is 'false'
      }
    }

    if (type === 'number') {
      updatedValue = Number(value)
    }


    const updatedAdmin = { ...admin }
    const nameParts = name.split('.')
    let currentAdmin = updatedAdmin
    for (let i = 0; i < nameParts.length - 1; i++) {
      if (!currentAdmin[nameParts[i]]) {
        currentAdmin[nameParts[i]] = {}
      }
      currentAdmin = currentAdmin[nameParts[i]]
    }
    currentAdmin[nameParts[nameParts.length - 1]] = updatedValue
    setAdmin(updatedAdmin)
  }

  const handleCheckboxChange = (key, p) => {
    const updatedPrevileges = [...admin.previleges] // Create a copy of the previleges array

    const hasPrevilege = updatedPrevileges.includes(`${key}/${p}`)

    if (hasPrevilege) {
      updatedPrevileges.splice(updatedPrevileges.indexOf(`${key}/${p}`), 1)
    } else {
      updatedPrevileges.push(`${key}/${p}`)
    }

    setAdmin({
      ...admin,
      previleges: updatedPrevileges
    })
  }

  const handleCheckboxAll = (e, key) => {
    const updatedPrevileges = [...admin.previleges] // Create a copy of the previleges array

    const targetPrevileges = previleges[key]

    for (let i = 0; i < targetPrevileges.length; ++i) {

      if (!e.target.checked) {
        updatedPrevileges.splice(updatedPrevileges.indexOf(`${key}/${targetPrevileges[i]}`), 1)
      } else {
        updatedPrevileges.push(`${key}/${targetPrevileges[i]}`)
      }
    }
    setAdmin({
      ...admin,
      previleges: updatedPrevileges
    })
  }

  const handleSave = async () => {
    if (!admin.username) {
      showAlert('아이디를 입력하세요')
      return
    }

    if (!admin.password || !admin.repassword) {
      showAlert('비밀번호를 입력하세요')
      return
    }

    if (admin.password !== admin.repassword) {
      showAlert('비밀번호가 일치하지 않습니다')
      return
    }
    setIsLoading(true)
    try {
      let success, message;

      ({ success, message } = await checkPrevilege("admin/create"));

      if (!success) throw new Error(message);

      ({ success, message } = await createAdmin(admin));

      if (!success) throw new Error(message);

      showSuccess('저장되었습니다!');
      refresh()
    } catch (error) {
      console.error('Failed to update user admin:', error)
      showAlert(error.message)
    }

    setIsLoading(false)
  }

  return (
    <>
      <Navbar
        style={{ backgroundColor: colors.primary, padding: '1rem' }}
        expand='md'
      >
        <h5 className='m-0'>{'관리자 생성'}</h5>{' '}
        {/* Use the label based on the active tab */}
      </Navbar>

      <Container className='page-content' style={{ paddingBottom: 100 }}>
        {isLoading && <Loader />}
        <Table bordered style={{ marginTop: '2rem' }}>
          <tbody className='align-items-center'>
            <tr>
              <td
                className='form-header text-center align-middle w-200p'
                xs={3}
              >
                아이디
              </td>
              <td colSpan={3}>
                <Input
                  type='text'
                  className='form-control-sm input-sm'
                  placeholder='아이디'
                  name='username'
                  value={admin?.username}
                  onChange={handleInputChange}
                />
              </td>
            </tr>
            <tr>
              <td className='form-header text-center align-middle' xs={3}>
                비밀번호
              </td>
              <td xs={9}>
                <div className='d-flex align-items-center'>
                  <Input
                    type='text'
                    className='form-control-sm input-sm'
                    placeholder='비밀번호'
                    name='password'
                    value={admin?.password}
                    onChange={handleInputChange}
                  />
                </div>
              </td>

              <td className='form-header text-center align-middle' xs={3}>
                비밀번호 확인
              </td>
              <td xs={9}>
                <div className='d-flex align-items-center'>
                  <Input
                    type='text'
                    className='form-control-sm input-sm'
                    placeholder='비밀번호 확인'
                    name='repassword'
                    value={admin?.repassword}
                    onChange={handleInputChange}
                  />
                </div>
              </td>
            </tr>

            <tr>
              <td className='form-header text-center align-middle' xs={3}>
                관리자 권한
              </td>
              <td colSpan={3}>
                {
                  Object.keys(previleges).map((key) => {
                    return (
                      <tr key={key}>
                        <td className='form-header text-center align-middle' style={{ width: 100, paddingTop: 10, paddingBottom: 10 }}>
                          {previlegeNames[key]}
                        </td>
                        <td className='align-middle' colSpan={3}>
                          <div style={{ display: 'flex', flexWrap: 'wrap' }}>

                            <Label key={`${key}`} className="d-flex align-items-middle me-2">
                              <Input
                                type='checkbox'
                                style={{ width: 20, height: 20, margin: 0, marginRight: 5 }}
                                // checked={!!hasPrevilege}
                                onChange={(e) => handleCheckboxAll(e, key)}
                              />{' '}
                              전체 선택
                            </Label>
                            {
                              previleges[key].map(p => {
                                let hasPrevilege = admin?.previleges.find(previlege => {
                                  return previlege === `${key}/${p}`
                                })
                                return (
                                  <Label key={`${key}/${p}`} className="d-flex align-items-middle me-2">
                                    <Input
                                      type='checkbox'
                                      style={{ width: 20, height: 20, margin: 0, marginRight: 5 }}
                                      checked={!!hasPrevilege}
                                      onChange={() => handleCheckboxChange(key, p)}
                                    />{' '}
                                    {previlegeNames[`${key}/${p}`]}
                                  </Label>
                                )
                              })
                            }
                          </div>
                        </td>
                      </tr>
                    )
                  })
                }

              </td>
            </tr>

            <tr>
              <td className='form-header text-center align-middle' xs={3}>
                메모
              </td>
              <td colSpan={3}>
                <div className='d-flex p-2'>
                  <Input
                    style={{ height: 200, resize: 'none' }}
                    type='textarea'
                    rows={4}
                    name="memo"
                    value={admin?.memo ?? ''}
                    onChange={handleInputChange}
                    placeholder='메모를 입력하세요'
                    className='my-textarea input-sm'
                  />
                </div>
              </td>
            </tr>
          </tbody>
        </Table>

        {/* Action bar */}
        <div className={`action-bar-container show`}>
          <div className='action-bar'>
            <div className='left-actions'></div>
            <div className='right-actions'>
              {/* Right-aligned action buttons */}
              <Button className='me-2' color='primary' onClick={handleSave}>
                저장
              </Button>
            </div>
          </div>
        </div>
      </Container>
    </>
  )
}

export default AdminCreate
