import React, { useState, useEffect } from "react";
import { Container, Table, Label, Input, Button, Navbar } from "reactstrap";
import { showAlert, showSuccess } from "utils/toast";
import { Loader } from "components";
import { createPopup } from "api/popupApi";
import colors from "assets/colors";
import { uploadImage } from "api/uploadApi";
import { DatePicker } from "reactstrap-date-picker";
import { getPublicUrl } from "libs/functions";
import { MdClose } from "react-icons/md";

const PUBLIC_URL = getPublicUrl();

function PopupCreate(props) {
  const id = props?.targetId
  const [isLoading, setIsLoading] = useState(false);
  const [error, setError] = useState(null);

  const [editorKey, setEditorKey] = useState(Date.now());
  const [popup, setPopup] = useState({
    site: id ?? '',
    name: "",
    image: "",
    mobileImage: "",
    active: true,
    link: "",
    showAt: null,
    hideAt: null,
    position: 0,
  });

  useEffect(() => {}, []);

  const refresh = () => {
    setPopup({
      site: id ?? '',
      name: "",
      image: "",
      mobileImage: "",
      active: true,
      link: "",
      showAt: null,
      hideAt: null,
      position: 0,
    });
    setEditorKey(Date.now());
  };

  const handleInputChange = (event) => {
    const { name, value, type } = event.target;

    let updatedValue = value;

    // Check if the input type is a radio button
    if (type === "radio") {
      if (value === "true") {
        updatedValue = true; // Set to true if value is 'true'
      } else if (value === "false") {
        updatedValue = false; // Set to false if value is 'false'
      }
    }

    if (type === "number") {
      updatedValue = Number(value);
    }

    const updatedPopup = { ...popup };
    const nameParts = name.split(".");
    let currentPopup = updatedPopup;
    for (let i = 0; i < nameParts.length - 1; i++) {
      if (!currentPopup[nameParts[i]]) {
        currentPopup[nameParts[i]] = {};
      }
      currentPopup = currentPopup[nameParts[i]];
    }
    currentPopup[nameParts[nameParts.length - 1]] = updatedValue;
    setPopup(updatedPopup);
  };

  const handleSave = async () => {
    if (!popup.image) {
      showAlert("PC이미지를 선택해주세요");
      return;
    }

    if (!popup.mobileImage) {
      showAlert("모바일 이미지를 선택해주세요");
      return;
    }

    setIsLoading(true);
    try {
      const { success, message } = await createPopup(popup);

      if (!success) throw new Error(message);

      showSuccess("저장되었습니다!");
      refresh();
    } catch (error) {
      console.error("Failed to update user popup:", error);
      showAlert(error.message);
    }

    setIsLoading(false);
  };

  const handleFileSelect = async (event, target) => {
    const file = event.target.files[0];

    const result = await uploadImage(file);

    setPopup((prevPopup) => ({
      ...prevPopup,
      [target]: `${result.link}`,
    }));
  };

  const handleDateChange = (date, target) => {
    console.log(date);
    let formattedDate;
    if (date) {
      formattedDate = new Date(date).toISOString().split("T")[0];
    }
    setPopup((prevParams) => ({
      ...prevParams,
      [target]: formattedDate,
    }));
  };

  return (
    <>
      <Navbar
        style={{ backgroundColor: colors.primary, padding: "1rem" }}
        expand="md"
      >
        <h5 className="m-0">{"팝업 생성"}</h5>{" "}
        {/* Use the label based on the active tab */}
        <div className="text-right">
          <MdClose
            className="clickable-icon"
            size={18}
            onClick={() => props.onClose && props.onClose()}
          />
        </div>
      </Navbar>

      <Container className="page-content" style={{ paddingBottom: 100 }}>
        {isLoading && <Loader />}
        <Table bordered style={{ marginTop: "2rem" }}>
          <tbody className="align-items-center">
            <tr>
              <td className="form-header text-center w-200p" xs={3}>
                제목
              </td>
              <td xs={9}>
                <Input
                  type="text"
                  className="form-control-sm input-sm"
                  placeholder="제목"
                  name="name"
                  value={popup?.name}
                  onChange={handleInputChange}
                />
              </td>
            </tr>
            <tr>
              <td className="form-header text-center" xs={3}>
                순서
              </td>
              <td xs={9}>
                <div className="d-flex align-items-center">
                  <div className="me-3">
                    <Input
                      type="number"
                      className="form-control-sm input-sm"
                      placeholder="순서"
                      name="position"
                      value={popup?.position}
                      onChange={handleInputChange}
                    />
                  </div>
                </div>
              </td>
            </tr>

            <tr>
              <td className="form-header text-center" xs={3}>
                PC이미지
              </td>
              <td xs={9}>
                <div className="d-flex align-items-center">
                  <div className="me-3">
                    {popup?.image ? (
                      <img
                        src={
                          typeof popup.image === "string"
                            ? `${PUBLIC_URL}/${popup.image}`
                            : URL.createObjectURL(popup.image)
                        }
                        width={30}
                        alt="Icon"
                        className="img-fluid me-3"
                      />
                    ) : (
                      "이미지 없음"
                    )}
                    <div style={{ padding: 5 }} />
                    <input
                      type="file"
                      accept="image/*"
                      onChange={(e) => handleFileSelect(e, "image")}
                    />
                  </div>
                </div>
              </td>
            </tr>

            <tr>
              <td className="form-header text-center" xs={3}>
                모바일 이미지
              </td>
              <td xs={9}>
                <div className="d-flex align-items-center">
                  <div className="me-3">
                    {popup?.mobileImage ? (
                      <img
                        src={
                          typeof popup.mobileImage === "string"
                            ? `${PUBLIC_URL}/${popup.mobileImage}`
                            : URL.createObjectURL(popup.mobileImage)
                        }
                        width={30}
                        alt="Icon"
                        className="img-fluid me-3"
                      />
                    ) : (
                      "이미지 없음"
                    )}
                    <div style={{ padding: 5 }} />
                    <input
                      type="file"
                      accept="image/*"
                      onChange={(e) => handleFileSelect(e, "mobileImage")}
                    />
                  </div>
                </div>
              </td>
            </tr>

            <tr>
              <td className="form-header text-center" xs={3}>
                활성여부
              </td>
              <td xs={9}>
                <Label
                  className="mb-0 mr-2"
                  style={{ marginRight: "1rem", padding: 0 }}
                >
                  <Input
                    type="radio"
                    name="active"
                    value={false}
                    checked={!popup?.active}
                    onChange={handleInputChange}
                  />{" "}
                  비활성
                </Label>
                <Label
                  className="mb-0 mr-2"
                  style={{ marginRight: "3rem", padding: 0 }}
                >
                  <Input
                    type="radio"
                    name="active"
                    value={true}
                    checked={popup?.active}
                    onChange={handleInputChange}
                  />{" "}
                  활성
                </Label>
              </td>
            </tr>

            <tr>
              <td className="form-header text-center" xs={3}>
                링크
              </td>
              <td xs={9}>
                <Input
                  type="text"
                  className="form-control-sm input-sm"
                  placeholder="링크"
                  name="link"
                  value={popup?.link}
                  onChange={handleInputChange}
                />
              </td>
            </tr>

            <tr>
              <td className="form-header text-center" xs={3}>
                게시시작일
              </td>
              <td xs={9}>
                <div className="d-flex align-items-center">
                  <div className="me-3">
                    <DatePicker
                      value={popup.showAt}
                      onChange={(date) => handleDateChange(date, "showAt")}
                      className="form-control form-control-sm"
                      placeholder="게시시작일"
                      showClearButton={true}
                      dateFormat="YYYY-MM-DD"
                    />
                  </div>
                </div>
              </td>
            </tr>

            <tr>
              <td className="form-header text-center" xs={3}>
                게시종료일
              </td>
              <td xs={9}>
                <div className="d-flex align-items-center">
                  <div className="me-3">
                    <DatePicker
                      value={popup.hideAt}
                      onChange={(date) => handleDateChange(date, "hideAt")}
                      className="form-control form-control-sm"
                      placeholder="게시종료일"
                      showClearButton={true}
                      dateFormat="YYYY-MM-DD"
                    />
                  </div>
                </div>
              </td>
            </tr>
          </tbody>
        </Table>

        {/* Action bar */}
        <div className={`action-bar-container show`}>
          <div className="action-bar">
            <div className="left-actions"></div>
            <div className="right-actions">
              {/* Right-aligned action buttons */}
              <Button
                className="me-2"
                color="warning"
                onClick={() => {
                  if (typeof props.onClose === "function") props.onClose();
                }}
              >
                닫기
              </Button>
              <Button className="me-2" color="primary" onClick={handleSave}>
                저장
              </Button>
            </div>
          </div>
        </div>
      </Container>
    </>
  );
}

export default PopupCreate;
