import api from './index.js'

export const updateProfile = async (userData) => {
  try {
    const response = await api.put(`/admin/profile`, userData)

    if (response.status === 200) {
      const data = response.data
      return data
    } else {
      throw new Error('Failed to update user profile')
    }
  } catch (error) {
    throw new Error('Failed to update user profile')
  }
}
