// AuthContext.js
import React, { useEffect } from 'react'
import { createContext, useContext } from 'react'
import { useNavigate, useLocation, redirect } from 'react-router-dom'
import { useDispatch, useSelector } from 'react-redux'
import { loginThunk, logoutThunk, checkAuthThunk, autoLoginThunk } from 'thunks/authThunks'
import { isAuthSelector, userSelector } from 'features/auth/authSelectors'
import { isConnectedSelector } from 'features/socket/socketSelectors'
import { connectSocket, disconnectSocket } from 'thunks/socketThunks'
import { configThunk } from 'thunks/configThunks'
import { statusThunk } from 'thunks/statusThunks'
import { toast } from 'react-toastify'
import { autoLogin } from 'api/authApi'

const AuthContext = createContext()

export const useAuth = () => {
  return useContext(AuthContext)
}

export const AuthProvider = ({ children }) => {
  const dispatch = useDispatch()
  const navigate = useNavigate()
  const location = useLocation()
  const isAuthenticated = useSelector(isAuthSelector)
  const isConnected = useSelector(isConnectedSelector)
  const currentUser = useSelector(userSelector)
  const { isRoot, isHeadQuarter, isPartner, isAdmin } = currentUser

  useEffect(() => {    
    // const isMemberDetail = /^\/member\/\w+$/.test(location.pathname)
    // const isGradeDetail = /^\/grade\/\w+$/.test(location.pathname)
    // const isMain = /^\/[a-zA-Z-]+(\/[a-zA-Z-]+)?$/.test(location.pathname)

    if(isPartner || isAdmin || isHeadQuarter || isRoot) {
      if (isAuthenticated) {
        dispatch(connectSocket())      
        return () => {
          dispatch(disconnectSocket())
        }
      }
    }

  }, [isAuthenticated])

  const handleLogin = async credentials => {
    try {
      await dispatch(loginThunk(credentials))
      dispatch(connectSocket());
      navigate('/')
    } catch (error) {
      console.error('Login failed:', error)
      toast.dismiss()
      navigate('/login', { state: { from: location } })
    }
  }

  const handleLogout = async () => {
    try {
      await dispatch(logoutThunk())
      toast.dismiss()
      navigate('/login', { state: { from: location } })
    } catch (error) {
      console.error('Logout failed:', error)
    }
  }

  const handleAutoLogin = async (session) => {
    try {
      await dispatch(autoLoginThunk(session))
      navigate('/')
    } catch (error) {
      console.error('Logout failed:', error)
    }
  }
  
  const checkAuthentication = async () => {
    try {
      await dispatch(checkAuthThunk());
    } catch (error) {
      console.error('Authentication check failed:', error);
      navigate('/login');
    }
  }

  useEffect(() => {
    // checkAuthentication()
  }, [])

  const value = {
    onLogin: handleLogin,
    onLogout: handleLogout,
    checkAuth: checkAuthentication,
    handleAutoLogin: handleAutoLogin,
  }

  return <AuthContext.Provider value={value}>{children}</AuthContext.Provider>
}
