export const statusRequest = () => ({
    type: 'STATUS_REQUEST'
  })
  
  export const statusSuccess = status => ({
    type: 'STATUS_SUCCESS',
    payload: status
  })
  
  export const statusFailure = error => ({
    type: 'STATUS_FAILURE',
    payload: error
  })