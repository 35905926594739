import React, { useState, useEffect, useRef } from "react";
import { Container, Table, Input, Button, Navbar, Label } from "reactstrap";
import { Editor } from "components";
import { showAlert, showSuccess } from "utils/toast";
import dayjs from "dayjs";
import { Loader } from "components";
import { fetchFaq, updateFaq } from "api/faqApi";
import colors from "assets/colors";
import { useParams } from "react-router-dom";
import { MdClose } from "react-icons/md";

function FaqEdit(props) {
  const editorRef = useRef();
  const id = props?.targetId ?? null;
  const [isLoading, setIsLoading] = useState(false);
  const [error, setError] = useState(null);

  const [editorKey, setEditorKey] = useState(Date.now());
  const [faq, setFaq] = useState({
    name: "",
    content: "",
    active: false,
  });

  useEffect(() => {}, []);

  const fetchData = async () => {
    setIsLoading(true);
    try {
      const { success, data, message } = await fetchFaq(id);

      if (!success) throw new Error(message);
      if (data) {
        setFaq(data);
      }
    } catch (error) {
      console.error(error);
      setError(error);
    } finally {
      setIsLoading(false);
    }
  };

  useEffect(() => {
    fetchData();
    setEditorKey(Date.now());
  }, []);

  const refresh = () => {
    fetchData();
    setEditorKey(Date.now());
  };

  const handleInputChange = (event) => {
    const { name, value, type } = event.target;

    let updatedValue = value;

    // Check if the input type is a radio button
    if (type === "radio") {
      if (value === "true") {
        updatedValue = true; // Set to true if value is 'true'
      } else if (value === "false") {
        updatedValue = false; // Set to false if value is 'false'
      }
    }

    if (type === "number") {
      updatedValue = Number(value);
    }

    const updatedFaq = { ...faq };
    const nameParts = name.split(".");
    let currentFaq = updatedFaq;
    for (let i = 0; i < nameParts.length - 1; i++) {
      if (!currentFaq[nameParts[i]]) {
        currentFaq[nameParts[i]] = {};
      }
      currentFaq = currentFaq[nameParts[i]];
    }
    currentFaq[nameParts[nameParts.length - 1]] = updatedValue;
    setFaq(updatedFaq);
  };

  const handleSave = async () => {
    setIsLoading(true);

    try {
      const content = editorRef?.current?.getContent();

      const { success, message } = await updateFaq(faq._id, {
        ...faq,
        content: content,
      });

      if (!success) throw new Error(message);

      showSuccess("수정되었습니다!");
      refresh();
    } catch (error) {
      console.error("Failed to update faq:", error);
      showAlert(error.message);
    }

    setIsLoading(false);
  };

  return (
    <>
      <Navbar
        style={{ backgroundColor: colors.primary, padding: "1rem" }}
        expand="md"
      >
        <h5 className="m-0">
          {"FAQ 수정: "}
          {faq.name}{" "}
        </h5>{" "}
        <div className="text-right">
          <MdClose
            className="clickable-icon"
            size={18}
            onClick={() => props.onClose && props.onClose()}
          />
        </div>
        {/* Use the label based on the active tab */}
      </Navbar>

      <Container className="page-content" style={{ paddingBottom: 100 }}>
        {isLoading ? (
          <Loader />
        ) : (
          <Table bordered style={{ marginTop: "2rem" }}>
            <tbody className="align-items-center">
              <tr>
                <td className="form-header text-center align-middle w-200p">
                  제목
                </td>
                <td>
                  <Input
                    type="text"
                    className="form-control-sm input-sm"
                    placeholder="제목"
                    name="name"
                    value={faq?.name}
                    onChange={handleInputChange}
                  />
                </td>
              </tr>
              <tr>
                <td className="form-header text-center w-200p" xs={3}>
                  활성여부
                </td>
                <td xs={9}>
                  <Label
                    className="mb-0 mr-2"
                    style={{ marginRight: "1rem", padding: 0 }}
                  >
                    <Input
                      type="radio"
                      name="active"
                      value={false}
                      checked={!faq?.active}
                      onChange={handleInputChange}
                    />{" "}
                    비활성
                  </Label>
                  <Label
                    className="mb-0 mr-2"
                    style={{ marginRight: "3rem", padding: 0 }}
                  >
                    <Input
                      type="radio"
                      name="active"
                      value={true}
                      checked={faq?.active}
                      onChange={handleInputChange}
                    />{" "}
                    활성
                  </Label>
                </td>
              </tr>
              <tr>
                <td className="form-header text-center align-middle">내용</td>
                <td>
                  <Editor
                    ref={editorRef}
                    key={editorKey}
                    value={faq?.content ?? ""}
                    onContentChange={(value) =>
                      handleInputChange({
                        target: { name: "content", value: value },
                      })
                    }
                  />
                </td>
              </tr>
            </tbody>
          </Table>
        )}

        {/* Action bar */}
        <div className={`action-bar-container show`}>
          <div className="action-bar">
            <div className="left-actions"></div>
            <div className="right-actions">
              {/* Right-aligned action buttons */}
              <Button
                className="me-2"
                color="warning"
                onClick={() => {
                  if (typeof props.onClose === "function") props.onClose();
                }}
              >
                닫기
              </Button>
              <Button className="me-2" color="primary" onClick={handleSave}>
                저장
              </Button>
            </div>
          </div>
        </div>
      </Container>
    </>
  );
}

export default FaqEdit;
