import React, { lazy, useState, Suspense, useEffect } from 'react'
import './Development.css'
import { Layout, UnderConstruction } from 'components'
import {
  Nav,
  NavItem,
  NavLink,
  TabContent,
  TabPane,
  Row,
  Container
} from 'reactstrap'
import { useParams, useLocation, useNavigate } from 'react-router-dom'

const BatchList = lazy(() => import('./BatchList'))
const GameSessionList = lazy(() => import('./GameSessionList'))
const ApiKeyList = lazy(() => import('../ApiKey/ApiKeyList'))

function Development (props) {
  const { search, pathname } = useLocation()
  const navigate = useNavigate()
  const [activeTab, setActiveTab] = useState('general')

  const toggleTab = tab => {
    if (activeTab !== tab) {
      setActiveTab(tab)
      navigate(`${pathname}?tab=${tab}`)
    }
  }

  useEffect(() => {
    const tabParam = new URLSearchParams(search).get('tab')
    const initialTab = tabParam || 'batch'
    setActiveTab(initialTab)
  }, [])

  return (
    <Layout>
      <Container className='page-content' fluid style={{ padding: 30 }}>
        <Row>
          <Nav tabs>
            {[
              { tab: 'batch', label: '배치' },
              { tab: 'game', label: '게임세션' },
              { tab: 'apiKey', label: 'API키' },
            ].map(({ tab, label }) => (
              <NavItem key={tab}>
                <NavLink
                  className={activeTab === tab ? 'sub-link active' : ''}
                  onClick={() => toggleTab(tab)}
                >
                  {label}
                </NavLink>
              </NavItem>
            ))}
          </Nav>
        </Row>
        <Row>
          <TabContent activeTab={activeTab}>
            {[
              { tab: 'batch', component: <BatchList /> },
              { tab: 'game', component: <GameSessionList /> },
              { tab: 'apiKey', component: <ApiKeyList /> },
            ].map(({ tab, component }) => (
              <TabPane key={tab} tabId={tab}>
                {activeTab === tab && ( // Only render the component when the activeTab matches the current tab
                  <Suspense fallback={<div>Loading...</div>}>
                    {component}
                  </Suspense>
                )}
              </TabPane>
            ))}
          </TabContent>
        </Row>
      </Container>
    </Layout>
  )
}

export default Development
