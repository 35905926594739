import {
  fetchUsersRequest,
  fetchUsersSuccess,
  fetchUsersFailure
} from '../features/user/userActions'

export const updateUser = (id, data) => {
  return () => Promise.resolve() // returns a resolved Promise
}

export const deleteUser = id => {
  return () => {} // returns an empty function
}

export const fetchUsers = (
  pageNumber = 1,
  pageSize = 10,
  query,
  sortColumn,
  sortDirection
) => {
  return async dispatch => {
    dispatch(fetchUsersRequest())
    try {
      const response = await fetch(
        `http://localhost:4000/admin/user?pageNumber=${pageNumber}&pageSize=${pageSize}&query=${query}&sortColumn=${sortColumn}&sortDirection=${sortDirection}`,
        {
          credentials: 'include'
        }
      )
      if (response.ok) {
        const result = await response.json()
        const { meta, params, users } = result.data
        dispatch(fetchUsersSuccess(users, meta, params))
      } else {
        throw new Error('Failed to fetch users')
      }
    } catch (error) {
      dispatch(fetchUsersFailure(error.message))
    }
  }
}

// export const updateUser = (id, data) => {
//   return (dispatch) => {
//     dispatch(updateUserRequest(id));
//     fetch(`/api/users/${id}`, {
//       method: 'PUT',
//       headers: { 'Content-Type': 'application/json' },
//       body: JSON.stringify(data)
//     })
//       .then(response => response.json())
//       .then(user => dispatch(updateUserSuccess(user)))
//       .catch(error => dispatch(updateUserFailure(error)));
//   };
// };

// export const deleteUser = (id) => {
//   return (dispatch) => {
//     dispatch(deleteUserRequest(id));
//     fetch(`/api/users/${id}`, {
//       method: 'DELETE'
//     })
//       .then(() => dispatch(deleteUserSuccess(id)))
//       .catch(error => dispatch(deleteUserFailure(error)));
//   };
// };
