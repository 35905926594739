import React, { useState, useEffect } from 'react'
import {
    Container,
    Table,
    Label,
    Input,
    Button,
    Navbar
} from 'reactstrap'
import { showAlert, showSuccess } from 'utils/toast'
import { Loader } from 'components'
import { createApiGame } from 'api/apiGameApi'
import colors from 'assets/colors'
import { uploadImage } from 'api/uploadApi'
import { DatePicker } from 'reactstrap-date-picker'
import { useParams } from 'react-router-dom'
import Zoom from 'react-medium-image-zoom'
import 'react-medium-image-zoom/dist/styles.css'
import { getPublicUrl } from 'libs/functions'

const PUBLIC_URL = getPublicUrl()

function ApiGameCreate(props) {
    const id = props?.targetId ?? null
    const [isLoading, setIsLoading] = useState(false)
    const [error, setError] = useState(null)

    const [api, setApi] = useState({
        name: '',
        gameId: '',
        image: '',
        active: false,
    })

    useEffect(() => { }, [])

    console.log(api)
    const refresh = () => {
        setApi({
            name: '',
            gameId: '',
            image: '',
            active: false,
        })
    }

    const handleInputChange = event => {
        const { name, value, type } = event.target

        let updatedValue = value

        // Check if the input type is a radio button
        if (type === 'radio') {
            if (value === 'true') {
                updatedValue = true // Set to true if value is 'true'
            } else if (value === 'false') {
                updatedValue = false // Set to false if value is 'false'
            }
        }

        if (type === 'number') {
            updatedValue = Number(value)
        }

        const updatedBanner = { ...api }
        const nameParts = name.split('.')
        let currentBanner = updatedBanner
        for (let i = 0; i < nameParts.length - 1; i++) {
            if (!currentBanner[nameParts[i]]) {
                currentBanner[nameParts[i]] = {}
            }
            currentBanner = currentBanner[nameParts[i]]
        }
        currentBanner[nameParts[nameParts.length - 1]] = updatedValue
        setApi(updatedBanner)
    }

    const handleSave = async () => {
        if(!api.name) {
            return showAlert('name을 입력하세요')
        }

        if(!api.gameId) {
            return showAlert('gameId를 입력하세요')
        }

        setIsLoading(true)
        try {
            const { success, message } = await createApiGame(id, api)

            if (!success) throw new Error(message)

            showSuccess('생성되었습니다!')
            refresh()
        } catch (error) {
            console.error('Failed to create api-game:', error)
            showAlert(error.message)
        }

        setIsLoading(false)
    }

    const handleFileSelect = async (event, target) => {
        const file = event.target.files[0]

        const result = await uploadImage(file)

        setApi(prevBanner => ({
            ...prevBanner,
            [target]: `${result.link}`
        }))
    }

    return (
        <>
            <Navbar
                style={{ backgroundColor: colors.primary, padding: '1rem' }}
                expand='md'
            >
                <h5 className='m-0'>
                    {'API게임 추가'}
                </h5>{' '}
                {/* Use the label based on the active tab */}
            </Navbar>

            <Container className='page-content' style={{ paddingBottom: 100 }}>
                {isLoading && <Loader />}
                <Table bordered style={{ marginTop: '2rem' }}>
                    <tbody className='align-items-center'>
                        <tr>
                            <td className='form-header text-center align-middle w-200p'>
                                이름
                            </td>
                            <td>
                                <Input
                                    type='text'
                                    className='form-control-sm input-sm'
                                    placeholder='제목'
                                    name='name'
                                    value={api?.name}
                                    onChange={handleInputChange}
                                />
                            </td>
                        </tr>
                        <tr>
                            <td className='form-header text-center align-middle w-200p'>
                                gameId
                            </td>
                            <td>
                                <Input
                                    type='text'
                                    className='form-control-sm input-sm'
                                    placeholder='gameId'
                                    name='gameId'
                                    value={api?.gameId}
                                    onChange={handleInputChange}
                                />
                            </td>
                        </tr>

                        <tr>
                            <td className='form-header text-center align-middle'>
                                이미지
                            </td>
                            <td>
                                <div className='d-flex align-items-center'>
                                    <div className='me-3'>
                                        {api?.image ? (
                                            <Zoom>
                                                <img
                                                    src={`${PUBLIC_URL}/${api?.image}`}
                                                    alt='이미지'
                                                    width={50}
                                                    className='img-fluid'
                                                />
                                            </Zoom>
                                        ) : (
                                            '이미지 없음'
                                        )}
                                        <div style={{ padding: 5 }} />
                                        <input
                                            type='file'
                                            accept='image/*'
                                            onChange={e => handleFileSelect(e, 'image')}
                                        />
                                    </div>
                                </div>
                            </td>
                        </tr>

                        <tr>
                            <td className='form-header text-center align-middle'>
                                활성여부
                            </td>
                            <td>
                                <Label
                                    className='mb-0 mr-2'
                                    style={{ marginRight: '1rem', padding: 0 }}
                                >
                                    <Input
                                        type='radio'
                                        name='active'
                                        value={false}
                                        checked={!api?.active}
                                        onChange={handleInputChange}
                                    />{' '}
                                    비활성
                                </Label>
                                <Label
                                    className='mb-0 mr-2'
                                    style={{ marginRight: '3rem', padding: 0 }}
                                >
                                    <Input
                                        type='radio'
                                        name='active'
                                        value={true}
                                        checked={api?.active}
                                        onChange={handleInputChange}
                                    />{' '}
                                    활성
                                </Label>
                            </td>
                        </tr>

                    </tbody>
                </Table>

                {/* Action bar */}
                <div className={`action-bar-container show`}>
                    <div className='action-bar'>
                        <div className='left-actions'>
                        </div>
                        <div className='right-actions'>
                            {/* Right-aligned action buttons */}
                            <Button className='me-2' color='primary' onClick={handleSave}>
                                저장
                            </Button>
                        </div>
                    </div>
                </div>
            </Container>
        </>
    )
}

export default ApiGameCreate
